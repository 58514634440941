import React, {useEffect, useState} from 'react';
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {FormDto} from "../../../../../modules/workflow/dto/FormDto";
import {FormApi} from "../../../../../modules/workflow/api/FormApi";
import {MenuItem, Select} from "@mui/material";

export type FormListSelectProps = {
    value: string;
    onChange: (value: string) => void;
};

const FormListSelect: React.FC<FormListSelectProps> = (props) => {
    const [forms, setForms] = useState<Array<FormDto>>([]);

    useEffect(() => {
        fetchFormList();
    }, []);

    const fetchFormList = async () => {
        const responseResult = await FormApi.getFormList();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setForms(responseResult.data);
        }
    };

    const handleChange = (value: string) => {
        props.onChange(value);
    }

    return (
        <Select
            fullWidth
            value={props.value}
            onChange={(e) => handleChange(e.target.value)}>
            {
                forms.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>
                        {item.name}
                    </MenuItem>
                })
            }
        </Select>
    );
};

export default FormListSelect;
