import React from 'react';
import {IconButton} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

type RefreshIconButtonProps = {
    onClick: () => void;
};

const RefreshIconButton: React.FC<RefreshIconButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <IconButton onClick={(e) => handleClick(e)}>
            <RefreshIcon/>
        </IconButton>
    );
};
export default RefreshIconButton;
