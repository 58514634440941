export default {
    VMS_SITE_LIST: '站点列表',
    VMS_SITE_CREATE: "新建站点",
    VMS_SITE_CREATE_SUCCESS: "新站点创建成功",
    VMS_SITE_MODIFY: "修改站点",
    VMS_SITE_MODIFY_SUCCESS: "站点修改成功",
    VMS_SITE_CODE: '编号',
    VMS_SITE_CODE_REQUIRED: '请输入编号',
    VMS_SITE_NAME: '名称',
    VMS_SITE_NAME_REQUIRED: '请输入名称',
    VMS_SITE_SEQUENCE: '排序',
    VMS_SITE_MSG_HAVE_CHILDREN: '站点存在位置，请先删除位置！',

    VMS_LOCATION_LIST: '位置列表',
    VMS_LOCATION_CREATE: "新建位置",
    VMS_LOCATION_CREATE_SUCCESS: "新位置创建成功",
    VMS_LOCATION_MODIFY: "修改位置",
    VMS_LOCATION_MODIFY_SUCCESS: "位置修改成功",
    VMS_LOCATION_SITE: '所属站点',
    VMS_LOCATION_SITE_REQUIRED: '请选择位置',
    VMS_LOCATION_CODE: '编号',
    VMS_LOCATION_CODE_REQUIRED: '请输入编号',
    VMS_LOCATION_NAME: '名称',
    VMS_LOCATION_NAME_REQUIRED: '请输入名称',
    VMS_LOCATION_SEQUENCE: '排序',
    VMS_LOCATION_MSG_HAVE_CHILDREN: '位置存在场所，请先删除场所！',

    VMS_AREA_LIST: '场所列表',
    VMS_AREA_CREATE: "新建场所",
    VMS_AREA_CREATE_SUCCESS: "新场所创建成功",
    VMS_AREA_MODIFY: "修改场所",
    VMS_AREA_MODIFY_SUCCESS: "场所修改成功",
    VMS_AREA_LOCATION: '所属位置',
    VMS_AREA_LOCATION_REQUIRED: '请选择场所',
    VMS_AREA_CODE: '编号',
    VMS_AREA_CODE_REQUIRED: '请输入编号',
    VMS_AREA_NAME: '名称',
    VMS_AREA_NAME_REQUIRED: '请输入名称',
    VMS_AREA_TYPE: '类型',
    VMS_AREA_TYPE_REQUIRED: '请输入类型',
    VMS_AREA_SEQUENCE: '排序',
    VMS_AREA_TYPE_NORMAL: "普通",
    VMS_AREA_TYPE_SPECIAL: "特殊",

    VMS_VISITOR_TYPE: '访客类型',
    VMS_VISITOR_TYPE_CREATE: '添加访客类型',
    VMS_VISITOR_TYPE_CREATE_SUCCESS: '访客类型添加成功',
    VMS_VISITOR_TYPE_MODIFY: '修改访客类型',
    VMS_VISITOR_TYPE_MODIFY_SUCCESS: '访客类型修改成功',
    VMS_VISITOR_TYPE_CODE: '编号',
    VMS_VISITOR_TYPE_CODE_REQUIRED: '请输入编号',
    VMS_VISITOR_TYPE_NAME: '名称',
    VMS_VISITOR_TYPE_NAME_REQUIRED: '请输入名称',
    VMS_VISITOR_TYPE_VALUE: '值',
    VMS_VISITOR_TYPE_VALUE_REQUIRED: '请输入值',
    VMS_VISITOR_TYPE_SEQUENCE: '排序',
    VMS_VISITOR_TYPE_WORKFLOW_FORM: '审批流表单',

    VMS_VISIT_REQUEST_NUMBER: '申请编号',
    VMS_VISIT_REQUEST_APPLY_DATE: '申请日期',
    VMS_VISIT_REQUEST_STATUS: '申请状态',
    VMS_VISIT_REQUEST_STATUS_NEW: '新申请',
    VMS_VISIT_REQUEST_STATUS_APPROVING: '审批中',
    VMS_VISIT_REQUEST_STATUS_APPROVED: '审批通过',
    VMS_VISIT_REQUEST_STATUS_REJECTED: '审批拒绝',
    VMS_VISIT_REQUEST_STATUS_EXPIRED: '审批过期',
    VMS_VISIT_REQUEST_STATUS_COMPLETED: '完成',
    VMS_VISIT_REQUEST_STATUS_CANCELED: '取消',

    VMS_VISIT_REQUEST_GENERAL_INFO: '基本信息',
    VMS_VISIT_REQUEST_VISITOR_INFO: '访客信息',
    VMS_VISIT_REQUEST_HOST_INFO: '联系人信息',
    VMS_VISIT_REQUEST_CONFIRM_INFO: '确认信息',
    VMS_VISIT_REQUEST_REGISTER_COMPLETE: '登记完成',
    VMS_VISIT_REQUEST_PLACE: '访问场所',
    VMS_VISIT_REQUEST_PLACE_SELECT: '选择访问场所',
    VMS_VISIT_REQUEST_PLACES_REQUIRED: '请选择访问场所',
    VMS_VISIT_REQUEST_VISITORS_REQUIRED: '请填写访客信息',
    VMS_VISIT_REQUEST_ARRIVE_DATE: '访问日期',
    VMS_VISIT_REQUEST_ARRIVE_START_DATE: '访问开始日期',
    VMS_VISIT_REQUEST_ARRIVE_END_DATE: '访问结束日期',
    VMS_VISIT_REQUEST_PURPOSE_TYPE: '访问目的',
    VMS_VISIT_REQUEST_EMPLOYEE_ENTITY: '接待者所属公司',

    VMS_VISIT_REQUEST_PURPOSE: '访问理由',
    VMS_VISIT_REQUEST_INVITE_SUCCESS: '访客邀请创建成功',
    VMS_VISIT_REQUEST_REGISTER_SUCCESS: '访客登记成功',

    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE: '申请来源',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_EMPLOYEE: '内部员工邀请',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_TERMINAL: '访客终端机申请',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_MOBILE: '访客移动端申请',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_OFFLINE: '线下数据导入',

    VMS_VISIT_REQUEST_HOST_MOBILE: '接待者手机号',
    VMS_VISIT_REQUEST_HOST_STAFF_NUMBER: '接待者员工编号',
    VMS_VISIT_REQUEST_HOST_STAFF_NUMBER_REQUIRED: '请填写接待者员工编号',
    VMS_VISIT_REQUEST_HOST_MOBILE_REQUIRED: '请填写接待者手机号',
    VMS_VISIT_REQUEST_HOST_EMAIL: '接待者邮箱',
    VMS_VISIT_REQUEST_HOST_EMAIL_REQUIRED: '请填写接待者邮箱',
    VMS_VISIT_REQUEST_HOST_NAME: '接待者姓名',
    VMS_VISIT_REQUEST_HOST_LAST_NAME: '接待者姓氏',
    VMS_VISIT_REQUEST_HOST_LAST_NAME_REQUIRED: '请填写接待者姓氏',
    VMS_VISIT_REQUEST_HOST_FIRST_NAME: '接待者名字',
    VMS_VISIT_REQUEST_HOST_FIRST_NAME_REQUIRED: '请填写接待者名字',
    VMS_VISIT_REQUEST_HOST_LAST_INFO_RELOAD: '接待者信息加载',
    VMS_VISIT_REQUEST_HOST_LAST_INFO_RELOAD_SUCCESS: '接待者信息加载成功',
    VMS_VISIT_REQUEST_HOST_LAST_INFO_RELOAD_CONFIRM: '该接待者存在历史记录，是否需要加载最近一次该接待者的信息?',
    VMS_VISIT_REQUEST_HOST_SUPERVISOR_NAME: '接待者上级姓名',
    VMS_VISIT_REQUEST_HOST_SUPERVISOR_EMAIL: '接待者上级邮箱',
    VMS_VISIT_REQUEST_HOST_DEPARTMENT_NAME: '接待者部门',

    VMS_VISIT_REQUEST_REGISTER_SUBMIT_SUCCESS: '访客登记提交成功',
    VMS_VISIT_REQUEST_REGISTER_ANOTHER_ONE: '登记下一位',
    VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO: '接待者信息',
    VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO_ALERT: '接待者信息填写过一次后，可通过员工号或邮箱进行查询自动填入',
    VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_INFO: '访客信息',
    VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_INFO_ALERT: '访客信息填过一次后，可通过手机号或者邮箱进行查询自动填入',
    VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_VEHICLE: '访客车辆',
    VMS_VISIT_REQUEST_REGISTER_STEP_CONFIRM_INFO: '确认信息',
    VMS_VISIT_REQUEST_REGISTER_STEP_CONSENT_LETTER: '同意书',
    VMS_VISIT_REQUEST_REGISTER_STEP_COMPLETE: '登记完成',
    VMS_VISIT_REQUEST_DETAIL: '访问申请详细',
    VMS_VISIT_REQUEST_PENDING_APPROVAL_LIST: '待审批列表',

    VMS_REQUEST_VISITOR_REGISTER: '访客登记',
    VMS_REQUEST_VISITOR_REGISTER_INFO_FILL: '访客信息补充',

    VMS_REQUEST_VISITOR_REGISTER_DESC: '登记访客的基本信息及联系人基本信息，完成访客登记',
    VMS_REQUEST_VISITOR_CHECKIN_TIME: '签到时间',
    VMS_REQUEST_VISITOR_CHECKOUT: '访客签出',
    VMS_REQUEST_VISITOR_CHECKOUT_TIME: '签出时间',
    VMS_REQUEST_VISITOR_CHECKOUT_DESC: '访客离开时，通过身份证或者卡号，完成签出确认',
    VMS_REQUEST_VISITOR_CHECKOUT_SUCCESS: '访客签出成功',
    VMS_REQUEST_VISITOR_SEARCH: '查询当日访客',
    VMS_REQUEST_VISITOR_SEARCH_PLACEHOLDER: '请输入/扫描访客的证件号码或者门禁卡号',
    VMS_REQUEST_VISITOR_CONFIRM_INFO: '访客信息确认',
    VMS_REQUEST_VISITOR_CHECKOUT_COMPLETE: '签出完成',
    VMS_REQUEST_VISITOR_CHECKOUT_ANOTHER_ONE: '签出下一位',
    VMS_REQUEST_VISITOR_NOT_FOUND: '未查询到访客信息',

    VMS_REQUEST_VISITOR_MANAGEMENT: '访客管理',
    VMS_REQUEST_VISITOR_MANAGEMENT_DESC: '管理访客的名单，显示访客的身份信息和接待者信息',

    VMS_REQUEST_VISITOR_INFO: '访客信息',
    VMS_REQUEST_VISITOR_LIST: '访客列表',
    VMS_REQUEST_VISITOR_DETAIL: '访客详细',
    VMS_REQUEST_VISITOR_CREATE: '创建访客对象',
    VMS_REQUEST_VISITOR_MODIFY: '修改访客信息',
    VMS_REQUEST_VISITOR_GENERAL_INFO: '访客信息',
    VMS_REQUEST_VISITOR_VISIT_REQUEST_INFO: '访问信息',

    VMS_REQUEST_VISITOR_VEHICLE_INFO: '访客车辆信息',
    VMS_REQUEST_VISITOR_ARRIVE_DATE: '访问日期',
    VMS_REQUEST_VISITOR_ARRIVE_START_DATE_REQUIRED: '请选择访问开始日期',
    VMS_REQUEST_VISITOR_ARRIVE_END_DATE_REQUIRED: '请选择访问结束日期',
    VMS_REQUEST_VISITOR_VISIT_CODE: "访问码",
    VMS_REQUEST_VISITOR_NAME: '访客姓名',
    VMS_REQUEST_VISITOR_NAME_REQUIRED: '请填写访客姓名',
    VMS_REQUEST_VISITOR_LAST_NAME: '访客姓',
    VMS_REQUEST_VISITOR_LAST_NAME_REQUIRED: '请填写访客姓氏',
    VMS_REQUEST_VISITOR_FIRST_NAME: '访客名',
    VMS_REQUEST_VISITOR_FIRST_NAME_REQUIRED: '请填写访客名字',
    VMS_REQUEST_VISITOR_MOBILE: '访客手机号',
    VMS_REQUEST_VISITOR_MOBILE_REQUIRED: '请填写访客手机号',
    VMS_REQUEST_VISITOR_EMAIL: '访客邮箱',
    VMS_REQUEST_VISITOR_EMAIL_REQUIRED: '请填写访客邮箱',
    VMS_REQUEST_VISITOR_COMPANY: '访客公司',
    VMS_REQUEST_VISITOR_COMPANY_REQUIRED: '请填写访客公司',
    VMS_REQUEST_VISITOR_ID_TYPE: '证件类型',
    VMS_REQUEST_VISITOR_ID_NUMBER: '证件号码',
    VMS_REQUEST_VISITOR_ID_NUMBER_REQUIRED: '请填写访客证件号码',
    VMS_REQUEST_VISITOR_TYPE: '访客类型',
    VMS_REQUEST_VISITOR_JOB: '职业',
    VMS_REQUEST_VISITOR_DRIVE_TYPE: '驾驶类型',
    VMS_REQUEST_VISITOR_PLATE_NUMBER: '车牌号码',
    VMS_REQUEST_VISITOR_PLATE_NUMBER_COLOR: '车牌颜色',
    VMS_REQUEST_VISITOR_VEHICLE_USE_TYPE: '使用性质',
    VMS_REQUEST_VISITOR_VEHICLE_TYPE: '车辆类型',
    VMS_REQUEST_VISITOR_ACCESS_INFO: '通行信息',
    VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER: '门禁卡号',
    VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER_PLACEHOLDER: '请输入门禁卡号',
    VMS_REQUEST_VISITOR_LAST_INFO_RELOAD: '访客信息加载',
    VMS_REQUEST_VISITOR_LAST_INFO_RELOAD_SUCCESS: '访客信息加载成功',
    VMS_REQUEST_VISITOR_LAST_INFO_RELOAD_CONFIRM: '该访客存在历史记录，是否需要加载最近一次该访客的信息?',
    VMS_REQUEST_VISITOR_EMPLOYEE_EMAIL: "被访者邮箱",

    // 访问场所
    VMS_REQUEST_PLACE_LIST: "访问场所列表",
    VMS_REQUEST_PLACE_AREA_NAME: "场所名",
    VMS_REQUEST_PLACE_AREA_CODE: "场所编号",
    VMS_REQUEST_PLACE_AREA_MANAGER_NAME: "管理员姓名",
    VMS_REQUEST_PLACE_AREA_MANAGER_EMAIL: "管理员邮箱",

    // 访客访问记录
    VMS_VISITOR_ACCESS_LOG_ACCESS_DATE: '通行日期',
    VMS_VISITOR_ACCESS_LOG_ACCESS_TIME: '通行时间',
    VMS_VISITOR_ACCESS_LOG_ACCESS_READER: '通行读卡器',
    VMS_VISITOR_ACCESS_LOG_ACCESS_CARD_NUMBER: '通行卡号',
    VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION: '通行方向',
    VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKIN: '签入',
    VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKOUT: '签出',
    VMS_VISITOR_ACCESS_LOG_LIST: "访客进出记录",
    VMS_VISITOR_ACCESS_LOG_LIST_FETCH: "获取访客进出记录",

    // 访客卡管理
    VMS_CARD_BUTTON_SHOW_FAILED_ONLY: "仅显示失败",
    VMS_CARD_SEND_CARD: "分配通行卡",
    VMS_CARD_IMPORT: "访客卡批量导入",
    VMS_CARD_NUMBER: "访客卡编号",
    VMS_CARD_STATUS: "访客卡状态",
    VMS_CARD_STATUS_AVAILABLE: "可用",
    VMS_CARD_STATUS_BINDING: "使用中",
    VMS_CARD_CLASSIFY: "卡分类",
    VMS_CARD_CLASSIFY_PERSON: "人卡",
    VMS_CARD_CLASSIFY_VEHICLE: "车卡",
    VMS_CARD_TERMINAL: "终端",
    VMS_CARD_NUMBER_DUPLICATE: "卡编号重复",
    VMS_CARD_NUMBER_REQUIRED: "卡编号是必填项",
    VMS_CARD_STATUS_REQUIRED: "卡状态是必填项",
    VMS_CARD_CLASSIFY_REQUIRED: "卡分类是必填项",
    VMS_CARD_AVAILABLE_CARD_NO_FOUND: "未找到有效访客卡",
    VMS_CARD_SEND_CARD_NOTICE: "请分配以下卡号给访客，如需重新获取卡号，请点击刷新按钮",
    VMS_CARD_SEND_CARD_FAILED: "分配卡号失败，请联系管理员确认卡池数据是否正确。",

    // 访客黑名单管理
    VMS_VISITOR_BLACK_LIST_ADD: "添加访客黑名单",
    VMS_VISITOR_BLACK_LIST_ADD_SUCCESS: "添加访客黑名单成功。",
    VMS_VISITOR_BLACK_LIST_MODIFY: "更新访客黑名单",
    VMS_VISITOR_BLACK_LIST_MODIFY_SUCCESS: "更新访客黑名单成功。",
    VMS_VISITOR_BLACK_LIST: "访客黑名单列表",
    VMS_VISITOR_FIRST_NAME: "名字",
    VMS_VISITOR_FIRST_NAME_REQUIRED: "名字是必填项",
    VMS_VISITOR_LAST_NAME: "姓氏",
    VMS_VISITOR_LAST_NAME_REQUIRED: "姓氏是必填项",
    VMS_VISITOR_NAME: "姓名",
    VMS_VISITOR_EMAIL: "邮箱",
    VMS_VISITOR_MOBILE: "手机号",
    VMS_VISITOR_PHOTO: "照片",
    VMS_VISITOR_COMPANY: "公司",
    VMS_VISITOR_ID_TYPE: "证件类型",
    VMS_VISITOR_ID_NUMBER: "证件号码",
    VMS_VISITOR_ID_NUMBER_REQUIRED: '请填写访客证件号码',
    VMS_VISITOR_BLACK_LIST_REGISTERED_WARNING: '该访客登记在黑名单中，确认要继续登记吗？',

    // v自助终端管理
    VMS_TERMINAL_DEVICE_LIST: "设备列表",
    VMS_TERMINAL_CODE: "编号",
    VMS_TERMINAL_NAME: "名称",
    VMS_TERMINAL_CODE_REQUIRED: "编号是必填项",
    VMS_TERMINAL_CODE_DUPLICATE: "编号重复",

    VMS_VISITOR_BTN_CHECKOUT: "签出",
    VMS_VISITOR_MSG_CHECKOUT_CONFIRM: "访客签出，请确认",

};
