import dayjs from 'dayjs';
import { RequestPlaceDto } from './RequestPlaceDto';
import {Formats} from "../../../constants/Formats";
import {RequestVisitorDto} from "./RequestVisitorDto";

export class VisitRequestDto {
  id: string = '';
  number: string = '';
  applyDate: string = '';
  arriveStartDate: string = dayjs().format(Formats.DATE_SIMPLE);
  arriveEndDate: string = dayjs().format(Formats.DATE_SIMPLE);
  arriveStartTime: string = '';
  arriveEndTime: string = '';
  purposeType: string = '01';
  purpose: string = '';
  status: string = '';
  applySourceType: string = '';
  hostUserId: string = '';
  hostFullName: string = '';
  hostLastName: string = '';
  hostFirstName: string = '';
  hostEmail: string = '';
  hostMobile: string = '';
  hostSupervisorId: string = '';
  hostSupervisorName: string = '';
  hostSupervisorEmail: string = '';
  hostDepartmentId: string = '';
  hostDepartmentName: string = '';
  groupFlag: string = '';
  groupVisitCode: string = '';
  workflowApplyFormId: string = '';
  visitors: Array<RequestVisitorDto> = [];
  places: Array<RequestPlaceDto> = [];

  hostStaffNumber: string = '';
  hostCompanyId: string = '';
  hostCompanyName: string = '';
}
