import React, {useState} from 'react';
import {DictTypeApi} from "../../../../modules/dict/api/DictTypeApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {DictTypeDto} from "../../../../modules/dict/dto/DictTypeDto";
import {useIntl} from "react-intl";
import {
    Card,
    CardContent,
    CardHeader,
    ListItem, ListItemIcon, ListItemText,
} from "@mui/material";
import AddIconButton from "../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../components/buttons/RefreshIconButton";
import {MessageUtils} from "../../../../utils/MessageUtils";
import ModifyIconButton from "../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../components/buttons/DeleteIconButton";
import LanIcon from '@mui/icons-material/Lan';
import DictTypeDetailModal from "./DictTypeDetailModal";
import {useConfirm} from "material-ui-confirm";

type DictTypeListProps = {
    onSelect: (value: DictTypeDto) => void;
    onRefresh: () => void;
    dictTypeList: Array<DictTypeDto>;
    selectedDictTypeId?: string;
};

const DictTypeList: React.FC<DictTypeListProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<DictTypeDto>(new DictTypeDto());

    const handleAdd = () => {
        setCurrentRow(new DictTypeDto());
        setOpenDetailModal(true);
    };

    const handleModify = async (record: DictTypeDto) => {
        setCurrentRow(record);
        setOpenDetailModal(true);
    };

    const handleDelete = async (record: DictTypeDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await DictTypeApi.deleteDictType(record);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    props.onRefresh();
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleSelect = (item: DictTypeDto) => {
        props.onSelect(item);
    }

    return (
        <div>
            <DictTypeDetailModal open={openDetailModal}
                                 onOk={() => props.onRefresh()}
                                 onClose={() => setOpenDetailModal(false)}
                                 dictType={currentRow}/>
            <Card>
                <CardHeader
                    title={intl.formatMessage({id: 'DICT_TYPE_LIST'})}
                    action={
                        <div>
                            <AddIconButton onClick={handleAdd}/>
                            <RefreshIconButton onClick={() => props.onRefresh()}/>
                        </div>
                    }
                />
                <CardContent>
                    {props.dictTypeList.map((row, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={row.id === props.selectedDictTypeId}
                            onClick={(e) => handleSelect(row)}
                            secondaryAction={
                                <div>
                                    <ModifyIconButton onClick={() => handleModify(row)}/>
                                    <DeleteIconButton onClick={() => handleDelete(row)}/>
                                </div>
                            }>
                            <ListItemText primary={row.name} secondary={row.code}/>
                        </ListItem>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};

export default DictTypeList;