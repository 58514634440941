import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {VisitRequestDto} from "../dto/VisitRequestDto";

export const VisitRequestApi = {

    registerVisitRequest: async (body: VisitRequestDto) => {
        try {
            const requestUrl = '/v1/vms/visit/request/register';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createVisitRequest: async (body: VisitRequestDto) => {
        try {
            const requestUrl = '/v1/vms/visit/request/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    updateVisitRequest: async (body: VisitRequestDto) => {
        try {
            const requestUrl = '/v1/vms/visit/request/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    getLastHostInfo: async (hostStaffNumber: string, hostEmail: string) => {
        try {
            const params = new Map<string, string>();
            params.set('hostStaffNumber', hostStaffNumber);
            params.set('hostEmail', hostEmail);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/visit/request/getLastHostInfo', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    inviteVisitRequest: async (body: VisitRequestDto) => {
        try {
            const requestUrl = '/v1/vms/visit/request/invite';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getVisitRequestDetailById: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/visit/request/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getPendingApprovalList: async () => {
        try {
            const requestUrl = '/v1/vms/visit/request/pending/approval/list';
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    approve: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/visit/request/approve', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    reject: async (id: string, body: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/visit/request/reject', params);
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
