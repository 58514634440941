export const UserStatus = {
    Map: new Map<string, string>([
        ['00', 'ACCOUNT_USER_STATUS_INACTIVE'],
        ['01', 'ACCOUNT_USER_STATUS_ACTIVE'],
    ]),
    Enum: {
        Inactive: "00",
        Active: "01"
    }
};
