import dayjs from "dayjs";
import {Formats} from "../../../constants/Formats";

export class VisitorAccessLogDto {
    id: string = '';
    requestVisitorId: string = '';
    accessDate: string = '';
    accessTime: string = '';
    accessReader: string = '';
    accessCardNumber: string = '';
    accessDirection: string = '';

    fetchAccessDateTime ():string{
        let dateSting = this.accessDate;
        let timeString = this.accessTime;
        return dayjs(dateSting, Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN) + " " + dayjs(timeString, Formats.TIME_SIMPLE).format(Formats.TIME_COLON);

    };
}