import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {LocationDto} from "../../../../../modules/vms/dto/LocationDto";
import {AreaDto} from "../../../../../modules/vms/dto/AreaDto";
import {AreaApi} from "../../../../../modules/vms/api/AreaApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from "@mui/material";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";
import FormFieldSelect from "../../../../../components/FormFieldSelect";
import FormFieldText from "../../../../../components/FormFieldText";

export type AreaDetailDialogProps = {
    open: boolean;
    locationList: Array<LocationDto>;
    area: AreaDto;
    onClose: () => void;
    onRefresh: (dictClassId: string) => void;
};

const AreaDetailDialog: React.FC<AreaDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.area.id ? 'VMS_AREA_MODIFY' : 'VMS_AREA_CREATE';

    const [innerArea, setInnerArea] = useState<AreaDto>(props.area);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerArea({...props.area});
    }, [props.area]);

    const handleChange = (name: string, value: any) => {
        setInnerArea(previousArea => ({...previousArea, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.area.id) {
                const responseResult = await AreaApi.updateArea(innerArea);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_AREA_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onRefresh(innerArea.locationId);
                }
            } else {
                const responseResult = await AreaApi.createArea(innerArea);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_AREA_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onRefresh(innerArea.locationId);
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerArea.locationId === '') {
            newMessageMap.set('locationId', intl.formatMessage({id: 'VMS_AREA_LOCATION_REQUIRED'}));
        }
        if (innerArea.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'VMS_AREA_CODE_REQUIRED'}));
        }
        if (innerArea.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'VMS_AREA_NAME_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (

        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldSelect label={intl.formatMessage({id: "VMS_AREA_LOCATION"})}
                                 required={true} errorText={messageMap.get("locationId")}
                                 value={innerArea.locationId}
                                 onChange={(value) => {
                                     handleChange("locationId", value)
                                 }}
                                 items={props.locationList.map((item) => {
                                         return {label: item.name, value: item.id}
                                     }
                                 )}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_AREA_CODE"})} value={innerArea.code}
                               required={true}
                               errorText={messageMap.get("code")}
                               onChange={(value) => handleChange('code', value)}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_AREA_NAME"})} value={innerArea.name}
                               required={true}
                               errorText={messageMap.get("name")}
                               onChange={(value) => handleChange('name', value)}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_AREA_SEQUENCE"})} value={innerArea.sequence}
                               type={'number'}
                               onChange={(value) => handleChange('sequence', value)}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default AreaDetailDialog;
