import React from 'react';

import {createTheme} from "@mui/material";


export const CUSTOM_THEME = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#004666',
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#54778f',
        },
        info: {
            // This is green.A700 as hex.
            main: '#4c5356',
        },
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                // the slot name defined in the `slot` and `overridesResolver` parameters
                // of the `styled` API
                root: {
                    backgroundImage: 'linear-gradient(#67879d, #345f7d)',
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    borderRadius: '7px',
                    boxShadow: 'rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                border:'none'
                }
            }
        },


    }

});

