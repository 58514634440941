import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {ResourceMenuApi} from "../../../../../modules/account/api/ResourceMenuApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, List, ListItemButton, ListItemIcon, ListItemText
} from "@mui/material";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";
import {ResourceAppApi} from "../../../../../modules/account/api/ResourceAppApi";
import {ResourceMenuDto} from "../../../../../modules/account/dto/ResourceMenuDto";
import {RoleApi} from "../../../../../modules/account/api/RoleApi";
import {IconUtils} from "../../../../../utils/IconUtils";

export type AppRoleMenuDialogProps = {
    open: boolean;
    appId: string;
    roleId: string;
    onClose: () => void;
};

const AppRoleMenuDialog: React.FC<AppRoleMenuDialogProps> = (props) => {
    const intl = useIntl();

    const [appMenus, setAppMenus] = useState<Array<ResourceMenuDto>>([]);

    const [selectedMenuIds, setSelectedMenuIds] = useState<Array<string>>([]);

    useEffect(() => {
        if (props.appId) {
            fetchAppMenus(props.appId);
        }
    }, [props.appId]);

    useEffect(() => {
        if (props.roleId) {
            fetchRoleMenus(props.roleId);
        }
    }, [props.roleId]);

    const fetchAppMenus = async (appId: string) => {
        const responseResult = await ResourceAppApi.getResourceAppMenus(appId);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setAppMenus(ResourceMenuApi.constructResourceMenuTree(responseResult.data));
        }
    };

    const fetchRoleMenus = async (roleId: string) => {
        const responseResult = await RoleApi.getRoleMenus(roleId);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            const menuIds = [];
            for (let item of responseResult.data) {
                menuIds.push(item.id);
            }
            setSelectedMenuIds(menuIds);
        }
    };

    const handleOk = async () => {
        const responseResult = await RoleApi.syncRoleMenus(props.roleId, selectedMenuIds);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_ROLE_MENUS_BIND_SUCCESS'}))
            props.onClose();
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleToggleCheck = (menuId: string) => {
        const tempSelectMenuIds = Object.assign([], selectedMenuIds);
        if (tempSelectMenuIds.includes(menuId)) {
            tempSelectMenuIds.splice(tempSelectMenuIds.indexOf(menuId), 1);
        } else {
            tempSelectMenuIds.push(menuId);
        }

        setSelectedMenuIds(tempSelectMenuIds);
    }

    const renderChildren = (parent: ResourceMenuDto, children: Array<ResourceMenuDto>) => {
        return (
            <List component="div" disablePadding>
                {
                    children.map((child, index) => {
                        return (
                            <ListItemButton key={child.id} sx={{pl: 4}} onClick={() => handleToggleCheck(child.id)} dense>
                                <ListItemIcon>
                                    {IconUtils.getIcon(child.icon)}
                                </ListItemIcon>
                                <ListItemText primary={child.nameZh} secondary={child.nameEn}/>
                                <Checkbox
                                    edge="start"
                                    checked={selectedMenuIds.includes(child.id)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemButton>
                        )
                    })
                }
            </List>
        )
    }

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                <FormattedMessage id={'ACCOUNT_ROLE_MENUS_BIND'}/>
            </DialogTitle>
            <DialogContent>
                <List component="div" disablePadding>
                    {
                        appMenus.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <ListItemButton key={index}
                                                    onClick={() => handleToggleCheck(item.id)}
                                                    dense>
                                        <ListItemIcon>
                                            {IconUtils.getIcon(item.icon)}
                                        </ListItemIcon>
                                        <ListItemText primary={item.nameZh} secondary={item.nameEn}/>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedMenuIds.includes(item.id)}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemButton>
                                    {item.children ? renderChildren(item, item.children) : null}
                                    <Divider sx={{mt: 2, mb: 2}}/>
                                </div>
                            )
                        })
                    }

                </List>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppRoleMenuDialog;
