import * as React from 'react';
import SearchCondition from "./components/SearchCondition";
import {useEffect, useState} from "react";
import {UserSearchCondition} from "../../../../modules/account/dto/UserSearchCondition";
import {UserDto} from "../../../../modules/account/dto/UserDto";
import {PaginationDto} from "../../../../modules/common/dto/PaginationDto";
import {UserApi} from "../../../../modules/account/api/UserApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {useIntl} from "react-intl";
import SearchResult from "./components/SearchResult";
import DetailDialog from "./components/DetailDialog";
import PaginationBar from "../../../../components/PaginationBar";
import {Paper} from "@mui/material";
import RoleDialog from "./components/RoleDialog";

export default function Index() {
    const intl = useIntl();

    useEffect(() => {
        handleSearch(searchCondition);
    }, []);

    // 查询条件
    const [searchCondition, setSearchCondition] = useState(new UserSearchCondition());

    // 查询结果
    const [searchResult, setSearchResult] = useState(new Array<UserDto>());

    // 分页数据
    const [pagination, setPagination] = useState(new PaginationDto());

    const [openDetailDialog, setOpenDetailDialog] = useState(false);

    const [openRoleDialog, setOpenRoleDialog] = useState(false);

    const [currentRow, setCurrentRow] = useState<UserDto>(new UserDto());

    const handleChangeCondition = (name: string, value: any) => {
        setSearchCondition({...searchCondition, [name]: value});
    };

    const handleSearch = async (searchCondition: UserSearchCondition) => {
        const responseResult = await UserApi.queryUsers(searchCondition);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setSearchResult(responseResult.data);
            setPagination(responseResult.pagination);
        }
    };

    const handleClickModify = async (record: UserDto) => {
        const responseResult = await UserApi.getUserDetail(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailDialog(true);
        }
    };

    const handleClickDelete = async (record: UserDto) => {
        const responseResult = await UserApi.deleteUser(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
            handleSearch(searchCondition);
        }
    };

    const handleClickAdd = () => {
        setCurrentRow(new UserDto());
        setOpenDetailDialog(true);
    };

    const handleClickRole = (record: UserDto) => {
        setCurrentRow(record);
        setOpenRoleDialog(true);
    }

    const handlePagination = (page: number, pageSize: number) => {
        const newSearchCondition = Object.assign({}, searchCondition);
        newSearchCondition.currentPage = page;
        newSearchCondition.pageSize = pageSize;
        handleSearch(newSearchCondition);
    };

    return (
        <div>
            <DetailDialog open={openDetailDialog} user={currentRow}
                          onClose={() => setOpenDetailDialog(false)} onOk={() => handleSearch(searchCondition)}/>
            <RoleDialog open={openRoleDialog} userId={currentRow.id}
                        onClose={() => setOpenRoleDialog(false)} onOk={() => handleSearch(searchCondition)}/>
            <Paper sx={{p: 1}}>
                <SearchCondition searchCondition={searchCondition}
                                 onChange={handleChangeCondition}
                                 onClickSearch={() => handleSearch(searchCondition)}
                                 onClickAdd={handleClickAdd}/>
            </Paper>
            <br/>

            <Paper sx={{p: 1}}>
                <PaginationBar pagination={pagination} onChange={handlePagination}/>
                <br/>
                <SearchResult searchResult={searchResult}
                              onClickDelete={(record) => handleClickDelete(record)}
                              onClickModify={(record) => handleClickModify(record)}
                              onClickRole={(record) => handleClickRole(record)}/>
            </Paper>
        </div>
    );
}