import React from 'react';
import {IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

type ModifyIconButtonProps = {
    onClick: () => void;
};

const ModifyIconButton: React.FC<ModifyIconButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <IconButton onClick={(e) => handleClick(e)}>
            <EditIcon/>
        </IconButton>
    );
};
export default ModifyIconButton;
