import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {LanguageContext} from "../context/LanguageContext";
import {
    AppBar, Avatar,
    Button, Divider, IconButton,
    ListItemIcon,
    ListItemText, Menu,
    MenuItem, Stack,
    Toolbar,
    Typography
} from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import {Language} from "../modules/common/enums/Language";
import {Check} from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import {UserApi} from "../modules/account/api/UserApi";
import {HttpStatus} from "../modules/common/enums/HttpStatus";
import LogoutIcon from '@mui/icons-material/Logout';
import {UserDto} from "../modules/account/dto/UserDto";
import {useNavigate} from "react-router-dom";
import {RoutePath} from "../constants/RoutePath";

type HeaderProps = {
    sessionUser?: UserDto
};

const Header: React.FC<HeaderProps> = (props) => {

    const navigate = useNavigate();

    // 多语言菜单控制
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    // 登录用户的菜单控制
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    // 多语言上下文设置
    const {language, setLanguage} = useContext(LanguageContext);

    const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLanguageMenuAnchorEl(event.currentTarget);
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleLogout = async () => {
        const responseResult = await UserApi.logout();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            navigate(RoutePath.ACCOUNT_USER_LOGIN);
        }
    };

    const handleClickUserMenu = (key: string) => {
        switch (key) {
            case 'profile':
                navigate(RoutePath.ACCOUNT_USER_PROFILE);
                break;
            case 'logout':
                handleLogout();
                break;
        }
        setAnchorElUser(null);
    };

    const handleChangeLanguage = (value: string) => {
        console.log(value);
        setLanguage(value);
        setLanguageMenuAnchorEl(null);
    }

    return (
        <AppBar position="static">
            <Toolbar>
                {/*<div style={{height: '60px', marginRight:'1rem'}}>*/}
                {/*    <img src={'/logo.png'} alt={'Matrix CMS'} style={{height: '100%'}}/>*/}
                {/*</div>*/}
                <Typography
                    variant="h6"
                    component="div"
                    sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>
                    VOLKSWAGEN VGC VMS
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Button color="inherit" onClick={handleClickLanguage}>
                        <LanguageIcon/>&nbsp;<FormattedMessage id={Language.Map.get(language)}/>
                    </Button>
                    {
                        props.sessionUser ?
                            <IconButton color="inherit" onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar alt={props.sessionUser.fullName} src={props.sessionUser.portrait}/>
                            </IconButton> : null
                    }
                </Stack>
                <Menu anchorEl={languageMenuAnchorEl}
                      open={Boolean(languageMenuAnchorEl)}
                      onClose={() => setLanguageMenuAnchorEl(null)}
                      MenuListProps={{
                          'aria-labelledby': 'basic-button',
                      }}>
                    {
                        Language.List.map((item, index) => {
                            return (
                                <MenuItem key={index} onClick={() => handleChangeLanguage(item.value)}>
                                    {item.value === language ? <ListItemIcon><Check/></ListItemIcon> : null}
                                    <ListItemText inset={item.value !== language}><FormattedMessage
                                        id={item.label}/></ListItemText>
                                </MenuItem>
                            )
                        })
                    }
                </Menu>

                {/*用户菜单*/}
                <Menu
                    sx={{mt: '45px'}}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={() => setAnchorElUser(null)}>
                    <MenuItem key={'profile'} onClick={() => handleClickUserMenu('profile')}>
                        <ListItemIcon>
                            <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText><FormattedMessage id={'ACCOUNT_USER_PROFILE'}/></ListItemText>
                    </MenuItem>
                    <Divider/>
                    <MenuItem key={'logout'} onClick={() => handleClickUserMenu('logout')}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText><FormattedMessage id={'ACCOUNT_USER_LOGOUT'}/></ListItemText>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}

export default Header;