import React, {useEffect, useState} from 'react';
import {HttpStatus} from "../../../modules/common/enums/HttpStatus";
import PaginationBar from "../../../components/PaginationBar";
import {useIntl} from "react-intl";
import {PaginationDto} from "../../../modules/common/dto/PaginationDto";
import {LogOperationSearchCondition} from "../../../modules/log/dto/LogOperationSearchCondition";
import {LogOperationApi} from "../../../modules/log/api/LogOperationApi";
import {LogOperationDto} from "../../../modules/log/dto/LogOperationDto";
import SearchCondition from "./components/SearchCondition";
import SearchResult from "./components/SearchResult";
import {Card, Paper} from "@mui/material";


const Index: React.FC = () => {

    const intl = useIntl();

    // 查询条件
    const [searchCondition, setSearchCondition] = useState(new LogOperationSearchCondition());

    // 查询结果
    const [searchResult, setSearchResult] = useState(new Array<LogOperationDto>());

    // 分页数据
    const [pagination, setPagination] = useState(new PaginationDto());

    const handleChangeCondition = (name: string, value: any) => {
        setSearchCondition({...searchCondition, [name]: value});
    };

    const handleSearch = async (searchCondition: LogOperationSearchCondition) => {
        const responseResult = await LogOperationApi.queryLogOperations(searchCondition);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setSearchResult(responseResult.data);
            setPagination(responseResult.pagination);
        }
    };

    const handlePagination = (page: number, pageSize: number) => {
        const newSearchCondition = Object.assign({}, searchCondition);
        newSearchCondition.currentPage = page;
        newSearchCondition.pageSize = pageSize;
        handleSearch(newSearchCondition);
    };

    return (
        <div>
            <Paper sx={{p: 1}}>
                <SearchCondition searchCondition={searchCondition}
                                 onChange={handleChangeCondition}
                                 onSearch={() => handleSearch(searchCondition)}/>
            </Paper>

            <br/>

            <Paper sx={{p: 1}}>
                <PaginationBar pagination={pagination} onChange={handlePagination}/>
                <SearchResult searchResult={searchResult}/>
            </Paper>
        </div>
    );
};

export default Index;
