import {DictItemDto} from "../dto/DictItemDto";

const DictItemUtils = {

    /**
     * 得到字典项目的文本
     *
     * @param dictItems 字典项列表
     * @param value 字典项值
     * @returns {string}
     */
    getText: (dictItems: Array<DictItemDto>, value: string) => {
        const dictItemMap = new Map<string, DictItemDto>();
        for (let dictItem of dictItems) {
            dictItemMap.set(dictItem.value, dictItem);
        }

        if (dictItemMap.has(value)) {
            const dictItem = dictItemMap.get(value);
            return dictItem?.name;
        } else {
            return "";
        }
    },
};
export default DictItemUtils;