export const AreaType = {
    List: [
        {label: "VMS_AREA_TYPE_NORMAL", value: "01"},
        {label: "VMS_AREA_TYPE_SPECIAL", value: "02"}
    ],
    Map: new Map<string, string>([
        ['01', 'VMS_AREA_TYPE_NORMAL'],
        ['02', 'VMS_AREA_TYPE_SPECIAL'],
    ]),
    Enum: {
        normal: "01",
        special: '02',
    }
};
