import React from 'react';
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {useIntl} from "react-intl";
import {Grid, MenuItem, TextField} from "@mui/material";
import FormFieldDictItems from "../../../../dict/components/FormFieldDictItems";
import FormFieldText from "../../../../../components/FormFieldText";

export type VisitorDetailVehicleFormProps = {
    requestVisitor: RequestVisitorDto;
    driveTypes: Array<DictItemDto>;
    plateNumberColors: Array<DictItemDto>;
    vehicleUseTypes: Array<DictItemDto>;
    vehicleTypes: Array<DictItemDto>;
    onChange: (name: string, value: any) => void;
};
const VisitorDetailVehicleForm: React.FC<VisitorDetailVehicleFormProps> = (props) => {
    const intl = useIntl();

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_DRIVE_TYPE"})}
                                    value={props.requestVisitor.driveType}
                                    onChange={(value) => {
                                        props.onChange("driveType", value)
                                    }} dictItems={props.driveTypes}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_PLATE_NUMBER"})}
                               value={props.requestVisitor.plateNumber}
                               onChange={(value) => {
                                   props.onChange("plateNumber", value)
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_PLATE_NUMBER_COLOR"})}
                                    value={props.requestVisitor.plateNumberColor}
                                    onChange={(value) => {
                                        props.onChange("plateNumberColor", value)
                                    }} dictItems={props.plateNumberColors}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_VEHICLE_USE_TYPE"})}
                                    value={props.requestVisitor.vehicleUseType}
                                    onChange={(value) => {
                                        props.onChange("vehicleUseType", value)
                                    }} dictItems={props.vehicleUseTypes}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_VEHICLE_TYPE"})}
                                    value={props.requestVisitor.vehicleType}
                                    onChange={(value) => {
                                        props.onChange("vehicleType", value)
                                    }} dictItems={props.vehicleTypes}/>
            </Grid>
        </Grid>
    );
};

export default VisitorDetailVehicleForm;
