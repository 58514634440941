export class VisitorBlackListDto {
  id: string = '';
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  mobile: string = '';
  email: string = '';
  photoUrl: string = '';
  company: string = '';
  idType: string = '';
  idNumber: string = '';
  remarks: string = '';
}
