export default {
    DICT_TYPE_CREATE: 'Create Dict Type',
    DICT_TYPE_CREATE_SUCCESS: 'Create Dict Type Successful',
    DICT_TYPE_MODIFY: 'Modify Dict Type',
    DICT_TYPE_MODIFY_SUCCESS: 'Modify Dict Type Successful',
    DICT_TYPE_LIST: 'Dictionary Type List',
    DICT_TYPE_CODE: 'Code',
    DICT_TYPE_CODE_REQUIRED: 'Please fill in code',
    DICT_TYPE_NAME: 'Name ',
    DICT_TYPE_NAME_REQUIRED: 'Please fill in name',
    DICT_TYPE_SEQUENCE: 'Sequence',

    DICT_CLASS_CREATE: 'Create Dict Class',
    DICT_CLASS_CREATE_SUCCESS: 'Create Dict Class Successful',
    DICT_CLASS_MODIFY: 'Modify Dict Class',
    DICT_CLASS_MODIFY_SUCCESS: 'Modify Dict Class Successful',
    DICT_CLASS_TYPE: 'Dictionary Type',
    DICT_CLASS_LIST: 'Dictionary Class List',
    DICT_CLASS_CODE: 'Code',
    DICT_CLASS_CODE_REQUIRED: 'Please fill in code',
    DICT_CLASS_NAME: 'Name',
    DICT_CLASS_NAME_REQUIRED: 'Please fill in name',
    DICT_CLASS_SEQUENCE: 'Sequence',

    DICT_ITEM_CREATE: 'Create Dict Item',
    DICT_ITEM_CREATE_SUCCESS: 'Create Dict Item Successful',
    DICT_ITEM_MODIFY: 'Modify Dict Item',
    DICT_ITEM_MODIFY_SUCCESS: 'Modify Dict Item Successful',
    DICT_ITEM_CLASS: 'Dictionary Class',
    DICT_ITEM_LIST: 'Dictionary Item List',
    DICT_ITEM_CODE: 'Code',
    DICT_ITEM_CODE_REQUIRED: 'Please fill in code',
    DICT_ITEM_NAME: 'Name',
    DICT_ITEM_NAME_REQUIRED: 'Please fill in name',
    DICT_ITEM_VALUE: 'Value',
    DICT_ITEM_VALUE_REQUIRED: 'Please fill in value',
    DICT_ITEM_SEQUENCE: 'Sequence',
};
