export const AccessDirection = {
    List: [
        {label: "VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKIN", value: "01"},
        {label: "VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKOUT", value: "02"}
    ],
    Map: new Map<string, string>([
        ['01', 'VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKIN'],
        ['02', 'VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKOUT'],
    ]),
    Enum: {
        in: "01",
        out: '02',
    }
};

