import React from 'react';
import {useIntl} from "react-intl";
import dayjs from "dayjs";
import {VisitRequestDto} from "../../../modules/vms/dto/VisitRequestDto";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";
import {StatusType} from "../../../modules/vms/enums/StatusType";
import {Formats} from "../../../constants/Formats";
import DictItemUtils from "../../../modules/dict/utils/DictItemUtils";
import EnumItemLabel from "../../../components/EnumItemLabel";
import {ApplySourceType} from "../../../modules/vms/enums/ApplySourceType";
import Descriptions from "../../../components/Descriptions";
import DescriptionItem from "../../../components/DescriptionItem";
import {Card, CardContent, CardHeader} from "@mui/material";

export type VisitRequestHostInfoProps = {
    visitRequest: VisitRequestDto;
    purposeTypes: Array<DictItemDto>;
    employeeEntityTypes: Array<DictItemDto>;

};

const VisitRequestHostInfo: React.FC<VisitRequestHostInfoProps> = (props) => {
    const intl = useIntl();

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: 'VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO'})}/>
            <CardContent>
                <Descriptions>

                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_NAME'})}>
                        {props.visitRequest.hostFullName}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_STAFF_NUMBER'})}>
                        {props.visitRequest.hostStaffNumber}
                    </DescriptionItem>
                    {/*<DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_MOBILE'})}>*/}
                    {/*    {props.visitRequest.hostMobile}*/}
                    {/*</DescriptionItem>*/}
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_EMAIL'})}>
                        {props.visitRequest.hostEmail}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_EMPLOYEE_ENTITY'})}>
                        {DictItemUtils.getText(props.employeeEntityTypes, props.visitRequest.hostCompanyName)}
                    </DescriptionItem>
                </Descriptions>
            </CardContent>
        </Card>
    );
};

export default VisitRequestHostInfo;
