import {LocationDto} from "./LocationDto";

export class AreaDto {
    id: string = '';
    locationId: string = '';
    name: string = '';
    code: string = '';
    type: string = '';
    sequence: number = 0;
    managerId: string = '';
    managerName: string = '';
    managerEmail: string = '';
    invisible: number = 0;
    remarks: string = '';
    locationName: string = '';
    locationCode: string = '';
    siteName: string = '';
    siteId: string = '';
    siteCode: string = '';
    locations: Array<LocationDto> = [];
}
