export const ProfileUat = {
    // 网关服务的地址
    MATRIX_GATEWAY_SERVICE_HOST : "https://vms-server.dokatest.com",

    // 登录页面
    MATRIX_LOGIN_PAGE_URL: "https://idp.qs.cloudidp.vgcserv.com.cn/auth/realms/vgcmfa/protocol/openid-connect/auth?response_type=code&client_id=KABA_QA&redirect_uri=https://vms.dokatest.com&scope=openid%20profile%20email&acr_values=gas:standard&state=1",

    // 连接 VMS Web Socket 服务的默认地址
    VMS_WEB_SOCKET_HOST : "ws://192.168.31.131:8090",

    // 连接护照扫描仪 Web Socket 服务的默认地址
    VMS_WEB_SOCKET_HOST_PASSPORT : "ws://127.0.0.1:90",
}