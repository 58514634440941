import React, {useEffect, useState} from 'react';
import VisitorDetailGeneralForm from './VisitorDetailGeneralForm';
import VisitorDetailVehicleForm from './VisitorDetailVehicleForm';
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {useIntl} from "react-intl";
import {DictClassCode} from "../../../../../modules/vms/constants/DictClassCode";
import {DictClassApi} from "../../../../../modules/dict/api/DictClassApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {ValidateUtils} from "../../../../../utils/ValidateUtils";
import {Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@mui/material";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";

export type VisitorDetailDialogProps = {
    onCancel: () => void;
    onOk: (requestVisitor: RequestVisitorDto) => void;
    open: boolean;
    requestVisitor: RequestVisitorDto;
    onChange: (name: string, value: any) => void;
};

const VisitorDetailDialog: React.FC<VisitorDetailDialogProps> = (props) => {
    const intl = useIntl();

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    const [tabIndex, setTabIndex] = React.useState(0);

    // 证件类型
    const [idTypes, setIdTypes] = useState(new Array<DictItemDto>());

    // 访客类型
    const [visitorTypes, setVisitorTypes] = useState(new Array<DictItemDto>());

    // 驾驶类型
    const [driveTypes, setDriveTypes] = useState(new Array<DictItemDto>());

    // 车牌颜色
    const [plateNumberColors, setPlateNumberColors] = useState(new Array<DictItemDto>());

    // 车辆使用性质
    const [vehicleUseTypes, setVehicleUseTypes] = useState(new Array<DictItemDto>());

    // 车辆类型
    const [vehicleTypes, setVehicleTypes] = useState(new Array<DictItemDto>());

    useEffect(() => {
        // 查询证件类型字典项
        fetchDictClassList();
    }, []);

    const fetchDictClassList = async () => {
        const codes = new Array<string>(DictClassCode.VMS_VISITOR_TYPE, DictClassCode.VMS_ID_TYPE, DictClassCode.VMS_DRIVE_TYPE, DictClassCode.VMS_PLATE_NUMBER_COLOR, DictClassCode.VMS_VEHICLE_USE_TYPE, DictClassCode.VMS_VEHICLE_TYPE);
        const responseResult = await DictClassApi.getDictClassList(codes);
        if (responseResult.status === HttpStatus.SUCCESS) {
            for (let dictClass of responseResult.data) {
                switch (dictClass.code) {
                    case DictClassCode.VMS_VISITOR_TYPE:
                        setVisitorTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_ID_TYPE:
                        setIdTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_DRIVE_TYPE:
                        setDriveTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_PLATE_NUMBER_COLOR:
                        setPlateNumberColors(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_VEHICLE_USE_TYPE:
                        setVehicleUseTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_VEHICLE_TYPE:
                        setVehicleTypes(dictClass.dictItems);
                        break;
                }
            }
        }
    };

    let title = intl.formatMessage({id: 'VMS_REQUEST_VISITOR_CREATE'});
    if (props.requestVisitor.id) {
        title = intl.formatMessage({id: 'VMS_REQUEST_VISITOR_MODIFY'});
        // setRequestVisitor(props.requestVisitor);
    }

    const handleOk = () => {
        if (!validate()) {
            return;
        }
        props.onOk(props.requestVisitor);
    };

    const handleClose = () => {
        props.onCancel();
    };

    const handleChange = (name: string, value: any) => {
        props.onChange(name, value);
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (props.requestVisitor.lastName === '') {
            newMessageMap.set('lastName', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_LAST_NAME_REQUIRED'}));
        }
        if (props.requestVisitor.firstName === '') {
            newMessageMap.set('firstName', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_FIRST_NAME_REQUIRED'}));
        }
        if (props.requestVisitor.mobile === '') {
            newMessageMap.set('mobile', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_MOBILE_REQUIRED'}));
        } else if (!ValidateUtils.isMobileNumber(props.requestVisitor.mobile)) {
            newMessageMap.set('mobile', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_MOBILE_FORMAT'}));
        }
        if (props.requestVisitor.email === '') {
            newMessageMap.set('email', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_EMAIL_REQUIRED'}));
        } else if (!ValidateUtils.isEmail(props.requestVisitor.email)) {
            newMessageMap.set('email', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_EMAIL_FORMAT'}));
        }
        if (props.requestVisitor.company === '') {
            newMessageMap.set('company', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_COMPANY_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    let tabPanel = null;
    switch (tabIndex) {
        case 0:
            tabPanel = <VisitorDetailGeneralForm idTypes={idTypes} visitorTypes={visitorTypes}
                                                 requestVisitor={props.requestVisitor}
                                                 messageMap={messageMap}
                                                 onChange={handleChange}/>;
            break;
        case 1:
            tabPanel = <VisitorDetailVehicleForm driveTypes={driveTypes}
                                                 plateNumberColors={plateNumberColors}
                                                 vehicleUseTypes={vehicleUseTypes}
                                                 vehicleTypes={vehicleTypes}
                                                 requestVisitor={props.requestVisitor}
                                                 onChange={handleChange}/>;
            break;
    }

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth={true}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)}>
                    <Tab label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_GENERAL_INFO'})}
                         value={0}/>
                    <Tab label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_VEHICLE_INFO'})}
                         value={1}/>
                </Tabs>
                {tabPanel}
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleClose}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default VisitorDetailDialog;
