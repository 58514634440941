import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AuthorizedLayout from "./layout/AuthorizedLayout";
import AnonymousLayout from "./layout/AnonymousLayout";
import {RoutePath} from "./constants/RoutePath";
import LogOperation from "./pages/log/operation";
import DictManage from "./pages/dict/manage";
import AccountUserLogin from "./pages/account/user/login";
import AccountUserRegister from "./pages/account/user/register";
import AccountUserProfile from "./pages/account/user/profile";
import AccountUserSecurity from "./pages/account/user/security";
import AccountTenantProfile from "./pages/account/tenant/profile";
import AccountTenantMember from "./pages/account/tenant/member";
import AccountAppActivated from "./pages/account/app/activated";
import AccountAppMarket from "./pages/account/app/market";
import AccountAppManage from "./pages/account/app/manage";
import VMSVisitRequestInvite from './pages/vms/visitor-register/invite';
import VMSVisitRequestHistory from "./pages/vms/visitor-register/history";
import VMSVisitorManageCheckin from "./pages/vms/visitor-manange/checkin";
import VMSVisitorManageCheckinSign from "./pages/vms/visitor-manange/checkin/sign";
import VMSVisitorManageHistory from "./pages/vms/visitor-manange/history";
import VMSVisitorManageHistoryNoTenant from "./pages/vms/visitor-manange/historyNoTenant";

import VMSVisitorManageBlacklist from "./pages/vms/visitor-manange/blacklist";
import VMSSystemManageVisitorType from "./pages/vms/system-manage/visitor/type";
import VMSVisitorCardList from "./pages/vms/system-manage/card/list";
import VMSVisitorDeviceList from "./pages/vms/system-manage/device/list";
import VMSPendingApprovalList from "./pages/vms/visitor-register/pending";
import VMSSystemManageArea from './pages/vms/system-manage/area';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route element={<AuthorizedLayout/>}>
                        <Route index element={<AccountUserProfile/>}/>
                        <Route path={RoutePath.LOG_OPERATION} element={<LogOperation/>}/>
                        <Route path={RoutePath.DICT_MANAGE} element={<DictManage/>}/>
                        <Route path={RoutePath.ACCOUNT_USER_PROFILE} element={<AccountUserProfile/>}/>
                        <Route path={RoutePath.ACCOUNT_USER_SECURITY} element={<AccountUserSecurity/>}/>
                        <Route path={RoutePath.ACCOUNT_TENANT_PROFILE} element={<AccountTenantProfile/>}/>
                        <Route path={RoutePath.ACCOUNT_TENANT_MEMBER} element={<AccountTenantMember/>}/>
                        <Route path={RoutePath.ACCOUNT_APP_ACTIVATED} element={<AccountAppActivated/>}/>
                        <Route path={RoutePath.ACCOUNT_APP_MARKET} element={<AccountAppMarket/>}/>
                        <Route path={RoutePath.ACCOUNT_APP_MANAGE} element={<AccountAppManage/>}/>
                        <Route path={RoutePath.VMS_VISITOR_REGISTER_INVITE} element={<VMSVisitRequestInvite/>}/>
                        <Route path={RoutePath.VMS_VISITOR_REGISTER_HISTORY} element={<VMSVisitRequestHistory/>}/>
                        <Route path={RoutePath.VMS_VISITOR_REGISTER_PENDING} element={<VMSPendingApprovalList/>}/>
                        <Route path={RoutePath.VMS_VISITOR_MANAGE_CHECKIN} element={<VMSVisitorManageCheckin/>}/>
                        <Route path={RoutePath.VMS_VISITOR_MANAGE_HISTORY} element={<VMSVisitorManageHistory/>}/>
                        <Route path={RoutePath.VMS_VISITOR_MANAGE_BLACKLIST} element={<VMSVisitorManageBlacklist/>}/>
                        <Route path={RoutePath.VMS_SYSTEM_MANAGE_AREA} element={<VMSSystemManageArea/>}/>
                        <Route path={RoutePath.VMS_SYSTEM_MANAGE_VISITOR_TYPE} element={<VMSSystemManageVisitorType/>}/>
                        <Route path={RoutePath.VMS_VISITOR_CARD_LIST} element={<VMSVisitorCardList/>}/>
                        <Route path={RoutePath.VMS_VISITOR_DEVICE_LIST} element={<VMSVisitorDeviceList/>}/>
                        <Route path={RoutePath.VMS_VISITOR_MANAGE_HISTORY_NO_TENANT} element={<VMSVisitorManageHistoryNoTenant/>}/>


                    </Route>
                    <Route element={<AnonymousLayout/>}>
                        <Route path={RoutePath.ACCOUNT_USER_LOGIN} element={<AccountUserLogin/>}/>
                        <Route path={RoutePath.ACCOUNT_USER_REGISTER} element={<AccountUserRegister/>}/>
                    </Route>
                    <Route path={RoutePath.VMS_VISITOR_MANAGE_CHECKIN_SIGN} element={<VMSVisitorManageCheckinSign/>}/>
                    {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                    {/*<Route path="*" element={<NoMatch />} />*/}
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();