import React, {ReactNode} from 'react';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Formats} from "../constants/Formats";
import dayjs, {Dayjs} from "dayjs";

type FormFieldDateProps = {
    label: string;
    value: Dayjs;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    errorText?: string;
    onChange?: (value: string) => void;
};

const FormFieldDate: React.FC<FormFieldDateProps> = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={props.label}
                format={Formats.DATE_SLASH}
                minDate={props.minDate}
                maxDate={props.maxDate}
                value={props.value}
                onChange={(data) => {
                    props.onChange && props.onChange(dayjs(data).format(Formats.DATE_SIMPLE))
                }}
                slotProps={{
                    textField: {
                        required: props.required,
                        variant: 'standard',
                        margin: "normal",
                        fullWidth: true,
                        helperText: props.errorText,
                        error: (props.errorText !== undefined && props.errorText !== '')
                    }
                }}
            />
        </LocalizationProvider>
    );
};
export default FormFieldDate;