import * as React from 'react';
import {Button, ButtonGroup} from "@mui/material";
import {FormattedMessage} from "react-intl";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export type PreviousNextButtonProps = {
    disabled?: boolean;
    previousDisabled?: boolean;
    nextDisabled?: boolean;
    previousText?: string;
    nextText?: string;
    onPrevious?: () => void;
    onNext?: () => void;
};

const PreviousNextButton: React.FC<PreviousNextButtonProps> = (props) => {

    return (
        <div style={{textAlign: 'center'}}>
            <ButtonGroup variant="contained" color="primary">
                <Button startIcon={<ArrowBackIosIcon/>}
                        disabled={props.disabled || props.previousDisabled}
                        onClick={props.onPrevious}>
                    {props.previousText ? props.previousText :
                        <FormattedMessage id={"COMMON_PREVIOUS_STEP"}/>}
                </Button>
                <Button endIcon={<ArrowForwardIosIcon/>}
                        disabled={props.disabled || props.nextDisabled}
                        onClick={props.onNext}>
                    {props.nextText ? props.nextText : <FormattedMessage id={"COMMON_NEXT_STEP"}/>}
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default PreviousNextButton;