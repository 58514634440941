import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {HttpStatus} from "../../../../../../modules/common/enums/HttpStatus";
import {VisitorTypeDto} from "../../../../../../modules/vms/dto/VisitorTypeDto";
import {VisitorTypeApi} from "../../../../../../modules/vms/api/VisitorTypeApi";
import FormListSelect from "../../../../../workflow/form/list/components/FormListSelect";
import {MessageUtils} from "../../../../../../utils/MessageUtils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
} from "@mui/material";
import FormFieldText from "../../../../../../components/FormFieldText";
import CancelButton from "../../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../../components/buttons/SaveButton";

export type VisitorTypeDetailDialogProps = {
    open: boolean;
    visitorType: VisitorTypeDto;
    onClose: () => void;
    onOk: () => void;
};

const VisitorTypeDetailDialog: React.FC<VisitorTypeDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.visitorType.id ? 'VMS_VISITOR_TYPE_MODIFY' : 'VMS_VISITOR_TYPE_CREATE';

    const [innerVisitorType, setInnerVisitorType] = useState<VisitorTypeDto>(props.visitorType);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerVisitorType({...props.visitorType});
    }, [props.visitorType]);

    const handleChange = (name: string, value: any) => {
        setInnerVisitorType(previousSite => ({...previousSite, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.visitorType.id) {
                const responseResult = await VisitorTypeApi.updateVisitorType(innerVisitorType);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_VISITOR_TYPE_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await VisitorTypeApi.createVisitorType(innerVisitorType);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_VISITOR_TYPE_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerVisitorType.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'VMS_VISITOR_TYPE_CODE_REQUIRED'}));
        }
        if (innerVisitorType.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'VMS_VISITOR_TYPE_NAME_REQUIRED'}));
        }
        if (innerVisitorType.value === '') {
            newMessageMap.set('value', intl.formatMessage({id: 'VMS_VISITOR_TYPE_VALUE_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_TYPE_CODE"})}
                               required={true} errorText={messageMap.get("code")}
                               value={innerVisitorType.code}
                               onChange={(value) => {
                                   handleChange("code", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_TYPE_NAME"})}
                               required={true} errorText={messageMap.get("name")}
                               value={innerVisitorType.name}
                               onChange={(value) => {
                                   handleChange("name", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_TYPE_VALUE"})}
                               required={true} errorText={messageMap.get("value")}
                               value={innerVisitorType.value}
                               onChange={(value) => {
                                   handleChange("value", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_TYPE_SEQUENCE"})}
                               required={true} errorText={messageMap.get("sequence")}
                               type="number"
                               value={innerVisitorType.sequence}
                               onChange={(value) => {
                                   handleChange("sequence", value)
                               }}/>
                <FormControl required={true} variant="standard" fullWidth margin="normal">
                    <InputLabel>{intl.formatMessage({id: 'VMS_VISITOR_TYPE_WORKFLOW_FORM'})}</InputLabel>
                    <FormListSelect value={innerVisitorType.workflowFormId}
                                    onChange={(value) => handleChange('workflowFormId', value)}/>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default VisitorTypeDetailDialog;
