export const FileClassify = {
    List: [
        {value: "01", label: "FTP_FILE_CLASSIFY_FOLDER"},
        {value: "02", label: "FTP_FILE_CLASSIFY_FILE"}
    ],
    Map: new Map<string, string>([
        ['01', 'FTP_FILE_CLASSIFY_FOLDER'],
        ['02', 'FTP_FILE_CLASSIFY_FILE'],
    ]),
    Enum: {
        Folder: "01",
        File: '02',
    }
};
