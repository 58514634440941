import {VisitRequestDto} from "./VisitRequestDto";
import {VisitorAccessLogDto} from "./VisitorAccessLogDto";

export class RequestVisitorDto {
    id: string = '';
    requestId: string = '';
    fullName: string = '';
    firstName: string = '';
    lastName: string = '';
    mobile: string = '';
    email: string = '';
    photoUrl: string = '';
    company: string = '';
    idType: string = '01';
    idNumber: string = '';
    visitCode: string = '';
    accessCardNumber: string = '';
    accessCode: string = '';
    accessCodeCounter: string = '';
    checkOutPermission: number = 0;
    visitorType: string = '01';
    carryGoods: string = '';
    job: string = '';
    driveType: string = '';
    plateNumber: string = '';
    plateNumberColor: string = '';
    vehicleUseType: string = '';
    vehicleType: string = '';
    driverLicenseNumber: string = '';
    lastTrainingDate: string = '';
    haveMeal: string = '';
    wxOpenId: string = '';
    wxUnionId: string = '';
    consentLetterFileId: string = '';

    visitRequest?: VisitRequestDto;
    checkInLog?: VisitorAccessLogDto;
    checkOutLog?: VisitorAccessLogDto;

}
