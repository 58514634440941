export const AuthType = {
    Map: new Map<string, string>([
        ['01', 'ACCOUNT_USER_AUTH_TYPE_UID'],
        ['02', 'ACCOUNT_USER_AUTH_TYPE_EMAIL'],
        ['03', 'ACCOUNT_USER_AUTH_TYPE_MOBILE'],
    ]),
    Enum: {
        UID: "01",
        Email: "02",
        Mobile: '03',
        OIDC: '04'
    }
};
