import * as React from 'react';
import {useEffect, useState} from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useIntl} from "react-intl";
import RegisterVisitorForm from "./RegisterVisitorForm";
import RegisterHostForm from "./RegisterHostForm";
import RegisterConfirmForm from "./RegisterConfirmForm";
import RegisterRequestForm from "./RegisterRequestForm";

import RegisterConsentForm from "./RegisterConsentForm";
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {ApplySourceType} from "../../../../../modules/vms/enums/ApplySourceType";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {VisitRequestApi} from "../../../../../modules/vms/api/VisitRequestApi";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CloseButton from "../../../../../components/buttons/CloseButton";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {DictClassCode} from "../../../../../modules/vms/constants/DictClassCode";
import {DictClassApi} from "../../../../../modules/dict/api/DictClassApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {SiteApi} from "../../../../../modules/vms/api/SiteApi";

type RegisterDialogProps = {
    open: boolean;
    onClose: () => void;
    onSubmitSuccess: () => void;
    visitRequest: VisitRequestDto;
    requestVisitor: RequestVisitorDto;
    onChangeVisitRequest: (record: VisitRequestDto) => void;
    onChangeRequestVisitor: (record: RequestVisitorDto) => void

};

const RegisterDialog: React.FC<RegisterDialogProps> = (props) => {
    const intl = useIntl();
    const steps = [
        intl.formatMessage({id: "VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_INFO"}),
        intl.formatMessage({id: "VMS_REQUEST_VISITOR_VISIT_REQUEST_INFO"}),
        intl.formatMessage({id: "VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO"}),
        intl.formatMessage({id: "VMS_VISIT_REQUEST_REGISTER_STEP_CONFIRM_INFO"}),
    ];
    const [stepIndex, setStepIndex] = React.useState(0);

    // const [visitRequest, setVisitRequest] = useState<VisitRequestDto>(new VisitRequestDto());
    // const [requestVisitor, setRequestVisitor] = useState<RequestVisitorDto>(new RequestVisitorDto());

    // 邀请配置
    const [inviteProperties, setInviteProperties] = useState(new Array<DictItemDto>());
    // 访问目的
    const [purposeTypes, setPurposeTypes] = useState(new Array<DictItemDto>());
    // 证件类型
    const [idTypes, setIdTypes] = useState(new Array<DictItemDto>());
    // 访客类型
    const [visitorTypes, setVisitorTypes] = useState(new Array<DictItemDto>());
    // // 驾驶类型
    // const [driveTypes, setDriveTypes] = useState(new Array<DictItemDto>());
    // // 车牌颜色
    // const [plateNumberColors, setPlateNumberColors] = useState(new Array<DictItemDto>());
    // // 车辆使用性质
    // const [vehicleUseTypes, setVehicleUseTypes] = useState(new Array<DictItemDto>());
    // // 车辆类型
    // const [vehicleTypes, setVehicleTypes] = useState(new Array<DictItemDto>());
    // 员工实体
    const [employeeEntityTypes, setEmployeeEntityTypes] = useState(new Array<DictItemDto>());
    const [currentSiteName, setCurrentSiteName] = useState("");


    useEffect(() => {
        // 查询访问目的字典项
        fetchDictClassList();
    }, []);
    useEffect(() => {
        // 获取当前的Site值
        fetchCurrentSite();
    }, []);


    useEffect(() => {
        if (props.open) {
            setStepIndex(0);
            // setVisitRequest(new VisitRequestDto());
            // setRequestVisitor(new RequestVisitorDto());
        }
    }, [props.open]);

    const fetchDictClassList = async () => {
        const codes = new Array<string>(
            DictClassCode.VMS_INVITE_PROPERTIES,
            DictClassCode.VMS_PURPOSE_TYPE, DictClassCode.VMS_VISITOR_TYPE,
            DictClassCode.VMS_ID_TYPE, DictClassCode.VMS_EMPLOYEE_ENTITY_TYPE);
        const responseResult = await DictClassApi.getDictClassList(codes);
        if (responseResult.status === HttpStatus.SUCCESS) {
            for (let dictClass of responseResult.data) {
                switch (dictClass.code) {
                    case DictClassCode.VMS_INVITE_PROPERTIES:
                        setInviteProperties(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_PURPOSE_TYPE:
                        setPurposeTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_VISITOR_TYPE:
                        setVisitorTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_ID_TYPE:
                        setIdTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_EMPLOYEE_ENTITY_TYPE:
                        setEmployeeEntityTypes(dictClass.dictItems);
                        break;
                    // case DictClassCode.VMS_DRIVE_TYPE:
                    //     setDriveTypes(dictClass.dictItems);
                    //     break;
                    // case DictClassCode.VMS_PLATE_NUMBER_COLOR:
                    //     setPlateNumberColors(dictClass.dictItems);
                    //     break;
                    // case DictClassCode.VMS_VEHICLE_USE_TYPE:
                    //     setVehicleUseTypes(dictClass.dictItems);
                    //     break;
                    // case DictClassCode.VMS_VEHICLE_TYPE:
                    //     setVehicleTypes(dictClass.dictItems);
                    //     break;
                }
            }
        }
    };
    const fetchCurrentSite = async () => {
        const responseResult = await SiteApi.getSiteList();
        if (responseResult.status === HttpStatus.SUCCESS) {
            if (responseResult.data.length > 0) {
                console.log(responseResult.data)
                setCurrentSiteName(responseResult.data[0].name);
            }
        }
    };
    const handleChange = (name: string, value: any) => {
        const newVisitRequest = Object.assign({}, props.visitRequest);
        // @ts-ignore
        newVisitRequest[name] = value;
        if (name === "arriveStartDate") {
            newVisitRequest["arriveEndDate"] = value;
        }
        props.onChangeVisitRequest(newVisitRequest);
    };

    const handleLoadRequestHost = (visitRequestHost: VisitRequestDto) => {
        const newVisitRequest = Object.assign({}, props.visitRequest);
        newVisitRequest["hostEmail"] = visitRequestHost.hostEmail;
        newVisitRequest["hostMobile"] = visitRequestHost.hostMobile;
        newVisitRequest["hostLastName"] = visitRequestHost.hostLastName;
        newVisitRequest["hostFirstName"] = visitRequestHost.hostFirstName;
        newVisitRequest["hostStaffNumber"] = visitRequestHost.hostStaffNumber;
        newVisitRequest["hostCompanyName"] = visitRequestHost.hostCompanyName;

        props.onChangeVisitRequest(newVisitRequest);
    }

    const handleVisitorChange = (name: string, value: any) => {
        props.onChangeRequestVisitor({...props.requestVisitor, [name]: value});
    };

    const handleLoadRequestVisitor = (lastRequestVisitor: RequestVisitorDto) => {
        const newRequestVisitor = Object.assign({}, props.requestVisitor);
        newRequestVisitor["idType"] = lastRequestVisitor.idType;
        newRequestVisitor["lastName"] = lastRequestVisitor.lastName;
        newRequestVisitor["firstName"] = lastRequestVisitor.firstName;
        newRequestVisitor["mobile"] = lastRequestVisitor.mobile;
        newRequestVisitor["email"] = lastRequestVisitor.email;
        newRequestVisitor["company"] = lastRequestVisitor.company;
        props.onChangeRequestVisitor(newRequestVisitor);
    }

    const handleNext = () => {
        setStepIndex(stepIndex + 1);
    }

    const handlePrevious = () => {
        setStepIndex(stepIndex - 1);
    }

    const handleSubmit = async (consentLetterFileId: string) => {
        props.visitRequest.applySourceType = ApplySourceType.Enum.Manager;
        props.visitRequest.visitors = new Array<RequestVisitorDto>();
        props.requestVisitor.consentLetterFileId = consentLetterFileId;
        props.visitRequest.visitors.push(props.requestVisitor);
        if (props.visitRequest.id && props.visitRequest.id !== "") {
            const responseResult = await VisitRequestApi.updateVisitRequest(props.visitRequest);
            if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
                MessageUtils.success(intl.formatMessage({id: 'VMS_VISIT_REQUEST_REGISTER_SUCCESS'}))
                setStepIndex(0);
                props.onChangeVisitRequest(new VisitRequestDto());
                // props.onSubmitSuccess();
                props.onClose();

            }
        } else {
            const responseResult = await VisitRequestApi.createVisitRequest(props.visitRequest);
            if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
                MessageUtils.success(intl.formatMessage({id: 'VMS_VISIT_REQUEST_REGISTER_SUCCESS'}))
                setStepIndex(0);
                props.onChangeVisitRequest(new VisitRequestDto());
                // props.onSubmitSuccess();
                props.onClose();

            }
        }

    }
    const handleSubmitRequest = async () => {
        props.visitRequest.applySourceType = ApplySourceType.Enum.Manager;
        props.visitRequest.visitors = new Array<RequestVisitorDto>();
        props.visitRequest.visitors.push(props.requestVisitor);
        if (props.visitRequest.id && props.visitRequest.id !== "") {
            const responseResult = await VisitRequestApi.updateVisitRequest(props.visitRequest);
            if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
                MessageUtils.success(intl.formatMessage({id: 'VMS_VISIT_REQUEST_REGISTER_SUCCESS'}))
                setStepIndex(0);
                props.onChangeVisitRequest(new VisitRequestDto());
                // props.onSubmitSuccess();
                props.onClose();

            }
        }

    }
    let stepComponent = null;
    switch (stepIndex) {
        case 0:
            stepComponent = <RegisterVisitorForm inviteProperties={inviteProperties}
                                                 requestVisitor={props.requestVisitor}
                                                 idTypes={idTypes}
                                                 visitorTypes={visitorTypes}
                                                 onPrevious={handlePrevious}
                                                 onNext={handleNext}
                                                 onChange={handleVisitorChange}
                                                 onLoadRequestVisitor={handleLoadRequestVisitor}/>;
            break;
        case 1:
            stepComponent = <RegisterRequestForm visitRequest={props.visitRequest}
                                                 requestVisitor={props.requestVisitor}
                                                 purposeTypes={purposeTypes}
                                                 onChange={handleChange}
                                                 onVisitorChange={handleVisitorChange}
                                                 onPrevious={handlePrevious}
                                                 onNext={handleNext}/>
            break;


        case 2:
            stepComponent = <RegisterHostForm inviteProperties={inviteProperties}
                                              visitRequest={props.visitRequest}
                                              purposeTypes={purposeTypes}
                                              employeeEntityTypes={employeeEntityTypes}
                                              onChange={handleChange}
                                              onLoadRequestHost={handleLoadRequestHost}
                                              onPrevious={handlePrevious}
                                              onNext={handleNext}/>
            break;
        case 3:
            stepComponent = <RegisterConfirmForm visitRequest={props.visitRequest}
                                                 requestVisitor={props.requestVisitor}
                                                 inviteProperties={inviteProperties}
                                                 purposeTypes={purposeTypes}
                                                 employeeEntityTypes={employeeEntityTypes}

                                                 idTypes={idTypes}
                                                 visitorTypes={visitorTypes}
                                                 onPrevious={handlePrevious}
                                                 onSubmit={() => handleSubmitRequest()}
                                                 onNext={handleNext}/>
            break;
        case 4:
            stepComponent = <RegisterConsentForm visitRequest={props.visitRequest}
                                                 currentSiteName={currentSiteName}
                                                 requestVisitor={props.requestVisitor}
                                                 onPrevious={handlePrevious}
                                                 onSubmit={(fileId) => handleSubmit(fileId)}
                                                 purposeTypes={purposeTypes}/>
            break;
    }
    if (!props.requestVisitor.id) {
        steps.push(intl.formatMessage({id: "VMS_VISIT_REQUEST_REGISTER_STEP_CONSENT_LETTER"}))

    }
    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                {intl.formatMessage({id: (!props.requestVisitor.id ? 'VMS_REQUEST_VISITOR_REGISTER' : 'VMS_REQUEST_VISITOR_REGISTER_INFO_FILL')})}
            </DialogTitle>
            <DialogContent>
                <Stepper activeStep={stepIndex}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <br/>
                <br/>
                {stepComponent}
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={props.onClose}/>
            </DialogActions>
        </Dialog>
    );

};

export default RegisterDialog;