export const DictClassCode = {
    VMS_INVITE_PROPERTIES: 'VMS_INVITE_PROPERTIES',
    VMS_PURPOSE_TYPE: 'VMS_PURPOSE_TYPE',
    VMS_VISITOR_TYPE: 'VMS_VISITOR_TYPE',
    VMS_ID_TYPE: 'VMS_ID_TYPE',
    VMS_DRIVE_TYPE: 'VMS_DRIVE_TYPE',
    VMS_PLATE_NUMBER_COLOR: 'VMS_PLATE_NUMBER_COLOR',
    VMS_VEHICLE_USE_TYPE: 'VMS_VEHICLE_USE_TYPE',
    VMS_VEHICLE_TYPE: 'VMS_VEHICLE_TYPE',
    VMS_EMPLOYEE_ENTITY_TYPE: 'VMS_EMPLOYEE_ENTITY_TYPE',

};
