import {AxiosRequest, HttpUtils} from '../../../utils/HttpUtils';
import {UserDto} from "../dto/UserDto";
import {HttpStatus} from "../../common/enums/HttpStatus";
import {AccountCacheKey} from "../constants/AccountCacheKey";
import {UserSearchCondition} from "../dto/UserSearchCondition";

export const UserApi = {

    queryUsers: async (body: UserSearchCondition) => {
        try {
            const requestUrl = '/v1/account/user/query';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getUserDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getUserDetailByKeywords: async (keywords: string) => {
        try {
            const params = new Map<string, string>();
            params.set('mobile', keywords);
            params.set('email', keywords);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createUser: async (body: UserDto) => {
        try {
            const requestUrl = '/v1/account/user/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateUser: async (body: UserDto) => {
        try {
            const requestUrl = '/v1/account/user/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteUser: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    /**
     * 用户登录系统
     * @param body 用户参数对象
     */
    login: async (body: UserDto, authType: string,  authCode: string) => {
        try {
            const params = new Map<string, string>();
            params.set('authType', authType);
            params.set('authCode', authCode);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/login', params);
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    logout: async () => {
        try {
            const requestUrl = '/v1/account/user/logout';
            const axiosResponse = await AxiosRequest.post(requestUrl);
            if (axiosResponse.data) {
                const responseResult = axiosResponse.data;
                if (responseResult.status === HttpStatus.SUCCESS) {
                    // 登出成功，删除令牌
                    localStorage.removeItem(AccountCacheKey.ACCESS_TOKEN);
                }
            }
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    /**
     * 获取当前的用户
     */
    getSessionUser: async () => {
        try {
            const requestUrl = '/v1/account/user/session/detail';
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateSessionUser: async (body: UserDto) => {
        try {
            const requestUrl = '/v1/account/user/session/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateSessionUserPassword: async (body: UserDto) => {
        try {
            const requestUrl = '/v1/account/user/session/update/password';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateSessionUserEmail: async (body: UserDto, captchaValue: string) => {
        try {
            const params = new Map<string, string>();
            params.set('captchaValue', captchaValue);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/session/update/email', params);
            ;
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    register: async (body: UserDto, captchaKey: string, captchaValue: string) => {
        try {
            const params = new Map<string, string>();
            params.set('captchaKey', captchaKey);
            params.set('captchaValue', captchaValue);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/register', params);
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getRoleList: async (userId: string) => {
        try {
            const params = new Map<string, string>();
            params.set('userId', userId);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/roles', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    syncRoleList: async (userId: string, roleIds: Array<string>) => {
        try {
            const params = new Map<string, string>();
            params.set('userId', userId);
            const requestUrl = HttpUtils.addQueryString('/v1/account/user/roles/sync', params);
            const axiosResponse = await AxiosRequest.post(requestUrl, roleIds);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
