import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import SignatureCanvas from 'react-signature-canvas';

type SignDialogProps = {
    open: boolean;
    onClose: () => void;
    onOk: (signImage: string) => void;
};

const SignDialog: React.FC<SignDialogProps> = (props) => {

    let sigCanvas: any;

    const clearSign = () => {
        sigCanvas.clear();
    };

    const handleSign = () => {
        const signImage = sigCanvas.toDataURL('image/png');
        props.onOk(signImage);
        props.onClose();
    };

    const handleCancel = () => {
        props.onClose();
    }

    return (
        <Dialog open={props.open}>
            <DialogContent>
                <SignatureCanvas
                    penColor="#000"
                    canvasProps={{
                        width: 500,
                        height: 200,
                        // className: styles.canvasContainer
                    }}
                    ref={ref => {
                        sigCanvas = ref;
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} color="info" onClick={handleCancel}>
                    取消 / Cancel
                </Button>
                <Button variant={'outlined'} color="secondary" onClick={clearSign}>
                    重置 / Reset
                </Button>
                <Button variant="contained" color="primary" onClick={handleSign}>
                    确定 / OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SignDialog;