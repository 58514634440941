import React, {useState} from 'react';
import {useIntl} from "react-intl";
import AreaDetailDialog from "./AreaDetailDialog";
import {LocationDto} from "../../../../../modules/vms/dto/LocationDto";
import {AreaDto} from "../../../../../modules/vms/dto/AreaDto";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {AreaApi} from "../../../../../modules/vms/api/AreaApi";
import {AreaType} from "../../../../../modules/vms/enums/AreaType";
import {
    Card,
    CardHeader, List,
    ListItem,
    ListItemIcon, ListItemText,
} from "@mui/material";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import AddIconButton from "../../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../../components/buttons/RefreshIconButton";
import ModifyIconButton from "../../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../../components/buttons/DeleteIconButton";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import {useConfirm} from "material-ui-confirm";

export type AreaListProps = {
    selectedLocationId: string;
    locationList: Array<LocationDto>;
    areaList: Array<AreaDto>;
    onRefresh: (dictClassId: string) => void;
};

const AreaList: React.FC<AreaListProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [selectedAreaId, setSelectedAreaId] = useState('');
    const [currentRow, setCurrentRow] = useState<AreaDto>(new AreaDto());

    const handleAdd = () => {
        const area = new AreaDto();
        area.locationId = props.selectedLocationId;
        area.type = AreaType.Enum.normal;
        setCurrentRow(area);
        setOpenDetailModal(true);
    };

    const handleModify = async (record: AreaDto) => {
        const responseResult = await AreaApi.getAreaDetail(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailModal(true);
        }
    };

    const handleDelete = async (record: AreaDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await AreaApi.deleteArea(record.id);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    props.onRefresh(record.locationId);
                }
            })
            .catch(() => {
                console.log('cancel');
            });

    };

    const handleSelect = (item: AreaDto) => {
        setSelectedAreaId(item.id);
    }

    return (
        <div>
            <AreaDetailDialog open={openDetailModal}
                              onRefresh={(dictClassId) => props.onRefresh(dictClassId)}
                              onClose={() => setOpenDetailModal(false)}
                              locationList={props.locationList}
                              area={currentRow}/>
            <Card>
                <CardHeader
                    action={
                        <div>
                            <AddIconButton onClick={handleAdd}/>
                            <RefreshIconButton onClick={() => props.onRefresh(props.selectedLocationId)}/>
                        </div>
                    }
                    title={intl.formatMessage({id: 'VMS_AREA_LIST'})}
                />
                <List>
                    {props.areaList.map((row, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={row.id === selectedAreaId}
                            onClick={(e) => handleSelect(row)}
                            secondaryAction={
                                <div>
                                    <ModifyIconButton onClick={() => handleModify(row)}/>
                                    <DeleteIconButton onClick={() => handleDelete(row)}/>
                                </div>
                            }>
                            <ListItemIcon>
                                <MeetingRoomIcon/>
                            </ListItemIcon>
                            <ListItemText primary={row.name}/>
                        </ListItem>
                    ))}
                </List>
            </Card>
        </div>
    );
};

export default AreaList;
