import React, {useEffect, useState} from 'react';
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {VisitRequestApi} from "../../../../modules/vms/api/VisitRequestApi";
import {VisitRequestDto} from "../../../../modules/vms/dto/VisitRequestDto";
import VisitRequestDetailDialog from "../../components/VisitRequestDetailDialog";
import {FormattedMessage, useIntl} from "react-intl";
import dayjs from "dayjs";
import {Formats} from "../../../../constants/Formats";
import {StatusType} from "../../../../modules/vms/enums/StatusType";
import {Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import RefreshIconButton from "../../../../components/buttons/RefreshIconButton";

const Index: React.FC = () => {

    const intl = useIntl();

    const [visitRequestList, setVisitRequestList] = useState<Array<VisitRequestDto>>([]);

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<VisitRequestDto>(new VisitRequestDto());

    const handleShowDetail = async (record: VisitRequestDto) => {
        const responseResult = await VisitRequestApi.getVisitRequestDetailById(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailModal(true);
        }
    };

    useEffect(() => {
        // 查询用户组列表
        fetchVisitRequestList();
    }, []);

    const fetchVisitRequestList = async () => {
        const responseResult = await VisitRequestApi.getPendingApprovalList();
        if (responseResult.status === HttpStatus.SUCCESS) {
            setVisitRequestList(responseResult.data);
        }
    };

    return (
        <Card>
            <CardHeader
                action={
                    <RefreshIconButton onClick={() => fetchVisitRequestList()}/>
                }
                title={intl.formatMessage({id: 'VMS_VISIT_REQUEST_PENDING_APPROVAL_LIST'})}
            />
            <VisitRequestDetailDialog open={openDetailModal}
                                      onClose={() => setOpenDetailModal(false)}
                                      visitRequest={currentRow}
                                      onApprove={fetchVisitRequestList}
                                      onReject={fetchVisitRequestList}/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISIT_REQUEST_NUMBER'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_ARRIVE_DATE'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_VISIT_REQUEST_STATUS"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visitRequestList.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.number}</TableCell>
                            <TableCell>{dayjs(row.arriveStartDate, Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}</TableCell>
                            <TableCell><FormattedMessage id={StatusType.Map.get(row.status)}/></TableCell>
                            <TableCell>
                                <a href={'javascript:void(0)'} onClick={() => handleShowDetail(row)}>
                                    <FormattedMessage id={'COMMON_DETAIL'}/>
                                </a>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default Index;
