import {AreaDto} from "./AreaDto";

export class RequestPlaceDto {
  id: string = '';
  requestId: string = '';
  areaId: string = '';
  areaName: string = '';
  areaCode: string = '';
  areaType: string = '';
  areaManagerId: string = '';
  areaManagerName: string = '';
  areaManagerEmail: string = '';
  locationId: string = '';

  locationName: string = '';
  locationCode: string = '';
  siteName: string = '';
  siteId: string = '';
  siteCode: string = '';
  constructor(area: AreaDto) {
    this.areaId = area.id;
    this.areaName = area.name;
    this.areaCode = area.code;
    this.areaType = area.type;
    this.areaManagerId = area.managerId;
    this.areaManagerName = area.managerName;
    this.areaManagerEmail = area.managerEmail;
    this.locationName= area.locationName;
    this.locationId= area.locationId;
    this.locationCode= area.locationCode;
    this.siteName= area.siteName;
    this.siteId= area.siteId;
    this.siteCode= area.siteCode;
  }
}
