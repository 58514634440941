import React, {ReactNode} from 'react';
import {InputAdornment, MenuItem, TextField} from "@mui/material";

type Item = {
    label: string,
    value: string
}

type FormFieldSelectProps = {
    label?: string;
    value: string;
    required?: boolean;
    errorText?: string;
    onChange: (value: string) => void;
    children?: React.ReactNode[];
    items?: Array<Item>;
    startAdornment?: ReactNode
};

const FormFieldSelect: React.FC<FormFieldSelectProps> = (props) => {

    return (
        <TextField
            select
            label={props.label}
            required={props.required}
            error={props.errorText !== undefined && props.errorText !== ''}
            helperText={props.errorText}
            fullWidth
            variant="standard"
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            value={props.value}
            onChange={(e) => {
                props.onChange(e.target.value)
            }}
            InputProps={{
                startAdornment: (<InputAdornment position="start">{props.startAdornment}</InputAdornment>),
            }}
        >
            {props.children}

            <MenuItem key={-1} value={''}>
                &nbsp;
            </MenuItem>
            {props.items?.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
export default FormFieldSelect;