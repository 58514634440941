import React, {useEffect, useState} from 'react';
import {
    Button, InputAdornment, Stack,
    TextField
} from "@mui/material";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {useIntl, FormattedMessage} from "react-intl";
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {CaptchaDto} from "../../../../../modules/account/dto/CaptchaDto";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmailIcon from '@mui/icons-material/Email';
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {ValidateUtils} from "../../../../../utils/ValidateUtils";
import {TaskApi} from "../../../../../modules/mail/api/TaskApi";

export type LoginEmailFormProps = {
    user: UserDto;
    captcha: CaptchaDto,
    messageMap: Map<string, string>;
    onChangeUser: (name: string, value: any) => void;
    onChangeCaptcha: (name: string, value: any) => void;
};

// 发送验证码的倒计时
let sendCaptchaInterval: NodeJS.Timeout;

const LoginEmailForm: React.FC<LoginEmailFormProps> = (props) => {
    const intl = useIntl();

    const [seconds, setSeconds] = useState(60);

    const [canSendCaptcha, setCanSendCaptcha] = useState(true);

    useEffect(() => {
        return () => {
            if (sendCaptchaInterval) {
                clearInterval(sendCaptchaInterval);
            }
        }
    }, []);

    useEffect(() => {
        if (seconds <= 0) {
            clearInterval(sendCaptchaInterval);
            setCanSendCaptcha(true);
            setSeconds(60);
        }
    }, [seconds])

    const _handleSendCaptchaCode = async () => {
        if (props.user.email === '') {
            MessageUtils.error(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_REQUIRED'}));
        } else if (!ValidateUtils.isEmail(props.user.email)) {
            MessageUtils.error(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_FORMAT_WRONG'}));
        } else {
            setCanSendCaptcha(false);
            sendCaptchaInterval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            const responseResult = await TaskApi.sendCaptchaCode(props.user.email, 'CAPTCHA_CODE', new Map<string, string>());
            if (responseResult.status === HttpStatus.SUCCESS) {
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_CAPTCHA_SEND_SUCCESS'}));
            }
        }
    }

    return (
        <Stack spacing={2}>
            <TextField
                placeholder={intl.formatMessage({id: "ACCOUNT_USER_EMAIL_REQUIRED"})}
                fullWidth
                required={true}
                error={props.messageMap.has("email")}
                value={props.user.email}
                onChange={(e) => {
                    props.onChangeUser("email", e.target.value);
                    props.onChangeCaptcha("captchaKey", e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon/>
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                placeholder={intl.formatMessage({id: "ACCOUNT_USER_CAPTCHA_REQUIRED"})}
                fullWidth
                required={true}
                error={props.messageMap.has("captchaValue")}
                value={props.captcha.captchaValue}
                onChange={(e) => {
                    props.onChangeCaptcha("captchaValue", e.target.value)
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <VerifiedUserIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                canSendCaptcha ? <Button onClick={_handleSendCaptchaCode}>
                                    <FormattedMessage id={'ACCOUNT_USER_CAPTCHA_RECEIVE'}/>
                                </Button> : <span>{seconds}</span>
                            }

                        </InputAdornment>
                    )
                }}
            />
        </Stack>
    );
};

export default LoginEmailForm;
