import React from 'react';
import {useIntl} from "react-intl";
import {RequestPlaceDto} from "../../../modules/vms/dto/RequestPlaceDto";
import {Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export type RequestPlaceListProps = {
    places: Array<RequestPlaceDto>;
};

const RequestPlaceList: React.FC<RequestPlaceListProps> = (props) => {
    const intl = useIntl();

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: 'VMS_VISIT_REQUEST_PLACE'})}/>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_PLACE_AREA_NAME'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_PLACE_AREA_CODE'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_PLACE_AREA_MANAGER_NAME'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_PLACE_AREA_MANAGER_EMAIL'})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.places.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.areaName}</TableCell>
                                <TableCell>{row.areaCode}</TableCell>
                                <TableCell>{row.areaManagerName}</TableCell>
                                <TableCell>{row.areaManagerEmail}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
};

export default RequestPlaceList;
