import React, {ReactNode} from 'react';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Formats} from "../constants/Formats";
import dayjs, {Dayjs} from "dayjs";
import {FormControl, InputAdornment, InputLabel, Stack} from "@mui/material";

type FormFieldDateRangeProps = {
    label?: string;
    fromValue: Dayjs;
    fromMinDate?: Dayjs;
    fromMaxDate?: Dayjs;
    fromOnChange?: (value: string) => void;
    fromStartAdornment?: ReactNode;
    toValue: Dayjs;
    toMinDate?: Dayjs;
    toMaxDate?: Dayjs;
    toOnChange?: (value: string) => void;
    toStartAdornment?: ReactNode;
};

const FormFieldDateRange: React.FC<FormFieldDateRangeProps> = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl variant={'standard'} margin={'normal'} fullWidth={true}>
                <InputLabel shrink>{props.label}</InputLabel>
                <Stack direction={'row'}>
                    <DatePicker
                        format={Formats.DATE_SLASH}
                        minDate={props.fromMinDate}
                        maxDate={props.fromMaxDate}
                        value={props.fromValue}
                        onChange={(data) => {
                            props.fromOnChange && props.fromOnChange(dayjs(data).format(Formats.DATE_SIMPLE))
                        }}
                        slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true,
                                InputProps: {
                                    startAdornment: (<InputAdornment position="start">{props.fromStartAdornment}</InputAdornment>)
                                },
                            }
                        }}
                    />
                    <span style={{
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>~</span>
                    <DatePicker
                        format={Formats.DATE_SLASH}
                        minDate={props.toMinDate}
                        maxDate={props.toMaxDate}
                        value={props.toValue}
                        onChange={(data) => {
                            props.toOnChange && props.toOnChange(dayjs(data).format(Formats.DATE_SIMPLE))
                        }}
                        slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true,
                                InputProps: {
                                    startAdornment: (<InputAdornment position="start">{props.toStartAdornment}</InputAdornment>)
                                },
                            }
                        }}
                    />
                </Stack>
            </FormControl>

        </LocalizationProvider>
    );
};
export default FormFieldDateRange;