export default {
    // 站点
    CMS_SITE_TITLE: '站点标题',
    CMS_SITE_TITLE_REQUIRED: '请输入站点标题',
    CMS_SITE_CODE: '站点编号',
    CMS_SITE_CODE_REQUIRED: '请输入站点编号',

    // 栏目
    CMS_CHANNEL_SITE: "所属站点",
    CMS_CHANNEL_SITE_REQUIRED: '请输入所属站点',
    CMS_CHANNEL_PARENT: "父专栏",
    CMS_CHANNEL_CODE: "编号",
    CMS_CHANNEL_CODE_PLACEHOLDER: "不输入编号则自动生成",
    CMS_CHANNEL_TITLE: "标题",
    CMS_CHANNEL_TITLE_REQUIRED: "请输入标题",
    CMS_CHANNEL_TITLE_DESC: "请输入专栏标题关键字",
    CMS_CHANNEL_TITLE_IMAGE: "标题图片",
    CMS_CHANNEL_TITLE_IMAGE_PREVIEW: "标题图片预览",
    CMS_CHANNEL_BANNER_IMAGE: "通栏图片",
    CMS_CHANNEL_RECOMMEND_IDS: "推荐内容",
    CMS_CHANNEL_BANNER_IMAGE_PREVIEW: "通栏图片预览",
    CMS_CHANNEL_RECOMMEND_SHOW_STYLE: "推荐内容样式",
    CMS_CHANNEL_SEQUENCE: "序号",
    CMS_CHANNEL_REMARKS: "备注",
    CMS_CHANNEL_TRACE_BATCH_CODE: "追踪批次号",
    CMS_CHANNEL_TRACE_PRODUCT_NAME: "追踪产品名",
    CMS_CHANNEL_STATUS: "状态",
    CMS_CHANNEL_STATUS_DRAFT: "草稿",
    CMS_CHANNEL_STATUS_PENDING_APPROVE: "待审批",
    CMS_CHANNEL_STATUS_APPROVE_PASS: "审批通过",
    CMS_CHANNEL_STATUS_APPROVE_REJECT: "审批拒绝",
    CMS_CHANNEL_STATUS_WITHDRAW: "下架",
    CMS_CHANNEL_STATUS_EXPIRE: "版权到期",
    CMS_CHANNEL_COPYRIGHT_EXPIRE_DATE: "版权到期日",
    CMS_CHANNEL_RESPONSIBLE_USER: "责任人",
    CMS_CHANNEL_APPROVER_USER: "最后审批者",
    CMS_CHANNEL_LAYOUT_TYPE: "布局类型",
    CMS_CHANNEL_LAYOUT_TYPE_DEFAULT: "默认布局",
    CMS_CHANNEL_LAYOUT_TYPE_BANNER: "通栏长图",
    CMS_CHANNEL_LAYOUT_DETAIL_DISPLAY: "布局展示详情",
    CMS_CHANNEL_EDITOR_PREVIEW: "编辑预览",
    CMS_CHANNEL_EDITOR_PREVIEW_ALERT: "预览专辑二维码为临时二维码，有效期30天，详情参照微信官方文档说明：https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/url-link.html",

    // 内容专辑
    CMS_CONTENT_SITE: "所属站点",
    CMS_CONTENT_CHANNEL: "所属专栏",
    CMS_CONTENT_TITLE: "标题",
    CMS_CONTENT_SUB_TITLE: "副标题",
    CMS_CONTENT_CODE: "编号",
    CMS_CONTENT_CODE_PLACEHOLDER: "不输入编号则自动生成",
    CMS_CONTENT_CLASSIFY: "分类",
    CMS_CONTENT_BASIC_INFO: "基本信息",
    CMS_CONTENT_BODY_EDITOR: "专辑编辑"
};
