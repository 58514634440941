import React from 'react';
import {Grid, Typography} from "@mui/material";

export type DescriptionsProps = {
    title?: string;
    children: Array<React.ReactNode>;
};

const Descriptions: React.FC<DescriptionsProps> = (props) => {
    return (
        <div>
            <Typography variant={'h6'}>{props.title}</Typography>
            <Grid container spacing={2}>
                {
                    props.children && props.children.map((child, index) =>
                        <Grid key={index} item xs={4}>
                            {child}
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
};
export default Descriptions;