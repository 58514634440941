import React from 'react';
import {useIntl} from "react-intl";
import {VisitorBlackListSearchCondition} from "../../../../../modules/vms/dto/VisitorBlackListSearchCondition";
import {Grid, Stack} from "@mui/material";
import SearchButton from "../../../../../components/buttons/SearchButton";
import AddButton from "../../../../../components/buttons/AddButton";
import FormFieldText from "../../../../../components/FormFieldText";

export type SearchConditionProps = {
    searchCondition: VisitorBlackListSearchCondition;
    onChange: (name: string, value: any) => void;
    onSearch: () => void;
    onAdd: () => void;
};

const SearchCondition: React.FC<SearchConditionProps> = (props) => {
    const intl = useIntl();

    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_NAME"})}
                               value={props.searchCondition.name}
                               onChange={(value) => {
                                   props.onChange("name", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL"})}
                               value={props.searchCondition.email}
                               onChange={(value) => {
                                   props.onChange("email", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE"})}
                               value={props.searchCondition.mobile}
                               onChange={(value) => {
                                   props.onChange("mobile", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <Stack spacing={2}>
                    <Stack direction={'row'} spacing={2}>
                        <SearchButton onClick={() => props.onSearch()}/>
                        <AddButton onClick={() => props.onAdd()}/>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default SearchCondition;
