import React, {useContext, useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {ResourceAppApi} from "../../../../../modules/account/api/ResourceAppApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack, Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {RoleDto} from "../../../../../modules/account/dto/RoleDto";
import CloseButton from "../../../../../components/buttons/CloseButton";
import {RoleApi} from "../../../../../modules/account/api/RoleApi";
import AddIconButton from "../../../../../components/buttons/AddIconButton";
import AppRoleDetailDialog from "./AppRoleDetailDialog";
import {useConfirm} from "material-ui-confirm";
import AppRoleMenuDialog from "./AppRoleMenuDialog";

export type AppRoleDialogProps = {
    open: boolean;
    appId: string;
    onClose: () => void;
};

const AppRoleDialog: React.FC<AppRoleDialogProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openRoleDetailModal, setOpenRoleDetailModal] = useState(false);
    const [openBindMenuModal, setOpenBindMenuModal] = useState(false);
    const [currentRow, setCurrentRow] = useState<RoleDto>(new RoleDto());

    const [roles, setRoles] = useState<Array<RoleDto>>([]);

    useEffect(() => {
        if (props.appId) {
            fetchAppRoles(props.appId);
        }
    }, [props.appId]);

    const fetchAppRoles = async (appId: string) => {
        const responseResult = await ResourceAppApi.getResourceAppRoles(appId);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setRoles(responseResult.data);
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleClickAdd = () => {
        const role = new RoleDto();
        role.appId = props.appId;
        setCurrentRow(role);
        setOpenRoleDetailModal(true);
    }

    const handleClickModify = (record: RoleDto) => {
        setCurrentRow(record);
        setOpenRoleDetailModal(true);
    }

    const handleClickDelete = async (record: RoleDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await RoleApi.deleteRole(record.id);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    fetchAppRoles(props.appId);
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleClickBindMenu = (record: RoleDto) => {
        setCurrentRow(record);
        setOpenBindMenuModal(true);
    }

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true} maxWidth={'md'}>
            <DialogTitle>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <FormattedMessage id={'ACCOUNT_RESOURCE_APP_ROLE'}/>
                    <AddIconButton onClick={handleClickAdd}/>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <AppRoleDetailDialog open={openRoleDetailModal} role={currentRow}
                                     onClose={() => setOpenRoleDetailModal(false)}
                                     onOk={() => fetchAppRoles(props.appId)}/>
                <AppRoleMenuDialog open={openBindMenuModal} appId={props.appId} roleId={currentRow.id}
                                     onClose={() => setOpenBindMenuModal(false)}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage({id: 'ACCOUNT_ROLE_CODE'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'ACCOUNT_ROLE_NAME'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: "ACCOUNT_ROLE_LEVEL"})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roles.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.level}</TableCell>
                                <TableCell>
                                    <Stack direction={'row'} spacing={1}>
                                        <a href={'javascript:void(0)'} onClick={() => handleClickBindMenu(row)}>
                                            <FormattedMessage id={'ACCOUNT_ROLE_MENUS_BIND'}/>
                                        </a>
                                        <a href={'javascript:void(0)'} onClick={() => handleClickModify(row)}>
                                            <FormattedMessage id={'COMMON_MODIFY'}/>
                                        </a>
                                        <a href={'javascript:void(0)'} onClick={() => handleClickDelete(row)}>
                                            <FormattedMessage id={'COMMON_DELETE'}/>
                                        </a>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={handleCancel}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppRoleDialog;
