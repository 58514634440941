import React from 'react';
import {Button} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DoneIcon from '@mui/icons-material/Done';

type ApproveButtonProps = {
    onClick: () => void;
    color?: string;
};

const ApproveButton: React.FC<ApproveButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <Button
            variant="outlined"
            size="small"
            // @ts-ignore
            color={props.color || "success"}
            startIcon={<DoneIcon/>}
            onClick={(e) => handleClick(e)}>
            <FormattedMessage id={'COMMON_APPROVE'}/>
        </Button>
    );
};
export default ApproveButton;
