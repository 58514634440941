import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {HttpStatus} from "../../../../../../modules/common/enums/HttpStatus";
import {CardDto} from "../../../../../../modules/vms/dto/CardDto";
import {CardApi} from "../../../../../../modules/vms/api/CardApi";
import {CardStatusType} from "../../../../../../modules/vms/enums/CardStatus";
import {CardClassifyType} from "../../../../../../modules/vms/enums/CardClassify";
import {MessageUtils} from "../../../../../../utils/MessageUtils";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField} from "@mui/material";

export type CardDetailDialogProps = {
    open: boolean;
    card: CardDto;
    onClose: () => void;
    onOk: () => void;
};

const CardDetailDialog: React.FC<CardDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.card.id ? 'COMMON_MODIFY' : 'COMMON_ADD';

    const [innerCard, setInnerCard] = useState<CardDto>(props.card);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerCard({...props.card});
    }, [props.card]);

    const handleChange = (name: string, value: any) => {
        setInnerCard(previousCard => ({...previousCard, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.card.id) {
                const responseResult = await CardApi.updateCard(innerCard);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await CardApi.createCard(innerCard);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_ADD_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        setMessageMap(new Map<string, string>());
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();

        if (innerCard.number === '') {
            newMessageMap.set('number', intl.formatMessage({id: 'VMS_CARD_NUMBER_REQUIRED'}));
        }

        if (innerCard.status === '') {
            newMessageMap.set('status', intl.formatMessage({id: 'VMS_CARD_STATUS_REQUIRED'}));
        }

        if (innerCard.classify === '') {
            newMessageMap.set('classify', intl.formatMessage({id: 'VMS_CARD_CLASSIFY_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={intl.formatMessage({id: "VMS_CARD_NUMBER"})}
                    margin="normal"
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    required={true}
                    error={messageMap.has("number")}
                    helperText={messageMap.get("number")}
                    value={innerCard.number}
                    onChange={(e) => {
                        handleChange('number', e.target.value);
                    }}
                />
                <TextField
                    select
                    required
                    fullWidth
                    label={intl.formatMessage({id: "VMS_CARD_STATUS"})}
                    error={messageMap.has("status")}
                    helperText={messageMap.get("status")}
                    variant="standard"
                    margin="normal"
                    value={innerCard.status} onChange={(e) => {
                    handleChange("status", e.target.value)
                }}>
                    {CardStatusType.List.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    required
                    fullWidth
                    label={intl.formatMessage({id: "VMS_CARD_CLASSIFY"})}
                    error={messageMap.has("classify")}
                    helperText={messageMap.get("classify")}
                    variant="standard"
                    margin="normal"
                    value={innerCard.classify} onChange={(e) => {
                    handleChange("classify", e.target.value)
                }}>
                    {CardClassifyType.List.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel} color="secondary">
                    <FormattedMessage id={'COMMON_CANCEL'}/>
                </Button>
                <Button variant="outlined" onClick={handleOk} color="primary">
                    <FormattedMessage id={'COMMON_OK'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CardDetailDialog;
