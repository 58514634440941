import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {LocationDto} from "../dto/LocationDto";

export const LocationApi = {

    /**
     * 获得位置列表
     * @param siteId 站点Id
     */
    getLocationList: async (siteId: string) => {
        try {
            const params = new Map<string, string>();
            params.set('siteId', siteId);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/location/list', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getLocationDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/location/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createLocation: async (body: LocationDto) => {
        try {
            const requestUrl = '/v1/vms/location/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateLocation: async (body: LocationDto) => {
        try {
            const requestUrl = '/v1/vms/location/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteLocation: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/location/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
