import React from 'react';
import {IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

type DeleteIconButtonProps = {
    onClick: () => void;
};

const DeleteIconButton: React.FC<DeleteIconButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <IconButton onClick={(e) => handleClick(e)}>
            <DeleteIcon/>
        </IconButton>
    );
};
export default DeleteIconButton;
