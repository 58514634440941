import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {Formats} from "../../../../constants/Formats";
import {LogOperationDto} from "../../../../modules/log/dto/LogOperationDto";
import dayjs from "dayjs";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import DetailDialog from "./DetailDialog";

export type SearchResultProps = {
    searchResult: Array<LogOperationDto>;
};

const SearchResult: React.FC<SearchResultProps> = (props) => {
    const intl = useIntl();

    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [currentRowId, setCurrentRowId] = useState('');

    const handleDetail = (row: LogOperationDto) => {
        setOpenDetailDialog(true);
        setCurrentRowId(row.id);
    }

    return (
        <div>
            <DetailDialog open={openDetailDialog} id={currentRowId} onClose={() => setOpenDetailDialog(false)}/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'LOG_OPERATION_OPERATOR_NAME'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'LOG_OPERATION_OPERATOR_IP'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "LOG_OPERATION_OPERATION"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "LOG_OPERATION_OPERATION_TIME"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "LOG_OPERATION_REMARKS"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.searchResult.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.operatorName}</TableCell>
                            <TableCell>{row.operatorIp}</TableCell>
                            <TableCell>{row.operation}</TableCell>
                            <TableCell>{dayjs(row.operationTime, Formats.DATE_TIME_SIMPLE).format(Formats.DATE_TIME_HYPHEN)}</TableCell>
                            <TableCell>{row.remarks}</TableCell>
                            <TableCell>
                                <a href={'javascript:void(0)'} onClick={() => handleDetail(row)}>
                                    <FormattedMessage id={'COMMON_DETAIL'}/>
                                </a>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default SearchResult;