import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {Formats} from "../../../../../constants/Formats";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import dayjs from "dayjs";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import RequestVisitorDetailDialog from "../../../components/RequestVisitorDetailDialog";
import {RequestVisitorApi} from "../../../../../modules/vms/api/RequestVisitorApi";
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import {VisitRequestApi} from "../../../../../modules/vms/api/VisitRequestApi";
import VisitRequestDetailDialog from "../../../components/VisitRequestDetailDialog";
import {Link, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import DictItemUtils from "../../../../../modules/dict/utils/DictItemUtils";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {VisitorAccessLogDto} from "../../../../../modules/vms/dto/VisitorAccessLogDto";

export type SearchResultProps = {
    searchResult: Array<RequestVisitorDto>;
    visitorTypes: Array<DictItemDto>;
};

const SearchResult: React.FC<SearchResultProps> = (props) => {
    const intl = useIntl();

    const [openRequestDetailModal, setOpenRequestDetailModal] = useState(false);
    const [openVisitorDetailModal, setOpenVisitorDetailModal] = useState(false);

    const [requestVisitor, setRequestVisitor] = useState(new RequestVisitorDto());

    const [visitRequest, setVisitRequest] = useState(new VisitRequestDto());

    const handleVisitDetail = async (record: RequestVisitorDto) => {
        const responseResult = await VisitRequestApi.getVisitRequestDetailById(record.requestId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setVisitRequest(responseResult.data);
            setOpenRequestDetailModal(true);
        }
    };

    const handleVisitorDetail = async (record: RequestVisitorDto) => {
        const responseResult = await RequestVisitorApi.getVisitorDetailById(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setRequestVisitor(responseResult.data);
            setOpenVisitorDetailModal(true);
        }
    };

    // @ts-ignore
    return (
        <div>

            <RequestVisitorDetailDialog open={openVisitorDetailModal}
                                        onClose={() => setOpenVisitorDetailModal(false)}
                                        requestVisitor={requestVisitor}/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISIT_REQUEST_NUMBER'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_ARRIVE_DATE'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_NAME"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_TYPE"})}</TableCell>

                        <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_CHECKIN_TIME"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_CHECKOUT_TIME"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.searchResult.map((row, index) => {
                        const checkOutLog = row.checkOutLog && Object.assign(new VisitorAccessLogDto(), row.checkOutLog);
                        const checkInLog = row.checkInLog && Object.assign(new VisitorAccessLogDto(), row.checkInLog);
                        return (
                            <TableRow key={row.id}>
                                <TableCell>{row.visitRequest?.number}</TableCell>
                                <TableCell>{dayjs(row.visitRequest?.arriveStartDate, Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}</TableCell>
                                <TableCell>{row.fullName}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.company}</TableCell>
                                <TableCell> {DictItemUtils.getText(props.visitorTypes, row.visitorType)}</TableCell>
                                <TableCell>{checkInLog?.fetchAccessDateTime()}</TableCell>
                                <TableCell>{checkOutLog?.fetchAccessDateTime()}</TableCell>
                                <TableCell>
                                    <Link href={'javascript:void(0)'} onClick={() => handleVisitorDetail(row)}>
                                        <FormattedMessage id={'COMMON_DETAIL'}/>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

export default SearchResult;