import React, {useContext} from 'react';
import {
    Button, Card, CardActions, CardContent, CardMedia, Typography
} from "@mui/material";
import {useIntl, FormattedMessage} from "react-intl";
import {ResourceAppDto} from "../../../../../modules/account/dto/ResourceAppDto";
import {LanguageContext} from "../../../../../context/LanguageContext";
import {Language} from "../../../../../modules/common/enums/Language";
import {useConfirm} from "material-ui-confirm";
import {useNavigate} from "react-router-dom";
import {AccountCacheKey} from "../../../../../modules/account/constants/AccountCacheKey";

export type TenantFormProps = {
    app: ResourceAppDto;
    active: boolean,
    onClickActive?: (item: ResourceAppDto) => void;
};

const AppCard: React.FC<TenantFormProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    // 多语言上下文设置
    const {language, setLanguage} = useContext(LanguageContext);

    const getName = () => {
        switch (language) {
            case Language.Enum.enUS:
                return props.app.nameEn;
            default:
                return props.app.nameZh;
        }
    }

    const handleClickActive = () => {
        confirm({title: intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_ACTIVE_CONFIRM'})})
            .then(() => {
                if (props.onClickActive) {
                    props.onClickActive(props.app);
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    }

    const handleClickAccess = () => {
        const accessToken = localStorage.getItem(AccountCacheKey.ACCESS_TOKEN);
        window.open(props.app.path + "?token=" + accessToken, '_blank');
    }

    return (
        <Card>
            <CardMedia
                sx={{height: 140}}
                image={props.app.icon}
                title={props.app.nameZh}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {getName()}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.app.remarks}
                </Typography>
            </CardContent>
            <CardActions>
                {
                    props.active ?
                        <Button variant={'outlined'} fullWidth={true} onClick={handleClickAccess}><FormattedMessage
                            id={'ACCOUNT_RESOURCE_APP_ACCESS'}/></Button> :
                        <Button variant={'outlined'} fullWidth={true} onClick={handleClickActive}><FormattedMessage
                            id={'ACCOUNT_RESOURCE_APP_ACTIVE'}/></Button>
                }
            </CardActions>
        </Card>
    );
};

export default AppCard;