import React from 'react';
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useConfirm} from "material-ui-confirm";

export type SearchResultProps = {
    searchResult: Array<UserDto>;
    onClickDelete: (record: UserDto) => void;
    onClickModify: (record: UserDto) => void;
    onClickRole: (record: UserDto) => void;
};

const SearchResult: React.FC<SearchResultProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const handleClickDetail = (record: UserDto) => {
        props.onClickModify(record);
    };

    const handleClickDelete = (record: UserDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(() => {
                console.log('ok');
                props.onClickDelete(record);
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleClickRole = (record: UserDto) => {
        props.onClickRole(record);
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell><FormattedMessage id={'ACCOUNT_USER_UID'}/></TableCell>
                    <TableCell><FormattedMessage id={'ACCOUNT_USER_NAME'}/></TableCell>
                    <TableCell><FormattedMessage id={'ACCOUNT_USER_EMAIL'}/></TableCell>
                    <TableCell><FormattedMessage id={'COMMON_ACTION'}/></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.searchResult.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {item.uid}
                        </TableCell>
                        <TableCell>
                            {item.fullName}
                        </TableCell>
                        <TableCell>
                            {item.email}
                        </TableCell>
                        <TableCell>
                            <Button size="small" onClick={() => handleClickDetail(item)}><FormattedMessage
                                id={'COMMON_MODIFY'}/></Button>
                            <Button size="small" onClick={() => handleClickRole(item)}><FormattedMessage
                                id={'ACCOUNT_USER_ROLE'}/></Button>
                            <Button size="small" onClick={() => handleClickDelete(item)}><FormattedMessage
                                id={'COMMON_DELETE'}/></Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default SearchResult;
