// import {message} from "antd";
import {enqueueSnackbar} from "notistack";

export const MessageUtils = {
    error: (content: string) => {
        // message.error(content);
        enqueueSnackbar(content, {variant: 'error'});
    },

    warning: (content: string) => {
        // message.warning(content);
        enqueueSnackbar(content, {variant: 'warning'});
    },

    success: (content: string) => {
        // message.success(content);
        enqueueSnackbar(content, {variant: 'success'});
    }
}