export default {
    // 站点
    CMS_SITE_TITLE: 'Site Title',
    CMS_SITE_TITLE_REQUIRED: 'Please fill in site title.',
    CMS_SITE_CODE: 'Site Code',
    CMS_SITE_CODE_REQUIRED: 'Please fill in site code.',

    // 栏目
    CMS_CHANNEL_SITE: "Site",
    CMS_CHANNEL_SITE_REQUIRED: 'Please fill in site',
    CMS_CHANNEL_PARENT: "Parent Channel",
    CMS_CHANNEL_CODE: "Code",
    CMS_CHANNEL_CODE_PLACEHOLDER: "Automatically generate without entering a number",
    CMS_CHANNEL_TITLE: "Title",
    CMS_CHANNEL_TITLE_REQUIRED: 'Please fill in title',
    CMS_CHANNEL_TITLE_DESC: "Please enter keywords for the column title",
    CMS_CHANNEL_TITLE_IMAGE: "Title Image",
    CMS_CHANNEL_TITLE_IMAGE_PREVIEW: "Title Image Preview",
    CMS_CHANNEL_BANNER_IMAGE: "Banner Image",
    CMS_CHANNEL_RECOMMEND_IDS: "Recommend Content",
    CMS_CHANNEL_BANNER_IMAGE_PREVIEW: "Banner Image Preview",
    CMS_CHANNEL_RECOMMEND_SHOW_STYLE: "Recommended Content Style",
    CMS_CHANNEL_SEQUENCE: "Sequence",
    CMS_CHANNEL_REMARKS: "Remarks",
    CMS_CHANNEL_TRACE_BATCH_CODE: "Trace Batch Code",
    CMS_CHANNEL_TRACE_PRODUCT_NAME: "Trace Product Name",
    CMS_CHANNEL_STATUS: "Status",
    CMS_CHANNEL_STATUS_DRAFT: "Draft",
    CMS_CHANNEL_STATUS_PENDING_APPROVE: "Pending Approve",
    CMS_CHANNEL_STATUS_APPROVE_PASS: "Approve Pass",
    CMS_CHANNEL_STATUS_APPROVE_REJECT: "Approve Reject",
    CMS_CHANNEL_STATUS_WITHDRAW: "Withdraw",
    CMS_CHANNEL_STATUS_EXPIRE: "Expire",
    CMS_CHANNEL_COPYRIGHT_EXPIRE_DATE: "Copyright Expire Date",
    CMS_CHANNEL_RESPONSIBLE_USER: "Responsible User",
    CMS_CHANNEL_APPROVER_USER: "Last Approver",
    CMS_CHANNEL_LAYOUT_TYPE: "Layout Type",
    CMS_CHANNEL_LAYOUT_TYPE_DEFAULT: "Default Layout Type",
    CMS_CHANNEL_LAYOUT_TYPE_BANNER: "Layout Type Banner",
    CMS_CHANNEL_LAYOUT_DETAIL_DISPLAY: "Layout Display Details",
    CMS_CHANNEL_EDITOR_PREVIEW: "Edit Preview",
    CMS_CHANNEL_EDITOR_PREVIEW_ALERT: "The preview album QR code is a temporary QR code, valid for 30 days. Please refer to the official WeChat documentation for details：https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/url-link.html",

    // 内容专辑
    CMS_CONTENT_SITE: "Site",
    CMS_CONTENT_CHANNEL: "Relation Channels",
    CMS_CONTENT_TITLE: "Title",
    CMS_CONTENT_SUB_TITLE: "Sub Title",
    CMS_CONTENT_CODE: "Code",
    CMS_CONTENT_CODE_PLACEHOLDER: "Automatically generate without entering a number",
    CMS_CONTENT_CLASSIFY: "Classify",
    CMS_CONTENT_BASIC_INFO: "Basic Info",
    CMS_CONTENT_BODY_EDITOR: "Content Editor"
};
