import React, {useEffect, useState} from 'react';
import DeviceList from "./components/DeviceList";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {DeviceDto} from "../../../../../modules/vms/dto/DeviceDto";
import {DeviceApi} from "../../../../../modules/vms/api/DeviceApi";
import {useIntl} from "react-intl";
import DeviceDetailDialog from "./components/DeviceDetailDialog";
import {MessageUtils} from "../../../../../utils/MessageUtils";

const Index: React.FC = () => {

    const intl = useIntl();

    const [deviceList, setDeviceList] = useState<Array<DeviceDto>>([]);

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<DeviceDto>(new DeviceDto());

    useEffect(() => {
        // 查询用户组列表
        fetchDeviceList();
    }, []);

    const fetchDeviceList = async () => {
        const responseResult = await DeviceApi.getDeviceList();
        if (responseResult.status === HttpStatus.SUCCESS) {
            setDeviceList(responseResult.data);
        }
    };

    const handleModify = async (record: DeviceDto) => {
        const responseResult = await DeviceApi.getDeviceDetail(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailModal(true);
        }
    };

    const handleDelete = async (record: DeviceDto) => {
        const responseResult = await DeviceApi.deleteDevice(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
            fetchDeviceList();
        }
    };

    const handleAdd = () => {
        setCurrentRow(new DeviceDto());
        setOpenDetailModal(true);
    };

    return (
        <div>
            <DeviceDetailDialog open={openDetailModal}
                                device={currentRow}
                                onOk={fetchDeviceList}
                                onClose={() => setOpenDetailModal(false)}/>
            <DeviceList deviceList={deviceList}
                        onRefresh={fetchDeviceList}
                        onAdd={handleAdd}
                        onModify={(record) => handleModify(record)}
                        onDelete={(record) => handleDelete(record)}/>
        </div>
    );
};

export default Index;
