import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {DictItemDto} from "../dto/DictItemDto";

export const DictItemApi = {

    // /**
    //  * 获得字典类列表
    //  * @param classId 字典类Id
    //  */
    // getDictItemList: async (classId: string) => {
    //     try {
    //         const params = new Map<string, string>();
    //         params.set('classId', classId);
    //         const requestUrl = HttpUtils.addQueryString('/v1/dict/item/list', params);
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    // getDictItemDetail: async (id: string) => {
    //     try {
    //         const params = new Map<string, string>();
    //         params.set('id', id);
    //         const requestUrl = HttpUtils.addQueryString('/v1/dict/item/detail', params);
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    createDictItem: async (body: DictItemDto) => {
        try {
            const requestUrl = '/v1/dict/item/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateDictItem: async (body: DictItemDto) => {
        try {
            const requestUrl = '/v1/dict/item/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteDictItem: async (body: DictItemDto) => {
        try {
            const requestUrl = '/v1/dict/item/delete';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
