import React, {useState} from 'react';
import {useIntl} from "react-intl";
import SiteDetailDialog from "./SiteDetailDialog";
import {SiteDto} from "../../../../../modules/vms/dto/SiteDto";
import {SiteApi} from "../../../../../modules/vms/api/SiteApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {
    Card,
    CardHeader,
    List, ListItem,
    ListItemIcon, ListItemText,
} from "@mui/material";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import AddIconButton from "../../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../../components/buttons/RefreshIconButton";
import LanguageIcon from '@mui/icons-material/Language';
import DeleteIconButton from "../../../../../components/buttons/DeleteIconButton";
import ModifyIconButton from "../../../../../components/buttons/ModifyIconButton";
import {useConfirm} from "material-ui-confirm";

export type SiteListProps = {
    onSelect: (value: SiteDto) => void;
    onRefresh: () => void;
    siteList: Array<SiteDto>;
    selectedSiteId?: string;
};

const SiteList: React.FC<SiteListProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<SiteDto>(new SiteDto());

    const handleAdd = () => {
        setCurrentRow(new SiteDto());
        setOpenDetailModal(true);
    };

    const handleModify = async (record: SiteDto) => {
        const responseResult = await SiteApi.getSiteDetail(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailModal(true);
        }
    };

    const handleDelete = (record: SiteDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await SiteApi.deleteSite(record.id);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    props.onRefresh();
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleSelect = (item: SiteDto) => {
        props.onSelect(item);
    }

    return (
        <div>
            <SiteDetailDialog open={openDetailModal}
                              onOk={() => props.onRefresh()}
                              onClose={() => setOpenDetailModal(false)}
                              site={currentRow}/>
            <Card>
                <CardHeader
                    action={
                        <div>
                            <AddIconButton onClick={handleAdd}/>
                            <RefreshIconButton onClick={() => props.onRefresh()}/>
                        </div>
                    }
                    title={intl.formatMessage({id: 'VMS_SITE_LIST'})}
                />
                <List>
                    {props.siteList.map((row, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={row.id === props.selectedSiteId}
                            onClick={(e) => handleSelect(row)}
                            secondaryAction={
                                <div>
                                    <ModifyIconButton onClick={() => handleModify(row)}/>
                                    <DeleteIconButton onClick={() => handleDelete(row)}/>
                                </div>
                            }>
                            <ListItemIcon>
                                <LanguageIcon/>
                            </ListItemIcon>
                            <ListItemText primary={row.name}/>
                        </ListItem>
                    ))}
                </List>
            </Card>
        </div>
    );
};

export default SiteList;
