export const RoutePath = {
    DICT_MANAGE: '/dict/manage',
    LOG_OPERATION: '/log/operation',
    ACCOUNT_USER_LOGIN: '/account/user/login',
    ACCOUNT_USER_REGISTER: '/account/user/register',
    ACCOUNT_USER_PROFILE: '/account/user/profile',
    ACCOUNT_USER_SECURITY: '/account/user/security',
    ACCOUNT_TENANT_PROFILE: '/account/tenant/profile',
    ACCOUNT_TENANT_MEMBER: '/account/tenant/member',
    ACCOUNT_APP_ACTIVATED: '/account/app/activated',
    ACCOUNT_APP_MARKET: '/account/app/market',
    ACCOUNT_APP_MANAGE: '/account/app/manage',
    VMS_VISITOR_REGISTER_INVITE: '/vms/visitor-register/invite',
    VMS_VISITOR_REGISTER_HISTORY: '/vms/visitor-register/history',
    VMS_VISITOR_REGISTER_PENDING: "/vms/visitor-register/pending",
    VMS_VISITOR_MANAGE_CHECKIN: '/vms/visitor-manage/checkin',
    VMS_VISITOR_MANAGE_CHECKIN_SIGN: '/vms/visitor-manage/checkin/sign',
    VMS_VISITOR_MANAGE_HISTORY: '/vms/visitor-manage/history',
    VMS_VISITOR_MANAGE_BLACKLIST: '/vms/visitor-manage/blacklist',
    VMS_VISITOR_CARD_LIST: '/visitor-manage/visitor/card/list',
    VMS_VISITOR_DEVICE_LIST: '/visitor-manage/visitor/device/list',
    VMS_SYSTEM_MANAGE_AREA: '/vms/system-manage/area',
    VMS_SYSTEM_MANAGE_VISITOR_TYPE: '/vms/system-manage/visitor/type',

    VMS_VISITOR_MANAGE_HISTORY_NO_TENANT: '/vms/visitor-manage/historyNoTenant',

}