import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormFieldSelect from "../../../../components/FormFieldSelect";
import FormFieldText from "../../../../components/FormFieldText";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import {DictClassDto} from "../../../../modules/dict/dto/DictClassDto";
import {DictItemDto} from "../../../../modules/dict/dto/DictItemDto";
import {useIntl} from "react-intl";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {DictItemApi} from "../../../../modules/dict/api/DictItemApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";

export type DictItemDetailModalProps = {
    open: boolean;
    classList: Array<DictClassDto>;
    dictItem: DictItemDto;
    onClose: () => void;
    onOk: () => void;
};

const DictItemDetailModal: React.FC<DictItemDetailModalProps> = (props) => {
    const intl = useIntl();
    const title = props.dictItem.id ? 'DICT_ITEM_MODIFY' : 'DICT_ITEM_CREATE';

    const [innerDictItem, setInnerDictItem] = useState<DictItemDto>(props.dictItem);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerDictItem({...props.dictItem});
    }, [props.dictItem]);

    const handleChange = (name: string, value: any) => {
        setInnerDictItem(previousDictItem => ({...previousDictItem, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.dictItem.id) {
                const responseResult = await DictItemApi.updateDictItem(innerDictItem);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'DICT_ITEM_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await DictItemApi.createDictItem(innerDictItem);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'DICT_ITEM_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerDictItem.classId === '') {
            newMessageMap.set('classId', intl.formatMessage({id: 'DICT_ITEM_CLASS_REQUIRED'}));
        }
        if (innerDictItem.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'DICT_ITEM_CODE_REQUIRED'}));
        }
        if (innerDictItem.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'DICT_ITEM_NAME_REQUIRED'}));
        }
        if (innerDictItem.value === '') {
            newMessageMap.set('value', intl.formatMessage({id: 'DICT_ITEM_VALUE_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldSelect label={intl.formatMessage({id: "DICT_ITEM_CLASS"})}
                                 required={true} errorText={messageMap.get("classId")}
                                 value={innerDictItem.classId}
                                 onChange={(value) => {
                                     handleChange("classId", value)
                                 }}
                                 items={props.classList.map((item) => {
                                         return {label: item.name, value: item.id}
                                     }
                                 )}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_ITEM_CODE"})} value={innerDictItem.code}
                               required={true} errorText={messageMap.get("code")}
                               onChange={(value) => handleChange('code', value)}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_ITEM_NAME"})} value={innerDictItem.name}
                               required={true} errorText={messageMap.get("name")}
                               onChange={(value) => handleChange('name', value)}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_ITEM_VALUE"})} value={innerDictItem.value}
                               required={true} errorText={messageMap.get("value")}
                               onChange={(value) => handleChange('value', value)}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_ITEM_SEQUENCE"})} value={innerDictItem.sequence}
                               type={'number'}
                               onChange={(value) => handleChange('sequence', value)}/>
                <FormFieldText label={intl.formatMessage({id: "COMMON_REMARKS"})} value={innerDictItem.remarks}
                               onChange={(value) => handleChange('remarks', value)}
                               maxRows={4}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default DictItemDetailModal;
