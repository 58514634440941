export default {
    // 审批流定义
    WORKFLOW_DEFINITION_TITLE_CREATE: "创建工作流定义",
    WORKFLOW_DEFINITION_TITLE_UPDATE: "更新工作流定义",
    WORKFLOW_DEFINITION_TITLE_BASIC_INFO: "基本信息",
    WORKFLOW_DEFINITION_TITLE_NODE_LIST: "节点流程",
    WORKFLOW_DEFINITION_TITLE_SELECT: "选择工作流定义",
    WORKFLOW_DEFINITION_CODE: "Code",
    WORKFLOW_DEFINITION_NAME: "Name",
    WORKFLOW_DEFINITION_CODE_REQUIRED: "编号是必填项",
    WORKFLOW_DEFINITION_NAME_REQUIRED: "名称是必填项",

    // 审批流定义节点
    WORKFLOW_DEFINITION_NODE_TITLE_EXECUTOR_RANK: "执行者配置",
    WORKFLOW_DEFINITION_NODE_CODE: "Code",
    WORKFLOW_DEFINITION_NODE_NAME: "Name",
    WORKFLOW_DEFINITION_NODE_NODE_INDEX: "Sequence",
    WORKFLOW_DEFINITION_NODE_TYPE: "Type",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE: "执行者类型",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_NONE: "无效",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_USER: "用户",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_ROLE: "角色",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_DEPARTMENT: "部门",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_DEPARTMENT_MANAGER: "部门主管",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_EXECUTOR_NODE_DEPARTMENT_MANAGER: "审批节点的部门主管",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_FORM_DEPARTMENT_MANAGER: "关联字段的部门主管",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_EXECUTOR_NODE_SUPERVISOR: "审批节点的上级主管",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_FORM_SUPERVISOR: "关联字段的上级主管",

    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_FORM_FIELD: "表单字段",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_TYPE_SPECIAL: "自定义",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_RANK: "Executor",
    WORKFLOW_DEFINITION_NODE_TYPE_INITIAL: "Initial",
    WORKFLOW_DEFINITION_NODE_TYPE_ACTION: "Action",
    WORKFLOW_DEFINITION_NODE_TYPE_DECISION: "Decision",
    WORKFLOW_DEFINITION_NODE_TYPE_FINAL: "Final",
    WORKFLOW_DEFINITION_NODE_TITLE_EXECUTOR_RANK_KEYS: "关键值输入",
    WORKFLOW_DEFINITION_NODE_EXECUTOR_RANK_KEYS: "关键值",

    // 审批表单定义
    WORKFLOW_FORM_TITLE_CREATE: "创建工作流表单",
    WORKFLOW_FORM_TITLE_UPDATE: "更新工作流表单",
    WORKFLOW_FORM_TITLE_BASIC_INFO: "基本信息",
    WORKFLOW_FORM_TITLE_LIST: "字段列表",
    WORKFLOW_FORM_CODE: "编号",
    WORKFLOW_FORM_NAME: "名称",
    WORKFLOW_FORM_DEFINITION: "关联审批流",
    WORKFLOW_FORM_CODE_REQUIRED: "编号是必填项",
    WORKFLOW_FORM_NAME_REQUIRED: "名称是必填项",
    WORKFLOW_FORM_DEFINITION_REQUIRED: "关联审批流必填项",

    // 审批表单字段
    WORKFLOW_FORM_ITEM_TITLE_DATA_EDIT: "编辑选项数据",
    WORKFLOW_FORM_ITEM_TYPE: "类型",
    WORKFLOW_FORM_ITEM_SUB_TYPE: "子类型",
    WORKFLOW_FORM_ITEM_TYPE_TEXT: "文本",
    WORKFLOW_FORM_ITEM_TYPE_TEXT_AREA: "多行文本",
    WORKFLOW_FORM_ITEM_TYPE_SELECT: "单择框",
    WORKFLOW_FORM_ITEM_TYPE_SELECT_MULTIPLE: "多选框",
    WORKFLOW_FORM_ITEM_TYPE_DATE: "日期选择",
    WORKFLOW_FORM_ITEM_TYPE_DATE_TIME: "日期时间",
    WORKFLOW_FORM_ITEM_TYPE_FILE: "附件",
    WORKFLOW_FORM_ITEM_TYPE_EMPLOYEE_EMAIL: "员工邮箱",
    WORKFLOW_FORM_ITEM_TYPE_EMPLOYEE_MOBILE: "员工手机",
    WORKFLOW_FORM_ITEM_TYPE_DEPARTMENT: "部门",
    WORKFLOW_FORM_ITEM_TYPE_LIST: "列表",
    WORKFLOW_FORM_ITEM_CODE: "编号",
    WORKFLOW_FORM_ITEM_NAME: "名称",
    WORKFLOW_FORM_ITEM_DEFAULT_VALUE: "默认值",
    WORKFLOW_FORM_ITEM_REQUIRED: "必填项",
    WORKFLOW_FORM_ITEM_DATA: "选项数据",
    WORKFLOW_FORM_ITEM_DATA_LABEL: "名称",
    WORKFLOW_FORM_ITEM_DATA_VALUE: "值",
    WORKFLOW_FORM_ITEM_DATA_LABEL_REQUIRED: "名称必填项",
    WORKFLOW_FORM_ITEM_DATA_VALUE_REQUIRED: "值必填项",
    WORKFLOW_FORM_ITEM_CODE_REQUIRED: "编号必填项",
    WORKFLOW_FORM_ITEM_NAME_REQUIRED: "名称必填项",

    // 申请表单定义
    WORKFLOW_APPLY_FORM_TITLE_CREATE: "创建工作流表单",
    WORKFLOW_APPLY_FORM_TITLE_UPDATE: "更新工作流表单",
    WORKFLOW_APPLY_FORM_TITLE_BASIC_INFO: "基本信息",
    WORKFLOW_APPLY_FORM_TITLE_LIST: "字段列表",
    WORKFLOW_APPLY_FORM_SELECT_FORM: "选择表单",
    WORKFLOW_APPLY_FORM_NUMBER: "编号",
    WORKFLOW_APPLY_FORM_NAME: "名称",
    WORKFLOW_APPLY_FORM_DEFINITION: "关联审批流",
    WORKFLOW_APPLY_FORM_APPLICANT_NAME: "申请者",
    WORKFLOW_APPLY_FORM_CODE_REQUIRED: "编号是必填项",
    WORKFLOW_APPLY_FORM_NAME_REQUIRED: "名称是必填项",
    WORKFLOW_APPLY_FORM_DEFINITION_REQUIRED: "关联审批流必填项",

    // 审批流
    WORKFLOW_PROCESS_APPROVE: 'Approve',
    WORKFLOW_PROCESS_REJECT: 'Reject',
    WORKFLOW_PROCESS_REJECT_REMARKS: 'Reject Remarks',
    WORKFLOW_PROCESS_DETAIL: '审批流详情',
    WORKFLOW_PROCESS_TITLE_THREAD_INDEX: "审批节点",
    WORKFLOW_PROCESS_APPROVE_SUCCESS: "Approve pass succeed.",
    WORKFLOW_PROCESS_APPROVE_CONFIRM: "Do you confirm to approve pass this request?",
    WORKFLOW_PROCESS_REJECT_SUCCESS: "Approve reject succeed.",
    WORKFLOW_PROCESS_REJECT_CONFIRM: "Do you confirm to approve reject this request?",
    WORKFLOW_PROCESS_REJECT_REMARKS_REQUIRED: "Please Input Reject reason",
    WORKFLOW_PROCESS_APPROVE_LIST_DONE: "Great, we have done all the operations!",
    // 审批流节点
    WORKFLOW_THREAD_TITLE_REJECT_MODAL: "Reject Remarks",
    WORKFLOW_THREAD_TITLE_MANAGER_APPROVE: "Manager Approve",
    WORKFLOW_THREAD_EXECUTOR_RANK_NAME: "Approver",
    WORKFLOW_THREAD_EXECUTOR_RANK_ROLE_NAME: "审批角色",
    WORKFLOW_THREAD_EXECUTOR_RANK_EMAIL: "Email",
    WORKFLOW_THREAD_EXECUTOR_RANK_MOBILE: "Mobile",
    WORKFLOW_THREAD_EXECUTOR_RANK_DEPARTMENT: "Department",
    WORKFLOW_THREAD_STATUS: "Status",
    WORKFLOW_THREAD_STATUS_NONE: "Wait",
    WORKFLOW_THREAD_STATUS_ACCEPTED: "Approving",
    WORKFLOW_THREAD_STATUS_APPROVED: "Approved",
    WORKFLOW_THREAD_STATUS_REJECTED: "Rejected",
    WORKFLOW_THREAD_REMARKS: "Remarks",
    WORKFLOW_THREAD_START_TIME: "Start Time",
    WORKFLOW_THREAD_FINISH_TIME: "Finish TIme",
    WORKFLOW_THREAD_REMARKS_REQUIRED: "Remarks is required",
    // 审批代理人
    WORKFLOW_DEPUTY_SEARCH: "Deputy Search",
    WORKFLOW_DEPUTY_SEARCH_PLACEHOLDER: "Enter the agent's email or phone number to query",
    WORKFLOW_DEPUTY_TITLE_DEPUTY: "Deputy",
    WORKFLOW_DEPUTY_TITLE_DELETE_MODAL: "Deputy Delete",
    WORKFLOW_DEPUTY_AGENT_NAME: "Agent Name",
    WORKFLOW_DEPUTY_AGENT_EMAIL: "Agent Email",
    WORKFLOW_DEPUTY_AGENT_MOBILE: "Agent Mobile",
    WORKFLOW_DEPUTY_START_TIME: "Start Time",
    WORKFLOW_DEPUTY_END_TIME: "End Time",
    WORKFLOW_DEPUTY_AGENT_NAME_REQUIRED: "Agent name is required",
    WORKFLOW_DEPUTY_AGENT_EMAIL_REQUIRED: "Agent email is required",
    WORKFLOW_DEPUTY_AGENT_MOBILE_REQUIRED: "Agent mobile is required",
    WORKFLOW_DEPUTY_START_TIME_REQUIRED: "Start time is required",
    WORKFLOW_DEPUTY_END_TIME_REQUIRED: "End time is required",
};