export default {
    DICT_TYPE_CREATE: '创建字典类型',
    DICT_TYPE_CREATE_SUCCESS: '创建字典类型成功',
    DICT_TYPE_MODIFY: '修改字典类型',
    DICT_TYPE_MODIFY_SUCCESS: '修改字典类型成功',
    DICT_TYPE_LIST: '字典类型列表',
    DICT_TYPE_CODE: '编号',
    DICT_TYPE_CODE_REQUIRED: '请输入编号',
    DICT_TYPE_NAME: '名称',
    DICT_TYPE_NAME_REQUIRED: '请输入名称',
    DICT_TYPE_SEQUENCE: '排序',

    DICT_CLASS_CREATE: '创建字典类',
    DICT_CLASS_CREATE_SUCCESS: '创建字典类成功',
    DICT_CLASS_MODIFY: '修改字典类',
    DICT_CLASS_MODIFY_SUCCESS: '修改字典类成功',
    DICT_CLASS_TYPE: '所属字典类型',
    DICT_CLASS_LIST: '字典类列表',
    DICT_CLASS_CODE: '编号',
    DICT_CLASS_CODE_REQUIRED: '请输入编号',
    DICT_CLASS_NAME: '名称',
    DICT_CLASS_NAME_REQUIRED: '请输入名称',
    DICT_CLASS_SEQUENCE: '排序',

    DICT_ITEM_CREATE: '创建字典项',
    DICT_ITEM_CREATE_SUCCESS: '创建字典项成功',
    DICT_ITEM_MODIFY: '修改字典项',
    DICT_ITEM_MODIFY_SUCCESS: '修改字典项成功',
    DICT_ITEM_CLASS: '所属字典类',
    DICT_ITEM_LIST: '字典项列表',
    DICT_ITEM_CODE: '编号',
    DICT_ITEM_CODE_REQUIRED: '请输入编号',
    DICT_ITEM_NAME: '名称',
    DICT_ITEM_NAME_REQUIRED: '请输入名称',
    DICT_ITEM_VALUE: '值',
    DICT_ITEM_VALUE_REQUIRED: '请输入值',
    DICT_ITEM_SEQUENCE: '排序',
};