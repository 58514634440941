import React from "react";
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import StoreIcon from '@mui/icons-material/Store';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AppsIcon from '@mui/icons-material/Apps';
import BadgeIcon from '@mui/icons-material/Badge';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ListIcon from '@mui/icons-material/List';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CloudIcon from '@mui/icons-material/Cloud';
import FolderIcon from '@mui/icons-material/Folder';
import ComputerIcon from '@mui/icons-material/Computer';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ShareIcon from '@mui/icons-material/Share';

export const IconUtils = {
    getIcon: (icon: string) => {
        if (icon) {
            switch (icon) {
                case 'person':
                    return <PersonIcon/>;
                case 'security':
                    return <SecurityIcon/>;
                case 'account-security':
                    return <AdminPanelSettingsIcon/>;
                case 'apartment':
                    return <ApartmentIcon/>;
                case 'store':
                    return <StoreIcon/>;
                case 'group':
                    return <GroupIcon/>;
                case 'group-add':
                    return <GroupAddIcon/>;
                case 'apps':
                    return <AppsIcon/>;
                case 'badge':
                    return <BadgeIcon/>;
                case 'setting':
                    return <SettingsIcon/>;
                case 'room':
                    return <MeetingRoomIcon/>;
                case 'list':
                    return <ListIcon/>;
                case 'manage-accounts':
                    return <ManageAccountsIcon/>
                case 'check-list':
                    return <ChecklistIcon/>
                case 'person-off':
                    return <PersonOffIcon/>
                case 'app-registration':
                    return <AppRegistrationIcon/>
                case 'mall':
                    return <LocalMallIcon/>
                case 'touch-app':
                    return <TouchAppIcon/>
                case 'cloud':
                    return <CloudIcon/>
                case 'folder':
                    return <FolderIcon/>
                case 'computer':
                    return <ComputerIcon/>
                case 'event':
                    return <EventIcon/>
                case 'logout':
                    return <LogoutIcon/>
                case 'menu-book':
                    return <MenuBookIcon/>
                case 'share':
                    return <ShareIcon/>
                default:
                    return <ListIcon/>;
            }
        }
    },
}