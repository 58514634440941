import * as React from 'react';
import {useEffect, useState} from "react";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {ResourceAppDto} from "../../../../modules/account/dto/ResourceAppDto";
import {ResourceAppApi} from "../../../../modules/account/api/ResourceAppApi";
import {Card, CardContent, CardHeader} from "@mui/material";
import SearchResult from "./components/SearchResult";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {useIntl} from "react-intl";
import AddIconButton from "../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../components/buttons/RefreshIconButton";
import {useConfirm} from "material-ui-confirm";
import AppDetailDialog from "./components/AppDetailDialog";
import AppMenuDialog from "./components/AppMenuDialog";
import AppRoleDialog from "./components/AppRoleDialog";

export default function Index() {
    const intl = useIntl();

    const confirm = useConfirm();

    const [searchResult, setSearchResult] = useState<Array<ResourceAppDto>>([]);
    const [currentRow, setCurrentRow] = useState<ResourceAppDto>(new ResourceAppDto());
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openMenuModal, setOpenMenuModal] = useState(false);
    const [openRoleModal, setOpenRoleModal] = useState(false);

    useEffect(() => {
        fetchApps();
    }, [])

    const fetchApps = async () => {
        const responseResult = await ResourceAppApi.getResourceAppList();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setSearchResult(responseResult.data);
        }
    };

    const handleAdd = () => {
        setCurrentRow(new ResourceAppDto());
        setOpenDetailModal(true);
    };

    const handleClickModify = async (record: ResourceAppDto) => {
        setCurrentRow(record);
        setOpenDetailModal(true);
    };

    const handleClickMenu = (record: ResourceAppDto) => {
        setCurrentRow(record);
        setOpenMenuModal(true);
    }

    const handleClickRole = (record: ResourceAppDto) => {
        setCurrentRow(record);
        setOpenRoleModal(true);
    }

    const handleClickDelete = async (record: ResourceAppDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await ResourceAppApi.deleteResourceApp(record.id);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    fetchApps();
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    return (
        <div>
            <AppDetailDialog open={openDetailModal}
                             onOk={() => fetchApps()}
                             onClose={() => setOpenDetailModal(false)}
                             resourceApp={currentRow}/>
            <AppMenuDialog open={openMenuModal} appId={currentRow.id} onClose={() => setOpenMenuModal(false)}/>
            <AppRoleDialog open={openRoleModal} appId={currentRow.id} onClose={() => setOpenRoleModal(false)}/>
            <Card>
                <CardHeader
                    action={
                        <div>
                            <AddIconButton onClick={handleAdd}/>
                            <RefreshIconButton onClick={fetchApps}/>
                        </div>
                    }
                />
                <CardContent>
                    <SearchResult searchResult={searchResult} onClickModify={handleClickModify}
                                  onClickMenu={(record) => handleClickMenu(record)}
                                  onClickAction={(record) => handleClickMenu(record)}
                                  onClickRole={(record) => handleClickRole(record)}
                                  onClickDelete={handleClickDelete}/>
                </CardContent>
            </Card>
        </div>
    );
}