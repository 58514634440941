import React from 'react';
import {
    Stack,
    Table, TableBody, TableCell, TableHead, TableRow
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {ResourceAppDto} from "../../../../../modules/account/dto/ResourceAppDto";
import ModifyIconButton from "../../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../../components/buttons/DeleteIconButton";

export type SearchResult = {
    searchResult: Array<ResourceAppDto>;
    onClickModify: (record: ResourceAppDto) => void;
    onClickMenu: (record: ResourceAppDto) => void;
    onClickAction: (record: ResourceAppDto) => void;
    onClickRole: (record: ResourceAppDto) => void;
    onClickDelete: (record: ResourceAppDto) => void;
};

const SearchResult: React.FC<SearchResult> = (props) => {
    const intl = useIntl();

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_CODE'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_NAME_ZH'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_NAME_EN"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_SEQUENCE"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.searchResult.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{row.nameZh}</TableCell>
                            <TableCell>{row.nameEn}</TableCell>
                            <TableCell>{row.sequence}</TableCell>
                            <TableCell>
                                <Stack direction={'row'} spacing={1}>
                                    <a href={'javascript:void(0)'} onClick={(e) => props.onClickModify(row)}>
                                        <FormattedMessage id={'COMMON_MODIFY'}/>
                                    </a>
                                    <a href={'javascript:void(0)'} onClick={(e) => props.onClickMenu(row)}>
                                        <FormattedMessage id={'ACCOUNT_RESOURCE_APP_MENU'}/>
                                    </a>
                                    <a href={'javascript:void(0)'} onClick={(e) => props.onClickRole(row)}>
                                        <FormattedMessage id={'ACCOUNT_RESOURCE_APP_ROLE'}/>
                                    </a>
                                    <a href={'javascript:void(0)'} onClick={(e) => props.onClickDelete(row)}>
                                        <FormattedMessage id={'COMMON_DELETE'}/>
                                    </a>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default SearchResult;