import React, {useState} from 'react';
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import PaginationBar from "../../../../../components/PaginationBar";
import {useIntl} from "react-intl";
import {PaginationDto} from "../../../../../modules/common/dto/PaginationDto";
import SearchCondition from "./components/SearchCondition";
import SearchResult from "./components/SearchResult";
import {CardSearchCondition} from "../../../../../modules/vms/dto/CardSearchCondition";
import {CardDto} from "../../../../../modules/vms/dto/CardDto";
import {CardApi} from "../../../../../modules/vms/api/CardApi";
import CardDetailDialog from "./components/CardDetailDialog";
import {Card} from "@mui/material";
import {MessageUtils} from "../../../../../utils/MessageUtils";

const Index: React.FC = () => {

    const intl = useIntl();

    // 查询条件
    const [searchCondition, setSearchCondition] = useState(new CardSearchCondition());

    // 查询结果
    const [searchResult, setSearchResult] = useState(new Array<CardDto>());

    // 分页数据
    const [pagination, setPagination] = useState(new PaginationDto());

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<CardDto>(new CardDto());

    const handleChangeCondition = (name: string, value: any) => {
        setSearchCondition({...searchCondition, [name]: value});
    };

    const handleSearch = async (searchCondition: CardSearchCondition) => {
        const responseResult = await CardApi.queryCards(searchCondition);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setSearchResult(responseResult.data);
            setPagination(responseResult.pagination);
        }
    };

    const handleModify = async (record: CardDto) => {
        const responseResult = await CardApi.getCardDetail(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailModal(true);
        }
    };

    const handleDelete = async (record: CardDto) => {
        const responseResult = await CardApi.deleteCard(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
            handleSearch(searchCondition);
        }
    };

    const handleAdd = () => {
        setCurrentRow(new CardDto());
        setOpenDetailModal(true);
    };

    const handlePagination = (page: number, pageSize: number) => {
        const newSearchCondition = Object.assign({}, searchCondition);
        newSearchCondition.currentPage = page;
        newSearchCondition.pageSize = pageSize;
        handleSearch(newSearchCondition);
    };

    return (
        <div>
            <CardDetailDialog open={openDetailModal}
                              card={currentRow}
                              onClose={() => setOpenDetailModal(false)}
                              onOk={() => handleSearch(searchCondition)}/>
            <Card>
                <SearchCondition searchCondition={searchCondition}
                                 onChange={handleChangeCondition}
                                 onSearch={() => handleSearch(searchCondition)}
                                 onAdd={handleAdd}/>
            </Card>

            <br/>

            <Card>
                <PaginationBar pagination={pagination} onChange={handlePagination}/>
                <SearchResult searchResult={searchResult}
                              onDelete={(record) => handleDelete(record)}
                              onModify={(record) => handleModify(record)}/>
            </Card>
        </div>
    );
};

export default Index;
