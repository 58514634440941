import dayjs from 'dayjs';
import {PaginationDto} from "../../common/dto/PaginationDto";
import {Formats} from "../../../constants/Formats";

export class LogOperationSearchCondition extends PaginationDto {
  operatorName: string = '';
  operation: string = '';
  startTime: string = dayjs().add(-7, 'days').format(Formats.DATE_SIMPLE + '000000');
  endTime: string = dayjs().format(Formats.DATE_SIMPLE + '235959');
}
