import React, {useEffect, useState} from 'react';
import {
    Button, Stack, TextField
} from "@mui/material";
import {useIntl, FormattedMessage} from "react-intl";
import {TenantDto} from "../../../../../modules/account/dto/TenantDto";
import {TenantApi} from "../../../../../modules/account/api/TenantApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../../utils/MessageUtils";

export type TenantFormProps = {
    tenant: TenantDto;
};

const TenantForm: React.FC<TenantFormProps> = (props) => {
    const intl = useIntl();

    const [innerTenant, setInnerTenant] = useState<TenantDto>(props.tenant);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    const [editFlag, setEditFlag] = useState(false);

    useEffect(() => {
        setInnerTenant({...props.tenant});
    }, [props.tenant]);

    const handleChange = (name: string, value: any) => {
        setInnerTenant(previousTenant => ({...previousTenant, [name]: value}));
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerTenant.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'ACCOUNT_TENANT_CODE_REQUIRED'}));
        }
        if (innerTenant.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'ACCOUNT_TENANT_NAME_REQUIRED'}));
        }
        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const handleCancelEdit = () => {
        setEditFlag(false);
        setInnerTenant(props.tenant);
    }

    const handleSave = async () => {
        if (validate()) {
            const responseResult = await TenantApi.updateMyTenant(innerTenant);
            if (responseResult.status === HttpStatus.SUCCESS) {
                fetchMyTenant();
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_TENANT_UPDATE_SUCCESS'}))
            }
        }
    }

    const fetchMyTenant = async () => {
        const responseResult = await TenantApi.getMyTenant();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setEditFlag(false);
        }
    };

    return (
        <Stack spacing={2}>
            <TextField
                variant="standard"
                label={intl.formatMessage({id: "ACCOUNT_TENANT_CODE"})}
                required={true}
                disabled={!editFlag}
                error={messageMap.has("code")}
                helperText={messageMap.get("code")}
                value={innerTenant.code}
                onChange={(e) => {
                    handleChange("code", e.target.value)
                }}
            />

            <TextField
                variant="standard"
                label={intl.formatMessage({id: "ACCOUNT_TENANT_NAME"})}
                required={true}
                disabled={!editFlag}
                error={messageMap.has("name")}
                helperText={messageMap.get("name")}
                value={innerTenant.name}
                onChange={(e) => {
                    handleChange("name", e.target.value)
                }}
            />
            {
                editFlag ?
                    <Stack direction={'row'} spacing={2}>
                        <Button variant="contained" onClick={handleSave}>
                            <FormattedMessage id={'COMMON_SAVE'}/>
                        </Button>
                        <Button variant="outlined" onClick={handleCancelEdit}>
                            <FormattedMessage id={'COMMON_CANCEL'}/>
                        </Button>
                    </Stack> :
                    <Button variant="outlined" onClick={() => setEditFlag(true)}>
                        <FormattedMessage id={'COMMON_EDIT'}/>
                    </Button>
            }
        </Stack>
    );
};

export default TenantForm;
