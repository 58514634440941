export const Formats = {

  DATE_TIME_SIMPLE: "YYYYMMDDHHmmss" as const,
  DATE_TIME_HYPHEN: "YYYY-MM-DD HH:mm:ss" as const,
  DATE_TIME_SLASH: "YYYY/MM/DD HH:mm:ss" as const,
  DATE_TIME_MONTH_DATE_HYPHEN: "MM-DD HH:mm:ss" as const,

  DATE_TIME_FULL_SIMPLE: "YYYYMMDDHHmmssSSS" as const,
  DATE_TIME_FULL_HYPHEN: "YYYY-MM-DD HH:mm:ss:SSS" as const,
  DATE_TIME_FULL_SLASH: "YYYY/MM/DD HH:mm:ss:SSS" as const,

  DATE_TIME_MINUTE_SIMPLE: "YYYYMMDDHHmm" as const,
  DATE_TIME_MINUTE_HYPHEN: "YYYY-MM-DD HH:mm" as const,

  DATE_TIME_HOUR_SIMPLE: "YYYYMMDDHH" as const,

  DATE_SIMPLE: "YYYYMMDD" as const,
  DATE_HYPHEN: "YYYY-MM-DD" as const,
  DATE_SLASH: "YYYY/MM/DD" as const,
  DATE_SLASH_EN: "MM/DD/YYYY" as const,

  DATE_YEAR_MONTH_SIMPLE: "YYYYMM" as const,
  DATE_YEAR_MONTH_HYPHEN: "YYYY-MM" as const,
  DATE_YEAR_MONTH_SLASH: "YYYY/MM" as const,
  DATE_YEAR_MONTH_ZH: "YYYY年MM月" as const,

  DATE_MONTH_DAY_HYPHEN: "MM-DD" as const,
  DATE_MONTH_DAY_SLASH: "MM/DD" as const,

  TIME_SIMPLE: "HHmmss" as const,
  TIME_COLON: "HH:mm:ss" as const,
  TIME_HOUR_MINUTE_COLON: "HH:mm" as const,
  AT_THE: "HHmmss" as const,

  WeekMap: new Map<number, string>([
    [0, "COMMON_SUNDAY"],
    [1, "COMMON_MONDAY"],
    [2, "COMMON_TUESDAY"],
    [3, "COMMON_WEDNESDAY"],
    [4, "COMMON_THURSDAY"],
    [5, "COMMON_FRIDAY"],
    [6, "COMMON_SATURDAY"]
  ])
}
