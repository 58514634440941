import React, {useEffect, useState} from 'react';
import {Card, CardHeader, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import AddIconButton from "../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../components/buttons/RefreshIconButton";
import ModifyIconButton from "../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../components/buttons/DeleteIconButton";
import {useConfirm} from "material-ui-confirm";
import {DictClassDto} from "../../../../modules/dict/dto/DictClassDto";
import {DictItemDto} from "../../../../modules/dict/dto/DictItemDto";
import {useIntl} from "react-intl";
import {DictItemApi} from "../../../../modules/dict/api/DictItemApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../utils/MessageUtils";
import DictItemDetailModal from "./DictItemDetailModal";
import AttachFileIcon from '@mui/icons-material/AttachFile';

export type DictItemListProps = {
    selectedDictClassId: string;
    dictClassList: Array<DictClassDto>;
    dictItemList: Array<DictItemDto>;
    onRefresh: (dictClassId: string) => void;
};

const DictItemList: React.FC<DictItemListProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [selectedDictItemId, setSelectedDictItemId] = useState('');
    const [currentRow, setCurrentRow] = useState<DictItemDto>(new DictItemDto());

    const handleAdd = () => {
        const dictItem = new DictItemDto();
        dictItem.classId = props.selectedDictClassId;
        setCurrentRow(dictItem);
        setOpenDetailModal(true);
    };

    const handleModify = async (record: DictItemDto) => {
        setCurrentRow(record);
        setOpenDetailModal(true);
    };

    const handleDelete = async (record: DictItemDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await DictItemApi.deleteDictItem(record);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    props.onRefresh(record.classId);
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleSelect = (item: DictItemDto) => {
        setSelectedDictItemId(item.id);
    }

    return (
        <div>
            <DictItemDetailModal open={openDetailModal}
                                 onOk={() => props.onRefresh(props.selectedDictClassId)}
                                 onClose={() => setOpenDetailModal(false)}
                                 classList={props.dictClassList}
                                 dictItem={currentRow}/>
            <Card>
                <CardHeader
                    action={
                        <div>
                            <AddIconButton onClick={handleAdd}/>
                            <RefreshIconButton onClick={() => props.onRefresh(props.selectedDictClassId)}/>
                        </div>
                    }
                    title={intl.formatMessage({id: 'DICT_ITEM_LIST'})}
                />
                <List>
                    {props.dictItemList.map((row, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={row.id === selectedDictItemId}
                            onClick={(e) => handleSelect(row)}
                            secondaryAction={
                                <div>
                                    <ModifyIconButton onClick={() => handleModify(row)}/>
                                    <DeleteIconButton onClick={() => handleDelete(row)}/>
                                </div>
                            }>
                            <ListItemText primary={row.name} secondary={row.code}/>
                        </ListItem>
                    ))}
                </List>
            </Card>
        </div>
    );
};

export default DictItemList;
