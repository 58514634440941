import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import LocationDetailDialog from "./LocationDetailDialog";
import {SiteDto} from "../../../../../modules/vms/dto/SiteDto";
import {LocationDto} from "../../../../../modules/vms/dto/LocationDto";
import {LocationApi} from "../../../../../modules/vms/api/LocationApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {
    Card,
    CardHeader, List,
    ListItem,
    ListItemIcon, ListItemText,
} from "@mui/material";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import AddIconButton from "../../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../../components/buttons/RefreshIconButton";
import ModifyIconButton from "../../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../../components/buttons/DeleteIconButton";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useConfirm} from "material-ui-confirm";

export type LocationListProps = {
    selectedSiteId: string;
    siteList: Array<SiteDto>;
    locationList: Array<LocationDto>;
    selectedLocationId?: string;
    onSelect: (value: LocationDto) => void;
    onRefresh: (dictTypeId: string) => void;
};

const LocationList: React.FC<LocationListProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<LocationDto>(new LocationDto());

    const handleAdd = () => {
        const location = new LocationDto();
        location.siteId = props.selectedSiteId;
        setCurrentRow(location);
        setOpenDetailModal(true);
    };

    const handleModify = async (record: LocationDto) => {
        const responseResult = await LocationApi.getLocationDetail(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailModal(true);
        }
    };

    const handleDelete = async (record: LocationDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await LocationApi.deleteLocation(record.id);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    props.onRefresh(record.siteId);
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleSelect = (item: LocationDto) => {
        setCurrentRow(item);
        props.onSelect(item);
    }

    return (
        <div>
            <LocationDetailDialog open={openDetailModal}
                                  onRefresh={(dictTypeId) => props.onRefresh(dictTypeId)}
                                  onClose={() => setOpenDetailModal(false)}
                                  siteList={props.siteList}
                                  location={currentRow}/>
            <Card>
                <CardHeader
                    action={
                        <div>
                            <AddIconButton onClick={handleAdd}/>
                            <RefreshIconButton onClick={() => props.onRefresh(props.selectedSiteId)}/>
                        </div>
                    }
                    title={intl.formatMessage({id: 'VMS_LOCATION_LIST'})}
                />
                <List>
                    {props.locationList.map((row, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={row.id === props.selectedLocationId}
                            onClick={(e) => handleSelect(row)}
                            secondaryAction={
                                <div>
                                    <ModifyIconButton onClick={() => handleModify(row)}/>
                                    <DeleteIconButton onClick={() => handleDelete(row)}/>
                                </div>
                            }>
                            <ListItemIcon>
                                <LocationOnIcon/>
                            </ListItemIcon>
                            <ListItemText primary={row.name}/>
                        </ListItem>
                    ))}
                </List>
            </Card>
        </div>
    );
};

export default LocationList;