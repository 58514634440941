import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Table, TableBody, TableCell,
    TableHead, TableRow,
    Tabs
} from "@mui/material";
import CloseButton from "../../../../components/buttons/CloseButton";
import {LogOperationDto} from "../../../../modules/log/dto/LogOperationDto";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {LogOperationApi} from "../../../../modules/log/api/LogOperationApi";
import Descriptions from "../../../../components/Descriptions";
import DescriptionItem from "../../../../components/DescriptionItem";
import DictItemUtils from "../../../../modules/dict/utils/DictItemUtils";
import dayjs from "dayjs";
import {Formats} from "../../../../constants/Formats";

export type DetailDialogProps = {
    open: boolean;
    id: string;
    onClose: () => void;
};

const DetailDialog: React.FC<DetailDialogProps> = (props) => {
    const intl = useIntl();

    const [tabIndex, setTabIndex] = React.useState(0);

    const [logOperation, setLogOperation] = useState<LogOperationDto>();

    useEffect(() => {
        if (props.open && props.id) {
            fetchLogOperationDetail(props.id);
        }
    }, [props.id]);

    const fetchLogOperationDetail = async (id: string) => {
        const responseResult = await LogOperationApi.getLogOperationDetail(id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setLogOperation(responseResult.data);
        }
    };

    const handleClose = () => {
        props.onClose();
    };

    const renderDetail = () => {
        if (tabIndex === 0) {
            return (
                <Descriptions>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_OPERATOR_NAME'})}>
                        {logOperation?.operatorName}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_OPERATOR_IP'})}>
                        {logOperation?.operatorIp}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_OPERATION'})}>
                        {logOperation?.operation}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_OPERATION_TIME'})}>
                        {dayjs(logOperation?.operationTime, Formats.DATE_TIME_SIMPLE).format(Formats.DATE_TIME_HYPHEN)}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_TABLE_NAME'})}>
                        {logOperation?.tableName}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_TABLE_KEY'})}>
                        {logOperation?.tableKey}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_REMARKS'})}>
                        {logOperation?.remarks}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'LOG_OPERATION_USER_AGENT'})}>
                        {logOperation?.userAgent}
                    </DescriptionItem>
                </Descriptions>
            )
        } else if (tabIndex === 1) {
            return (
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{intl.formatMessage({id: 'LOG_FIELD_NAME'})}</TableCell>
                                <TableCell>{intl.formatMessage({id: 'LOG_FIELD_SOURCE'})}</TableCell>
                                <TableCell>{intl.formatMessage({id: "LOG_FIELD_TARGET"})}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logOperation?.logFields.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.fieldName}</TableCell>
                                    <TableCell>{row.source}</TableCell>
                                    <TableCell>{row.target}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )
        }
    }

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>{intl.formatMessage({id: "LOG_OPERATION"})}</DialogTitle>
            <DialogContent>
                <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)}>
                    <Tab label={intl.formatMessage({id: 'LOG_OPERATION'})}
                         value={0}/>
                    <Tab label={intl.formatMessage({id: 'LOG_FIELD'})}
                         value={1}/>
                </Tabs>
                <div style={{padding: '1rem 0'}}>
                    {renderDetail()}
                </div>
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={handleClose}/>
            </DialogActions>
        </Dialog>
    );
};

export default DetailDialog;
