import React from 'react';
import {IconButton} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

type SearchIconButtonProps = {
    onClick: () => void;
};

const SearchIconButton: React.FC<SearchIconButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <IconButton onClick={(e) => handleClick(e)}>
            <SearchIcon/>
        </IconButton>
    );
};
export default SearchIconButton;
