import {AxiosRequest, HttpUtils} from '../../../utils/HttpUtils';
import {HttpStatus} from "../../common/enums/HttpStatus";
import {ResourceMenuDto} from "../dto/ResourceMenuDto";

export const ResourceMenuApi = {

    // getResourceMenuTreeList: async (appCode: string) => {
    //     const responseResult = await ResourceMenuApi.getResourceMenuList(appCode, "");
    //     if (responseResult.status === HttpStatus.SUCCESS) {
    //         responseResult.data = ResourceMenuApi.constructResourceMenuTree(responseResult.data);
    //     }
    //     return responseResult;
    // },

    // getResourceMenuList: async (appCode: string, roleId:string) => {
    //     try {
    //         const params = new Map<string, string>();
    //         params.set('appCode', appCode);
    //         params.set('roleId', roleId);
    //         const requestUrl = HttpUtils.addQueryString('/v1/account/resource/menu/list', params);
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    constructResourceMenuTree: (resourceMenuList: Array<ResourceMenuDto>) => {
        // 构建树
        const resourceMenuMap = new Map<string, ResourceMenuDto>();
        const rootResourceMenuList = new Array<ResourceMenuDto>();
        for (let resourceMenu of resourceMenuList) {
            resourceMenuMap.set(resourceMenu.id, resourceMenu);
        }

        for (let resourceMenu of resourceMenuList) {
            if (resourceMenu.parentId && resourceMenuMap.has(resourceMenu.parentId)) {
                const parentResourceMenu = resourceMenuMap.get(resourceMenu.parentId);
                if (parentResourceMenu) {
                    // 加了parent的引用，提交的数据转json会异常
                    // resourceMenu.parent = parentResourceMenu;
                    if (!parentResourceMenu.children) {
                        parentResourceMenu.children = [];
                    }
                    parentResourceMenu.children.push(resourceMenu);
                }
            } else {
                rootResourceMenuList.push(resourceMenu);
            }
        }
        return rootResourceMenuList;
    },

    getResourceMenuActions: async (menuId: string) => {
        try {
            const params = new Map<string, string>();
            params.set('menuId', menuId);
            const requestUrl = HttpUtils.addQueryString('/v1/account/resource/menu/actions', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createResourceMenu: async (body: ResourceMenuDto) => {
        try {
            const requestUrl = '/v1/account/resource/menu/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateResourceMenu: async (body: ResourceMenuDto) => {
        try {
            const requestUrl = '/v1/account/resource/menu/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteResourceMenu: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/account/resource/menu/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    syncMenuActions: async (menuId: string, body: Array<string>) => {
        try {
            const params = new Map<string, string>();
            params.set('menuId', menuId);
            const requestUrl = HttpUtils.addQueryString('/v1/account/resource/menu/actions/sync', params);
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
