import {ProfileDev} from "./Profile_dev";
import {ProfileUat} from "./Profile_uat";
import {ProfileProd} from "./Profile_prod";

let INIT_ENV = ProfileDev;
let env = 'dev';
if (typeof process !== undefined) {
    // @ts-ignore
    env = process.env.REACT_APP_ENV
}
console.log('当前运行环境 ===>', env);
if (env === 'dev') {
    // 本地环境配置
    INIT_ENV = ProfileDev;
} else if (env === 'uat') {
    // 测试环境配置
    INIT_ENV = ProfileUat;
} else if (env === 'prod') {
    // 线上环境配置
    INIT_ENV = ProfileProd;
}
export const INIT_ENV_TYPE = env;

// 网关服务的地址
export const MATRIX_GATEWAY_SERVICE_HOST = INIT_ENV.MATRIX_GATEWAY_SERVICE_HOST;

// 认证路径
export const MATRIX_LOGIN_PAGE_URL = INIT_ENV.MATRIX_LOGIN_PAGE_URL;

// 连接 VMS Web Socket 服务的默认地址
export const VMS_WEB_SOCKET_HOST = INIT_ENV.VMS_WEB_SOCKET_HOST;

// 连接护照扫描仪 Web Socket 服务的默认地址
export const VMS_WEB_SOCKET_HOST_PASSPORT = INIT_ENV.VMS_WEB_SOCKET_HOST_PASSPORT

// 内容管理系统APP的代号
export const MATRIX_APP_ID = 'a1cb968421484952973bad3da40b8aa9';