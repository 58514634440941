export default {
    ACCOUNT_USER_ADD: 'Add User',
    ACCOUNT_USER_ADD_SUCCESS: 'Add User Successful',
    ACCOUNT_USER_MODIFY: 'Modify User',
    ACCOUNT_USER_MODIFY_SUCCESS: 'Modify User Successful',
    ACCOUNT_USER_LOGIN: 'User Login',
    ACCOUNT_USER_LOGIN_AGREE_POLICY: 'By login, I agree ',
    ACCOUNT_USER_PRIVACY_POLICY: '《Privacy Policy》',
    ACCOUNT_USER_AGREEMENT: '《User Agreement》',
    ACCOUNT_USER_REGISTER: 'New Account',
    ACCOUNT_USER_REGISTER_AGREE_POLICY: 'By sign up, I agree ',
    ACCOUNT_USER_REGISTER_SUCCESS: 'User registration successful, automatic login redirects to homepage',
    ACCOUNT_USER_FORGOT_PASSWORD: 'Forgot Password',
    ACCOUNT_USER_LOGOUT: 'User Logout',
    ACCOUNT_USER_UID: 'UID',
    ACCOUNT_USER_UID_REQUIRED: 'Please fill in UID',
    ACCOUNT_USER_PASSWORD: 'Password',
    ACCOUNT_USER_PASSWORD_MODIFY: 'Modify Password',
    ACCOUNT_USER_PASSWORD_MODIFY_SUCCESS: 'Modify Password Successfully',
    ACCOUNT_USER_PASSWORD_MODIFY_HELP: 'Regular replacement helps with account security',
    ACCOUNT_USER_PASSWORD_RESET: 'Reset Password',
    ACCOUNT_USER_PASSWORD_REQUIRED: 'Please fill in password',
    ACCOUNT_USER_PASSWORD_STRENGTH_REQUIRED: "The password must be no less than 8 digits and contain a combination of English, numbers, and special symbols",
    ACCOUNT_USER_PASSWORD_NEW: 'New Password',
    ACCOUNT_USER_PASSWORD_NEW_REQUIRED: 'Please input new password',
    ACCOUNT_USER_PASSWORD_NEW_CONFIRM: 'New Password Confirm',
    ACCOUNT_USER_PASSWORD_NEW_CONFIRM_REQUIRED: 'Please input new password again',
    ACCOUNT_USER_PASSWORD_NEW_CONFIRM_FAILED: 'The two password inputs are inconsistent',
    ACCOUNT_USER_PASSWORD_OLD: 'Old Password',
    ACCOUNT_USER_PASSWORD_OLD_REQUIRED: 'Please input old password',
    ACCOUNT_USER_EMAIL: 'Email',
    ACCOUNT_USER_EMAIL_MODIFY: 'Modify Email',
    ACCOUNT_USER_EMAIL_MODIFY_HELP: 'Used for login and password retrieval',
    ACCOUNT_USER_EMAIL_MODIFY_SUCCESS: 'Email modify successfully',
    ACCOUNT_USER_EMAIL_REQUIRED: 'Please input email',
    ACCOUNT_USER_EMAIL_FORMAT_WRONG: "Please enter a correctly formatted email address",
    ACCOUNT_USER_EMAIL_EDIT_HELP: 'Please modify the email address in the account security page',
    ACCOUNT_USER_MOBILE: 'Mobile',
    ACCOUNT_USER_MOBILE_EDIT_HELP: 'Please modify the mobile in the account security page',
    ACCOUNT_USER_STATUS: 'Status',
    ACCOUNT_USER_STATUS_ACTIVE: 'Active',
    ACCOUNT_USER_STATUS_INACTIVE: 'Inactive',
    ACCOUNT_USER_NAME: 'Name',
    ACCOUNT_USER_NAME_REQUIRED: 'Please fill in user name',
    ACCOUNT_USER_LAST_NAME: 'Last Name',
    ACCOUNT_USER_LAST_NAME_REQUIRED: 'Please input last name',
    ACCOUNT_USER_FIRST_NAME: 'First Name',
    ACCOUNT_USER_FIRST_NAME_REQUIRED: 'Please input first name',
    ACCOUNT_USER_GENDER: 'Gender',
    ACCOUNT_USER_GENDER_NONE: 'None',
    ACCOUNT_USER_GENDER_MALE: 'Male',
    ACCOUNT_USER_GENDER_FEMALE: 'Female',
    ACCOUNT_USER_ROLE: 'Role',
    ACCOUNT_USER_ROLE_SETTING: 'Role Setting',
    ACCOUNT_USER_ROLE_SETTING_SUCCESS: 'Role setting successful',
    ACCOUNT_USER_ROLE_REQUIRED: 'Please select role',
    ACCOUNT_USER_SUPERVISOR: 'Supervisor',
    ACCOUNT_USER_SUPERVISOR_SEARCH_PLACEHOLDER: 'Please click to search for the superior you want to set up',
    ACCOUNT_USER_SEARCH: 'User Search',
    ACCOUNT_USER_LOCKED: 'Due to consecutive incorrect account and password input, the account has been locked for 5 minutes. Please try again in 5 minutes',
    ACCOUNT_USER_PASSWORD_WRONG: 'Wrong Password',
    ACCOUNT_USER_PROFILE: "User Profile",
    ACCOUNT_USER_CAPTCHA: "Captcha",
    ACCOUNT_USER_CAPTCHA_RECEIVE: "Receive Captcha",
    ACCOUNT_USER_CAPTCHA_REQUIRED: 'Please input captcha',
    ACCOUNT_USER_CAPTCHA_SEND_SUCCESS: 'The verification code was successfully sent. Please check and fill in the correct verification code',
    ACCOUNT_USER_AUTH_TYPE_UID: 'UID Password',
    ACCOUNT_USER_AUTH_TYPE_EMAIL: 'Email Captcha',
    ACCOUNT_USER_HAVE_ACCOUNT: 'Already have an account? ',
    ACCOUNT_USER_UPDATE_SUCCESS: 'User information updated successfully',

    ACCOUNT_TENANT_CODE: 'Company Code',
    ACCOUNT_TENANT_NAME: 'Company Name',

    ACCOUNT_ROLE_CREATE: 'Create Role',
    ACCOUNT_ROLE_CREATE_SUCCESS: 'Create Role Successful',
    ACCOUNT_ROLE_MODIFY: 'Modify Role',
    ACCOUNT_ROLE_MODIFY_SUCCESS: 'Modify Role Successful',
    ACCOUNT_ROLE_CODE: 'Role Code',
    ACCOUNT_ROLE_CODE_REQUIRED: 'Please input role code',
    ACCOUNT_ROLE_NAME: 'Role Name',
    ACCOUNT_ROLE_NAME_REQUIRED: 'Please input role name',
    ACCOUNT_ROLE_LEVEL: 'Role Level',
    ACCOUNT_ROLE_LEVEL_REQUIRED: 'Please input role level',
    ACCOUNT_ROLE_MENUS_BIND: 'Bind Menu',
    ACCOUNT_ROLE_MENUS_BIND_SUCCESS: 'Bind Menu Successful',
    ACCOUNT_ROLE_ACTIONS_BIND: 'Bind Action',
    ACCOUNT_ROLE_ACTIONS_BIND_SUCCESS: 'Bind Action Successful',

    ACCOUNT_RESOURCE_APP_CREATE: 'Create APP',
    ACCOUNT_RESOURCE_APP_CREATE_SUCCESS: 'Create APP Successful',
    ACCOUNT_RESOURCE_APP_MODIFY: 'Modify APP',
    ACCOUNT_RESOURCE_APP_MODIFY_SUCCESS: 'Modify APP Successful',
    ACCOUNT_RESOURCE_APP_ACTIVE: 'Active',
    ACCOUNT_RESOURCE_APP_ACTIVE_CONFIRM: 'Are you sure you want to activate the application?',
    ACCOUNT_RESOURCE_APP_ACTIVATED: 'Activated APP',
    ACCOUNT_RESOURCE_APP_INACTIVATED: 'Inactivated APP',
    ACCOUNT_RESOURCE_APP_ACTIVE_SUCCESS: 'Successfully opened the application, has become the administrator of the application, and can grant permissions to other members',
    ACCOUNT_RESOURCE_APP_ACCESS: 'Access',
    ACCOUNT_RESOURCE_APP_CODE: 'APP Code',
    ACCOUNT_RESOURCE_APP_NAME_ZH: 'APP Chinese Name',
    ACCOUNT_RESOURCE_APP_NAME_EN: 'APP English Name',
    ACCOUNT_RESOURCE_APP_PATH: 'APP Path',
    ACCOUNT_RESOURCE_APP_ICON: 'APP Icon',
    ACCOUNT_RESOURCE_APP_SEQUENCE: 'Sequence',
    ACCOUNT_RESOURCE_APP_MENU: 'Menu',
    ACCOUNT_RESOURCE_APP_ACTION: 'Action',
    ACCOUNT_RESOURCE_APP_ROLE: 'Role',

    ACCOUNT_RESOURCE_MENU_CREATE: 'Create Menu',
    ACCOUNT_RESOURCE_MENU_CREATE_SUCCESS: 'Create Menu Successful',
    ACCOUNT_RESOURCE_MENU_MODIFY: 'Modify Menu',
    ACCOUNT_RESOURCE_MENU_MODIFY_SUCCESS: 'Modify Menu Successful',
    ACCOUNT_RESOURCE_MENU_PARENT: 'Parent Menu',
    ACCOUNT_RESOURCE_MENU_CODE: 'Menu Code',
    ACCOUNT_RESOURCE_MENU_CODE_REQUIRED: 'Please input menu code',
    ACCOUNT_RESOURCE_MENU_NAME_ZH: 'Menu Chinese Name',
    ACCOUNT_RESOURCE_MENU_NAME_ZH_REQUIRED: 'Please input menu chinese name',
    ACCOUNT_RESOURCE_MENU_NAME_EN: 'Menu English Name',
    ACCOUNT_RESOURCE_MENU_NAME_EN_REQUIRED: 'Please input menu english name',
    ACCOUNT_RESOURCE_MENU_PATH: 'Menu Path',
    ACCOUNT_RESOURCE_MENU_PATH_REQUIRED: '请输入菜单路径',
    ACCOUNT_RESOURCE_MENU_ICON: 'Menu Icon',
    ACCOUNT_RESOURCE_MENU_SEQUENCE: 'Sequence',
    ACCOUNT_RESOURCE_MENU_ACTIONS_BIND: 'Bind Actions',
    ACCOUNT_RESOURCE_MENU_ACTIONS_BIND_SUCCESS: 'Bind Actions Successful',

    ACCOUNT_RESOURCE_ACTION_APP_ID: '所属应用',
    ACCOUNT_RESOURCE_ACTION_APP_ID_REQUIRED: '请选择所属应用',
    ACCOUNT_RESOURCE_ACTION_CODE: '操作编号',
    ACCOUNT_RESOURCE_ACTION_CODE_REQUIRED: '请输入操作编号',
    ACCOUNT_RESOURCE_ACTION_NAME: '操作名称',
    ACCOUNT_RESOURCE_ACTION_NAME_REQUIRED: '请输入操作名称',
    ACCOUNT_RESOURCE_ACTION_PATH: '操作路径',
    ACCOUNT_RESOURCE_ACTION_PATH_REQUIRED: '请输入操作路径',

    // 组织管理-组织
    ACCOUNT_DEPARTMENT_LIST: "Department List",
    ACCOUNT_DEPARTMENT_PARENT: "Parent",
    ACCOUNT_DEPARTMENT_COMPANY: "Company",
    ACCOUNT_DEPARTMENT_COMPANY_REQUIRED: "Please select company",
    ACCOUNT_DEPARTMENT_COMPANY_PLACEHOLDER: "Select Company",
    ACCOUNT_DEPARTMENT_CODE: "Code",
    ACCOUNT_DEPARTMENT_NAME: "Name",
    ACCOUNT_DEPARTMENT_TYPE: "Type",
    ACCOUNT_DEPARTMENT_TYPE_COMPANY: "Company",
    ACCOUNT_DEPARTMENT_TYPE_DEPARTMENT: "Department",
    ACCOUNT_DEPARTMENT_CONTACTS: "Contacts",
    ACCOUNT_DEPARTMENT_TELEPHONE: "Telephone",
    ACCOUNT_DEPARTMENT_ADDRESS: "Address",
    ACCOUNT_DEPARTMENT_DISABLED: "Disabled",
    ACCOUNT_DEPARTMENT_TEMPORARY: "Temporary",
    ACCOUNT_DEPARTMENT_DATA_GROUP: "Data Group",
    ACCOUNT_DEPARTMENT_MANAGER: "Manager",
    ACCOUNT_DEPARTMENT_CODE_REQUIRED: "Department code is required",
    ACCOUNT_DEPARTMENT_NAME_REQUIRED: "Department name is required",
    ACCOUNT_DEPARTMENT_TYPE_REQUIRED: "Department type is required",
    ACCOUNT_DEPARTMENT_CREATE_SUCCESS: "Create organization success",
    ACCOUNT_DEPARTMENT_UPDATE_SUCCESS: "Update organization success",
    ACCOUNT_DEPARTMENT_DELETE_SUCCESS: "Delete organization success",
    ACCOUNT_DEPARTMENT_DELETE_CONFIRM: "Please confirm to delete organization",
    ACCOUNT_DEPARTMENT_PARENT_DEAD_CYCLE: "Could not set self as parent",
    ACCOUNT_DEPARTMENT_HAVE_CHILDREN: "Department have children",
    ACCOUNT_DEPARTMENT_HAVE_EMPLOYEE: "The organization have employee",
    ACCOUNT_DEPARTMENT_NOT_FOUND: "Department not found",
};
