import {ApplyThreadDto} from "./ApplyThreadDto";

export class ApplyProcessDto {
    keyId: string = '';
    id: string = '';
    definitionId: string = '';
    definitionCode: string = '';
    currentThreadId: string = '';
    currentNodeIndex: number = 0;

    threadList: Array<ApplyThreadDto> = [];
}