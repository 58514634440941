import {RoleDto} from "./RoleDto";

export class ResourceAppDto {
    keyId: string = '';
    id: string = '';
    code: string = '';
    nameZh: string = '';
    nameEn: string = '';
    path: string = '';
    icon: string = '';
    sequence: string = '';
    remarks: string = '';

    roles: Array<RoleDto> = [];
}
