import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {RoleDto} from "../../../../../modules/account/dto/RoleDto";
import {RoleApi} from "../../../../../modules/account/api/RoleApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import FormFieldText from "../../../../../components/FormFieldText";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";

export type AppRoleDetailDialogProps = {
    open: boolean;
    role: RoleDto;
    onClose: () => void;
    onOk: () => void;
};

const AppRoleDetailDialog: React.FC<AppRoleDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.role.id ? 'ACCOUNT_ROLE_MODIFY' : 'ACCOUNT_ROLE_CREATE';

    const [innerRole, setInnerRole] = useState<RoleDto>(props.role);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerRole({...props.role});
        setMessageMap(new Map<string, string>());
    }, [props.role]);

    const handleChange = (name: string, value: any) => {
        setInnerRole(previousSite => ({...previousSite, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.role.id) {
                const responseResult = await RoleApi.updateRole(innerRole);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_ROLE_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await RoleApi.createRole(innerRole);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_ROLE_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerRole.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'ACCOUNT_ROLE_CODE_REQUIRED'}));
        }
        if (innerRole.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'ACCOUNT_ROLE_NAME_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_ROLE_CODE"})}
                               required={true} errorText={messageMap.get("code")}
                               value={innerRole.code}
                               onChange={(value) => {
                                   handleChange("code", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_ROLE_NAME"})}
                               required={true} errorText={messageMap.get("name")}
                               value={innerRole.name}
                               onChange={(value) => {
                                   handleChange("name", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_ROLE_LEVEL"})}
                               type="number"
                               value={innerRole.level}
                               onChange={(value) => {
                                   handleChange("level", value)
                               }}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppRoleDetailDialog;
