import React from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {CardDto} from "../../../../../../modules/vms/dto/CardDto";
import {CardStatusType} from "../../../../../../modules/vms/enums/CardStatus";
import {CardClassifyType} from "../../../../../../modules/vms/enums/CardClassify";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";


export type SearchResultProps = {
    searchResult: Array<CardDto>;
    onDelete: (record: CardDto) => void;
    onModify: (record: CardDto) => void;
};

const SearchResult: React.FC<SearchResultProps> = (props) => {
    const intl = useIntl();

    const handleModify = async (record: CardDto) => {
        props.onModify(record);
    };

    const handleDelete = async (record: CardDto) => {
        props.onDelete(record);
    };


    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_CARD_NUMBER'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_CARD_STATUS'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_CARD_CLASSIFY"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.searchResult.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.number}</TableCell>
                            <TableCell><FormattedMessage id={CardStatusType.Map.get(row.status)}/></TableCell>
                            <TableCell><FormattedMessage id={CardClassifyType.Map.get(row.classify)}/></TableCell>
                            <TableCell>
                                <div>
                                    <a onClick={() => handleModify(row)}>
                                        <FormattedMessage id={'COMMON_MODIFY'}/>
                                    </a>
                                    {/*<Divider type="vertical"/>*/}
                                    <a onClick={() => handleDelete(row)}><FormattedMessage id={'COMMON_DELETE'}/></a>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default SearchResult;
