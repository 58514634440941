import React from 'react';
import {
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput, Stack,
    TextField
} from "@mui/material";
import {useIntl} from "react-intl";
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {AccountCircle, Visibility, VisibilityOff} from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';

export type LoginUIDFormProps = {
    user: UserDto;
    messageMap: Map<string, string>;
    onChange: (name: string, value: any) => void;
};

const LoginUIDForm: React.FC<LoginUIDFormProps> = (props) => {
    const intl = useIntl();

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Stack spacing={2}>
            <TextField
                placeholder={intl.formatMessage({id: "ACCOUNT_USER_UID_REQUIRED"})}
                fullWidth
                required={true}
                error={props.messageMap.has("uid")}
                value={props.user.uid}
                onChange={(e) => {
                    props.onChange("uid", e.target.value)
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <AccountCircle/>
                        </InputAdornment>
                    ),
                }}
            />
            <FormControl fullWidth
                         required={true} variant="outlined">
                <OutlinedInput
                    placeholder={intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_REQUIRED"})}
                    type={showPassword ? 'text' : 'password'}
                    error={props.messageMap.has("password")}
                    value={props.user.password}
                    onChange={(e) => {
                        props.onChange("password", e.target.value)
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <LockIcon/>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Stack>
    );
};

export default LoginUIDForm;
