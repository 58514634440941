import * as React from 'react';
import {useState} from "react";
import {
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import MailLockOutlinedIcon from '@mui/icons-material/MailLockOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import {useIntl, FormattedMessage} from "react-intl";
import PasswordDialog from "./components/PasswordDialog";
import EmailDialog from "./components/EmailDialog";

export default function Index() {

    const intl = useIntl();

    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    return (
        <div>
            <PasswordDialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}/>
            <EmailDialog open={openEmailDialog} onClose={() => setOpenEmailDialog(false)}/>
            <List>
                <ListItem
                    secondaryAction={
                        <Button variant="outlined" onClick={() => setOpenPasswordDialog(true)}>
                            <FormattedMessage id={'ACCOUNT_USER_PASSWORD_MODIFY'}/>
                        </Button>
                    }>
                    <ListItemAvatar>
                        <LockOutlinedIcon color="success" fontSize="large"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography
                            variant="h5">{intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD'})}</Typography>}
                        secondary={intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_MODIFY_HELP'})}
                    />
                </ListItem>
                <ListItem
                    secondaryAction={
                        <Button variant="outlined" onClick={() => setOpenEmailDialog(true)}>
                            <FormattedMessage id={'ACCOUNT_USER_EMAIL_MODIFY'}/>
                        </Button>
                    }>
                    <ListItemAvatar>
                        <MailLockOutlinedIcon color="success" fontSize="large"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography
                            variant="h5">{intl.formatMessage({id: 'ACCOUNT_USER_EMAIL'})}</Typography>}
                        secondary={intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_MODIFY_HELP'})}
                    />
                </ListItem>
            </List>
        </div>
    );
}