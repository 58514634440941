import React, {useEffect, useState} from 'react';
import {
    Badge,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, Grid, InputLabel,
    List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, NativeSelect, Select, Tab, Tabs
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {AreaDto} from "../../../../../modules/vms/dto/AreaDto";
import {SiteDto} from "../../../../../modules/vms/dto/SiteDto";
import {LocationDto} from "../../../../../modules/vms/dto/LocationDto";
import {SiteApi} from "../../../../../modules/vms/api/SiteApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {LocationApi} from "../../../../../modules/vms/api/LocationApi";
import {AreaApi} from "../../../../../modules/vms/api/AreaApi";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";

export type AreaSelectDialogProps = {
    onCancel: () => void;
    onOk: (values: Array<AreaDto>) => void;
    open: boolean;
};
const AreaSelectDialog: React.FC<AreaSelectDialogProps> = (props) => {
    const intl = useIntl();

    const [sites, setSites] = useState(new Array<SiteDto>());
    const [selectedSiteId, setSelectedSiteId] = useState('');
    const [locations, setLocations] = useState(new Array<LocationDto>());
    const [selectedLocationId, setSelectedLocationId] = useState('');
    const [selectedLocationAreaMap, setSelectedLocationAreaMap] = useState(new Map<string, number>());
    const [areas, setAreas] = useState(new Array<AreaDto>());
    const [selectedAreaMap, setSelectedAreaMap] = useState(new Map<string, AreaDto>());

    useEffect(() => {
        // 查询访问站点
        fetchSiteList();
    }, []);

    const fetchSiteList = async () => {
        const responseResult = await SiteApi.getSiteList();
        if (responseResult.status === HttpStatus.SUCCESS) {
            if (responseResult.data.length > 0) {
                setSites(responseResult.data);
                setSelectedSiteId(responseResult.data[0].id);
                fetchLocationList(responseResult.data[0].id);
            }
        }
    };

    const fetchLocationList = async (siteId: string) => {
        const responseResult = await LocationApi.getLocationList(siteId);
        console.log(responseResult);
        if (responseResult.status === HttpStatus.SUCCESS) {
            if (responseResult.data.length > 0) {
                setLocations(responseResult.data);
                setSelectedLocationId(responseResult.data[0].id);
                fetchAreaList(responseResult.data[0].id);
            }
        }
    };

    const fetchAreaList = async (locationId: string) => {
        const responseResult = await AreaApi.getAreaList(locationId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setAreas(responseResult.data);
        }
    };

    const handleOk = () => {
        props.onOk(Array.from(selectedAreaMap.values()));
    };

    const handleCancel = () => {
        props.onCancel();
    }

    const handleSiteSelectChange = (value: string) => {
        setSelectedSiteId(value);
        fetchLocationList(value);
    };

    const handleLocationSelectChange = (activeKey: string) => {
        console.log(activeKey)
        setSelectedLocationId(activeKey);
        fetchAreaList(activeKey);
    };

    const handleAreaCheckChange = (area: AreaDto) => {
        const newSelectedAreaMap = new Map<string, AreaDto>();
        const newSelectedLocationAreaMap = new Map<string, number>();

        if (!selectedAreaMap.has(area.id)) {
            newSelectedAreaMap.set(area.id, area);

            // 把选择的场所按Location分组
            newSelectedLocationAreaMap.set(area.locationId, 1);
        }

        // 旧的选择场所也要过滤出来加入新的选择场所表
        for (let item of selectedAreaMap.values()) {
            if (item.id === area.id) {
                // 点击的这个场所过滤掉，不需要处理
                continue;
            }
            newSelectedAreaMap.set(item.id, item);

            if (newSelectedLocationAreaMap.has(item.locationId)) {
                const number = newSelectedLocationAreaMap.get(item.locationId);
                if (number) {
                    newSelectedLocationAreaMap.set(item.locationId, number + 1);
                }
            } else {
                // 把选择的场所按Location分组
                newSelectedLocationAreaMap.set(item.locationId, 1);
            }
        }
        setSelectedAreaMap(newSelectedAreaMap);
        setSelectedLocationAreaMap(newSelectedLocationAreaMap);
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                <FormattedMessage id={'VMS_VISIT_REQUEST_PLACE_SELECT'}/>
            </DialogTitle>
            <DialogContent>
                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectedSiteId}
                        onChange={(e) => handleSiteSelectChange(e.target.value)}>
                        {
                            sites.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Tabs value={selectedLocationId}
                              orientation="vertical"
                              variant="scrollable"
                              onChange={(e, value) => handleLocationSelectChange(value)}>
                            {
                                locations.map((location, locationIndex) => {
                                    return (
                                        <Tab key={locationIndex}
                                             value={location.id}
                                             label={<Badge badgeContent={selectedLocationAreaMap.get(location.id)}
                                                           color="primary"
                                                           overlap="rectangular">{location.name}</Badge>}/>
                                    )
                                })
                            }
                        </Tabs>
                    </Grid>
                    <Grid item xs={8}>
                        {
                            locations.map((location, locationIndex) => {
                                return (
                                    <List key={locationIndex} dense hidden={selectedLocationId !== location.id}>
                                        {areas.map((item, areaIndex) => {
                                            return (
                                                <ListItem key={areaIndex} button
                                                          onClick={() => handleAreaCheckChange(item)}>
                                                    <ListItemText
                                                        primary={item.name}/>
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={(event) => handleAreaCheckChange(item)}
                                                            checked={selectedAreaMap.has(item.id)}
                                                            // inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default AreaSelectDialog;
