import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {RequestVisitorSearchCondition} from "../../../../../modules/vms/dto/RequestVisitorSearchCondition";
import {
    Button,
    Grid,
    Stack,
} from "@mui/material";
import FormFieldText from "../../../../../components/FormFieldText";
import SearchButton from "../../../../../components/buttons/SearchButton";
import AddButton from "../../../../../components/buttons/AddButton";
import RegisterDialog from "./RegisterDialog";

export type SearchConditionProps = {
    searchCondition: RequestVisitorSearchCondition;
    onChange: (name: string, value: any) => void;
    onSearch: () => void;
    onSearchReport: () => void;
    onOpenRegisterDialog:()=>void;
};

const SearchCondition: React.FC<SearchConditionProps> = (props) => {
    const intl = useIntl();


    return (
        <div>

            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={3}>
                    <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_NAME"})}
                                   value={props.searchCondition.visitorName}
                                   onChange={(value) => {
                                       props.onChange("visitorName", value)
                                   }}/>
                </Grid>
                <Grid item xs={3}>
                    <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL"})}
                                   value={props.searchCondition.visitorEmail}
                                   onChange={(value) => {
                                       props.onChange("visitorEmail", value)
                                   }}/>
                </Grid>
                <Grid item xs={3}>
                    <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE"})}
                                   value={props.searchCondition.visitorMobile}
                                   onChange={(value) => {
                                       props.onChange("visitorMobile", value)
                                   }}/>
                </Grid>
                <Grid item xs={3}>
                    <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY"})}
                                   value={props.searchCondition.visitorCompany}
                                   onChange={(value) => {
                                       props.onChange("visitorCompany", value)
                                   }}/>
                </Grid>
                <Grid item xs={3}>
                    <FormFieldText startAdornment={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_NAME"})}
                                   value={props.searchCondition.hostName}
                                   onChange={(value) => {
                                       props.onChange("hostName", value)
                                   }}/>
                </Grid>
                <Grid item xs={3}>
                    <FormFieldText startAdornment={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_EMAIL"})}
                                   value={props.searchCondition.hostEmail}
                                   onChange={(value) => {
                                       props.onChange("hostEmail", value)
                                   }}/>
                </Grid>
                <Grid item xs={3}>
                    <FormFieldText startAdornment={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_STAFF_NUMBER"})}
                                   value={props.searchCondition.hostStaffNumber}
                                   onChange={(value) => {
                                       props.onChange("hostStaffNumber", value)
                                   }}/>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'row'} spacing={2}>
                        <SearchButton onClick={() => props.onSearch()}/>
                        <AddButton onClick={() => props.onOpenRegisterDialog()}
                                   text={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_REGISTER'})}/>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
};

export default SearchCondition;
