import React, {useEffect, useState} from "react";
import {IntlProvider} from "react-intl";
import zh_CN from "../locales/zh-CN";
import en_US from "../locales/en-US";

export const LanguageContext = React.createContext({
    language: "zh-CN",
    setLanguage: (value: string) => {
    }
})

export const LanguageContextProvider = (props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => {

    const [language, setLanguage] = useState("zh-CN");
    const [messages, setMessages] = useState(zh_CN);

    const languageContextValue = {language, setLanguage};

    useEffect(() => {
        console.log(language);
        if (language === "en-US") {
            setMessages(en_US);
        } else {
            setMessages(zh_CN);
        }
    }, [language]);

    console.log(messages);
    return (
        <LanguageContext.Provider value={languageContextValue}>
            <IntlProvider messages={messages} locale={language}>
                {props.children}
            </IntlProvider>
        </LanguageContext.Provider>
    )
}