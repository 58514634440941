import React, {useContext, useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {ResourceAppApi} from "../../../../../modules/account/api/ResourceAppApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack,
} from "@mui/material";
import {ResourceMenuDto} from "../../../../../modules/account/dto/ResourceMenuDto";
import CloseButton from "../../../../../components/buttons/CloseButton";
import {ResourceMenuApi} from "../../../../../modules/account/api/ResourceMenuApi";
import {IconUtils} from "../../../../../utils/IconUtils";
import AddIconButton from "../../../../../components/buttons/AddIconButton";
import AppMenuDetailDialog from "./AppMenuDetailDialog";
import {useConfirm} from "material-ui-confirm";
import AppMenuActionDialog from "./AppMenuActionDialog";

export type AppMenuDialogProps = {
    open: boolean;
    appId: string;
    onClose: () => void;
};

const AppMenuDialog: React.FC<AppMenuDialogProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openMenuDetailModal, setOpenMenuDetailModal] = useState(false);
    const [openBindActionModal, setOpenBindActionModal] = useState(false);
    const [currentRow, setCurrentRow] = useState<ResourceMenuDto>(new ResourceMenuDto());

    const [resourceMenus, setResourceMenus] = useState<Array<ResourceMenuDto>>([]);

    useEffect(() => {
        if (props.appId) {
            fetchAppMenus(props.appId);
        }
    }, [props.appId]);

    const fetchAppMenus = async (appId: string) => {
        const responseResult = await ResourceAppApi.getResourceAppMenus(appId);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setResourceMenus(ResourceMenuApi.constructResourceMenuTree(responseResult.data));
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleClickAdd = () => {
        const resourceMenu = new ResourceMenuDto();
        resourceMenu.appId = props.appId;
        setCurrentRow(resourceMenu);
        setOpenMenuDetailModal(true);
    }

    const handleClickModify = (record: ResourceMenuDto) => {
        setCurrentRow(record);
        setOpenMenuDetailModal(true);
    }

    const handleClickDelete = async (record: ResourceMenuDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await ResourceMenuApi.deleteResourceMenu(record.id);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    fetchAppMenus(props.appId);
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleClickBindAction = (record: ResourceMenuDto) => {
        setCurrentRow(record);
        setOpenBindActionModal(true);
    }

    const renderChildren = (parent: ResourceMenuDto, children: Array<ResourceMenuDto>) => {
        return (
            <List component="div" disablePadding>
                {
                    children.map((child, index) => {
                        return (
                            <ListItemButton key={child.id} sx={{pl: 4}}>
                                <ListItemIcon>
                                    {IconUtils.getIcon(child.icon)}
                                </ListItemIcon>
                                <ListItemText primary={child.nameZh} secondary={child.nameEn}/>
                                <Stack direction={'row'} spacing={1}>
                                    <a href={'javascript:void(0)'} onClick={() => handleClickBindAction(child)}>
                                        <FormattedMessage id={'ACCOUNT_RESOURCE_MENU_ACTIONS_BIND'}/>
                                    </a>
                                    <a href={'javascript:void(0)'} onClick={() => handleClickModify(child)}>
                                        <FormattedMessage id={'COMMON_MODIFY'}/>
                                    </a>
                                    <a href={'javascript:void(0)'} onClick={() => handleClickDelete(child)}>
                                        <FormattedMessage id={'COMMON_DELETE'}/>
                                    </a>
                                </Stack>
                            </ListItemButton>
                        )
                    })
                }
            </List>
        )
    }

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <FormattedMessage id={'ACCOUNT_RESOURCE_APP_MENU'}/>
                    <AddIconButton onClick={handleClickAdd}/>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <AppMenuDetailDialog open={openMenuDetailModal} resourceMenu={currentRow} resourceMenus={resourceMenus}
                                     onClose={() => setOpenMenuDetailModal(false)}
                                     onOk={() => fetchAppMenus(props.appId)}/>
                <AppMenuActionDialog open={openBindActionModal} appId={props.appId} menuId={currentRow.id}
                                     onClose={() => setOpenBindActionModal(false)}/>
                <List>
                    {
                        resourceMenus.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {IconUtils.getIcon(item.icon)}
                                        </ListItemIcon>
                                        <ListItemText primary={item.nameZh} secondary={item.nameEn}/>
                                        <Stack direction={'row'} spacing={1}>
                                            <a href={'javascript:void(0)'}
                                               onClick={() => handleClickBindAction(item)}>
                                                <FormattedMessage id={'ACCOUNT_RESOURCE_MENU_ACTIONS_BIND'}/>
                                            </a>
                                            <a href={'javascript:void(0)'} onClick={() => handleClickModify(item)}>
                                                <FormattedMessage id={'COMMON_MODIFY'}/>
                                            </a>
                                            <a href={'javascript:void(0)'} onClick={() => handleClickDelete(item)}>
                                                <FormattedMessage id={'COMMON_DELETE'}/>
                                            </a>
                                        </Stack>
                                    </ListItemButton>
                                    {item.children ? renderChildren(item, item.children) : null}
                                    <Divider sx={{mt: 2, mb: 2}}/>
                                </div>
                            )
                        })
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={handleCancel}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppMenuDialog;
