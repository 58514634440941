import React from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {RequestVisitorDto} from "../../../modules/vms/dto/RequestVisitorDto";
import {Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export type RequestVisitorListProps = {
    visitors: Array<RequestVisitorDto>;
};

const RequestVisitorList: React.FC<RequestVisitorListProps> = (props) => {
    const intl = useIntl();

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_LIST'})}/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_NAME'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_MOBILE'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_EMAIL'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_COMPANY'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_VISIT_CODE'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.visitors.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.fullName}</TableCell>
                            <TableCell>{row.mobile}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.company}</TableCell>
                            <TableCell>{row.visitCode}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    )
};

export default RequestVisitorList;