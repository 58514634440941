import common from '../modules/common/locales/zh-CN';
import log from '../modules/log/locales/zh-CN';
import dict from '../modules/dict/locales/zh-CN';
import account from '../modules/account/locales/zh-CN';
import workflow from '../modules/workflow/locales/zh-CN';
import cms from "../modules/cms/locales/zh-CN";
import vms from "../modules/vms/locales/zh-CN";

const zh_CN = {
    ...common,
    ...log,
    ...dict,
    ...account,
    ...workflow,
    ...cms,
    ...vms,
}

export default zh_CN;