import { Gender } from "../enums/Gender";
import {RoleDto} from "./RoleDto";
import {UserStatus} from "../enums/UserStatus";

export class UserDto {
    keyId: string = '';
    id: string = '';
    uid: string = '';
    email: string = '';
    mobile: string = '';
    password: string = '';
    oldPassword: string = '';
    status: string = UserStatus.Enum.Active;
    fullName: string = '';
    lastName: string = '';
    firstName: string = '';
    nickname: string = '';
    gender: string = Gender.Enum.None;
    birthday: string = '';
    portrait: string = '';
    roles: Array<RoleDto> = [];
}
