import React, {useEffect, useState} from 'react';
import {DictTypeDto} from "../../../../modules/dict/dto/DictTypeDto";
import {useIntl} from "react-intl";
import {DictTypeApi} from "../../../../modules/dict/api/DictTypeApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormFieldText from "../../../../components/FormFieldText";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";

export type DictTypeDetailModalProps = {
    open: boolean;
    dictType: DictTypeDto;
    onClose: () => void;
    onOk: () => void;
};

const DictTypeDetailModal: React.FC<DictTypeDetailModalProps> = (props) => {
    const intl = useIntl();
    const title = props.dictType.id ? 'DICT_TYPE_MODIFY' : 'DICT_TYPE_CREATE';

    const [innerDictType, setInnerDictType] = useState<DictTypeDto>(props.dictType);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerDictType({...props.dictType})
    }, [props.dictType]);

    const handleChange = (name: string, value: any) => {
        setInnerDictType(previousDictType => ({...previousDictType, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.dictType.id) {
                const responseResult = await DictTypeApi.updateDictType(innerDictType);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'DICT_TYPE_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await DictTypeApi.createDictType(innerDictType);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'DICT_TYPE_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerDictType.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'DICT_TYPE_CODE_REQUIRED'}));
        }
        if (innerDictType.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'DICT_TYPE_NAME_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>{intl.formatMessage({id: title})}</DialogTitle>
            <DialogContent>
                <FormFieldText label={intl.formatMessage({id: "DICT_TYPE_CODE"})} value={innerDictType.code}
                               required={true}
                               errorText={messageMap.get("code")}
                               onChange={(value) => handleChange('code', value)}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_TYPE_NAME"})} value={innerDictType.name}
                               required={true}
                               errorText={messageMap.get("name")}
                               onChange={(value) => handleChange('name', value)}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_TYPE_SEQUENCE"})} value={innerDictType.sequence}
                               type={'number'}
                               onChange={(value) => handleChange('sequence', value)}/>
                <FormFieldText label={intl.formatMessage({id: "COMMON_REMARKS"})} value={innerDictType.remarks}
                               onChange={(value) => handleChange('remarks', value)}
                               maxRows={4}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default DictTypeDetailModal;
