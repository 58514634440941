import {AreaDto} from "./AreaDto";

export class LocationDto {
    id: string = '';
    siteId: string = '';
    name: string = '';
    code: string = '';
    sequence: number = 0;
    invisible: number = 0;
    remarks: string = '';
    areas: Array<AreaDto> = [];
}
