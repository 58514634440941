import React, {useEffect, useState} from 'react';
import SiteList from "./components/SiteList";
import LocationList from "./components/LocationList";
import AreaList from "./components/AreaList";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {SiteApi} from "../../../../modules/vms/api/SiteApi";
import {LocationApi} from "../../../../modules/vms/api/LocationApi";
import {AreaApi} from "../../../../modules/vms/api/AreaApi";
import {SiteDto} from "../../../../modules/vms/dto/SiteDto";
import {AreaDto} from "../../../../modules/vms/dto/AreaDto";
import {LocationDto} from "../../../../modules/vms/dto/LocationDto";
import {Grid} from "@mui/material";

const Index: React.FC = () => {

    const [siteList, setSiteList] = useState<Array<SiteDto>>([]);
    const [selectedSiteId, setSelectedSiteId] = useState('');

    const [locationList, setLocationList] = useState<Array<LocationDto>>([]);
    const [selectedLocationId, setSelectedLocationId] = useState('');

    const [areaList, setAreaList] = useState<Array<AreaDto>>([]);

    useEffect(() => {
        // 查询用户组列表
        fetchSiteList();
    }, []);

    const fetchSiteList = async () => {
        const responseResult = await SiteApi.getSiteList();
        if (responseResult.status === HttpStatus.SUCCESS) {
            setSiteList(responseResult.data);
        }
    };

    const fetchLocationList = async (siteId: string) => {
        const responseResult = await LocationApi.getLocationList(siteId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setLocationList(responseResult.data);
        }
    };

    const fetchAreaList = async (areaId: string) => {
        const responseResult = await AreaApi.getAreaList(areaId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setAreaList(responseResult.data);
        }
    };

    const handleSelectSite = (item: SiteDto) => {
        setSelectedSiteId(item.id);
        fetchLocationList(item.id);
    }

    const handleSelectLocation = (item:LocationDto)=>{
        setSelectedLocationId(item.id);
        fetchAreaList(item.id)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <SiteList siteList={siteList} onSelect={(value => handleSelectSite(value))} onRefresh={fetchSiteList}
                          selectedSiteId={selectedSiteId}/>
            </Grid>
            <Grid item xs={4}>
                <LocationList siteList={siteList} locationList={locationList}
                              onSelect={(value => handleSelectLocation(value))}
                              selectedSiteId={selectedSiteId}
                              selectedLocationId={selectedLocationId}
                              onRefresh={(dictTypeId) => fetchLocationList(dictTypeId)}/>
            </Grid>
            <Grid item xs={4}>
                <AreaList selectedLocationId={selectedLocationId} locationList={locationList}
                          areaList={areaList}
                          onRefresh={(dictClassId) => fetchAreaList(dictClassId)}/>
            </Grid>
        </Grid>
    );
};

export default Index;
