import React from 'react';
import {IconButton} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

type AddIconButtonProps = {
    onClick: () => void;
};

const AddIconButton: React.FC<AddIconButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <IconButton color={'primary'} onClick={(e) => handleClick(e)}>
            <AddCircleIcon/>
        </IconButton>
    );
};
export default AddIconButton;