import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {LogOperationSearchCondition} from "../dto/LogOperationSearchCondition";

export const LogOperationApi = {
    queryLogOperations: async (body: LogOperationSearchCondition) => {
        try {
            const requestUrl = '/v1/log/operation/query';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getLogOperationDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/log/operation/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
