import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {CardDto} from "../dto/CardDto";
import {CardSearchCondition} from "../dto/CardSearchCondition";

export const CardApi = {
    /**
     * 获得卡号列表
     */
    queryCards: async (body: CardSearchCondition) => {
        try {
            const requestUrl = '/v1/vms/card/query';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getCardDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/card/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createCard: async (body: CardDto) => {
        try {
            const requestUrl = '/v1/vms/card/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateCard: async (body: CardDto) => {
        try {
            const requestUrl = '/v1/vms/card/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteCard: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/card/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
