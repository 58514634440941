import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";

export type DescriptionItemProps = {
    label: string;
    children: React.ReactNode;
};

const DescriptionItem: React.FC<DescriptionItemProps> = (props) => {
    return (
        <Stack direction={'row'} spacing={1}>
            <Typography variant="body1">{props.label}:</Typography>
            <Typography variant="body1" gutterBottom>{props.children}</Typography>
        </Stack>
    );
};
export default DescriptionItem;