import React from 'react';
import {FormattedMessage} from "react-intl";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack,
} from "@mui/material";
import {ResourceMenuDto} from "../../../../../modules/account/dto/ResourceMenuDto";
import CloseButton from "../../../../../components/buttons/CloseButton";
import {IconUtils} from "../../../../../utils/IconUtils";

export type AppMenuSelectDialogProps = {
    open: boolean;
    resourceMenus: Array<ResourceMenuDto>;
    onSelect: (value: string) => void;
    onClose: () => void;
};

const AppMenuSelectDialog: React.FC<AppMenuSelectDialogProps> = (props) => {

    const handleCancel = () => {
        props.onClose();
    };

    const handleSelect = (item: ResourceMenuDto) => {
        props.onSelect(item.id);
        props.onClose();
    }

    const renderChildren = (parent: ResourceMenuDto, children: Array<ResourceMenuDto>) => {
        return (
            <List component="div" disablePadding>
                {
                    children.map((child, index) => {
                        return (
                            <ListItemButton key={child.id} sx={{pl: 4}} onClick={() => handleSelect(child)}>
                                <ListItemIcon>
                                    {IconUtils.getIcon(child.icon)}
                                </ListItemIcon>
                                <ListItemText primary={child.nameZh} secondary={child.nameEn}/>
                            </ListItemButton>
                        )
                    })
                }
            </List>
        )
    }

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <FormattedMessage id={'ACCOUNT_RESOURCE_APP_MENU'}/>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <List>
                    {
                        props.resourceMenus.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <ListItemButton onClick={() => handleSelect(item)}>
                                        <ListItemIcon>
                                            {IconUtils.getIcon(item.icon)}
                                        </ListItemIcon>
                                        <ListItemText primary={item.nameZh} secondary={item.nameEn}/>
                                    </ListItemButton>
                                    {item.children ? renderChildren(item, item.children) : null}
                                    <Divider sx={{mt: 2, mb: 2}}/>
                                </div>
                            )
                        })
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={handleCancel}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppMenuSelectDialog;
