import React from 'react';
import {useIntl} from "react-intl";
import dayjs from "dayjs";
import {VisitRequestDto} from "../../../modules/vms/dto/VisitRequestDto";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";
import {StatusType} from "../../../modules/vms/enums/StatusType";
import {Formats} from "../../../constants/Formats";
import DictItemUtils from "../../../modules/dict/utils/DictItemUtils";
import EnumItemLabel from "../../../components/EnumItemLabel";
import {ApplySourceType} from "../../../modules/vms/enums/ApplySourceType";
import Descriptions from "../../../components/Descriptions";
import DescriptionItem from "../../../components/DescriptionItem";
import {Card, CardContent, CardHeader} from "@mui/material";

export type VisitRequestBasicProps = {
    visitRequest: VisitRequestDto;
    purposeTypes: Array<DictItemDto>
};

const VisitRequestBasic: React.FC<VisitRequestBasicProps> = (props) => {
    const intl = useIntl();

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: 'VMS_VISIT_REQUEST_GENERAL_INFO'})}/>
            <CardContent>
                <Descriptions>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_NUMBER'})}>
                        {props.visitRequest.number}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_STATUS'})}>
                        <EnumItemLabel map={StatusType.Map} value={props.visitRequest.status}/>
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE'})}>
                        <EnumItemLabel map={ApplySourceType.Map} value={props.visitRequest.applySourceType}/>
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_APPLY_DATE'})}>
                        {dayjs(props.visitRequest.applyDate, Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_ARRIVE_START_DATE'})}>
                        {dayjs(props.visitRequest?.arriveStartDate ?? '', Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_ARRIVE_END_DATE'})}>
                        {dayjs(props.visitRequest?.arriveEndDate ?? '', Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_PURPOSE_TYPE'})}>
                        {DictItemUtils.getText(props.purposeTypes, props.visitRequest.purposeType)}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_PURPOSE'})}>
                        {props.visitRequest.purpose}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_NAME'})}>
                        {props.visitRequest.hostFullName}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_MOBILE'})}>
                        {props.visitRequest.hostMobile}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_EMAIL'})}>
                        {props.visitRequest.hostEmail}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_DEPARTMENT_NAME'})}>
                        {props.visitRequest.hostDepartmentName}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_SUPERVISOR_NAME'})}>
                        {props.visitRequest.hostSupervisorName}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_SUPERVISOR_EMAIL'})}>
                        {props.visitRequest.hostSupervisorEmail}
                    </DescriptionItem>
                </Descriptions>
            </CardContent>
        </Card>
    );
};

export default VisitRequestBasic;
