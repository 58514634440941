import React from 'react';
import {Button} from "@mui/material";
import {FormattedMessage} from "react-intl";
import SendIcon from '@mui/icons-material/Send';

type SubmitButtonProps = {
    onClick: () => void;
    color?: string;
};

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <Button
            variant="contained"
            size="small"
            // @ts-ignore
            color={props.color || "primary"}
            startIcon={<SendIcon/>}
            onClick={(e) => handleClick(e)}>
            <FormattedMessage id={'COMMON_SUBMIT'}/>
        </Button>
    );
};
export default SubmitButton;
