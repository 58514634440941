import React from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {VisitorAccessLogDto} from "../../../modules/vms/dto/VisitorAccessLogDto";
import dayjs from "dayjs";
import {Formats} from "../../../constants/Formats";
import {AccessDirection} from "../../../modules/vms/enums/AccessDirection";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseButton from "../../../components/buttons/CloseButton";

export type VisitorAccessLogListDialogProps = {
    open: boolean;
    accessLogs: Array<VisitorAccessLogDto>;
    onClose: () => void;
};

const VisitorAccessLogListDialog: React.FC<VisitorAccessLogListDialogProps> = (props) => {
    const intl = useIntl();

    const handleCancel = () => {
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: 'VMS_VISITOR_ACCESS_LOG_LIST'})}
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_ACCESS_LOG_ACCESS_TIME'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_ACCESS_LOG_ACCESS_READER'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_ACCESS_LOG_ACCESS_CARD_NUMBER'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION'})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.accessLogs.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    {dayjs(row.accessDate + "" + row.accessTime ?? '', Formats.DATE_TIME_SIMPLE).format(Formats.DATE_TIME_HYPHEN)}
                                </TableCell>
                                <TableCell>
                                    {row.accessReader}
                                </TableCell>
                                <TableCell>
                                    {row.accessCardNumber}
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id={AccessDirection.Map.get(row.accessDirection)}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={props.onClose}/>
            </DialogActions>
        </Dialog>
    );
};

export default VisitorAccessLogListDialog;
