import React, {useState} from 'react';
import {DictClassDto} from "../../../../modules/dict/dto/DictClassDto";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import AddIconButton from "../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../components/buttons/RefreshIconButton";
import ModifyIconButton from "../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../components/buttons/DeleteIconButton";
import LanIcon from "@mui/icons-material/Lan";
import DictClassDetailModal from "./DictClassDetailModal";
import {DictClassApi} from "../../../../modules/dict/api/DictClassApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {useConfirm} from "material-ui-confirm";
import {DictTypeDto} from "../../../../modules/dict/dto/DictTypeDto";
import ClassIcon from '@mui/icons-material/Class';

export type DictClassListProps = {
    selectedDictTypeId: string;
    dictTypeList: Array<DictTypeDto>;
    dictClassList: Array<DictClassDto>;
    selectedDictClassId?: string;
    onSelect: (value: DictClassDto) => void;
    onRefresh: (dictTypeId: string) => void;
};

const DictClassList: React.FC<DictClassListProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<DictClassDto>(new DictClassDto());

    const handleAdd = () => {
        const dictClass = new DictClassDto();
        dictClass.typeId = props.selectedDictTypeId;
        setCurrentRow(dictClass);
        setOpenDetailModal(true);
    };

    const handleModify = async (record: DictClassDto) => {
        setCurrentRow(record);
        setOpenDetailModal(true);
    };

    const handleDelete = async (record: DictClassDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await DictClassApi.deleteDictClass(record);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    props.onRefresh(record.typeId);
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    const handleSelect = (item: DictClassDto) => {
        props.onSelect(item);
    }

    return (
        <div>
            <DictClassDetailModal open={openDetailModal}
                                  onOk={() => props.onRefresh(props.selectedDictTypeId)}
                                  onClose={() => setOpenDetailModal(false)}
                                  typeList={props.dictTypeList}
                                  dictClass={currentRow}/>
            <Card>
                <CardHeader
                    title={intl.formatMessage({id: 'DICT_CLASS_LIST'})}
                    action={
                        <div>
                            <AddIconButton onClick={handleAdd}/>
                            <RefreshIconButton onClick={() => props.onRefresh(props.selectedDictTypeId)}/>
                        </div>
                    }
                />
                <CardContent>
                    {props.dictClassList.map((row, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={row.id === props.selectedDictClassId}
                            onClick={(e) => handleSelect(row)}
                            secondaryAction={
                                <div>
                                    <ModifyIconButton onClick={() => handleModify(row)}/>
                                    <DeleteIconButton onClick={() => handleDelete(row)}/>
                                </div>
                            }>
                            <ListItemText primary={row.name} secondary={row.code}/>
                        </ListItem>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};


export default DictClassList;
