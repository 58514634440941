import * as React from 'react';
import {useEffect, useState} from "react";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {ResourceAppDto} from "../../../../modules/account/dto/ResourceAppDto";
import {Grid} from "@mui/material";
import AppCard from "./components/AppCard";
import {TenantApi} from "../../../../modules/account/api/TenantApi";

export default function Index() {

    const [myApps, setMyApps] = useState<Array<ResourceAppDto>>([]);

    useEffect(() => {
        fetchMyApps();
    }, [])

    const fetchMyApps = async () => {
        const responseResult = await TenantApi.getMyResourceApps(false);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setMyApps(responseResult.data);
        }
    };

    return (
        <Grid container spacing={3}>
            {
                myApps.map((item, index) => {
                    return (
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                            <AppCard app={item}/>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}