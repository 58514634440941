import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {DeviceDto} from "../../../../../../modules/vms/dto/DeviceDto";
import {Card, CardHeader, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

export type DeviceListProps = {
    onRefresh: () => void;
    deviceList: Array<DeviceDto>;
    onAdd: () => void;
    onDelete: (record: DeviceDto) => void;
    onModify: (record: DeviceDto) => void;
};

const DeviceList: React.FC<DeviceListProps> = (props) => {
    const intl = useIntl();

    const handleAdd = () => {
        // setCurrentRow(new DeviceDto());
        // setOpenDetailModal(true);

        props.onAdd();
    };

    const handleModify = async (record: DeviceDto) => {
        // const responseResult = await DeviceApi.getDeviceDetail(record.id);
        // if (responseResult.status === HttpStatus.SUCCESS) {
        //     setCurrentRow(responseResult.data);
        //     setOpenDetailModal(true);
        // }

        props.onModify(record);
    };

    const handleDelete = async (record: DeviceDto) => {
        // const responseResult = await DeviceApi.deleteDevice(record.id);
        // if (responseResult.status === HttpStatus.SUCCESS) {
        //     message.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
        //     props.onRefresh();
        // }

        props.onDelete(record);
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: 'VMS_VISITOR_TYPE'})}
                        action={
                            <div>
                                <IconButton onClick={() => handleAdd()}>
                                    <RefreshIcon/>
                                </IconButton>
                                <IconButton onClick={() => props.onRefresh()}>
                                    <RefreshIcon/>
                                </IconButton>
                            </div>
                        }/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_DEVICE_CODE'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_DEVICE_NAME'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.deviceList.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                                <div>
                                    <a onClick={() => handleModify(row)}>
                                        <FormattedMessage id={'COMMON_MODIFY'}/>
                                    </a>
                                    {/*<Divider type="vertical"/>*/}
                                    <a onClick={() => handleDelete(row)}><FormattedMessage id={'COMMON_DELETE'}/></a>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </Card>
    );
};

export default DeviceList;
