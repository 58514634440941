import dayjs from "dayjs";
import {Formats} from "../constants/Formats";

export const ImageUtils = {
    covertStrToImage: (contents: Array<string>, width: number, height: number, fontSize: number, lineSpace: number) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext("2d");
        // @ts-ignore
        context.font = fontSize + "px Baoli SC";
        // 绘制文本
        let x = 10;
        let y = 10;
        for (let i = 0; i < contents.length; i++) {
            const content = contents[i];
            // @ts-ignore
            context.fillText(content, x, y + lineSpace);
            y = y + lineSpace;
        }

        const result = canvas.toDataURL("image/png");

        return result;
    },

    // 获取图片Base64
    getImageBase64: (path: string, callback: (base64: string) => void) => {
        fetch(path)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);

                const promise = new Promise((res) => {
                    reader.onloadend = () => {
                        if (reader.result) {
                            // @ts-ignore
                            callback(reader.result);
                        }
                    }
                });
            })
    },

    /**
     * 拼接图片（利用canvas）
     * @param  imgObj1   背景的图片对象{src:图片路径}
     * @param  imgObj2   要拼接的图片对象{src:图片路径, offsetX:偏移量X, offsetY:偏移量Y}
     * @param  callback  回调函数
     */
    stitchPicture: (imgObj1: string, imgObj2: string, imgObj2X: number, imgObj2Y: number, callback: (base64: string) => void) => {

        const backgroundImg = new Image();
        // backgroundImg.src = require("../images/notice_pic/visitor_notice_1.png");  //背景图片 你自己本地的图片或者在线图片
        backgroundImg.src = imgObj1;  //背景图片 你自己本地的图片或者在线图片

        // // @ts-ignore
        // context.rect(0, 0, canvas.width, canvas.height);
        // // @ts-ignore
        // context.fillStyle = "#fff";
        // // @ts-ignore
        // context.fill();

        backgroundImg.onload = function () {

            const canvas = document.createElement('canvas');
            canvas.width = backgroundImg.width;
            canvas.height = backgroundImg.height;

            const context = canvas.getContext("2d");

            // console.log('backgroundImg.onload');
            // console.log("backgroundImg: " + backgroundImg.width + "-" + backgroundImg.height);
            // @ts-ignore
            context.drawImage(backgroundImg, 0, 0, backgroundImg.width, backgroundImg.height);
            const stitchImg = new Image();
            // stitchImg.src = require("../images/notice_pic/visitor_notice_2.png");  //你自己本地的图片或者在线图片
            stitchImg.src = imgObj2;  //你自己本地的图片或者在线图片
            // console.log(stitchImg);
            stitchImg.onload = function () {
                // console.log("stitchImg: " + imgObj2.offsetX + "-" + imgObj2.offsetY + "-" + stitchImg.width + "-" + stitchImg.height);
                // console.log(stitchImg);
                // console.log('stitchImg.onload');
                // console.log("stitchImg: " + stitchImg.width + "-" + stitchImg.height);
                // @ts-ignore
                context.drawImage(stitchImg, imgObj2X, imgObj2Y, stitchImg.width, stitchImg.height);
                let base64 = canvas.toDataURL("image/png");
                // console.log("base64:" + base64);
                callback(base64);
            }
        }
    },

    // base64 转为Blob
    base64ToBlob: (base64Data: string) => {
        const dataArr = base64Data.split(','); // 根据,来分隔
        if (dataArr[0] != null) {
            // @ts-ignore
            const imageType = dataArr[0].match(/:(.*?);/)[1]; // 获取文件类型。使用正则捕获 image/jpeg

            const textData = window.atob(dataArr[1]); // 使用atob() 将base64 转为文本文件
            const arrayBuffer = new ArrayBuffer(textData.length); // 创建一个二进制数据缓冲区，可以理解为一个数组
            const uint8Array = new Uint8Array(arrayBuffer); // 创建一个类型化数组对象，可以理解为上面的数组的成员，给这个对象赋值就会放到上面的数组中。
            for (let i = 0; i < textData.length; i++) {
                uint8Array[i] = textData.charCodeAt(i); // 将文本文件转为UTF-16的ASCII, 放到类型化数组对象中
            }

            return [new Blob([arrayBuffer], {type: imageType}), imageType.slice(6)]; // 返回两个值，一个Blob对象，一个图片格式（如jpeg）
        }
    },

    // 转为formData
    toFormData: (base64Data: string) => {
        // @ts-ignore
        const [imageBlob, imageType] = ImageUtils.base64ToBlob(base64Data); // 获取处理好的Blob 和文件类型
        const formData = new FormData();
        formData.append('file', imageBlob, `${Date.now()}.${imageType}`); // 添加到表单，传入文件名
        return formData;
    },


}