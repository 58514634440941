import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ThreadStatus} from "../../../../modules/workflow/enums/ThreadStatus";
import {ApplyThreadDto} from "../../../../modules/workflow/dto/ApplyThreadDto";
import EnumItemLabel from "../../../../components/EnumItemLabel";
import dayjs from "dayjs";
import {Formats} from "../../../../constants/Formats";
import {ApplyProcessDto} from "../../../../modules/workflow/dto/ApplyProcessDto";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {ApplyFormApi} from "../../../../modules/workflow/api/ApplyFormApi";
import {ApplyFormDto} from '../../../../modules/workflow/dto/ApplyFormDto';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step,
    Stepper
} from "@mui/material";
import {useConfirm} from "material-ui-confirm";
import ApproveButton from "../../../../components/buttons/ApproveButton";
import RejectButton from "../../../../components/buttons/RejectButton";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import RejectDialog from "./RejectDialog";

export type ApproveTimeLineProps = {
    applyFormId: string;
    onApprove?: () => void;
    onReject?: (remarks: string) => void;
    readonly?: boolean;
};

const ApproveTimeLine: React.FC<ApproveTimeLineProps> = (props) => {
    const intl = useIntl();
    const confirm = useConfirm();

    const [applyForm, setApplyForm] = useState<ApplyFormDto>(new ApplyFormDto());
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [rejectRemarks, setRejectRemarks] = useState('');

    useEffect(() => {
        // 查询访问目的字典项
        fetchApplyForm();
    }, [props.applyFormId]);

    const fetchApplyForm = async () => {
        const responseResult = await ApplyFormApi.getApplyFormDetail(props.applyFormId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setApplyForm(responseResult.data);
        }
    };

    const _renderThread = (thread: ApplyThreadDto, index: number) => {
        let statusColor = "gray";
        switch (thread.status) {
            case ThreadStatus.Enum.Approved:
                statusColor = "green";
                break;
            case ThreadStatus.Enum.Rejected:
                statusColor = "red";
                break;
        }

        return (
            <Step color={statusColor} key={index}>
                {_renderThreadHeader(thread)}
                {_renderThreadExecutors(thread)}
            </Step>
        );
    }

    const _renderThreadHeader = (thread: ApplyThreadDto) => {
        return (
            <div>
                <div>
                    <span>{thread.nodeName}{_renderThreadExecutorRanges(thread)}</span>
                </div>
                <div>
                    <span>{thread.finishTime ? dayjs(thread.finishTime, Formats.DATE_TIME_SIMPLE).format(Formats.DATE_TIME_HYPHEN) : ""}</span>
                </div>
                <div>{thread.remarks}</div>
            </div>
        )
    }

    const _renderThreadExecutorRanges = (thread: ApplyThreadDto) => {
        if (!thread.executorRanges || thread.executorRanges.length <= 0) {
            return null;
        }

        let executorNames = thread.executorRanges.map((item) => {
            return item.executorName
        }).join(',');

        return (
            <span>（{executorNames}）</span>
        );
    }

    const handleApprove = () => {
        confirm({title: intl.formatMessage({id: 'WORKFLOW_PROCESS_APPROVE_CONFIRM'})})
            .then(() => {
                if (props.onApprove) {
                    props.onApprove();
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    }

    const _renderThreadExecutors = (thread: ApplyThreadDto) => {

        let statusColor = "default";
        switch (thread.status) {
            case ThreadStatus.Enum.Approved:
                statusColor = "success";
                break;
            case ThreadStatus.Enum.Rejected:
                statusColor = "error";
                break;
        }

        return (
            <div>
                {
                    thread.executors && thread.executors.map((item, index) => {

                        return (
                            <div key={index}>
                                <div>
                                    <span>{item.executorName}</span>&nbsp;
                                    {/*// @ts-ignore*/}
                                    <Chip color={statusColor} size="small"
                                          label={<EnumItemLabel value={item.executeStatus} map={ThreadStatus.Map}/>}/>
                                    &nbsp;
                                    {item.deputyFlag ?
                                        <Chip color="success" size="small" label={<FormattedMessage
                                            id={'WORKFLOW_APPLY_THREAD_EXECUTOR_DEPUTY_FLAG'}/>}/> : null}
                                </div>
                            </div>
                        )
                    })
                }
                {
                    !props.readonly && thread.currentUserCanApprove ?
                        <Stack direction={'row'} spacing={2}>
                            <ApproveButton onClick={handleApprove}/>
                            <RejectButton onClick={() => setOpenRejectDialog(true)}/>
                        </Stack> : null
                }
            </div>
        );
    }

    const applyProcess = applyForm.applyProcesses.length > 0 ?
        applyForm.applyProcesses[0] : new ApplyProcessDto();
    return (
        <div>
            <RejectDialog onCancel={() => setOpenRejectDialog(false)} onOk={(value) => {
                setOpenRejectDialog(false);
                props.onReject && props.onReject(value);
            }}
                          open={openRejectDialog}/>
            <Card>
                <CardHeader title={intl.formatMessage({id: 'WORKFLOW_PROCESS_DETAIL'})}/>
                <CardContent>
                    <Stepper orientation="vertical">
                        {
                            applyProcess.threadList.map((item: ApplyThreadDto, index: number) => {
                                return _renderThread(item, index);
                            })
                        }
                    </Stepper>
                </CardContent>
            </Card>
        </div>
    );
};

export default ApproveTimeLine;
