import React, {useState} from 'react';
import VisitorDetailDialog from './VisitorDetailDialog';
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import {FormattedMessage, useIntl} from "react-intl";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import AddIconButton from "../../../../../components/buttons/AddIconButton";
import ModifyIconButton from "../../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../../components/buttons/DeleteIconButton";
import FormHelperText from '@mui/material/FormHelperText';

export type VisitorListProps = {
    visitRequest: VisitRequestDto;
    messageMap: Map<string, string>;
    onChange: (name: string, value: any) => void;
};

const VisitorList: React.FC<VisitorListProps> = (props) => {
    const intl = useIntl();

    const [visitorDetailModalOpen, setVisitorDetailModalOpen] = useState(false);
    const [selectedRequestVisitor, setSelectedRequestVisitor] = useState(new RequestVisitorDto());

    const handleDeleteVisitor = (index: number) => {
        // 拷贝一份副本操作再刷新，否则画面无法刷新
        const newRequestVisitors = [...props.visitRequest.visitors];
        newRequestVisitors.splice(index, 1);
        props.onChange('visitors', newRequestVisitors);
    };

    const handleShowVisitorAddModal = () => {
        setSelectedRequestVisitor(new RequestVisitorDto());
        setVisitorDetailModalOpen(true);
    };

    const handleShowVisitorModifyModal = (requestVisitor: RequestVisitorDto, index: number) => {
        setSelectedRequestVisitor(requestVisitor);
        setVisitorDetailModalOpen(true);
    };

    const handleSaveVisitorDetail = (requestVisitor: RequestVisitorDto) => {
        const newRequestVisitors = new Array<RequestVisitorDto>();

        if (requestVisitor.id) {
            // 更新
            for (let item of props.visitRequest.visitors) {
                if (item.id === requestVisitor.id) {
                    newRequestVisitors.push(requestVisitor);
                } else {
                    newRequestVisitors.push(item);
                }
            }
        } else {
            // 添加
            for (let item of props.visitRequest.visitors) {
                newRequestVisitors.push(item);
            }
            newRequestVisitors.push(requestVisitor);
        }

        for (let i = 0; i < newRequestVisitors.length; i++) {
            newRequestVisitors[i].id = (i + 1).toString();
        }

        setVisitorDetailModalOpen(false);

        props.onChange('visitors', newRequestVisitors);
    };

    const handleChangeSelectedVisitor = (name: string, value: any) => {
        setSelectedRequestVisitor({...selectedRequestVisitor, [name]: value});
    };

    return (
        <div>
            <VisitorDetailDialog open={visitorDetailModalOpen}
                                 requestVisitor={selectedRequestVisitor}
                                 onCancel={() => setVisitorDetailModalOpen(false)}
                                 onOk={(requestVisitor) => handleSaveVisitorDetail(requestVisitor)}
                                 onChange={handleChangeSelectedVisitor}/>
            <Card>
                <CardHeader
                    action={
                        <AddIconButton onClick={() => handleShowVisitorAddModal()}/>
                    }
                    title={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_LIST'})}
                    subheader={
                        props.messageMap.has('visitors') ?
                            <FormHelperText error={true}> {props.messageMap.get('visitors')}</FormHelperText> : ''}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_NAME'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'VMS_REQUEST_VISITOR_MOBILE'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL"})}</TableCell>
                            <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY"})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.visitRequest.visitors.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.lastName + ' ' + row.firstName}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.company}</TableCell>
                                <TableCell>
                                    <ModifyIconButton onClick={() => handleShowVisitorModifyModal(row, index)}/>
                                    <DeleteIconButton onClick={() => handleDeleteVisitor(index)}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
        </div>
    );
};

export default VisitorList;
