import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {RequestVisitorSearchCondition} from "../dto/RequestVisitorSearchCondition";

export const RequestVisitorApi = {
    getLastVisitorInfo: async (idNumber: string, mobile: string, email: string) => {
        try {
            const params = new Map<string, string>();
            params.set('idNumber', idNumber);
            params.set('mobile', mobile);
            params.set('email', email);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/request/visitor/getLastVisitorInfo', params);

            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    getAvailableVisitor: async (keywords: string) => {
        try {
            const params = new Map<string, string>();
            params.set('keywords', keywords);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/request/visitor/getAvailableVisitor', params);

            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    checkout: async ( visitorId: string) => {
        try {
            const params = new Map<string, string>();
            params.set('visitorId', visitorId);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/request/visitor/checkout', params);

            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    queryMyRequestVisitors: async (body: RequestVisitorSearchCondition) => {
        try {
            const requestUrl = '/v1/vms/request/visitor/query/my/list';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    queryRequestVisitors: async (body: RequestVisitorSearchCondition) => {
        try {
            const requestUrl = '/v1/vms/request/visitor/query';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    queryRequestVisitorsNoTenant: async (body: RequestVisitorSearchCondition) => {
        try {
            const requestUrl = '/v1/vms/request/visitor/queryNoTenant';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    getVisitorDetailById: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/request/visitor/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    /**
     * 获得访客访问记录列表
     */
    getVisitorAccessLogList: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/request/visitor/access/logs', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
