import React, {useEffect, useState} from 'react';
import {Alert, Grid} from "@mui/material";
import PreviousNextButton from "../../../../../components/PreviousNextButton";
import {FormattedMessage, useIntl} from "react-intl";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {RequestVisitorApi} from "../../../../../modules/vms/api/RequestVisitorApi";
import {useConfirm} from "material-ui-confirm";
import FormFieldSelect from "../../../../../components/FormFieldSelect";
import FormFieldText from "../../../../../components/FormFieldText";
import SearchIconButton from "../../../../../components/buttons/SearchIconButton";
import {DictItemCode} from "../../../../../modules/vms/constants/DictItemCode";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {VisitorBlackListApi} from "../../../../../modules/vms/api/VisitorBlackListApi";

export type RegisterVisitorFormProps = {
    requestVisitor: RequestVisitorDto;
    inviteProperties: Array<DictItemDto>;
    idTypes: Array<DictItemDto>;
    visitorTypes: Array<DictItemDto>;
    onChange: (name: string, value: any) => void;
    onLoadRequestVisitor: (requestVisitor: RequestVisitorDto) => void;
    onPrevious: () => void;
    onNext: () => void;
};
const RegisterVisitorForm: React.FC<RegisterVisitorFormProps> = (props) => {
    const intl = useIntl();
    const confirm = useConfirm();
    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    // 访客ID字段的可见性
    const [idNumberVisible, setIdNumberVisible] = useState(false);
    const [visitorEmailVisible, setVisitorEmailVisible] = useState(false);
    const [visitorTypeVisible, setVisitorTypeVisible] = useState(false);

    // ID Card 的WebSocket对象
    const [idCardSocketConnected, setIdCardSocketConnected] = useState(false);
    let idCardSocket: WebSocket;
    let idCardReaderInterval: NodeJS.Timeout;

    useEffect(() => {
        for (let item of props.inviteProperties) {
            switch (item.code) {
                case DictItemCode.VMS_INVITE_PROPERTIES_VISITOR_ID_FIELD_VISIBLE:
                    if (item.value === '1') {
                        setIdNumberVisible(true);
                    } else {
                        setIdNumberVisible(false);
                    }
                    break;
                case DictItemCode.VMS_INVITE_PROPERTIES_VISITOR_EMAIL_FIELD_VISIBLE:
                    if (item.value === '1') {
                        setVisitorEmailVisible(true);
                    } else {
                        setVisitorEmailVisible(false);
                    }
                    break;
                case DictItemCode.VMS_INVITE_PROPERTIES_VISITOR_TYPE_FIELD_VISIBLE:
                    if (item.value === '1') {
                        setVisitorTypeVisible(true);
                    } else {
                        setVisitorTypeVisible(false);
                    }
                    break;
            }
        }
    }, [props.inviteProperties]);

    useEffect(() => {

        // 连接Id Card Web Socket
        // connectIdCardSocket();

        // 销毁WebSocket连接和定时器
        // return () => {
        //     console.log("Will UnMount")
        //     if (idCardSocket) {
        //         console.log("idCardSocket close")
        //         idCardSocket.close();
        //         if (idCardReaderInterval) {
        //             console.log("clearInterval idCardReaderInterval")
        //             clearInterval(idCardReaderInterval);
        //         }
        //     }
        // }

    }, []);

    const fetchLastVisitorInfo = async (idNumber: string, mobile: string, email: string) => {
        if (idNumber || mobile || email) {
            const responseResult = await RequestVisitorApi.getLastVisitorInfo(idNumber, mobile, email);
            if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
                if (responseResult.data) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_REQUEST_VISITOR_LAST_INFO_RELOAD_SUCCESS'}));
                    props.onLoadRequestVisitor(responseResult.data);
                }
            }
        }
    };

    const fetchVisitorBlackList = async (lastName: string, firstName: string) => {
        if (lastName && firstName) {
            const responseResult = await VisitorBlackListApi.checkVisitorBlackListByName(lastName, firstName);
            if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
                if (responseResult.data === true) {
                    return true;
                }
            }
        }
        return false;
    };

    const handlePrevious = () => {
        props.onPrevious();
    }

    const handleNext = async () => {
        if (validate()) {
            if (await fetchVisitorBlackList(props.requestVisitor.lastName, props.requestVisitor.firstName)) {
                confirm({title: intl.formatMessage({id: 'VMS_VISITOR_BLACK_LIST_REGISTERED_WARNING'})})
                    .then(async () => {
                        props.onNext();
                    })
                    .catch(() => {
                        console.log('cancel');
                    });
            } else {
                props.onNext();
            }
        }
    }

    const handleChange = (name: string, value: any) => {
        props.onChange(name, value);
    };

    // const connectIdCardSocket = () => {
    //     // init the websocket client
    //     idCardSocket = new WebSocket(VMS_WEB_SOCKET_HOST + "/idCard");
    //
    //     idCardSocket.onopen = function () {
    //         setIdCardSocketConnected(true);
    //         console.log("connected");
    //         idCardSocket.send("init");
    //     };
    //     idCardSocket.onclose = function () {
    //         console.log("closed");
    //         if (idCardSocketConnected) {
    //             idCardSocket.send("close");
    //         }
    //         setIdCardSocketConnected(false);
    //     };
    //     idCardSocket.onmessage = function (e) {
    //         const data = JSON.parse(e.data);
    //         console.log(data);
    //         if (data.command === "init" && data.status === true) {
    //             // 初始化成功，开始读卡
    //             // self.interval = setInterval(() => idCardSocket.send("read"), 2000);
    //
    //             idCardReaderInterval = setInterval(() => {
    //                 idCardSocket.send("read");
    //             }, 2000);
    //
    //         } else if (data.command === 'read' && data.data && data.data.length > 0) {
    //             console.log(data.data);
    //             const idNumber = data.data;
    //             const names = StringUtils.splitName(data.name);
    //             const lastName = names && names[0] ? names[0] : '';
    //             const firstName = names && names[1] ? names[1] : '';
    //
    //             handleChange('idNumber', idNumber);
    //             handleChange('lastName', lastName);
    //             handleChange('firstName', firstName);
    //
    //             fetchLastVisitorInfo(idNumber, '', '');
    //         }
    //     };
    // };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (idNumberVisible) {
            if (props.requestVisitor.idNumber === '') {
                newMessageMap.set('idNumber', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_ID_NUMBER_REQUIRED'}));
            }
        }
        if (props.requestVisitor.lastName === '') {
            newMessageMap.set('lastName', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_NAME_REQUIRED'}));
        }
        if (props.requestVisitor.firstName === '') {
            newMessageMap.set('firstName', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_NAME_REQUIRED'}));
        }
        // if (props.requestVisitor.mobile === '') {
        //     newMessageMap.set('mobile', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_MOBILE_REQUIRED'}));
        // } else if (props.requestVisitor.mobile !== '' && !ValidateUtils.isMobileNumber(props.requestVisitor.mobile)) {
        //     newMessageMap.set('mobile', intl.formatMessage({id: 'COMMON_MOBILE_FORMAT'}));
        // }
        // if (props.requestVisitor.email === '') {
        //     newMessageMap.set('email', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_EMAIL_REQUIRED'}));
        // } else if (props.requestVisitor.email !== '' && !ValidateUtils.isEmail(props.requestVisitor.email)) {
        //     newMessageMap.set('email', intl.formatMessage({id: 'COMMON_EMAIL_FORMAT'}));
        // }
        // if (props.requestVisitor.company === '') {
        //     newMessageMap.set('company', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_COMPANY_REQUIRED'}));
        // }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const renderIdNumberType = () => {
        if (idNumberVisible) {
            return (
                <Grid item xs={6}>
                    <FormFieldSelect label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_TYPE"})}
                                     required={true} errorText={messageMap.get("idType")}
                                     value={props.requestVisitor.idType}
                                     onChange={(value) => {
                                         handleChange("idType", value)
                                     }}
                                     items={props.idTypes.map((item) => {
                                         return {label: item.name, value: item.value}
                                     })}/>
                </Grid>
            )
        } else {
            return null;
        }
    }

    const renderIdNumber = () => {
        if (idNumberVisible) {
            return (
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_NUMBER"})}
                                   placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_NUMBER_REQUIRED"})}
                                   value={props.requestVisitor.idNumber}
                                   required={true} errorText={messageMap.get("idNumber")}
                                   onChange={(value) => {
                                       handleChange("idNumber", value)
                                   }}
                                   endAdornment={<SearchIconButton
                                       onClick={() => fetchLastVisitorInfo(props.requestVisitor.idNumber, '', '')}/>}
                    />
                </Grid>
            )
        } else {
            return null;
        }
    }
    return (
        <div>
            <Alert severity={"warning"}>
                <FormattedMessage id={'VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_INFO_ALERT'}/>
            </Alert>
            <Grid container spacing={2} style={{height: '60vh'}}>
                {renderIdNumberType()}
                {renderIdNumber()}
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE"})}
                                   placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE_REQUIRED"})}
                                   value={props.requestVisitor.mobile}
                                   type={"number"}
                                   errorText={messageMap.get("mobile")}
                                   onChange={(value) => {
                                       handleChange("mobile", value)
                                   }}
                                   endAdornment={<SearchIconButton
                                       onClick={() => fetchLastVisitorInfo('', props.requestVisitor.mobile, '')}/>}/>
                </Grid>
                {visitorEmailVisible ?
                    <Grid item xs={6}>
                        <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL"})}
                                       placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL_REQUIRED"})}
                                       value={props.requestVisitor.email}
                                       type={"email"}
                                       errorText={messageMap.get("email")}
                                       onChange={(value) => {
                                           handleChange("email", value)
                                       }}
                                       endAdornment={<SearchIconButton
                                           onClick={() => fetchLastVisitorInfo('', '', props.requestVisitor.email)}/>}/>
                    </Grid> : null}
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY"})}
                                   placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY_REQUIRED"})}
                                   value={props.requestVisitor.company}
                                   onChange={(value) => {
                                       handleChange("company", value)
                                   }}/>
                </Grid>
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_LAST_NAME"})}
                                   placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_LAST_NAME_REQUIRED"})}
                                   value={props.requestVisitor.lastName}
                                   required={true} errorText={messageMap.get("lastName")}
                                   onChange={(value) => {
                                       handleChange("lastName", value)
                                   }}/>
                </Grid>
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_FIRST_NAME"})}
                                   placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_FIRST_NAME_REQUIRED"})}
                                   value={props.requestVisitor.firstName}
                                   required={true} errorText={messageMap.get("firstName")}
                                   onChange={(value) => {
                                       handleChange("firstName", value)
                                   }}/>
                </Grid>


                {visitorTypeVisible ?
                    <Grid item xs={6}>
                        <FormFieldSelect label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_TYPE"})}
                                         required={true} errorText={messageMap.get("visitorType")}
                                         value={props.requestVisitor.visitorType}
                                         onChange={(value) => {
                                             handleChange("visitorType", value)
                                         }}
                                         items={props.visitorTypes.map((item) => {
                                             return {label: item.name, value: item.value}
                                         })}/>
                    </Grid>
                    : null}

            </Grid>
            <div>
                <PreviousNextButton previousDisabled={true} onNext={handleNext}/>
            </div>
        </div>
    );
};

export default RegisterVisitorForm;
