import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {ResourceMenuDto} from "../../../../../modules/account/dto/ResourceMenuDto";
import {ResourceMenuApi} from "../../../../../modules/account/api/ResourceMenuApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel,
} from "@mui/material";
import FormFieldText from "../../../../../components/FormFieldText";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";
import AppMenuSelectDialog from "./AppMenuSelectDialog";
import {it} from "node:test";
import SearchIconButton from '../../../../../components/buttons/SearchIconButton';

export type AppMenuDetailDialogProps = {
    open: boolean;
    resourceMenu: ResourceMenuDto;
    resourceMenus: Array<ResourceMenuDto>;
    onClose: () => void;
    onOk: () => void;
};

const AppMenuDetailDialog: React.FC<AppMenuDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.resourceMenu.id ? 'ACCOUNT_RESOURCE_MENU_MODIFY' : 'ACCOUNT_RESOURCE_MENU_CREATE';

    const [innerResourceMenu, setInnerResourceMenu] = useState<ResourceMenuDto>(props.resourceMenu);

    const [openMenuSelectDialog, setOpenMenuSelectDialog] = useState(false);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerResourceMenu({...props.resourceMenu});
        setMessageMap(new Map<string, string>());
    }, [props.resourceMenu]);

    const handleChange = (name: string, value: any) => {
        setInnerResourceMenu(previousResourceMenu => ({...previousResourceMenu, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.resourceMenu.id) {
                const responseResult = await ResourceMenuApi.updateResourceMenu(innerResourceMenu);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_RESOURCE_MENU_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await ResourceMenuApi.createResourceMenu(innerResourceMenu);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_RESOURCE_MENU_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerResourceMenu.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'ACCOUNT_RESOURCE_MENU_CODE_REQUIRED'}));
        }
        if (innerResourceMenu.nameZh === '') {
            newMessageMap.set('nameZh', intl.formatMessage({id: 'ACCOUNT_RESOURCE_MENU_NAME_ZH_REQUIRED'}));
        }
        if (innerResourceMenu.nameEn === '') {
            newMessageMap.set('nameEn', intl.formatMessage({id: 'ACCOUNT_RESOURCE_MENU_NAME_EN_REQUIRED'}));
        }
        if (innerResourceMenu.path === '') {
            newMessageMap.set('path', intl.formatMessage({id: 'ACCOUNT_RESOURCE_MENU_PATH_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const getResourceMenuParentName = (id: string, resourceMenus: Array<ResourceMenuDto>) => {
        if (id) {
            for (let item of resourceMenus) {
                if (item.id === id) {
                    return item.nameZh;
                } else if (item.children && item.children.length > 0) {
                    getResourceMenuParentName(id, item.children);
                }
            }
        } else {
            return '';
        }
    }

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <AppMenuSelectDialog resourceMenus={props.resourceMenus} open={openMenuSelectDialog}
                                     onSelect={(value) => handleChange("parentId", value)}
                                     onClose={() => setOpenMenuSelectDialog(false)}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_MENU_PARENT"})}
                               value={getResourceMenuParentName(innerResourceMenu.parentId, props.resourceMenus)}
                               disabled={true}
                               endAdornment={<SearchIconButton onClick={() => setOpenMenuSelectDialog(true)}/>}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_MENU_CODE"})}
                               required={true} errorText={messageMap.get("code")}
                               value={innerResourceMenu.code}
                               onChange={(value) => {
                                   handleChange("code", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_MENU_NAME_ZH"})}
                               required={true} errorText={messageMap.get("nameZh")}
                               value={innerResourceMenu.nameZh}
                               onChange={(value) => {
                                   handleChange("nameZh", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_MENU_NAME_EN"})}
                               required={true} errorText={messageMap.get("nameEn")}
                               value={innerResourceMenu.nameEn}
                               onChange={(value) => {
                                   handleChange("nameEn", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_MENU_PATH"})}
                               required={true} errorText={messageMap.get("path")}
                               value={innerResourceMenu.path}
                               onChange={(value) => {
                                   handleChange("path", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_MENU_ICON"})}
                               value={innerResourceMenu.icon}
                               onChange={(value) => {
                                   handleChange("icon", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_MENU_SEQUENCE"})}
                               type="number"
                               value={innerResourceMenu.sequence}
                               onChange={(value) => {
                                   handleChange("sequence", value)
                               }}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppMenuDetailDialog;
