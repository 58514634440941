export const ApplySourceType = {
    Map: new Map<string, string>([
        ['01', 'VMS_VISIT_REQUEST_FIELD_SOURCE_EMPLOYEE'],
        ['02', 'VMS_VISIT_REQUEST_FIELD_SOURCE_MANAGER'],
        ['03', 'VMS_VISIT_REQUEST_FIELD_SOURCE_TERMINAL'],
        ['04', 'VMS_VISIT_REQUEST_FIELD_SOURCE_MOBILE'],
        ['99', 'VMS_VISIT_REQUEST_FIELD_SOURCE_OFFLINE'],
    ]),
    Enum: {
        Employee: "01",
        Manager: "02",
        Terminal: '03',
        Mobile: '04',
        Offline: "99"
    }
};
