export class FileDto {
    keyId: string = '';
    id: string = '';
    name: string = '';
    path: string = '';
    pathPreview: string = '';
    parentId: string = '';
    // 建立父关系，获取对象引用效率更高
    parent?: FileDto;
    size: number = 0;
    classify: string = '';
    type: string = '';
    sequence: number = 0;
    lastModified: string = '';
    thumbnail: string = '';

    children?: Array<FileDto>;
}