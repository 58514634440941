export const ThreadStatus = {
    List: [
        {label: "WORKFLOW_THREAD_STATUS_NONE", value: "00"},
        // {label: "WORKFLOW_THREAD_STATUS_ACCEPTED", value: "01"},
        {label: "WORKFLOW_THREAD_STATUS_APPROVED", value: "01"},
        {label: "WORKFLOW_THREAD_STATUS_REJECTED", value: "02"}
    ],
    Map: new Map<string, string>([
        ['00', 'WORKFLOW_THREAD_STATUS_NONE'],
        ['01', 'WORKFLOW_THREAD_STATUS_APPROVED'],
        ['02', 'WORKFLOW_THREAD_STATUS_REJECTED'],
    ]),
    Enum: {
        None: "00",
        // Accepted: "01",
        Approved: "01",
        Rejected: "02"
    }
};