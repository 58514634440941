export const ValidateUtils = {

  /*
  * 验证身份证号码
  * */
  isIdNumber: (idNumber: string) => {

    const regex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!regex.test(idNumber)) {
      return false;
    }
    return true;
  },

  /*
   * 验证电话号码
   * */
  isMobileNumber: (mobile: string) => {

    const regex = /^(1[0-9]{10})$/;
    if (!regex.test(mobile)) {
      return false;
    }
    return true;
  },

  /**
   * 验证邮箱格式
   * @param email 邮箱地址
   * @returns {boolean} 是否是邮箱格式
   */
  isEmail: (email: string) => {
    const regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!regex.test(email)) {
      return false;
    }
    return true;
  },

  isNumber: (number: string) => {
    let regex = /^[0-9]*$/;
    if (!regex.test(number)) {
      return false;
    }
    return true;
  },

  /**
   * 数字、字母大/小写、以及特殊字符四者至少取其三
   */
  isPasswordStrength: (password: string) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*\(\)_+`\-={}:";'<>?,.\/]).{8,20}$/;
    if (regex.test(password)) {
      return true;
    }
    return false;
  },

  containsSpecialCharacter: (str: string) => {
    const pattern = new RegExp('[`~!@#$^&*()=|{}\':;\',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“\'。，、？]');
    return pattern.test(str);
  },

  containsNumber: (str: string) => {
    const pattern = /\d/;
    return pattern.test(str);
  },
};
