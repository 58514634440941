import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, CardHeader, FormControlLabel, Radio, RadioGroup, Stack,} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import PreviousNextButton from "../../../../../components/PreviousNextButton";
import {Language} from "../../../../../modules/common/enums/Language";
import {ImageUtils} from "../../../../../utils/ImageUtils";
import dayjs from "dayjs";
import {Formats} from "../../../../../constants/Formats";
import {RoutePath} from "../../../../../constants/RoutePath";
import {VMSCacheKey} from "../../../../../modules/vms/constants/VMSCacheKey";
import DictItemUtils from "../../../../../modules/dict/utils/DictItemUtils";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {FileApi} from "../../../../../modules/ftp/api/FileApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";

export type RegisterConsentFormProps = {
    onPrevious: () => void;
    onSubmit: (fileId: string) => void;
    requestVisitor: RequestVisitorDto;
    visitRequest: VisitRequestDto;
    purposeTypes: Array<DictItemDto>;
    currentSiteName: string;
};

// 检查签名图片的定时任务
let checkSignImageInterval: NodeJS.Timeout;

const RegisterConsentForm: React.FC<RegisterConsentFormProps> = (props) => {
    const intl = useIntl();

    const [consentLetterLanguage, setConsentLetterLanguage] = useState(Language.Enum.zhCN);

    const [consentLetterImgZh, setConsentLetterImgZh] = useState('');
    const [consentLetterImgEn, setConsentLetterImgEn] = useState('');
    const [consentLetterImg, setConsentLetterImg] = useState('');

    const [visitPlace, setVisitPlace] = useState('');
    const [visitorName, setVisitorName] = useState('');
    const [visitPurpose, setVisitPurpose] = useState('');

    // 英文和中文的布局不同，所以签名的图片要跟源图片合并，需要考虑参数变量
    const signImageYZh = 2280;
    const signImageYEn = 2680;

    useEffect(() => {
        console.log(props.currentSiteName);

        let selectedPlaceStr = props.currentSiteName;
        if (props.visitRequest.places && props.visitRequest.places.length > 0) {
            selectedPlaceStr = selectedPlaceStr + ": " + props.visitRequest.places.map((place) => {
                return place.locationName + "/" + place.areaName;
            }).join(',');
        }
        setVisitPlace(selectedPlaceStr);

        const visitorName = props.requestVisitor.lastName + ' ' + props.requestVisitor.firstName;
        setVisitorName(visitorName);

        const visitPurpose = DictItemUtils.getText(props.purposeTypes, props.visitRequest.purposeType) + ' ' + props.visitRequest.purpose;
        setVisitPurpose(visitPurpose);

        generateConsentLetterZh(selectedPlaceStr, visitorName, visitPurpose);

        return () => {
            if (checkSignImageInterval) {
                clearInterval(checkSignImageInterval);
            }
        }

    }, []);

    const generateConsentLetterZh = (visitPlace: string, visitorName: string, visitPurpose: string) => {
        // console.log("generateConsentLetterZh");
        const visitContents = new Array<string>();
        visitContents.push(visitPlace);
        visitContents.push(visitorName);
        visitContents.push(visitPurpose);

        const visitContentsImage = ImageUtils.covertStrToImage(visitContents, 500, 500, 20, 40);

        const signDateStrArray = new Array<string>();
        signDateStrArray.push(dayjs().format(Formats.DATE_HYPHEN));

        const signDateImage = ImageUtils.covertStrToImage(signDateStrArray, 500, 500, 30, 40);

        ImageUtils.getImageBase64('/ConsentLetter/ConsentLetter_zh-CN.jpeg', (backgroundImg) => {

            // console.log("backgroundImg:" + backgroundImg);
            // 拼凑通知图片和签名图片
            ImageUtils.stitchPicture(backgroundImg, visitContentsImage, 600, 2000, (stitchImage) => {
                // console.log("stitchImage:" + stitchImage);
                // 拼凑签署日期
                ImageUtils.stitchPicture(stitchImage, signDateImage, 600, signImageYZh, (consentLetter) => {
                    // console.log(consentLetter);
                    setConsentLetterImgZh(consentLetter);
                    setConsentLetterImg(consentLetter);
                })
            })
        });
    }

    const generateConsentLetterEn = (visitPlace: string, visitorName: string, visitPurpose: string) => {
        // console.log("generateConsentLetterEn");

        const visitContents = new Array<string>();
        visitContents.push(visitPlace);
        visitContents.push(visitorName);
        visitContents.push(visitPurpose);

        const visitContentsImage = ImageUtils.covertStrToImage(visitContents, 500, 500, 20, 40);

        const signDateStrArray = new Array<string>();
        signDateStrArray.push(dayjs().format(Formats.DATE_HYPHEN));

        const signDateImage = ImageUtils.covertStrToImage(signDateStrArray, 500, 500, 30, 40);

        ImageUtils.getImageBase64('/ConsentLetter/ConsentLetter_en-US.jpeg', (backgroundImg) => {

            // console.log("backgroundImg:" + backgroundImg);
            // 拼凑通知图片和签名图片
            ImageUtils.stitchPicture(backgroundImg, visitContentsImage, 600, 2420, (stitchImage) => {
                // console.log("stitchImage:" + stitchImage);
                // 拼凑签署日期
                ImageUtils.stitchPicture(stitchImage, signDateImage, 600, signImageYEn, (consentLetter) => {
                    // console.log(consentLetter);
                    setConsentLetterImgEn(consentLetter);
                    setConsentLetterImg(consentLetter);
                })
            })
        });
    }

    const handleChangeConsentLetterLanguage = (value: string) => {
        console.log(value);
        setConsentLetterLanguage(value);
        if (value === Language.Enum.enUS) {
            if (consentLetterImgEn === '') {
                generateConsentLetterEn(visitPlace, visitorName, visitPurpose);
            } else {
                setConsentLetterImg(consentLetterImgEn);
            }
        } else {
            if (consentLetterImgZh === '') {
                generateConsentLetterZh(visitPlace, visitorName, visitPurpose);
            } else {
                setConsentLetterImg(consentLetterImgZh);
            }
        }
        // console.log('handleChangeConsentLetterLanguage');
        // console.log(consentLetterImg);
    }

    const handleScreenProjection = async () => {

        // 缓存当前的同意书
        localStorage.setItem(VMSCacheKey.CONSENT_LETTER, consentLetterImg);

        // @ts-ignore
        const screenDetails = await window.getScreenDetails().catch(e => {
            console.error(e);
            return null;
        });
        console.log(screenDetails);

        let targetScreen = window.screen;

        for (let screenDetail of screenDetails.screens) {
            if (!screenDetail.isPrimary) {
                targetScreen = screenDetail;
                break;
            }
        }
        console.log(targetScreen);

        // @ts-ignore
        const features = "left=" + targetScreen.availLeft + ",top=" + targetScreen.availTop +
            ",width=" + targetScreen.availWidth + ",height=" + targetScreen.availHeight + ",fullscreen";

        // 英文和中文的布局不同，所以签名的图片要跟源图片合并，需要考虑参数变量
        const signImageY = consentLetterLanguage === Language.Enum.enUS ? signImageYEn : signImageYZh;
        const pageUrl = RoutePath.VMS_VISITOR_MANAGE_CHECKIN_SIGN + "?signImageY=" + signImageY;
        window.open(pageUrl, '_blank', features);

        // 等待签名返回
        checkSignImageInterval = setInterval(() => {
            const signedConsentLetter = localStorage.getItem(VMSCacheKey.CONSENT_LETTER);
            if (signedConsentLetter && signedConsentLetter.length > consentLetterImg.length) {
                clearInterval(checkSignImageInterval);

                // 清空缓存的签名照片
                localStorage.removeItem(VMSCacheKey.CONSENT_LETTER);
                // 更新本地的签名照片
                setConsentLetterImg(signedConsentLetter);
            }
        }, 1000);
    }

    const handleReset = () => {
        if (consentLetterLanguage === Language.Enum.enUS) {
            setConsentLetterImg(consentLetterImgEn);
        } else {
            setConsentLetterImg(consentLetterImgZh);
        }
    }

    const handleSubmit = async () => {
        let targetFolder = dayjs().format(Formats.DATE_SIMPLE);
        targetFolder = targetFolder.substr(0, 6) + "/" + targetFolder.substr(6, 7);
        const responseResult = await FileApi.uploadBase64File(targetFolder, "", consentLetterImg);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            props.onSubmit(responseResult.data);
        }
    }

    return (
        <div>
            <Stack spacing={2} style={{height: '60vh'}}>
                <Card style={{height: '100%'}}>
                    <CardHeader
                        action={
                            <Stack direction={'row'} spacing={2}>
                                <RadioGroup row value={consentLetterLanguage}
                                            onChange={(e) => handleChangeConsentLetterLanguage(e.target.value)}>
                                    {
                                        Language.List.map((item, index) => {
                                            return (
                                                <FormControlLabel key={index} value={item.value} control={<Radio/>}
                                                                  label={intl.formatMessage({id: item.label})}/>
                                            )
                                        })
                                    }
                                </RadioGroup>
                                <Button variant={'contained'} color="primary" size={'small'}
                                        onClick={handleScreenProjection}>
                                    <FormattedMessage id={'COMMON_SCREEN_PROJECTION'}/>
                                </Button>
                                <Button variant={'outlined'} color="primary" size={'small'} onClick={handleReset}>
                                    <FormattedMessage id={'COMMON_RESET'}/>
                                </Button>
                            </Stack>
                        }
                    />
                    <CardContent style={{overflow: 'auto', height: '55vh'}}>
                        <img alt={'consentLetterImg'} src={consentLetterImg} style={{width: '100%'}}/>
                    </CardContent>
                </Card>
            </Stack>
            <br/>
            <PreviousNextButton onPrevious={props.onPrevious} onNext={handleSubmit}
                                nextText={intl.formatMessage({id: "COMMON_SUBMIT"})}/>
        </div>
    );
};

export default RegisterConsentForm;
