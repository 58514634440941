import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {DictClassDto} from "../dto/DictClassDto";

export const DictClassApi = {
    /**
     * 获得字典类列表
     * @param codes 字典类编号列表
     */
    getDictClassList: async (codes: Array<string>) => {
        try {
            const params = new Map<string, string>();
            params.set('codes', codes.join(','));
            const requestUrl = HttpUtils.addQueryString('/v1/dict/class/list', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    // /**
    //  * 获得字典类列表
    //  * @param typeId 字典类类型Id
    //  */
    // getDictClassListByTypeId: async (typeId: string) => {
    //     try {
    //         const params = new Map<string, string>();
    //         params.set('typeId', typeId);
    //         const requestUrl = HttpUtils.addQueryString('/v1/dict/class/list', params);
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    // getDictClassDetail: async (id: string) => {
    //     try {
    //         const params = new Map<string, string>();
    //         params.set('id', id);
    //         const requestUrl = HttpUtils.addQueryString('/v1/dict/class/detail', params);
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    getDictItems: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/dict/class/items', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createDictClass: async (body: DictClassDto) => {
        try {
            const requestUrl = '/v1/dict/class/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateDictClass: async (body: DictClassDto) => {
        try {
            const requestUrl = '/v1/dict/class/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteDictClass: async (body: DictClassDto) => {
        try {
            const requestUrl = '/v1/dict/class/delete';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
