import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {LocationDto} from "../../../../../modules/vms/dto/LocationDto";
import {LocationApi} from "../../../../../modules/vms/api/LocationApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {SiteDto} from "../../../../../modules/vms/dto/SiteDto";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem
} from "@mui/material";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";
import FormFieldText from "../../../../../components/FormFieldText";
import FormFieldSelect from "../../../../../components/FormFieldSelect";

export type LocationDetailDialogProps = {
    open: boolean;
    siteList: Array<SiteDto>;
    location: LocationDto;
    onClose: () => void;
    onRefresh: (dictTypeId: string) => void;
};

const LocationDetailDialog: React.FC<LocationDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.location.id ? 'VMS_LOCATION_MODIFY' : 'VMS_LOCATION_CREATE';

    const [innerLocation, setInnerLocation] = useState<LocationDto>(props.location);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerLocation({...props.location});
    }, [props.location]);

    const handleChange = (name: string, value: any) => {
        setInnerLocation(previousLocation => ({...previousLocation, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.location.id) {
                const responseResult = await LocationApi.updateLocation(innerLocation);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_LOCATION_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onRefresh(innerLocation.siteId);
                }
            } else {
                const responseResult = await LocationApi.createLocation(innerLocation);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_LOCATION_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onRefresh(innerLocation.siteId);
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerLocation.siteId === '') {
            newMessageMap.set('siteId', intl.formatMessage({id: 'VMS_LOCATION_SITE_REQUIRED'}));
        }
        if (innerLocation.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'VMS_LOCATION_CODE_REQUIRED'}));
        }
        if (innerLocation.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'VMS_LOCATION_NAME_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldSelect label={intl.formatMessage({id: "VMS_LOCATION_SITE"})}
                                 required={true} errorText={messageMap.get("siteId")}
                                 value={innerLocation.siteId}
                                 onChange={(value) => {
                                     handleChange("siteId", value)
                                 }}>
                    {props.siteList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </FormFieldSelect>
                <FormFieldText label={intl.formatMessage({id: "VMS_LOCATION_CODE"})} value={innerLocation.code}
                               required={true}
                               errorText={messageMap.get("code")}
                               onChange={(value) => handleChange('code', value)}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_LOCATION_NAME"})} value={innerLocation.name}
                               required={true}
                               errorText={messageMap.get("name")}
                               onChange={(value) => handleChange('name', value)}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_LOCATION_SEQUENCE"})} value={innerLocation.sequence}
                               type={'number'}
                               onChange={(value) => handleChange('sequence', value)}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default LocationDetailDialog;
