import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormHelperText,
    IconButton, Input,
    InputAdornment, InputLabel, TextField,
} from "@mui/material";
import {useIntl, FormattedMessage} from "react-intl";
import {UserApi} from "../../../../../modules/account/api/UserApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {ValidateUtils} from "../../../../../utils/ValidateUtils";

export type PasswordDialogProps = {
    open: boolean;
    onClose: () => void;
};

const PasswordDialog: React.FC<PasswordDialogProps> = (props) => {
    const intl = useIntl();

    const [user, setUser] = useState<UserDto>(new UserDto());
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = React.useState(false);
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowNewPasswordConfirm = () => setShowNewPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleChangeUser = (name: string, value: any) => {
        setUser({...user, [name]: value});
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (user.password === '') {
            newMessageMap.set('password', intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_NEW_REQUIRED'}));
        } else if (user.password.length < 8 || !ValidateUtils.isPasswordStrength(user.password)) {
            newMessageMap.set("password", intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_STRENGTH_REQUIRED"}));
        }
        if (user.oldPassword === '') {
            newMessageMap.set('oldPassword', intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_OLD_REQUIRED'}));
        }
        if (newPasswordConfirm === '') {
            newMessageMap.set('newPasswordConfirm', intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_NEW_CONFIRM_REQUIRED'}));
        } else if (user.password !== newPasswordConfirm) {
            newMessageMap.set('newPasswordConfirm', intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_NEW_CONFIRM_FAILED'}));
        }
        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const handleClose = () => {
        props.onClose();
        setUser(new UserDto());
        setNewPasswordConfirm('');
    }

    const handleSave = async () => {
        if (validate()) {
            const responseResult = await UserApi.updateSessionUserPassword(user);
            if (responseResult.status === HttpStatus.SUCCESS) {
                handleClose();
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_MODIFY_SUCCESS'}))
            }
        }
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
                <FormattedMessage id={'ACCOUNT_USER_PASSWORD_MODIFY'}/>
            </DialogTitle>
            <DialogContent>
                <FormControl required={true} variant="standard" fullWidth margin="normal">
                    <InputLabel>
                        <FormattedMessage id={'ACCOUNT_USER_PASSWORD_OLD'}/>
                    </InputLabel>
                    <Input
                        placeholder={intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_OLD_REQUIRED"})}
                        type={showOldPassword ? 'text' : 'password'}
                        error={messageMap.has("oldPassword")}
                        value={user.oldPassword}
                        onChange={(e) => {
                            handleChangeUser("oldPassword", e.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {messageMap.has("oldPassword") ? <FormHelperText
                        error={messageMap.has("oldPassword")}>{messageMap.get("oldPassword")}</FormHelperText> : null}
                </FormControl>
                <FormControl required={true} variant="standard" fullWidth margin="normal">
                    <InputLabel>
                        <FormattedMessage id={'ACCOUNT_USER_PASSWORD_NEW'}/>
                    </InputLabel>
                    <Input
                        placeholder={intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_NEW_REQUIRED"})}
                        type={showNewPassword ? 'text' : 'password'}
                        error={messageMap.has("password")}
                        value={user.password}
                        onChange={(e) => {
                            handleChangeUser("password", e.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {messageMap.has("password") ? <FormHelperText
                            error={messageMap.has("password")}>{messageMap.get("password")}</FormHelperText> : null}
                </FormControl>
                <FormControl required={true} variant="standard" fullWidth margin="normal">
                    <InputLabel>
                        <FormattedMessage id={'ACCOUNT_USER_PASSWORD_NEW_CONFIRM'}/>
                    </InputLabel>
                    <Input
                        placeholder={intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_NEW_CONFIRM_REQUIRED"})}
                        type={showNewPasswordConfirm ? 'text' : 'password'}
                        error={messageMap.has("newPasswordConfirm")}
                        value={newPasswordConfirm}
                        onChange={(e) => {
                            setNewPasswordConfirm(e.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowNewPasswordConfirm}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showNewPasswordConfirm ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {messageMap.has("newPasswordConfirm") ? <FormHelperText
                        error={messageMap.has("newPasswordConfirm")}>{messageMap.get("newPasswordConfirm")}</FormHelperText> : null}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="secondary">
                    <FormattedMessage id={'COMMON_CANCEL'}/>
                </Button>
                <Button variant="outlined" onClick={handleSave} color="primary">
                    <FormattedMessage id={'COMMON_SAVE'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordDialog;
