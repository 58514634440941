export default {
    COMMON_SUNDAY: 'Sunday',
    COMMON_MONDAY: 'Monday',
    COMMON_TUESDAY: 'Tuesday',
    COMMON_WEDNESDAY: 'Wednesday',
    COMMON_THURSDAY: 'Thursday',
    COMMON_FRIDAY: 'Friday',
    COMMON_SATURDAY: 'Saturday',

    COMMON_ACTION: 'Action',
    COMMON_ADD: 'Add',
    COMMON_ADD_SUCCESS: 'Add succeed.',
    COMMON_MODIFY: 'Modify',
    COMMON_MODIFY_SUCCESS: 'Modify succeed.',
    COMMON_DELETE: 'Delete',
    COMMON_DELETE_SUCCESS: 'Delete succeed.',
    COMMON_DELETE_CONFIRM: 'Please confirm delete this item?',
    COMMON_SUBMIT: 'Submit',
    COMMON_SUBMIT_SUCCESS: 'Submit succeed.',
    COMMON_RESET: 'Reset',
    COMMON_HOME: 'Home',
    COMMON_NEXT_STEP: 'Next Step',
    COMMON_PREVIOUS_STEP: 'Previous Step',
    COMMON_RETURN_HOME: 'Return Home',
    COMMON_CANCEL: 'Cancel',
    COMMON_CLOSE: "Close",
    COMMON_CONFIRM: 'Confirm',

    COMMON_SEARCH: 'Search',
    COMMON_DETAIL: 'Detail',
    COMMON_REFRESH: 'Refresh',
    COMMON_SELECT: 'Select',
    COMMON_COPY: 'Copy',
    COMMON_OK: 'OK',
    COMMON_APPROVE: "Approve",
    COMMON_REJECT: "Reject",
    COMMON_EXPORT: 'Export',
    COMMON_SORT: 'Sort',
    COMMON_SAVE: "Save",

    COMMON_PAGINATION_TOTAL: 'Total {sum} items',
    COMMON_PAGINATION_PAGE_SIZE: 'Rows per page',

    COMMON_MOBILE_FORMAT: 'Please provide the correct phone number, consisting of 11 digits',
    COMMON_EMAIL_FORMAT: 'Please fill in the correct email format',

    COMMON_LANGUAGE_ZH_CN: '简体中文',
    COMMON_LANGUAGE_EN_US: 'English',

    COMMON_SCREEN_PROJECTION: "Screen Projection",

    COMMON_REMARKS: 'Remarks',
};