import axios, {ResponseType} from "axios";
import {AccountCacheKey} from "../modules/account/constants/AccountCacheKey";
import {MessageUtils} from "./MessageUtils";
import {HttpStatus} from "../modules/common/enums/HttpStatus";
import {MATRIX_GATEWAY_SERVICE_HOST, MATRIX_LOGIN_PAGE_URL} from "../constants/Profile";
import {RoutePath} from "../constants/RoutePath";
import {ResponseResult} from "../modules/common/dto/ResponseResult";

export const HttpUtils = {
    addQueryString: (url: string, params: Map<string, string>) => {
        for (let key of params.keys()) {
            if (url.indexOf("?") > 0) {
                // 存在参数，则直接加入
                url = url + "&" + key + "=" + params.get(key);
            } else {
                url = url + "?" + key + "=" + params.get(key);
            }
        }
        return url;
    },

    getQueryString: (url: string, param: string) => {
        let name, value;
        let query = url.substring(url.indexOf("?") + 1);
        let arr = query.split("&");
        for (let i = 0; i < arr.length; i++) {
            let num = arr[i].indexOf("=");
            if (num > 0) {
                name = arr[i].substring(0, num);
                value = arr[i].substring(num + 1);
                if (param === name) {
                    return value;
                }
            }
        }
    },

    removeQueryString: (url: string, param: string) => {
        if (url.indexOf("?") > 0) {
            let newParams = new Map<string, string>;
            let query = url.substring(url.indexOf("?") + 1);
            let arr = query.split("&");
            for (let i = 0; i < arr.length; i++) {
                let num = arr[i].indexOf("=");
                if (num > 0) {
                    const name = arr[i].substring(0, num);
                    const value = arr[i].substring(num + 1);
                    if (name !== param) {
                        newParams.set(name, value);
                    }
                }
            }
            let newUrl = url.substring(0, url.indexOf("?"));
            newUrl = HttpUtils.addQueryString(newUrl, newParams);
            return newUrl;
        } else {
            return url;
        }
    }
}

export const AxiosRequest = axios.create({
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
    // to methods of that instance.
    baseURL: MATRIX_GATEWAY_SERVICE_HOST,
    // `headers` are custom headers to be sent
    headers: {
        "Content-Type": "application/json",
    },
    // `params` are the URL parameters to be sent with the request
    // Must be a plain object or a URLSearchParams object
    // params: {
    //     tenantId: "b72b7e3add8d4daca8b7e391aec7c1a1"
    // },
});

// Add a request interceptor
// 统一处理访问请求发送的添加头部信息等等逻辑
AxiosRequest.interceptors.request.use(function (config) {
    // 把登录获得的Token加入到Request的Header，通过拦截器配置，每次请求都读取一次缓存
    config.headers['X-Auth-Token'] = localStorage.getItem(AccountCacheKey.ACCESS_TOKEN);
    // console.log(config);
    // Do something before request is sent
    return config;
}, function (error) {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
}, {synchronous: true});

// Add a response interceptor
// 统一处理返回响应的异常处理等等逻辑
AxiosRequest.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    console.log(error);
    if (error.response) {
        if (error.response.status === HttpStatus.NOT_FOUND) {
            MessageUtils.warning(error.message);
        } else {
            // const message = useIntl().formatMessage({id: error.response.data.message});
            // enqueueSnackbar(error.response.data.message, {variant: 'warning'});
            MessageUtils.warning(error.response.data.message);
            if (error.response.data.description) {
                MessageUtils.warning(error.response.data.description);
                // enqueueSnackbar(error.response.data.description, {variant: 'warning'});
            }
            if (error.response.status === HttpStatus.UNAUTHORIZED) {
                // 如果是未登录异常，则重定向到登录页面
                if (error.response.data.message === "GATEWAY_MSG_ACCESS_TOKEN_EXPIRED" || error.response.data.message === "GATEWAY_MSG_ACCESS_TOKEN_NOT_FOUND") {
                    // 如果是登录过期或者未登录，则跳转到登录页面
                    if (!window.location.href.includes('redirect')) {
                        window.location.href = MATRIX_LOGIN_PAGE_URL + "?redirect=" + window.location.href;
                    }
                }
            }
        }
    } else {
        // 没有请求的时候，构造一个空对象，否则外部判断会报异常
        error.response = {
            data: new ResponseResult()
        };
        MessageUtils.error(error.message);
        // enqueueSnackbar(error.message, {variant: 'error'});
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});


export const AxiosFileRequest = axios.create({
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
    // to methods of that instance.
    baseURL: MATRIX_GATEWAY_SERVICE_HOST,
    // `headers` are custom headers to be sent
    headers: {
        'Content-Type': 'multipart/form-data',
    },

    timeout: 60000,
    // `params` are the URL parameters to be sent with the request
    // Must be a plain object or a URLSearchParams object
    // params: {
    //     tenantId: "b72b7e3add8d4daca8b7e391aec7c1a1"
    // },
});
// Add a request interceptor
// 统一处理访问请求发送的添加头部信息等等逻辑
AxiosFileRequest.interceptors.request.use(function (config) {
    // 把登录获得的Token加入到Request的Header，通过拦截器配置，每次请求都读取一次缓存
    config.headers['X-Auth-Token'] = localStorage.getItem(AccountCacheKey.ACCESS_TOKEN);
    // console.log(config);
    // Do something before request is sent
    return config;
}, function (error) {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
}, {synchronous: true});
// Add a response interceptor
// 统一处理返回响应的异常处理等等逻辑
AxiosFileRequest.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    console.log(error);
    if (error.response.status === HttpStatus.NOT_FOUND) {
        MessageUtils.warning(error.message);
    } else if (error.response) {
        // const message = useIntl().formatMessage({id: error.response.data.message});
        // enqueueSnackbar(error.response.data.message, {variant: 'warning'});
        MessageUtils.warning(error.response.data.message);
        if (error.response.data.description) {
            MessageUtils.warning(error.response.data.description);
            // enqueueSnackbar(error.response.data.description, {variant: 'warning'});
        }
        if (error.response.status === HttpStatus.UNAUTHORIZED) {
            // 如果是未登录异常，则重定向到登录页面
            // window.location.href = RoutePath.ACCOUNT_USER_LOGIN;
        }
    } else {
        MessageUtils.error(error.message);
        // enqueueSnackbar(error.message, {variant: 'error'});
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export const AxiosDownloadFileRequest = axios.create({
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
    // to methods of that instance.
    baseURL: MATRIX_GATEWAY_SERVICE_HOST,
    // `headers` are custom headers to be sent
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 60000,
    // `params` are the URL parameters to be sent with the request
    // Must be a plain object or a URLSearchParams object
    // params: {
    //     tenantId: "b72b7e3add8d4daca8b7e391aec7c1a1"
    // },
});
// Add a request interceptor
// 统一处理访问请求发送的添加头部信息等等逻辑
AxiosDownloadFileRequest.interceptors.request.use(function (config) {
    // 把登录获得的Token加入到Request的Header，通过拦截器配置，每次请求都读取一次缓存
    config.headers['X-Auth-Token'] = localStorage.getItem(AccountCacheKey.ACCESS_TOKEN);
    config.responseType = 'blob';
    // console.log(config);
    // Do something before request is sent
    return config;
}, function (error) {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
}, {synchronous: true});
// Add a response interceptor
// 统一处理返回响应的异常处理等等逻辑
AxiosDownloadFileRequest.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    console.log(error);
    if (error.response.status === HttpStatus.NOT_FOUND) {
        MessageUtils.warning(error.message);
    } else if (error.response) {
        // const message = useIntl().formatMessage({id: error.response.data.message});
        // enqueueSnackbar(error.response.data.message, {variant: 'warning'});
        MessageUtils.warning(error.response.data.message);
        if (error.response.data.description) {
            MessageUtils.warning(error.response.data.description);
            // enqueueSnackbar(error.response.data.description, {variant: 'warning'});
        }
        if (error.response.status === HttpStatus.UNAUTHORIZED) {
            // 如果是未登录异常，则重定向到登录页面
            // window.location.href = RoutePath.ACCOUNT_USER_LOGIN;
        }
    } else {
        MessageUtils.error(error.message);
        // enqueueSnackbar(error.message, {variant: 'error'});
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});