import React, {useEffect, useState} from 'react';
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import PaginationBar from "../../../../components/PaginationBar";
import {useIntl} from "react-intl";
import {PaginationDto} from "../../../../modules/common/dto/PaginationDto";
import {RequestVisitorSearchCondition} from "../../../../modules/vms/dto/RequestVisitorSearchCondition";
import {RequestVisitorApi} from "../../../../modules/vms/api/RequestVisitorApi";
import {RequestVisitorDto} from "../../../../modules/vms/dto/RequestVisitorDto";
import SearchCondition from "../history/components/SearchCondition";
import SearchResult from "../history/components/SearchResult";
import {Card, Paper} from "@mui/material";
import {DictClassCode} from "../../../../modules/vms/constants/DictClassCode";
import {DictClassApi} from "../../../../modules/dict/api/DictClassApi";
import {DictItemDto} from "../../../../modules/dict/dto/DictItemDto";


const Index: React.FC = () => {

    const intl = useIntl();

    // 查询条件
    const [searchCondition, setSearchCondition] = useState(new RequestVisitorSearchCondition());
    const [visitorTypes, setVisitorTypes] = useState(new Array<DictItemDto>());

    // 查询结果
    const [searchResult, setSearchResult] = useState(new Array<RequestVisitorDto>());

    // 分页数据
    const [pagination, setPagination] = useState(new PaginationDto());
    useEffect(() => {
        // 查询访问目的字典项
        fetchDictClassList();
    }, []);
    const fetchDictClassList = async () => {
        const codes = new Array<string>(DictClassCode.VMS_VISITOR_TYPE);
        const responseResult = await DictClassApi.getDictClassList(codes);
        if (responseResult.status === HttpStatus.SUCCESS) {
            for (let dictClass of responseResult.data) {
                switch (dictClass.code) {
                    case DictClassCode.VMS_VISITOR_TYPE:
                        setVisitorTypes(dictClass.dictItems);
                        break;


                }
            }
        }
    };
    const handleChangeCondition = (name: string, value: any) => {
        setSearchCondition({...searchCondition, [name]: value});
    };

    const handleSearch = async (searchCondition: RequestVisitorSearchCondition) => {
        const responseResult = await RequestVisitorApi.queryRequestVisitorsNoTenant(searchCondition);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setSearchResult(responseResult.data);
            setPagination(responseResult.pagination);
        }
    };

    const handleSearchReport = () => {

    };

    const handlePagination = (page: number, pageSize: number) => {
        const newSearchCondition = Object.assign({}, searchCondition);
        newSearchCondition.currentPage = page;
        newSearchCondition.pageSize = pageSize;
        handleSearch(newSearchCondition);
    };

    return (
        <div>
            <Paper sx={{p: 1}}>
                <SearchCondition searchCondition={searchCondition}
                                 onChange={handleChangeCondition}
                                 onSearch={() => handleSearch(searchCondition)}
                                 onSearchReport={() => handleSearchReport()}/>
            </Paper>

            <br/>

            <Paper sx={{p: 1}}>
                <PaginationBar pagination={pagination} onChange={handlePagination}/>
                <SearchResult searchResult={searchResult} visitorTypes={visitorTypes}/>
            </Paper>
        </div>
    );
};

export default Index;
