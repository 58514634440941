export default {
    COMMON_SUNDAY: '星期天',
    COMMON_MONDAY: '星期一',
    COMMON_TUESDAY: '星期二',
    COMMON_WEDNESDAY: '星期三',
    COMMON_THURSDAY: '星期四',
    COMMON_FRIDAY: '星期五',
    COMMON_SATURDAY: '星期六',

    COMMON_ACTION: '操作',
    COMMON_ADD: '添加',
    COMMON_ADD_SUCCESS: '添加成功',
    COMMON_MODIFY: '修改',
    COMMON_MODIFY_SUCCESS: '修改成功',
    COMMON_DELETE: '删除',
    COMMON_DELETE_SUCCESS: '删除成功',
    COMMON_DELETE_CONFIRM: '删除后无法恢复，请确认是否删除？',
    COMMON_SUBMIT: '提交',
    COMMON_SUBMIT_SUCCESS: '提交成功',
    COMMON_RESET: '重置',
    COMMON_HOME: '首页',
    COMMON_NEXT_STEP: '下一步',
    COMMON_PREVIOUS_STEP: '上一步',
    COMMON_RETURN_HOME: '返回首页',
    COMMON_CANCEL: '取消',
    COMMON_CLOSE: "关闭",
    COMMON_CONFIRM: '确定',

    COMMON_SEARCH: '查询',
    COMMON_DETAIL: '详细',
    COMMON_REFRESH: '刷新',
    COMMON_SELECT: '选择',
    COMMON_COPY: '复制',
    COMMON_OK: '确定',
    COMMON_APPROVE: "批准",
    COMMON_REJECT: "拒绝",
    COMMON_EXPORT: '导出',
    COMMON_SORT: '排序',
    COMMON_SAVE: "保存",
    COMMON_PAGINATION_TOTAL: '共 {sum} 条',
    COMMON_PAGINATION_PAGE_SIZE: '每页显示',

    COMMON_MOBILE_FORMAT: '请填写正确的手机号，11位数字',
    COMMON_EMAIL_FORMAT: '请填写正确的邮箱格式',

    COMMON_LANGUAGE_ZH_CN: '简体中文',
    COMMON_LANGUAGE_EN_US: 'English',

    COMMON_SCREEN_PROJECTION: "投屏",

    COMMON_REMARKS: '备注',
};
