import React, {useEffect, useState} from 'react';
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import PaginationBar from "../../../../components/PaginationBar";
import {useIntl} from "react-intl";
import {PaginationDto} from "../../../../modules/common/dto/PaginationDto";
import {RequestVisitorSearchCondition} from "../../../../modules/vms/dto/RequestVisitorSearchCondition";
import {RequestVisitorApi} from "../../../../modules/vms/api/RequestVisitorApi";
import {RequestVisitorDto} from "../../../../modules/vms/dto/RequestVisitorDto";
import SearchCondition from "./components/SearchCondition";
import SearchResult from "./components/SearchResult";
import {Paper} from "@mui/material";
import RegisterDialog from "./components/RegisterDialog";
import {VisitRequestDto} from "../../../../modules/vms/dto/VisitRequestDto";


const Index: React.FC = () => {

    const intl = useIntl();

    // 查询条件
    const [searchCondition, setSearchCondition] = useState(new RequestVisitorSearchCondition());

    // 查询结果
    const [searchResult, setSearchResult] = useState(new Array<RequestVisitorDto>());

    // 分页数据
    const [pagination, setPagination] = useState(new PaginationDto());
    const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
    const [requestVisitor, setRequestVisitor] = useState(new RequestVisitorDto());
    const [visitRequest, setVisitRequest] = useState(new VisitRequestDto());
    useEffect(() => {
        if (openRegisterDialog) {

            // setVisitRequest(new VisitRequestDto());
            // setRequestVisitor(new RequestVisitorDto());
        }
    }, [openRegisterDialog]);
    const handleChangeCondition = (name: string, value: any) => {
        setSearchCondition({...searchCondition, [name]: value});
    };

    const handleSearch = async (searchCondition: RequestVisitorSearchCondition) => {
        const responseResult = await RequestVisitorApi.queryRequestVisitors(searchCondition);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setSearchResult(responseResult.data);
            setPagination(responseResult.pagination);
        }
    };

    const handleSearchReport = () => {

    };

    const handlePagination = (page: number, pageSize: number) => {
        const newSearchCondition = Object.assign({}, searchCondition);
        newSearchCondition.currentPage = page;
        newSearchCondition.pageSize = pageSize;
        handleSearch(newSearchCondition);
    };

    const handleCloseRegisterDialog = () => {
        setOpenRegisterDialog(false);
        handleSearch(searchCondition)
    }

    const handleOpenRegisterDialog = () => {
        setVisitRequest(new VisitRequestDto());
        setRequestVisitor(new RequestVisitorDto());
        setOpenRegisterDialog(true);
    }
    const handleOpenDetailRegisterDialog = async (record: RequestVisitorDto) => {
        const responseResult = await RequestVisitorApi.getVisitorDetailById(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            const data = responseResult.data;
            const newVisitor = Object.assign({}, data);
            const newVisitRequest = Object.assign({}, data.visitRequest);

            setRequestVisitor(newVisitor);
            setVisitRequest(newVisitRequest);
            setOpenRegisterDialog(true);
        }
    }
    return (
        <div>
            <RegisterDialog open={openRegisterDialog} onClose={handleCloseRegisterDialog}
                            requestVisitor={requestVisitor}
                            visitRequest={visitRequest}
                            onChangeVisitRequest={setVisitRequest}
                            onChangeRequestVisitor={setRequestVisitor}
                            onSubmitSuccess={() => handleSearch(searchCondition)}/>
            <Paper sx={{p: 1}}>
                <SearchCondition searchCondition={searchCondition}
                                 onChange={handleChangeCondition}
                                 onOpenRegisterDialog={handleOpenRegisterDialog}
                                 onSearch={() => handleSearch(searchCondition)}
                                 onSearchReport={() => handleSearchReport()}/>
            </Paper>

            <br/>

            <Paper sx={{p: 1}}>
                <PaginationBar pagination={pagination} onChange={handlePagination}/>
                <SearchResult searchResult={searchResult}
                              onOpenDetailRegisterDialog={handleOpenDetailRegisterDialog}
                              onSearch={() => handleSearch(searchCondition)}/>
            </Paper>
        </div>
    );
};

export default Index;
