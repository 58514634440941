import {AxiosRequest} from "../../../utils/HttpUtils";

export const FormApi = {

    getFormList: async () => {
        try {
            const requestUrl = '/v1/workflow/form/list';
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
