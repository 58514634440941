import React, {useEffect, useState} from 'react';
import {
    Grid, Stack
} from "@mui/material";
import {useIntl} from "react-intl";
import {UserSearchCondition} from "../../../../../modules/account/dto/UserSearchCondition";
import FormFieldText from "../../../../../components/FormFieldText";
import SearchButton from "../../../../../components/buttons/SearchButton";
import AddButton from "../../../../../components/buttons/AddButton";

export type SearchConditionProps = {
    searchCondition: UserSearchCondition;
    onChange: (name: string, value: any) => void;
    onClickSearch: () => void;
    onClickAdd: () => void;
};

const SearchCondition: React.FC<SearchConditionProps> = (props) => {
    const intl = useIntl();

    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "ACCOUNT_USER_UID"})}
                               value={props.searchCondition.uid}
                               onChange={(value) => {
                                   props.onChange("uid", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "ACCOUNT_USER_NAME"})}
                               value={props.searchCondition.name}
                               onChange={(value) => {
                                   props.onChange("name", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "ACCOUNT_USER_EMAIL"})}
                               value={props.searchCondition.email}
                               onChange={(value) => {
                                   props.onChange("email", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <Stack direction={'row'} spacing={2}>
                    <SearchButton onClick={props.onClickSearch}/>
                    <AddButton onClick={props.onClickAdd}/>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default SearchCondition;
