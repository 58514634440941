import React, {useContext, useEffect, useState} from "react";
import {ResourceMenuDto} from "../modules/account/dto/ResourceMenuDto";
import {LanguageContext} from "../context/LanguageContext";
import {IconUtils} from "../utils/IconUtils";
import {Language} from "../modules/common/enums/Language";
import {Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";

export type SideMenuProps = {
    resourceMenus: Array<ResourceMenuDto>
};

const SideMenu: React.FC<SideMenuProps> = (props) => {

    // This hook returns the current location object.
    // This can be useful if you'd like to perform some side effect whenever the current location changes.
    const location = useLocation();

    const navigate = useNavigate();

    // 多语言上下文设置
    const {language, setLanguage} = useContext(LanguageContext);

    // 展开的资源
    const [expandResources, setExpandResources] = useState<Array<string>>([]);

    useEffect(() => {
        const newExpandResources = new Array<string>();
        for (let resourceMenu of props.resourceMenus) {
            // 默认根节点都展开
            newExpandResources.push(resourceMenu.path);
        }
        setExpandResources(newExpandResources);
    }, [props.resourceMenus])

    const getMenuName = (resourceMenu: ResourceMenuDto) => {
        switch (language) {
            case Language.Enum.enUS:
                return resourceMenu.nameEn;
            default:
                return resourceMenu.nameZh;
        }
    }

    const handleClickMenu = (resourceMenu: ResourceMenuDto) => {
        console.log(resourceMenu);
        console.log(expandResources);
        if (resourceMenu.children) {
            const newExpandResources = Object.assign([], expandResources);
            // 展开或者折叠
            if (expandResources.includes(resourceMenu.path)) {
                console.log(resourceMenu.path)
                newExpandResources.splice(expandResources.indexOf(resourceMenu.path), 1);
            } else {
                newExpandResources.push(resourceMenu.path);
            }
            console.log(newExpandResources);
            setExpandResources(newExpandResources);
        } else {
            navigate(resourceMenu.path);
        }
    }

    const renderChildren = (parent: ResourceMenuDto, children: Array<ResourceMenuDto>) => {
        return (
            <Collapse in={expandResources.includes(parent.path)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        children.map((child, index) => {
                            return (
                                <ListItemButton key={child.id}
                                                onClick={() => handleClickMenu(child)} sx={{pl: 4}}
                                                selected={child.path === location.pathname}>
                                    <ListItemIcon>
                                        {IconUtils.getIcon(child.icon)}
                                    </ListItemIcon>
                                    <ListItemText>{getMenuName(child)}</ListItemText>
                                </ListItemButton>
                            )
                        })
                    }
                </List>
            </Collapse>
        )
    }

    const renderExpandIcon = (resourceMenu: ResourceMenuDto) => {
        if (resourceMenu.children && resourceMenu.children.length > 0) {
            if (expandResources.includes(resourceMenu.path)) {
                return <ExpandLess/>;
            } else {
                return <ExpandMore/>
            }
        }
    }

    console.log(expandResources);
    return (
        <List>
            {
                props.resourceMenus.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <ListItemButton onClick={() => handleClickMenu(item)}>
                                <ListItemIcon>
                                    {IconUtils.getIcon(item.icon)}
                                </ListItemIcon>
                                <ListItemText>{getMenuName(item)}</ListItemText>
                                {renderExpandIcon(item)}
                            </ListItemButton>
                            {item.children ? renderChildren(item, item.children) : null}
                            <Divider sx={{mt: 2, mb: 2}}/>
                        </div>
                    )
                })
            }
        </List>
    )
}

export default SideMenu;