import React, {useContext, useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import Header from "./Header";
import {ResourceMenuDto} from "../modules/account/dto/ResourceMenuDto";
import {ResourceMenuApi} from "../modules/account/api/ResourceMenuApi";
import {MATRIX_APP_ID} from "../constants/Profile";
import {HttpStatus} from "../modules/common/enums/HttpStatus";
import {UserDto} from "../modules/account/dto/UserDto";
import {Language} from "../modules/common/enums/Language";
import {LanguageContext} from "../context/LanguageContext";
import {Outlet, useLocation} from 'react-router-dom';
import {UserApi} from "../modules/account/api/UserApi";
import {ConfirmProvider} from "material-ui-confirm";
import {useIntl} from "react-intl";
import {ResourceAppApi} from "../modules/account/api/ResourceAppApi";

const AuthorizedLayout: React.FC = () => {
    const intl = useIntl();

    // This hook returns the current location object.
    // This can be useful if you'd like to perform some side effect whenever the current location changes.
    const location = useLocation();

    // 多语言上下文设置
    const {language, setLanguage} = useContext(LanguageContext);

    const [sessionUser, setSessionUser] = useState<UserDto>();

    const [pageTitle, setPageTitle] = useState('');

    const [resourceMenus, setResourceMenus] = useState<Array<ResourceMenuDto>>([]);

    useEffect(() => {
        fetchSessionUser();
        fetchAppMenus();
    }, []);

    // 路径变化的时候，重新构造Breadcrumb
    useEffect(() => {
        getPageName(resourceMenus);
    }, [location]);

    const fetchSessionUser = async () => {
        const responseResult = await UserApi.getSessionUser();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setSessionUser(responseResult.data);
        }
    };

    const fetchAppMenus = async () => {
        const responseResult = await ResourceAppApi.getMyResourceMenuTreeList(MATRIX_APP_ID);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setResourceMenus(responseResult.data);
        }
    };

    const getPageName = (items: Array<ResourceMenuDto>) => {
        for (let resourceMenu of items) {
            if (resourceMenu.path === location.pathname) {
                switch (language) {
                    case Language.Enum.enUS:
                        setPageTitle(resourceMenu.nameEn);
                        return;
                    default:
                        setPageTitle(resourceMenu.nameZh);
                        return;
                }
            } else if (resourceMenu.children) {
                getPageName(resourceMenu.children);
            }
        }
    }

    return (
        <ConfirmProvider defaultOptions={{
            confirmationText: intl.formatMessage({id: 'COMMON_OK'}),
            cancellationText: intl.formatMessage({id: 'COMMON_CANCEL'})
        }}>
            <Header sessionUser={sessionUser}/>
            <Grid container spacing={2} style={{padding: '1rem'}}>
                <Grid item xs={2} style={{maxWidth: '15rem',borderRight:'1px solid rgb(229, 234, 239)'}}>
                    <SideMenu resourceMenus={resourceMenus}/>
                </Grid>
                <Grid item xs={10}  >
                    <Typography variant="h4" gutterBottom>
                        {pageTitle}
                    </Typography>
                    {/*<Paper style={{padding: '1rem'}}>*/}
                    <Outlet/>
                    {/*</Paper>*/}
                    <Footer/>
                </Grid>
            </Grid>
        </ConfirmProvider>
    );
};

export default AuthorizedLayout;