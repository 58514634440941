import React, {useRef, useState} from 'react';
import VisitRequest from './components/VisitRequest';
import VisitorList from './components/VisitorList';
import {FormattedMessage, useIntl} from "react-intl";
import {VisitRequestDto} from "../../../../modules/vms/dto/VisitRequestDto";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {VisitRequestApi} from "../../../../modules/vms/api/VisitRequestApi";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {Button, Stack} from "@mui/material";
import SubmitButton from "../../../../components/buttons/SubmitButton";

const VisitorInvite: React.FC = () => {
    const intl = useIntl();

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    const [visitRequest, setVisitRequest] = useState(new VisitRequestDto());

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }
        const responseResult = await VisitRequestApi.inviteVisitRequest(visitRequest);
        if (responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'VMS_VISIT_REQUEST_INVITE_SUCCESS'}));
            handleReset();
        }
    };

    const handleReset = () => {
        setVisitRequest(new VisitRequestDto());
    };

    const handleChange = (name: string, value: any) => {
        setVisitRequest({...visitRequest, [name]: value});
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (visitRequest.arriveStartDate === '') {
            newMessageMap.set('arriveStartDate', intl.formatMessage({id: 'VMS_VISIT_REQUEST_ARRIVE_DATE_REQUIRED'}));
        }
        if (visitRequest.purposeType === '') {
            newMessageMap.set('purposeType', intl.formatMessage({id: 'VMS_VISIT_REQUEST_PURPOSE_TYPE_REQUIRED'}));
        }
        if (visitRequest.places.length <= 0) {
            newMessageMap.set('places', intl.formatMessage({id: 'VMS_VISIT_REQUEST_PLACES_REQUIRED'}));
        }
        if (visitRequest.visitors.length <= 0) {
            newMessageMap.set('visitors', intl.formatMessage({id: 'VMS_VISIT_REQUEST_VISITORS_REQUIRED'}));
        }
        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <div>
            <VisitRequest visitRequest={visitRequest} messageMap={messageMap} onChange={handleChange}/>
            <br/>
            <VisitorList visitRequest={visitRequest} messageMap={messageMap} onChange={handleChange}/>
            <br/>
            <Stack direction={'row'} spacing={2} justifyContent={'center'} alignItems={'center'}>
                <SubmitButton onClick={handleSubmit}/>
            </Stack>
        </div>
    );
};

export default VisitorInvite;
