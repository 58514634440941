import * as React from 'react';
import {useEffect, useState} from "react";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {ResourceAppDto} from "../../../../modules/account/dto/ResourceAppDto";
import {ResourceAppApi} from "../../../../modules/account/api/ResourceAppApi";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import AppCard from "./components/AppCard";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {useIntl} from "react-intl";
import {TenantApi} from "../../../../modules/account/api/TenantApi";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AppsIcon from '@mui/icons-material/Apps';

export default function Index() {
    const intl = useIntl();

    const [apps, setApps] = useState<Array<ResourceAppDto>>([]);
    const [myApps, setMyApps] = useState<Array<ResourceAppDto>>([]);
    const [myAppIds, setMyAppIds] = useState<Array<string>>([]);

    useEffect(() => {
        fetchMyApps();
        fetchApps();
    }, [])

    const fetchApps = async () => {
        const responseResult = await ResourceAppApi.getResourceAppList();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setApps(responseResult.data);
        }
    };

    const fetchMyApps = async () => {
        const responseResult = await TenantApi.getMyResourceApps(false);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setMyApps(responseResult.data);
            const appIds = [];
            for (let item of responseResult.data) {
                appIds.push(item.id);
            }
            setMyAppIds(appIds);
        }
    };

    const handleClickActive = async (item: ResourceAppDto) => {
        const responseResult = await TenantApi.activeMyResourceApp(item.id);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_ACTIVE_SUCCESS'}));
            fetchMyApps();
            fetchApps();
        }
    }

    return (
        <div>
            <Card>
                <CardHeader
                    avatar={
                        <StarBorderIcon/>
                    }
                    title={intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_ACTIVATED'})}
                />
                <CardContent>
                    <Grid container spacing={3}>
                        {
                            myApps.map((item, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                        <AppCard app={item} active={true}
                                                 onClickActive={() => handleClickActive(item)}/>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </CardContent>
            </Card>
            <br/>
            <Card>
                <CardHeader
                    avatar={
                        <AppsIcon/>
                    }
                    title={intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_INACTIVATED'})}
                />
                <CardContent>
                    <Grid container spacing={3}>
                        {
                            apps.map((item, index) => {
                                if (!myAppIds.includes(item.id)) {
                                    return (
                                        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                            <AppCard app={item} active={false}
                                                     onClickActive={(selectedApp) => handleClickActive(selectedApp)}/>
                                        </Grid>
                                    )
                                }
                            })
                        }
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}