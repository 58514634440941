import * as React from 'react';
import TenantForm from "./components/TenantForm";
import {useEffect, useState} from "react";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {TenantDto} from "../../../../modules/account/dto/TenantDto";
import {TenantApi} from "../../../../modules/account/api/TenantApi";

export default function Index() {

    const [tenant, setTenant] = useState<TenantDto>(new TenantDto());

    useEffect(() => {
        fetchMyTenant();
    }, [])

    const fetchMyTenant = async () => {
        const responseResult = await TenantApi.getMyTenant();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setTenant(responseResult.data);
        }
    };

    return (
        <TenantForm tenant={tenant}/>
    );
}