import React from 'react';
import {useIntl} from "react-intl";
import {RequestVisitorSearchCondition} from "../../../../../modules/vms/dto/RequestVisitorSearchCondition";
import {Formats} from "../../../../../constants/Formats";
import {StatusType} from "../../../../../modules/vms/enums/StatusType";
import dayjs from "dayjs";
import {
    Grid
} from "@mui/material";
import SearchButton from "../../../../../components/buttons/SearchButton";
import FormFieldText from "../../../../../components/FormFieldText";
import FormFieldDateRange from "../../../../../components/FormFieldDateRange";
import FormFieldSelect from "../../../../../components/FormFieldSelect";

export type SearchConditionProps = {
    searchCondition: RequestVisitorSearchCondition;
    onChange: (name: string, value: any) => void;
    onSearch: () => void;
};

const SearchCondition: React.FC<SearchConditionProps> = (props) => {
    const intl = useIntl();

    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_NAME"})}
                               value={props.searchCondition.visitorName}
                               onChange={(value) => {
                                   props.onChange("visitorName", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL"})}
                               value={props.searchCondition.visitorEmail}
                               onChange={(value) => {
                                   props.onChange("visitorEmail", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE"})}
                               value={props.searchCondition.visitorMobile}
                               onChange={(value) => {
                                   props.onChange("visitorMobile", value)
                               }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY"})}
                               value={props.searchCondition.visitorCompany}
                               onChange={(value) => {
                                   props.onChange("visitorCompany", value)
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldDateRange fromStartAdornment={intl.formatMessage({id: "VMS_VISIT_REQUEST_ARRIVE_DATE"})}
                                    fromValue={dayjs(props.searchCondition.arriveDateFrom, Formats.DATE_SIMPLE)}
                                    fromOnChange={(value) => props.onChange("arriveDateFrom", value)}
                                    toValue={dayjs(props.searchCondition.arriveDateTo, Formats.DATE_SIMPLE)}
                                    toOnChange={(value) => props.onChange("arriveDateTo", value)}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldSelect startAdornment={intl.formatMessage({id: "VMS_VISIT_REQUEST_STATUS"})}
                                 value={props.searchCondition.status}
                                 onChange={(value) => {
                                     props.onChange("status", value)
                                 }}
                                 items={StatusType.List.map((item) => {
                                     return {label: intl.formatMessage({id: item.label}), value: item.value}
                                 })}/>
            </Grid>
            <Grid item xs={3}>
                <SearchButton onClick={() => props.onSearch()}/>
            </Grid>
        </Grid>
    )
};

export default SearchCondition;
