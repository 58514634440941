import React, {useEffect, useState} from 'react';
import {VisitRequestDto} from "../../../modules/vms/dto/VisitRequestDto";
import {FormattedMessage, useIntl} from "react-intl";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";
import ApproveTimeLine from "../../workflow/apply-process/components/ApproveTimeLine";
import {DictClassCode} from "../../../modules/vms/constants/DictClassCode";
import {DictClassApi} from "../../../modules/dict/api/DictClassApi";
import {HttpStatus} from "../../../modules/common/enums/HttpStatus";
import VisitRequestBasic from "./VisitRequestBasic";
import RequestVisitorList from "./RequestVisitorList";
import RequestPlaceList from "./RequestPlaceList";
import {VisitRequestApi} from "../../../modules/vms/api/VisitRequestApi";
import {MessageUtils} from "../../../utils/MessageUtils";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import CloseButton from "../../../components/buttons/CloseButton";

export type VisitRequestDetailDialogProps = {
    open: boolean;
    visitRequest: VisitRequestDto;
    onClose: () => void;
    readonly?: boolean;
    onApprove?: () => void;
    onReject?: () => void;
};

const VisitRequestDetailDialog: React.FC<VisitRequestDetailDialogProps> = (props) => {
    const intl = useIntl();

    // 访问目的
    const [purposeTypes, setPurposeTypes] = useState(new Array<DictItemDto>());

    // 证件类型
    const [idTypes, setIdTypes] = useState(new Array<DictItemDto>());

    useEffect(() => {
        // 查询访问目的字典项
        fetchDictClassList();
    }, []);

    const fetchDictClassList = async () => {
        const codes = new Array<string>(DictClassCode.VMS_PURPOSE_TYPE, DictClassCode.VMS_ID_TYPE);
        const responseResult = await DictClassApi.getDictClassList(codes);
        if (responseResult.status === HttpStatus.SUCCESS) {
            for (let dictClass of responseResult.data) {
                switch (dictClass.code) {
                    case DictClassCode.VMS_PURPOSE_TYPE:
                        setPurposeTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_ID_TYPE:
                        setIdTypes(dictClass.dictItems);
                        break;
                }
            }
        }
    };

    const handleApprove = async () => {
        const responseResult = await VisitRequestApi.approve(props.visitRequest.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'WORKFLOW_PROCESS_APPROVE_SUCCESS'}));
            if (props.onApprove) {
                props.onApprove();
                props.onClose();
            }
        }
    }

    const handleReject = async (remarks: string) => {
        const responseResult = await VisitRequestApi.reject(props.visitRequest.id, remarks);
        if (responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'WORKFLOW_PROCESS_REJECT_SUCCESS'}));
            if (props.onReject) {
                props.onReject();
                props.onClose();
            }
        }
    }

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                {intl.formatMessage({id: 'VMS_VISIT_REQUEST_DETAIL'})}
            </DialogTitle>
            <DialogContent>
                {/*访问申请信息*/}
                <VisitRequestBasic visitRequest={props.visitRequest} purposeTypes={purposeTypes}/>
                <br/>
                {/*访客列表*/}
                <RequestVisitorList visitors={props.visitRequest.visitors}/>
                <br/>
                {/*访问场所*/}
                <RequestPlaceList places={props.visitRequest.places}/>
                <br/>
                {/*审批信息*/}
                <ApproveTimeLine applyFormId={props.visitRequest.workflowApplyFormId}
                                 onApprove={handleApprove}
                                 onReject={(remarks: string) => handleReject(remarks)}
                                 readonly={props.readonly}/>
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={props.onClose}/>
            </DialogActions>
        </Dialog>
    );
};

export default VisitRequestDetailDialog;