import React, {useEffect, useState} from 'react';
import {Badge, Button, Grid} from "@mui/material";
import PreviousNextButton from "../../../../../components/PreviousNextButton";
import {FormattedMessage, useIntl} from "react-intl";
import {Formats} from "../../../../../constants/Formats";
import dayjs from "dayjs";
import AreaSelectDialog from "./AreaSelectDialog";
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {AreaDto} from "../../../../../modules/vms/dto/AreaDto";
import {RequestPlaceDto} from "../../../../../modules/vms/dto/RequestPlaceDto";
import {useConfirm} from "material-ui-confirm";
import FormFieldText from "../../../../../components/FormFieldText";
import FormFieldDate from "../../../../../components/FormFieldDate";
import {VisitorAccessLogDto} from "../../../../../modules/vms/dto/VisitorAccessLogDto";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";

export type RegisterRequestFormProps = {
    visitRequest: VisitRequestDto;
    requestVisitor: RequestVisitorDto;
    purposeTypes: Array<DictItemDto>;
    onChange: (name: string, value: any) => void;
    onNext: () => void;
    onPrevious: () => void;

    onVisitorChange: (name: string, value: any) => void;
};
const RegisterRequestForm: React.FC<RegisterRequestFormProps> = (props) => {
    const intl = useIntl();
    const confirm = useConfirm();
    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    // 场所选择的对话框
    const [areaSelectDialogOpen, setAreaSelectDialogOpen] = useState(false);

    // 选择的场所显示字符串
    const [selectedPlaceStr, setSelectedPlaceStr] = useState('');
    useEffect(() => {
        const placeString = props.visitRequest?.places.map(item => item.areaName).join(",");
        setSelectedPlaceStr(placeString);
    })

    const handleNext = () => {
        if (validate()) {
            props.onNext();
        }
    }
    const handlePrevious = () => {
        props.onPrevious();
    }

    const handleVisitorChange = (name: string, value: any) => {
        props.onVisitorChange(name, value);
    };
    const handleChange = (name: string, value: any) => {
        props.onChange(name, value);
    };
    const validate = () => {
        const newMessageMap = new Map<string, string>();

        // if (props.visitRequest.places.length <= 0) {
        //     newMessageMap.set('places', intl.formatMessage({id: 'VMS_VISIT_REQUEST_PLACES_REQUIRED'}));
        // }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const handleSelectAreas = (areas: Array<AreaDto>) => {
        const places = new Array<RequestPlaceDto>();
        for (let area of areas) {
            const place = new RequestPlaceDto(area);
            places.push(place);
        }
        handleChange("places", places);
        const selectedPlaceStr = areas.map((area) => {
            return area.name
        }).join(',')

        setSelectedPlaceStr(selectedPlaceStr);
        setAreaSelectDialogOpen(false);
    }
    const checkInLog = props.requestVisitor.checkInLog && Object.assign(new VisitorAccessLogDto(), props.requestVisitor.checkInLog);

    return (
        <div>
            <AreaSelectDialog open={areaSelectDialogOpen} onCancel={() => setAreaSelectDialogOpen(false)}
                              onOk={handleSelectAreas}/>
            {/*<Alert severity={"warning"}>*/}
            {/*    <FormattedMessage id={'VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO_ALERT'}/>*/}
            {/*</Alert>*/}
            <Grid container spacing={2} style={{height: '60vh'}}>

                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PURPOSE"})}
                                   value={props.visitRequest.purpose}
                                   onChange={(value) => {
                                       handleChange("purpose", value)
                                   }}/>
                </Grid>
                <Grid item xs={6}>
                    <FormFieldDate label={intl.formatMessage({id: "VMS_VISIT_REQUEST_ARRIVE_DATE"})}
                                   required={true} errorText={messageMap.get("arriveStartDate")}
                                   value={dayjs(props.visitRequest.arriveStartDate, Formats.DATE_SIMPLE)}
                                   minDate={dayjs()}
                                   maxDate={dayjs().add(15, "days")}
                                   onChange={(value) => handleChange("arriveStartDate", value)}/>
                </Grid>

                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PLACE"})}
                                   disabled={true} errorText={messageMap.get("places")}
                                   value={ props.visitRequest.places&&props.visitRequest.places.map(item => item.areaName).join(",")}
                                   endAdornment={
                                       <Badge badgeContent={props.visitRequest.places.length} color="secondary"
                                              overlap="rectangular">
                                           <Button color="primary"
                                                   onClick={() => setAreaSelectDialogOpen(true)}>
                                               <FormattedMessage id="VMS_VISIT_REQUEST_PLACE_SELECT"/>
                                           </Button>
                                       </Badge>}/>
                </Grid>

                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER"})}
                                   placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER_PLACEHOLDER"})}
                                   value={props.requestVisitor.accessCardNumber}
                                   onChange={(value) => {
                                       handleVisitorChange("accessCardNumber", value)
                                   }}/>
                </Grid>
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_CHECKIN_TIME"})}
                                   disabled={true}
                                   value={checkInLog?.fetchAccessDateTime()}
                    />
                </Grid>

            </Grid>
            <Grid>
                <PreviousNextButton onPrevious={handlePrevious} onNext={() => handleNext()}/>
            </Grid>
        </div>
    );
};

export default RegisterRequestForm;
