import React, {useEffect} from 'react';
import './App.css';

import {Outlet} from "react-router-dom";
import {LanguageContextProvider} from "./context/LanguageContext";
import {SnackbarProvider} from 'notistack'
import {AccountCacheKey} from "./modules/account/constants/AccountCacheKey";
import {HttpUtils} from "./utils/HttpUtils";
import {ThemeProvider} from "@mui/material";
import {CUSTOM_THEME} from "./CustomTheme";
import {RoutePath} from "./constants/RoutePath";

function App() {

    useEffect(() => {
        // 通行验证的token取到则缓存
        const token = HttpUtils.getQueryString(window.location.href, "token");
        if (token !== undefined && token !== "") {
            localStorage.setItem(AccountCacheKey.ACCESS_TOKEN, decodeURIComponent(token));
            // 重新刷新一次，把token参数去掉，否则由于localStorage.setItem异步的问题，导致请求的时候token不是最新的
            window.location.href = HttpUtils.removeQueryString(window.location.href, "token");
        } else {
            const code = HttpUtils.getQueryString(window.location.href, "code");
            console.log("code:" + code);
            console.log("host:" + window.location.href);
            if (code) {
                window.location.href = RoutePath.ACCOUNT_USER_LOGIN + "?authCode=" + code;
            }
            // else {
            //     if (window.location.href === VMS_WEB_HOST || window.location.href === VMS_WEB_HOST + "/") {
            //         window.location.href = MATRIX_LOGIN_PAGE_URL;
            //     }
            // }
        }
    }, []);

    return (
        <ThemeProvider theme={CUSTOM_THEME}>
            <LanguageContextProvider>
                <SnackbarProvider anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Outlet/>
                </SnackbarProvider>
            </LanguageContextProvider></ThemeProvider>
    );
}

export default App;