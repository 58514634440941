export default {
    ACCOUNT_USER_ADD: '添加新用户',
    ACCOUNT_USER_ADD_SUCCESS: '新用户添加成功',
    ACCOUNT_USER_MODIFY: '修改用户信息',
    ACCOUNT_USER_MODIFY_SUCCESS: '用户信息修改成功',
    ACCOUNT_USER_LOGIN: '用户登录',
    ACCOUNT_USER_LOGIN_AGREE_POLICY: '登录即表示同意',
    ACCOUNT_USER_PRIVACY_POLICY: '《隐私政策》',
    ACCOUNT_USER_AGREEMENT: '《用户协议》',
    ACCOUNT_USER_REGISTER: '用户注册',
    ACCOUNT_USER_REGISTER_AGREE_POLICY: '注册即表示同意',
    ACCOUNT_USER_REGISTER_SUCCESS: '用户注册成功，自动登录跳转至首页',
    ACCOUNT_USER_FORGOT_PASSWORD: '忘记密码',
    ACCOUNT_USER_LOGOUT: '用户登出',
    ACCOUNT_USER_UID: '用户ID',
    ACCOUNT_USER_UID_REQUIRED: '请输入用户ID',
    ACCOUNT_USER_PASSWORD: '登录密码',
    ACCOUNT_USER_PASSWORD_MODIFY: '修改密码',
    ACCOUNT_USER_PASSWORD_MODIFY_SUCCESS: '修改密码成功',
    ACCOUNT_USER_PASSWORD_MODIFY_HELP: '定期更换有助于账号安全',
    ACCOUNT_USER_PASSWORD_RESET: '重置密码',
    ACCOUNT_USER_PASSWORD_REQUIRED: '请输入用户密码',
    ACCOUNT_USER_PASSWORD_STRENGTH_REQUIRED: "密码不少于8位，必须包含英文，数字，特殊符号的组合",
    ACCOUNT_USER_PASSWORD_NEW: '新密码',
    ACCOUNT_USER_PASSWORD_NEW_REQUIRED: '请输入新密码',
    ACCOUNT_USER_PASSWORD_NEW_CONFIRM: '新密码确认',
    ACCOUNT_USER_PASSWORD_NEW_CONFIRM_REQUIRED: '请再次输入新密码',
    ACCOUNT_USER_PASSWORD_NEW_CONFIRM_FAILED: '两次密码输入不一致',
    ACCOUNT_USER_PASSWORD_OLD: '旧密码',
    ACCOUNT_USER_PASSWORD_OLD_REQUIRED: '请输入旧密码',
    ACCOUNT_USER_EMAIL: '邮箱',
    ACCOUNT_USER_EMAIL_MODIFY: '修改邮箱',
    ACCOUNT_USER_EMAIL_MODIFY_HELP: '用于登录、找回密码',
    ACCOUNT_USER_EMAIL_MODIFY_SUCCESS: '邮箱修改成功',
    ACCOUNT_USER_EMAIL_REQUIRED: '请输入邮箱',
    ACCOUNT_USER_EMAIL_FORMAT_WRONG: "请输入格式正确的邮箱地址",
    ACCOUNT_USER_EMAIL_EDIT_HELP: '请在账号安全画面修改邮箱地址',
    ACCOUNT_USER_MOBILE: '手机号',
    ACCOUNT_USER_MOBILE_EDIT_HELP: '请在账号安全画面修改手机号',
    ACCOUNT_USER_STATUS: '状态',
    ACCOUNT_USER_STATUS_ACTIVE: '启用',
    ACCOUNT_USER_STATUS_INACTIVE: '禁用',
    ACCOUNT_USER_NAME: '姓名',
    ACCOUNT_USER_NAME_REQUIRED: '请输入用户姓名',
    ACCOUNT_USER_LAST_NAME: '姓',
    ACCOUNT_USER_LAST_NAME_REQUIRED: '请输入姓氏',
    ACCOUNT_USER_FIRST_NAME: '名',
    ACCOUNT_USER_FIRST_NAME_REQUIRED: '请输入名字',
    ACCOUNT_USER_GENDER: '性别',
    ACCOUNT_USER_GENDER_NONE: '未设定',
    ACCOUNT_USER_GENDER_MALE: '男性',
    ACCOUNT_USER_GENDER_FEMALE: '女性',
    ACCOUNT_USER_ROLE: '角色',
    ACCOUNT_USER_ROLE_SETTING: '角色设定',
    ACCOUNT_USER_ROLE_SETTING_SUCCESS: '用户角色设定成功',
    ACCOUNT_USER_ROLE_REQUIRED: '请选择用户角色',
    ACCOUNT_USER_SUPERVISOR: '上级',
    ACCOUNT_USER_SUPERVISOR_SEARCH_PLACEHOLDER: '请点击搜索要设置的上级',
    ACCOUNT_USER_SEARCH: '用户查询',
    ACCOUNT_USER_LOCKED: '由于连续输错账号密码，该账号被锁定5分钟，请五分钟后再试',
    ACCOUNT_USER_PASSWORD_WRONG: '密码错误',
    ACCOUNT_USER_PROFILE: "账号信息",
    ACCOUNT_USER_CAPTCHA: "验证码",
    ACCOUNT_USER_CAPTCHA_RECEIVE: "获取验证码",
    ACCOUNT_USER_CAPTCHA_REQUIRED: '请填写验证码',
    ACCOUNT_USER_CAPTCHA_SEND_SUCCESS: '验证码发送成功，请查收后填入正确的验证码',
    ACCOUNT_USER_AUTH_TYPE_UID: '用户名密码',
    ACCOUNT_USER_AUTH_TYPE_EMAIL: '邮箱验证码',
    ACCOUNT_USER_HAVE_ACCOUNT: '已有账号？',
    ACCOUNT_USER_UPDATE_SUCCESS: '用户信息更新成功',

    ACCOUNT_TENANT_CODE: '公司编号',
    ACCOUNT_TENANT_NAME: '公司名称',

    ACCOUNT_ROLE_CREATE: '新建角色',
    ACCOUNT_ROLE_CREATE_SUCCESS: '新建角色成功',
    ACCOUNT_ROLE_MODIFY: '修改角色',
    ACCOUNT_ROLE_MODIFY_SUCCESS: '修改角色成功',
    ACCOUNT_ROLE_CODE: '角色编号',
    ACCOUNT_ROLE_CODE_REQUIRED: '请输入角色编号',
    ACCOUNT_ROLE_NAME: '角色名称',
    ACCOUNT_ROLE_NAME_REQUIRED: '请输入角色名称',
    ACCOUNT_ROLE_LEVEL: '角色级别',
    ACCOUNT_ROLE_LEVEL_REQUIRED: '请输入角色级别',
    ACCOUNT_ROLE_MENUS_BIND: '绑定菜单',
    ACCOUNT_ROLE_MENUS_BIND_SUCCESS: '绑定菜单成功',
    ACCOUNT_ROLE_ACTIONS_BIND: '绑定操作',
    ACCOUNT_ROLE_ACTIONS_BIND_SUCCESS: '绑定操作成功',

    ACCOUNT_RESOURCE_APP_CREATE: '新建应用',
    ACCOUNT_RESOURCE_APP_CREATE_SUCCESS: '新建应用成功',
    ACCOUNT_RESOURCE_APP_MODIFY: '修改应用',
    ACCOUNT_RESOURCE_APP_MODIFY_SUCCESS: '修改应用成功',
    ACCOUNT_RESOURCE_APP_ACTIVE: '开通应用',
    ACCOUNT_RESOURCE_APP_ACTIVE_CONFIRM: '确认要开通应用吗？',
    ACCOUNT_RESOURCE_APP_ACTIVATED: '已开通应用',
    ACCOUNT_RESOURCE_APP_INACTIVATED: '未开通应用',
    ACCOUNT_RESOURCE_APP_ACTIVE_SUCCESS: '开通应用成功，已成为该应用的管理员，可以为其他成员赋予权限',
    ACCOUNT_RESOURCE_APP_ACCESS: '访问应用',
    ACCOUNT_RESOURCE_APP_CODE: '应用编号',
    ACCOUNT_RESOURCE_APP_NAME_ZH: '应用中文名',
    ACCOUNT_RESOURCE_APP_NAME_EN: '应用英文名',
    ACCOUNT_RESOURCE_APP_PATH: '应用路径',
    ACCOUNT_RESOURCE_APP_ICON: '应用图标',
    ACCOUNT_RESOURCE_APP_SEQUENCE: '排序',
    ACCOUNT_RESOURCE_APP_MENU: '菜单',
    ACCOUNT_RESOURCE_APP_ACTION: '功能',
    ACCOUNT_RESOURCE_APP_ROLE: '角色',

    ACCOUNT_RESOURCE_MENU_CREATE: '添加菜单',
    ACCOUNT_RESOURCE_MENU_CREATE_SUCCESS: '添加菜单成功',
    ACCOUNT_RESOURCE_MENU_MODIFY: '修改菜单',
    ACCOUNT_RESOURCE_MENU_MODIFY_SUCCESS: '修改菜单成功',
    ACCOUNT_RESOURCE_MENU_PARENT: '父菜单',
    ACCOUNT_RESOURCE_MENU_CODE: '菜单编号',
    ACCOUNT_RESOURCE_MENU_CODE_REQUIRED: '请输入菜单编号',
    ACCOUNT_RESOURCE_MENU_NAME_ZH: '菜单中文名',
    ACCOUNT_RESOURCE_MENU_NAME_ZH_REQUIRED: '请输入菜单中文名',
    ACCOUNT_RESOURCE_MENU_NAME_EN: '菜单英文名',
    ACCOUNT_RESOURCE_MENU_NAME_EN_REQUIRED: '请输入菜单英文名',
    ACCOUNT_RESOURCE_MENU_PATH: '菜单路径',
    ACCOUNT_RESOURCE_MENU_PATH_REQUIRED: '请输入菜单路径',
    ACCOUNT_RESOURCE_MENU_ICON: '菜单图标',
    ACCOUNT_RESOURCE_MENU_SEQUENCE: '菜单排序',
    ACCOUNT_RESOURCE_MENU_ACTIONS_BIND: '绑定操作',
    ACCOUNT_RESOURCE_MENU_ACTIONS_BIND_SUCCESS: '绑定操作成功',

    ACCOUNT_RESOURCE_ACTION_APP_ID: '所属应用',
    ACCOUNT_RESOURCE_ACTION_APP_ID_REQUIRED: '请选择所属应用',
    ACCOUNT_RESOURCE_ACTION_CODE: '操作编号',
    ACCOUNT_RESOURCE_ACTION_CODE_REQUIRED: '请输入操作编号',
    ACCOUNT_RESOURCE_ACTION_NAME: '操作名称',
    ACCOUNT_RESOURCE_ACTION_NAME_REQUIRED: '请输入操作名称',
    ACCOUNT_RESOURCE_ACTION_PATH: '操作路径',
    ACCOUNT_RESOURCE_ACTION_PATH_REQUIRED: '请输入操作路径',

    // 组织管理-组织
    ACCOUNT_DEPARTMENT_LIST: "部门列表",
    ACCOUNT_DEPARTMENT_PARENT: "上级组织",
    ACCOUNT_DEPARTMENT_COMPANY: "所属公司",
    ACCOUNT_DEPARTMENT_COMPANY_REQUIRED: "所属公司不能为空",
    ACCOUNT_DEPARTMENT_COMPANY_PLACEHOLDER: "请选择所属公司",
    ACCOUNT_DEPARTMENT_CODE: "编号",
    ACCOUNT_DEPARTMENT_NAME: "名称",
    ACCOUNT_DEPARTMENT_TYPE: "类型",
    ACCOUNT_DEPARTMENT_TYPE_COMPANY: "公司",
    ACCOUNT_DEPARTMENT_TYPE_DEPARTMENT: "部门",
    ACCOUNT_DEPARTMENT_CONTACTS: "联系人",
    ACCOUNT_DEPARTMENT_TELEPHONE: "联系电话",
    ACCOUNT_DEPARTMENT_ADDRESS: "联系地址",
    ACCOUNT_DEPARTMENT_DISABLED: "禁用",
    ACCOUNT_DEPARTMENT_TEMPORARY: "临时",
    ACCOUNT_DEPARTMENT_DATA_GROUP: "数据组",
    ACCOUNT_DEPARTMENT_MANAGER: "主管",
    ACCOUNT_DEPARTMENT_CODE_REQUIRED: "组织编号不能为空",
    ACCOUNT_DEPARTMENT_NAME_REQUIRED: "组织名不能为空",
    ACCOUNT_DEPARTMENT_TYPE_REQUIRED: "组织类型不能为空",
    ACCOUNT_DEPARTMENT_CREATE_SUCCESS: "组织创建成功",
    ACCOUNT_DEPARTMENT_UPDATE_SUCCESS: "组织更新成功",
    ACCOUNT_DEPARTMENT_DELETE_SUCCESS: "组织删除成功",
    ACCOUNT_DEPARTMENT_DELETE_CONFIRM: "组织删除确认吗？",
    ACCOUNT_DEPARTMENT_PARENT_DEAD_CYCLE: "不可设置父组织为自己",
    ACCOUNT_DEPARTMENT_HAVE_CHILDREN: "该组织下有子组织",
    ACCOUNT_DEPARTMENT_HAVE_EMPLOYEE: "该组织下有员工",
    ACCOUNT_DEPARTMENT_NOT_FOUND: "没有找到组织",
};
