import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";

export const TaskApi = {

    sendCaptchaCode: async (mailTo: string, templateCode: string, body: Map<string, string>) => {
        try {
            const params = new Map<string, string>();
            params.set('mailTo', mailTo);
            params.set('templateCode', templateCode);
            const requestUrl = HttpUtils.addQueryString('/v1/mail/task/sendCaptchaCode', params);
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
