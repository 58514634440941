import React from 'react';
import {Button} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {FormattedMessage} from "react-intl";

type AddButtonProps = {
    onClick: () => void;
    color?: string;
    text?: string;
};

const AddButton: React.FC<AddButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <Button
            variant="outlined"
            size="small"
            // @ts-ignore
            color={props.color || "primary"}
            startIcon={<AddCircleIcon/>}
            onClick={(e) => handleClick(e)}>
            {props.text ? props.text : <FormattedMessage id={'COMMON_ADD'}/>}
        </Button>
    );
};
export default AddButton;