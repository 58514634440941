import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {DictTypeDto} from "../dto/DictTypeDto";

export const DictTypeApi = {
    /**
     * 获得字典类型列表
     */
    getDictTypeList: async () => {
        try {
            const requestUrl = '/v1/dict/type/list';
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    // getDictTypeDetail: async (id: string) => {
    //     try {
    //         const params = new Map<string, string>();
    //         params.set('id', id);
    //         const requestUrl = HttpUtils.addQueryString('/v1/dict/type/detail', params);
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    getDictClasses: async (id: string, commonFlag: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            params.set('commonFlag', commonFlag);
            const requestUrl = HttpUtils.addQueryString('/v1/dict/type/classes', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createDictType: async (body: DictTypeDto) => {
        try {
            const requestUrl = '/v1/dict/type/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateDictType: async (body: DictTypeDto) => {
        try {
            const requestUrl = '/v1/dict/type/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteDictType: async (body: DictTypeDto) => {
        try {
            const requestUrl = '/v1/dict/type/delete';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
