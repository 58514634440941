import React from "react";

const Footer: React.FC = () => {

    return (
        <div style={{textAlign: 'center', padding: '1rem', fontSize:'0.8rem', color:'#585858'}}>
            <div>Volkswagen VCG Visitor Management System ©{new Date().getFullYear()}</div>
            <div>v1.0.0.20240613</div>
        </div>
    )
}

export default Footer;