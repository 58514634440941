import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {RoleDto} from "../../../../../modules/account/dto/RoleDto";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {UserApi} from "../../../../../modules/account/api/UserApi";

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Tab,
    Tabs,
} from "@mui/material";
import {ResourceAppDto} from "../../../../../modules/account/dto/ResourceAppDto";
import {TenantApi} from "../../../../../modules/account/api/TenantApi";
import {LanguageContext} from "../../../../../context/LanguageContext";
import {Language} from "../../../../../modules/common/enums/Language";
import {MessageUtils} from "../../../../../utils/MessageUtils";

export type RoleDialogProps = {
    open: boolean;
    userId: string;
    onClose: () => void;
    onOk: () => void;
};

const RoleDialog: React.FC<RoleDialogProps> = (props) => {
    const intl = useIntl();

    // 当前租户的所有App
    const [myApps, setMyApps] = useState<Array<ResourceAppDto>>([]);
    const [currentAppId, setCurrentAppId] = useState('');

    // 当前用户的拥有角色
    const [currentUserRoleIds, setCurrentUserRoleIds] = useState<Array<string>>([]);

    // 应用角色映射表，Key:app id, value:role list
    const [appRolesMap, setAppRolesMap] = useState<Map<string, Array<RoleDto>>>();
    // 按AppId分类，当前用户的最高级别，当前用户的角色如果低于该级别，那么无权操作该用户的角色，只有高权限才能修改
    const [myAppRoleTopLevelMap, setMyAppRoleTopLevelMap] = useState<Map<string, number>>();

    // 多语言上下文设置
    const {language, setLanguage} = useContext(LanguageContext);

    const getAppName = (app: ResourceAppDto) => {
        switch (language) {
            case Language.Enum.enUS:
                return app.nameEn;
            default:
                return app.nameZh;
        }
    }

    useEffect(() => {
        if (props.userId) {
            fetchCurrentUserRoleList(props.userId);
        }
    }, [props.userId]);

    useEffect(() => {
        fetchTenantApps();
        fetchSessionUser();
    }, []);

    const fetchCurrentUserRoleList = async (userId: string) => {
        const responseResult = await UserApi.getRoleList(userId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            const tempMyRoleIds = [];
            for (let role of responseResult.data) {
                tempMyRoleIds.push(role.id);
            }
            setCurrentUserRoleIds(tempMyRoleIds);
        }
    };

    const fetchTenantApps = async () => {
        const responseResult = await TenantApi.getMyResourceApps(true);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setMyApps(responseResult.data);
            if (responseResult.data.length > 0) {
                setCurrentAppId(responseResult.data[0].id);
                const tempAppRolesMap = new Map<string, Array<RoleDto>>();
                for (let app of responseResult.data) {
                    tempAppRolesMap.set(app.id, app.roles);
                }
                setAppRolesMap(tempAppRolesMap);
            }
        }
    };

    const fetchSessionUser = async () => {
        const responseResult = await UserApi.getSessionUser();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            const myAppRoleTopLevelMap = new Map<string, number>();
            for (let role of responseResult.data.roles) {
                if (myAppRoleTopLevelMap.has(role.appId)) {
                    const tempRoleLevel = myAppRoleTopLevelMap.get(role.appId);
                    if (tempRoleLevel && tempRoleLevel > role.level) {
                        myAppRoleTopLevelMap.set(role.appId, role.level);
                    }
                } else {
                    myAppRoleTopLevelMap.set(role.appId, role.level);
                }
            }
            setMyAppRoleTopLevelMap(myAppRoleTopLevelMap);
        }
    };

    // const fetchTenantRoles = async () => {
    //     const responseResult = await TenantApi.getRoles();
    //     if (responseResult.status === HttpStatus.SUCCESS) {
    //         const tempAppRolesMap = new Map<string, Array<RoleDto>>();
    //         for (let role of responseResult.data) {
    //             if (!tempAppRolesMap.has(role.appId)) {
    //                 tempAppRolesMap.set(role.appId, new Array<RoleDto>());
    //             }
    //
    //             // @ts-ignore
    //             tempAppRolesMap.get(role.appId).push(role);
    //         }
    //         setAppRolesMap(tempAppRolesMap);
    //     }
    // };

    const handleOk = async () => {
        const responseResult = await UserApi.syncRoleList(props.userId, currentUserRoleIds);
        if (responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_ROLE_SETTING_SUCCESS'}));
            props.onClose();
            props.onOk();
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleChangeTab = (value: string) => {
        setCurrentAppId(value);
    }

    const handleClickRole = (roleId: string, checked: boolean) => {
        const tempMyRoleIds = Object.assign([], currentUserRoleIds);
        if (checked) {
            tempMyRoleIds.push(roleId);
        } else {
            tempMyRoleIds.splice(tempMyRoleIds.indexOf(roleId), 1);
        }
        console.log(tempMyRoleIds);
        setCurrentUserRoleIds(tempMyRoleIds);
    }

    return (
        <Dialog open={props.open} onClose={handleCancel}>
            <DialogTitle>{intl.formatMessage({id: 'ACCOUNT_USER_ROLE_SETTING'})}</DialogTitle>
            <DialogContent>
                <Tabs value={currentAppId} onChange={(e, value) => handleChangeTab(value)}>
                    {
                        myApps.map((item, index) => {
                            return (
                                <Tab key={index} value={item.id} label={getAppName(item)}/>
                            )
                        })
                    }
                </Tabs>
                <List dense sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                    {appRolesMap?.get(currentAppId)?.map((item) => {
                        return (
                            <ListItem
                                key={item.id}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        // @ts-ignore
                                        disabled={!myAppRoleTopLevelMap?.has(currentAppId) || myAppRoleTopLevelMap?.get(currentAppId) > item.level}
                                        onChange={(e) => handleClickRole(item.id, e.target.checked)}
                                        checked={currentUserRoleIds.includes(item.id)}
                                    />
                                }>
                                <ListItemButton>
                                    <ListItemText primary={item.name}/>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel} color="secondary">
                    <FormattedMessage id={'COMMON_CANCEL'}/>
                </Button>
                <Button variant="outlined" onClick={handleOk} color="primary">
                    <FormattedMessage id={'COMMON_OK'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoleDialog;
