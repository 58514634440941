import {PaginationDto} from "../../common/dto/PaginationDto";

export class UserSearchCondition extends PaginationDto {
  uid: string = '';
  name: string = '';
  email: string = '';
  mobile: string = '';
  roleIds: Array<string> = [];
  departmentId: string = '';
}
