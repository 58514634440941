import React from 'react';
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {useIntl} from "react-intl";
import {Grid} from "@mui/material";
import FormFieldText from "../../../../../components/FormFieldText";
import FormFieldDictItems from "../../../../dict/components/FormFieldDictItems";

export type VisitorDetailGeneralFormProps = {
    requestVisitor: RequestVisitorDto;
    idTypes: Array<DictItemDto>;
    visitorTypes: Array<DictItemDto>;
    messageMap: Map<string, string>;
    onChange: (name: string, value: any) => void;
};
const VisitorDetailGeneralForm: React.FC<VisitorDetailGeneralFormProps> = (props) => {
    const intl = useIntl();

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_TYPE"})}
                                    required={true} errorText={props.messageMap.get("visitorType")}
                                    value={props.requestVisitor.visitorType}
                                    onChange={(value) => {
                                        props.onChange("visitorType", value)
                                    }} dictItems={props.visitorTypes}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY"})}
                               placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY_REQUIRED"})}
                               required={true} errorText={props.messageMap.get("company")}
                               value={props.requestVisitor.company}
                               onChange={(value) => {
                                   props.onChange("company", value)
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_LAST_NAME"})}
                               placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_LAST_NAME_REQUIRED"})}
                               required={true} errorText={props.messageMap.get("lastName")}
                               value={props.requestVisitor.lastName}
                               onChange={(value) => {
                                   props.onChange("lastName", value)
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_FIRST_NAME"})}
                               placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_FIRST_NAME_REQUIRED"})}
                               required={true} errorText={props.messageMap.get("firstName")}
                               value={props.requestVisitor.firstName}
                               onChange={(value) => {
                                   props.onChange("firstName", value)
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE"})}
                               placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE_REQUIRED"})}
                               required={true} errorText={props.messageMap.get("mobile")}
                               type={"number"}
                               value={props.requestVisitor.mobile}
                               onChange={(value) => {
                                   props.onChange("mobile", value)
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL"})}
                               placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL_REQUIRED"})}
                               required={true} errorText={props.messageMap.get("email")}
                               type={"email"}
                               value={props.requestVisitor.email}
                               onChange={(value) => {
                                   props.onChange("email", value)
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_TYPE"})}
                                    required={true} errorText={props.messageMap.get("idType")}
                                    value={props.requestVisitor.idType}
                                    onChange={(value) => {
                                        props.onChange("idType", value)
                                    }} dictItems={props.idTypes}/>
            </Grid>
            <Grid item xs={6}>
                <FormFieldText label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_NUMBER"})}
                               placeholder={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_NUMBER_REQUIRED"})}
                               value={props.requestVisitor.idNumber}
                               onChange={(value) => {
                                   props.onChange("idNumber", value)
                               }}/>
            </Grid>
        </Grid>
    );
};

export default VisitorDetailGeneralForm;
