import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {AreaDto} from "../dto/AreaDto";

export const AreaApi = {

    /**
     * 获得区域列表
     * @param locationId 位置Id
     */
    getAreaList: async (locationId: string) => {
        try {
            const params = new Map<string, string>();
            params.set('locationId', locationId);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/area/list', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getAreaDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/area/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createArea: async (body: AreaDto) => {
        try {
            const requestUrl = '/v1/vms/area/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateArea: async (body: AreaDto) => {
        try {
            const requestUrl = '/v1/vms/area/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteArea: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/area/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
