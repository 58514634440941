import {ApplyProcessDto} from "./ApplyProcessDto";
import {ApplyFieldDto} from "./ApplyFieldDto";

export class ApplyFormDto {
    keyId: string = '';
    id: string = '';
    formId: string = '';
    number: string = '';
    name: string = '';
    applyDate: string = '';
    applicantId: string = '';
    applicantName: string = '';
    status: string = '';

    fields:Array<ApplyFieldDto> = [];
    applyProcesses: Array<ApplyProcessDto> = [];
}