import React, {useEffect, useState} from 'react';
import VisitorTypeList from "./components/VisitorTypeList";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {VisitorTypeApi} from "../../../../../modules/vms/api/VisitorTypeApi";
import {VisitorTypeDto} from "../../../../../modules/vms/dto/VisitorTypeDto";

const Index: React.FC = () => {

    const [visitorTypeList, setVisitorTypeList] = useState<Array<VisitorTypeDto>>([]);

    useEffect(() => {
        // 查询用户组列表
        fetchVisitorTypeList();
    }, []);

    const fetchVisitorTypeList = async () => {
        const responseResult = await VisitorTypeApi.getVisitorTypeList();
        if (responseResult.status === HttpStatus.SUCCESS) {
            setVisitorTypeList(responseResult.data);
        }
    };

    return (
        <div>
            <VisitorTypeList visitorTypeList={visitorTypeList} onRefresh={fetchVisitorTypeList}/>
        </div>
    );
};

export default Index;
