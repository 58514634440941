import React from 'react';
import {useIntl} from "react-intl";
import {VisitorBlackListDto} from "../../../../../modules/vms/dto/VisitorBlackListDto";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ModifyIconButton from "../../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../../components/buttons/DeleteIconButton";
import {useConfirm} from "material-ui-confirm";

export type SearchResultProps = {
    searchResult: Array<VisitorBlackListDto>;
    onDelete: (record: VisitorBlackListDto) => void;
    onModify: (record: VisitorBlackListDto) => void;
};

const SearchResult: React.FC<SearchResultProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const handleModify = async (record: VisitorBlackListDto) => {
        props.onModify(record);
    };

    const handleDelete = async (record: VisitorBlackListDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                props.onDelete(record);
            })
            .catch(() => {
                console.log('cancel');
            });

    };

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_NAME'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_EMAIL'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_VISITOR_MOBILE"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_VISITOR_COMPANY"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.searchResult.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.fullName}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.mobile}</TableCell>
                            <TableCell>{row.company}</TableCell>
                            <TableCell>
                                <ModifyIconButton onClick={() => handleModify(row)}/>
                                <DeleteIconButton onClick={() => handleDelete(row)}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default SearchResult;
