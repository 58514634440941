import React, {useEffect, useState} from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputAdornment, Stack,
    TextField
} from "@mui/material";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {useIntl, FormattedMessage} from "react-intl";
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {CaptchaDto} from "../../../../../modules/account/dto/CaptchaDto";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmailIcon from '@mui/icons-material/Email';
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {ValidateUtils} from "../../../../../utils/ValidateUtils";
import {TaskApi} from "../../../../../modules/mail/api/TaskApi";
import {UserApi} from "../../../../../modules/account/api/UserApi";

export type EmailDialogProps = {
    open: boolean;
    onClose: () => void;
};

// 发送验证码的倒计时
let sendCaptchaInterval: NodeJS.Timeout;

const EmailDialog: React.FC<EmailDialogProps> = (props) => {
    const intl = useIntl();

    const [seconds, setSeconds] = useState(60);

    const [canSendCaptcha, setCanSendCaptcha] = useState(true);

    const [user, setUser] = useState<UserDto>(new UserDto());
    const [captcha, setCaptcha] = useState<CaptchaDto>(new CaptchaDto());

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        return () => {
            if (sendCaptchaInterval) {
                clearInterval(sendCaptchaInterval);
            }
        }
    }, []);

    useEffect(() => {
        if (seconds <= 0) {
            clearInterval(sendCaptchaInterval);
            setCanSendCaptcha(true);
            setSeconds(60);
        }
    }, [seconds])

    const handleChangeUser = (name: string, value: any) => {
        setUser({...user, [name]: value});
    };

    const handleChangeCaptcha = (name: string, value: any) => {
        setCaptcha({...captcha, [name]: value});
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();

        if (user.email === '') {
            newMessageMap.set('email', intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_REQUIRED'}));
        } else if (!ValidateUtils.isEmail(user.email)) {
            newMessageMap.set('email', intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_FORMAT_WRONG'}));
        }
        if (captcha.captchaValue === '') {
            newMessageMap.set('captchaValue', intl.formatMessage({id: 'ACCOUNT_USER_CAPTCHA_REQUIRED'}));
        }
        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const _handleSendCaptchaCode = async () => {
        if (user.email === '') {
            MessageUtils.error(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_REQUIRED'}));
        } else if (!ValidateUtils.isEmail(user.email)) {
            MessageUtils.error(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_FORMAT_WRONG'}));
        } else {
            setCanSendCaptcha(false);
            sendCaptchaInterval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            const responseResult = await TaskApi.sendCaptchaCode(user.email, 'CAPTCHA_CODE', new Map<string, string>());
            if (responseResult.status === HttpStatus.SUCCESS) {
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_CAPTCHA_SEND_SUCCESS'}));
            }
        }
    }

    const handleClose = () => {
        props.onClose();
        setUser(new UserDto());
    }

    const handleSave = async () => {
        if (validate()) {
            const responseResult = await UserApi.updateSessionUserEmail(user, captcha.captchaValue);
            if (responseResult.status === HttpStatus.SUCCESS) {
                handleClose();
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_MODIFY_SUCCESS'}))
            }
        }
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
                <FormattedMessage id={'ACCOUNT_USER_PASSWORD_MODIFY'}/>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={intl.formatMessage({id: "ACCOUNT_USER_EMAIL"})}
                    placeholder={intl.formatMessage({id: "ACCOUNT_USER_EMAIL_REQUIRED"})}
                    fullWidth
                    required={true}
                    error={messageMap.has("email")}
                    helperText={messageMap.get("email")}
                    value={user.email}
                    margin={'normal'} variant="standard"
                    onChange={(e) => {
                        handleChangeUser("email", e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon/>
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    label={intl.formatMessage({id: "ACCOUNT_USER_CAPTCHA"})}
                    placeholder={intl.formatMessage({id: "ACCOUNT_USER_CAPTCHA_REQUIRED"})}
                    fullWidth
                    required={true}
                    margin={'normal'} variant="standard"
                    error={messageMap.has("captchaValue")}
                    helperText={messageMap.get("captchaValue")}
                    value={captcha.captchaValue}
                    onChange={(e) => {
                        handleChangeCaptcha("captchaValue", e.target.value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VerifiedUserIcon/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {
                                    canSendCaptcha ? <Button onClick={_handleSendCaptchaCode}>
                                        <FormattedMessage id={'ACCOUNT_USER_CAPTCHA_RECEIVE'}/>
                                    </Button> : <span>{seconds}</span>
                                }

                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="secondary">
                    <FormattedMessage id={'COMMON_CANCEL'}/>
                </Button>
                <Button variant="outlined" onClick={handleSave} color="primary">
                    <FormattedMessage id={'COMMON_SAVE'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailDialog;
