import React, {useEffect, useState} from 'react';
import {
    Button, Stack, TextField
} from "@mui/material";
import {useIntl, FormattedMessage} from "react-intl";
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {UserApi} from "../../../../../modules/account/api/UserApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../../utils/MessageUtils";

export type UserFormProps = {
    user: UserDto;
};

const UserForm: React.FC<UserFormProps> = (props) => {
    const intl = useIntl();

    const [innerUser, setInnerUser] = useState<UserDto>(props.user);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    const [editFlag, setEditFlag] = useState(false);

    useEffect(() => {
        setInnerUser({...props.user});
    }, [props.user]);

    const handleChange = (name: string, value: any) => {
        setInnerUser(previousUser => ({...previousUser, [name]: value}));
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerUser.uid === '') {
            newMessageMap.set('uid', intl.formatMessage({id: 'ACCOUNT_USER_UID_REQUIRED'}));
        }
        if (innerUser.lastName === '') {
            newMessageMap.set('lastName', intl.formatMessage({id: 'ACCOUNT_USER_LAST_NAME_REQUIRED'}));
        }
        if (innerUser.firstName === '') {
            newMessageMap.set('firstName', intl.formatMessage({id: 'ACCOUNT_USER_FIRST_NAME_REQUIRED'}));
        }
        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const handleCancelEdit = () => {
        setEditFlag(false);
        setInnerUser(props.user);
    }

    const handleSave = async () => {
        if (validate()) {
            const responseResult = await UserApi.updateSessionUser(innerUser);
            if (responseResult.status === HttpStatus.SUCCESS) {
                fetchSessionUser();
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_UPDATE_SUCCESS'}))
            }
        }
    }

    const fetchSessionUser = async () => {
        const responseResult = await UserApi.getSessionUser();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setEditFlag(false);
        }
    };

    return (
        <Stack spacing={2}>
            <TextField
                variant="standard"
                label={intl.formatMessage({id: "ACCOUNT_USER_UID"})}
                required={true}
                disabled={!editFlag}
                error={messageMap.has("uid")}
                helperText={messageMap.get("uid")}
                value={innerUser.uid}
                onChange={(e) => {
                    handleChange("uid", e.target.value)
                }}
            />

            <TextField
                variant="standard"
                label={intl.formatMessage({id: "ACCOUNT_USER_LAST_NAME"})}
                required={true}
                disabled={!editFlag}
                error={messageMap.has("lastName")}
                helperText={messageMap.get("lastName")}
                value={innerUser.lastName}
                onChange={(e) => {
                    handleChange("lastName", e.target.value)
                }}
            />

            <TextField
                variant="standard"
                label={intl.formatMessage({id: "ACCOUNT_USER_FIRST_NAME"})}
                required={true}
                disabled={!editFlag}
                error={messageMap.has("firstName")}
                helperText={messageMap.get("firstName")}
                value={innerUser.firstName}
                onChange={(e) => {
                    handleChange("firstName", e.target.value)
                }}
            />

            <TextField
                variant="standard"
                disabled={true}
                label={intl.formatMessage({id: "ACCOUNT_USER_EMAIL"})}
                helperText={intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_EDIT_HELP'})}
                value={innerUser.email}
            />

            <TextField
                variant="standard"
                disabled={true}
                label={intl.formatMessage({id: "ACCOUNT_USER_MOBILE"})}
                helperText={intl.formatMessage({id: 'ACCOUNT_USER_MOBILE_EDIT_HELP'})}
                value={innerUser.mobile}
            />
            {
                editFlag ?
                    <Stack direction={'row'} spacing={2}>
                        <Button variant="contained" onClick={handleSave}>
                            <FormattedMessage id={'COMMON_SAVE'}/>
                        </Button>
                        <Button variant="outlined" onClick={handleCancelEdit}>
                            <FormattedMessage id={'COMMON_CANCEL'}/>
                        </Button>
                    </Stack> :
                    <Button variant="outlined" onClick={() => setEditFlag(true)}>
                        <FormattedMessage id={'COMMON_EDIT'}/>
                    </Button>
            }
        </Stack>
    );
};

export default UserForm;
