import React, {useEffect, useState} from 'react';
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {FormattedMessage, useIntl} from "react-intl";
import {RoleDto} from "../../../../../modules/account/dto/RoleDto";
import {RoleApi} from "../../../../../modules/account/api/RoleApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {MATRIX_APP_ID} from "../../../../../constants/Profile";
import {UserApi} from "../../../../../modules/account/api/UserApi";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormHelperText,
    FormLabel, IconButton, Input, InputAdornment, InputLabel, Switch,
    TextField
} from "@mui/material";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {ValidateUtils} from "../../../../../utils/ValidateUtils";
import {UserStatus} from "../../../../../modules/account/enums/UserStatus";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export type DetailDialogProps = {
    open: boolean;
    user: UserDto;
    onClose: () => void;
    onOk: () => void;
};

const DetailDialog: React.FC<DetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.user.id ? 'ACCOUNT_USER_MODIFY' : 'ACCOUNT_USER_ADD';

    const [innerUser, setInnerUser] = useState<UserDto>(props.user);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        setInnerUser({...props.user});
    }, [props.user]);

    const handleChange = (name: string, value: any) => {
        // setState是异步，多次设置要采用
        // https://legacy.reactjs.org/docs/hooks-reference.html#functional-updates
        setInnerUser(previousInnerUser => ({...previousInnerUser, [name]: value}));
        if (name === 'supervisor') {
            setInnerUser(previousInnerUser => ({...previousInnerUser, 'supervisorId': value.id}));
        }
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.user.id) {
                console.log(innerUser);
                const responseResult = await UserApi.updateUser(innerUser);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await UserApi.createUser(innerUser);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_ADD_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        setMessageMap(new Map<string, string>());
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();

        if (innerUser.uid === '') {
            newMessageMap.set('uid', intl.formatMessage({id: 'ACCOUNT_USER_UID_REQUIRED'}));
        }
        if (innerUser.id === '') {
            // 创建用户的时候需要验证密码必填项
            if (innerUser.password === '') {
                newMessageMap.set('password', intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_REQUIRED'}));
            } else if (innerUser.password.length < 8 || !ValidateUtils.isPasswordStrength(innerUser.password)) {
                newMessageMap.set("password", intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_STRENGTH_REQUIRED"}));
            }
        } else if (innerUser.id !== '' &&innerUser.password &&innerUser.password !== '') {
            // 修改用户的时候，填写了密码需要验证密码必填项
            if (innerUser.password.length < 8 || !ValidateUtils.isPasswordStrength(innerUser.password)) {
                newMessageMap.set("password", intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_STRENGTH_REQUIRED"}));
            }
        }

        if (innerUser.lastName === '') {
            newMessageMap.set('lastName', intl.formatMessage({id: 'ACCOUNT_USER_LAST_NAME_REQUIRED'}));
        }
        if (innerUser.firstName === undefined || innerUser.firstName === '') {
            newMessageMap.set('firstName', intl.formatMessage({id: 'ACCOUNT_USER_FIRST_NAME_REQUIRED'}));
        }
        if (innerUser.email === '') {
            newMessageMap.set('email', intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_REQUIRED'}));
        } else if (!ValidateUtils.isEmail(innerUser.email)) {
            newMessageMap.set('email', intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_FORMAT_WRONG'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel}>
            <DialogTitle>{intl.formatMessage({id: title})}</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard" margin={'normal'} fullWidth
                    label={intl.formatMessage({id: "ACCOUNT_USER_UID"})}
                    required={true}
                    error={messageMap.has("uid")}
                    helperText={messageMap.get("uid")}
                    value={innerUser.uid}
                    onChange={(e) => {
                        handleChange("uid", e.target.value)
                    }}
                />
                <FormControl required={true} variant="standard" fullWidth margin="normal">
                    <InputLabel>
                        {
                            innerUser.id === '' ?
                                <FormattedMessage id={'ACCOUNT_USER_PASSWORD'}/> :
                                <FormattedMessage id={'ACCOUNT_USER_PASSWORD_RESET'}/>
                        }
                    </InputLabel>
                    <Input
                        placeholder={intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_REQUIRED"})}
                        type={showPassword ? 'text' : 'password'}
                        error={messageMap.has("password")}
                        value={innerUser.password}
                        onChange={(e) => {
                            handleChange("password", e.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {messageMap.has("password") ? <FormHelperText
                        error={messageMap.has("password")}>{messageMap.get("password")}</FormHelperText> : null}
                </FormControl>
                <TextField
                    variant="standard" margin={'normal'} fullWidth
                    label={intl.formatMessage({id: "ACCOUNT_USER_LAST_NAME"})}
                    required={true}
                    error={messageMap.has("lastName")}
                    helperText={messageMap.get("lastName")}
                    value={innerUser.lastName}
                    onChange={(e) => {
                        handleChange("lastName", e.target.value)
                    }}
                />

                <TextField
                    variant="standard" margin={'normal'} fullWidth
                    label={intl.formatMessage({id: "ACCOUNT_USER_FIRST_NAME"})}
                    required={true}
                    error={messageMap.has("firstName")}
                    helperText={messageMap.get("firstName")}
                    value={innerUser.firstName}
                    onChange={(e) => {
                        handleChange("firstName", e.target.value)
                    }}
                />

                <TextField
                    variant="standard" margin={'normal'} fullWidth
                    required={true}
                    error={messageMap.has("email")}
                    helperText={messageMap.get("email")}
                    label={intl.formatMessage({id: "ACCOUNT_USER_EMAIL"})}
                    value={innerUser.email}
                    onChange={(e) => {
                        handleChange("email", e.target.value)
                    }}
                />

                <TextField
                    variant="standard" margin={'normal'} fullWidth
                    label={intl.formatMessage({id: "ACCOUNT_USER_MOBILE"})}
                    value={innerUser.mobile}
                    onChange={(e) => {
                        handleChange("mobile", e.target.value)
                    }}
                />
                <FormControl variant="standard" margin={'normal'}>
                    <FormLabel>
                        <FormattedMessage id={'ACCOUNT_USER_STATUS'}/>
                    </FormLabel>
                    <FormControlLabel
                        control={
                            <Switch checked={(innerUser.status === UserStatus.Enum.Active)}
                                    onChange={(e) => {
                                        handleChange('status', e.target.checked ? UserStatus.Enum.Active : UserStatus.Enum.Inactive)
                                    }}/>
                        }
                        label={innerUser.status === UserStatus.Enum.Active ?
                            <FormattedMessage id={'ACCOUNT_USER_STATUS_ACTIVE'}/> :
                            <FormattedMessage id={'ACCOUNT_USER_STATUS_INACTIVE'}/>}/>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel} color="secondary">
                    <FormattedMessage id={'COMMON_CANCEL'}/>
                </Button>
                <Button variant="outlined" onClick={handleOk} color="primary">
                    <FormattedMessage id={'COMMON_OK'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DetailDialog;
