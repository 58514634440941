import React from 'react';
import {PaginationDto} from '../modules/common/dto/PaginationDto';
import {FormattedMessage} from "react-intl";
import {Pagination} from "@mui/material";

export type PaginationBarProps = {
    pagination: PaginationDto;
    onChange: (current: number, pageSize: number) => void;
};

const PaginationBar: React.FC<PaginationBarProps> = (props) => {
    return (
        <Pagination color="primary"
            showFirstButton showLastButton
            count={props.pagination.pageSum}
            // rowsPerPage={props.pagination.pageSize}
            // defaultCurrent={props.pagination.currentPage}
            page={props.pagination.currentPage}
            onChange={(e, page) => props.onChange(page, props.pagination.pageSize)}
            // total={props.pagination.recordSum}
            // labelRowsPerPage={<FormattedMessage id={'COMMON_PAGINATION_PAGE_SIZE'}/>}
            // pageSizeOptions={['10', '20', '30', '50', '100', '200']}
            //              showSizeChanger={true}
            // onRowsPerPageChange={(e) => props.onChange(props.pagination.currentPage, Number(e.target.value))}
        />
    );
};
export default PaginationBar;