export const CardClassifyType = {
    List: [
        {label: "VMS_CARD_CLASSIFY_PERSON", value: "00"},
        {label: "VMS_CARD_CLASSIFY_VEHICLE", value: "01"}
    ],
    Map: new Map<string, string>([
        ['00', 'VMS_CARD_CLASSIFY_PERSON'],
        ['01', 'VMS_CARD_CLASSIFY_VEHICLE'],
    ]),
    Enum: {
        person: "00",
        vehicle: '01',
    }
};

