import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {SiteDto} from "../../../../../modules/vms/dto/SiteDto";
import {SiteApi} from "../../../../../modules/vms/api/SiteApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormFieldText from "../../../../../components/FormFieldText";
import SaveButton from "../../../../../components/buttons/SaveButton";
import CancelButton from "../../../../../components/buttons/CancelButton";

export type SiteDetailDialogProps = {
    open: boolean;
    site: SiteDto;
    onClose: () => void;
    onOk: () => void;
};

const SiteDetailDialog: React.FC<SiteDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.site.id ? 'VMS_SITE_MODIFY' : 'VMS_SITE_CREATE';

    const [innerSite, setInnerSite] = useState<SiteDto>(props.site);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerSite({...props.site});
    }, [props.site]);

    const handleChange = (name: string, value: any) => {
        setInnerSite(previousSite => ({...previousSite, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.site.id) {
                const responseResult = await SiteApi.updateSite(innerSite);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_SITE_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await SiteApi.createSite(innerSite);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_SITE_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerSite.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'VMS_SITE_CODE_REQUIRED'}));
        }
        if (innerSite.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'VMS_SITE_NAME_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>{intl.formatMessage({id: title})}</DialogTitle>
            <DialogContent>
                <FormFieldText label={intl.formatMessage({id: "VMS_SITE_CODE"})} value={innerSite.code} required={true}
                               errorText={messageMap.get("code")}
                               onChange={(value) => handleChange('code', value)}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_SITE_NAME"})} value={innerSite.name} required={true}
                               errorText={messageMap.get("name")}
                               onChange={(value) => handleChange('name', value)}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_SITE_SEQUENCE"})} value={innerSite.sequence}
                               type={'number'}
                               onChange={(value) => handleChange('sequence', value)}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default SiteDetailDialog;
