import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import PreviousNextButton from "../../../../../components/PreviousNextButton";
import {useIntl} from "react-intl";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import FormFieldText from "../../../../../components/FormFieldText";
import dayjs from "dayjs";
import DictItemUtils from "../../../../../modules/dict/utils/DictItemUtils";
import {Formats} from "../../../../../constants/Formats";
import {DictItemCode} from "../../../../../modules/vms/constants/DictItemCode";

export type RegisterConfirmFormProps = {
    onPrevious: () => void;
    onNext: () => void;
    requestVisitor: RequestVisitorDto;
    visitRequest: VisitRequestDto;
    inviteProperties: Array<DictItemDto>;
    purposeTypes: Array<DictItemDto>;
    idTypes: Array<DictItemDto>;
    visitorTypes: Array<DictItemDto>;
    employeeEntityTypes: Array<DictItemDto>;
    onSubmit: () => void;
};
const RegisterConfirmForm: React.FC<RegisterConfirmFormProps> = (props) => {
    const intl = useIntl();

    // 访客ID字段的可见性
    const [idNumberVisible, setIdNumberVisible] = useState(false);

    useEffect(() => {
        for (let item of props.inviteProperties) {
            switch (item.code) {
                case DictItemCode.VMS_INVITE_PROPERTIES_VISITOR_ID_FIELD_VISIBLE:
                    if (item.value === '1') {
                        setIdNumberVisible(true);
                    } else {
                        setIdNumberVisible(false);
                    }
                    break;
            }
        }
    }, [props.inviteProperties]);

    const getSelectAreasStr = () => {
        const selectedPlaceStr = props.visitRequest.places.map((place) => {
            return place.areaName
        }).join(',')

        return selectedPlaceStr;
    }
    const handleSubmit = async () => {
        props.onSubmit();
    }

    const renderIdNumberType = () => {
        if (idNumberVisible) {
            return (
                <FormFieldText
                    label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_TYPE"})}
                    disabled={true}
                    value={DictItemUtils.getText(props.idTypes, props.requestVisitor.idType)}
                />
            )
        }
    }

    const renderIdNumber = () => {
        if (idNumberVisible) {
            return (
                <FormFieldText
                    label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ID_NUMBER"})}
                    disabled={true}
                />
            )
        }
    }

    return (
        <div>
            <Grid container spacing={2} style={{height: '60vh'}}>
                <Grid item xs={6}>
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_NAME"})}
                        disabled={true}
                        value={props.requestVisitor.lastName + ' ' + props.requestVisitor.firstName}
                    />
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_MOBILE"})}
                        disabled={true}
                        value={props.requestVisitor.mobile}
                    />
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_EMAIL"})}
                        disabled={true}
                        value={props.requestVisitor.email}
                    />
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_COMPANY"})}
                        disabled={true}
                        value={props.requestVisitor.company}
                    />
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_TYPE"})}
                        disabled={true}
                        value={DictItemUtils.getText(props.visitorTypes, props.requestVisitor.visitorType)}
                    />
                    <FormFieldText
                    label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PURPOSE"})}
                    disabled={true}
                    value={props.visitRequest.purpose}
                />
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_VISIT_REQUEST_ARRIVE_DATE"})}
                        value={dayjs(props.visitRequest.arriveStartDate, Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}
                        disabled={true}/>




                </Grid>
                <Grid item xs={6}>
                    {renderIdNumberType()}
                    {renderIdNumber()}
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PLACE"})}
                        value={getSelectAreasStr()}
                        disabled={true}/>


                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER"})}
                        disabled={true}
                        value={props.requestVisitor.accessCardNumber}
                    />

                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_EMAIL"})}
                        value={props.visitRequest.hostEmail}
                        disabled={true}/>
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_STAFF_NUMBER"})}
                        value={props.visitRequest.hostStaffNumber}
                        disabled={true}/>
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_NAME"})}
                        value={props.visitRequest.hostLastName + ' ' + props.visitRequest.hostFirstName}
                        disabled={true}/>
                    <FormFieldText
                        label={intl.formatMessage({id: "VMS_VISIT_REQUEST_EMPLOYEE_ENTITY"})}
                        value={DictItemUtils.getText(props.employeeEntityTypes, props.visitRequest.hostCompanyName)}
                        disabled={true}/>

                </Grid>
            </Grid>
            <Grid>
                {props.requestVisitor.id && props.requestVisitor.id !== "" ?
                    <PreviousNextButton onPrevious={props.onPrevious} onNext={handleSubmit}
                                        nextText={intl.formatMessage({id: "COMMON_SUBMIT"})}/>
                    :
                    <PreviousNextButton onPrevious={props.onPrevious} onNext={props.onNext}/>}
            </Grid>
        </div>
    );
};

export default RegisterConfirmForm;
