import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {HttpStatus} from "../../../../../../modules/common/enums/HttpStatus";
import {DeviceDto} from "../../../../../../modules/vms/dto/DeviceDto";
import {DeviceApi} from "../../../../../../modules/vms/api/DeviceApi";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {MessageUtils} from "../../../../../../utils/MessageUtils";

export type DeviceDetailDialogProps = {
    open: boolean;
    device: DeviceDto;
    onClose: () => void;
    onOk: () => void;
};

const DeviceDetailDialog: React.FC<DeviceDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.device.id ? 'COMMON_MODIFY' : 'COMMON_ADD';

    const [innerDevice, setInnerDevice] = useState<DeviceDto>(props.device);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerDevice({...props.device});
    }, [props.device]);

    const handleChange = (name: string, value: any) => {
        setInnerDevice(previousDevice => ({...previousDevice, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.device.id) {
                const responseResult = await DeviceApi.updateDevice(innerDevice);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await DeviceApi.createDevice(innerDevice);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_ADD_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerDevice.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'VMS_TERMINAL_CODE_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={intl.formatMessage({id: "VMS_TERMINAL_CODE"})}
                    margin="normal"
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    required={true}
                    error={messageMap.has("code")}
                    helperText={messageMap.get("code")}
                    value={innerDevice.code}
                    onChange={(e) => {
                        handleChange('code', e.target.value);
                    }}
                />
                <TextField
                    label={intl.formatMessage({id: "VMS_TERMINAL_NAME"})}
                    margin="normal"
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    required={true}
                    error={messageMap.has("name")}
                    helperText={messageMap.get("name")}
                    value={innerDevice.name}
                    onChange={(e) => {
                        handleChange('name', e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel} color="secondary">
                    <FormattedMessage id={'COMMON_CANCEL'}/>
                </Button>
                <Button variant="outlined" onClick={handleOk} color="primary">
                    <FormattedMessage id={'COMMON_OK'}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeviceDetailDialog;
