import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {FormattedMessage, useIntl} from "react-intl";
import RegisterForm from "./components/RegisterForm";
import {Button, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {UserDto} from "../../../../modules/account/dto/UserDto";
import {UserApi} from "../../../../modules/account/api/UserApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {CaptchaDto} from "../../../../modules/account/dto/CaptchaDto";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {ValidateUtils} from "../../../../utils/ValidateUtils";
import {Link} from 'react-router-dom';
import {RoutePath} from "../../../../constants/RoutePath";

export default function Index() {
    const intl = useIntl();

    const [user, setUser] = useState<UserDto>(new UserDto());
    const [captcha, setCaptcha] = useState<CaptchaDto>(new CaptchaDto());

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    const _handleChangeUser = (name: string, value: any) => {
        setUser({...user, [name]: value});
    };

    const _handleChangeCaptcha = (name: string, value: any) => {
        setCaptcha({...captcha, [name]: value});
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (user.uid === '') {
            newMessageMap.set('uid', intl.formatMessage({id: 'ACCOUNT_USER_UID_REQUIRED'}));
        }
        if (user.password === '') {
            newMessageMap.set('password', intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_REQUIRED'}));
        } else if (user.password.length < 8 || !ValidateUtils.isPasswordStrength(user.password)) {
            newMessageMap.set("password", intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_STRENGTH_REQUIRED"}));
        }

        if (user.email === '') {
            newMessageMap.set('email', intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_REQUIRED'}));
        }
        if (user.lastName === '') {
            newMessageMap.set('lastName', intl.formatMessage({id: 'ACCOUNT_USER_LAST_NAME_REQUIRED'}));
        }
        if (user.firstName === '') {
            newMessageMap.set('firstName', intl.formatMessage({id: 'ACCOUNT_USER_FIRST_NAME_REQUIRED'}));
        }
        if (captcha.captchaValue === '') {
            newMessageMap.set('captchaValue', intl.formatMessage({id: 'ACCOUNT_USER_CAPTCHA_REQUIRED'}));
        }
        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const _handleRegister = async () => {
        if (validate()) {
            const responseResult = await UserApi.register(user, captcha.captchaKey, captcha.captchaValue);
            if (responseResult.status === HttpStatus.SUCCESS) {
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_REGISTER_SUCCESS'}))
            }
        }
    }

    return (
        <Container>
            <Box display="flex"
                 flexDirection={"column"}
                 alignItems="center">
                <br/>
                <Typography variant="h4" gutterBottom>
                    <FormattedMessage id='ACCOUNT_USER_REGISTER'/>
                </Typography>
                <Box style={{
                    padding: '3rem',
                    backgroundColor: 'hsla(0,0%,100%,.8)',
                    borderRadius: '2rem',
                    boxShadow: '0 1rem 2rem 0 rgba(0,0,0,.1)',
                    width: '40vw'
                }}>
                    <RegisterForm user={user} captcha={captcha} messageMap={messageMap}
                                  onChangeUser={_handleChangeUser}
                                  onChangeCaptcha={_handleChangeCaptcha}/>
                    <br/>
                    <Stack direction="row" justifyContent="right" spacing={2}>
                        <FormattedMessage id={'ACCOUNT_USER_HAVE_ACCOUNT'}/>
                        <Link to={RoutePath.ACCOUNT_USER_LOGIN} target={'_blank'}>
                            <FormattedMessage id={'ACCOUNT_USER_LOGIN'}/>
                        </Link>
                    </Stack>
                    <br/>
                    <Stack spacing={2}>
                        <Button variant="contained" onClick={_handleRegister} fullWidth size={'large'}>
                            <FormattedMessage id={'ACCOUNT_USER_REGISTER'}/>
                        </Button>
                        <Typography sx={{color: '#585858'}} variant="caption" display="block" gutterBottom
                                    align={'center'}>
                            <FormattedMessage id={'ACCOUNT_USER_REGISTER_AGREE_POLICY'}/>
                            <a target={"_blank"} href={''}><FormattedMessage
                                id={'ACCOUNT_USER_PRIVACY_POLICY'}/></a>&nbsp;
                            <a target={"_blank"} href={''}><FormattedMessage id={'ACCOUNT_USER_AGREEMENT'}/></a>
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
}