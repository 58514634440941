import React from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {CardSearchCondition} from "../../../../../../modules/vms/dto/CardSearchCondition";
import {Button, Card, Stack, TextField} from "@mui/material";

export type SearchConditionProps = {
    searchCondition: CardSearchCondition;
    onChange: (name: string, value: any) => void;
    onSearch: () => void;
    onAdd: () => void;
};

const SearchCondition: React.FC<SearchConditionProps> = (props) => {
    const intl = useIntl();

    return (
        <Card>
            <TextField
                label={intl.formatMessage({id: "VMS_CARD_NUMBER"})}
                margin="normal"
                variant="standard"
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                required={true}
                value={props.searchCondition.number}
                onChange={(e) => {
                    props.onChange("number", e.target.value)
                }}
            />
            <Stack spacing={2}>
                <Button color="primary" onClick={() => props.onSearch()}>
                    <FormattedMessage id='COMMON_SEARCH'/>
                </Button>
                <Button onClick={() => props.onAdd()}><FormattedMessage id={'COMMON_ADD'}/></Button>
            </Stack>
        </Card>
    );
};

export default SearchCondition;
