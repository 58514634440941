export class DictItemDto {
    keyId: string = '';
    id: string = '';
    classId: string = '';
    code: string = '';
    name: string = '';
    value: string = '';
    sequence: number = 0;
    workflowFormId: string = '';
    remarks: string = '';
}
