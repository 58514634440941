export default {
  // 操作日志
  LOG_OPERATION: 'Operation Log',
  LOG_OPERATION_OPERATOR_NAME: 'Operator Name',
  LOG_OPERATION_OPERATOR_IP: 'Operator IP',
  LOG_OPERATION_OPERATION: 'Operation',
  LOG_OPERATION_OPERATION_TIME: 'Operation Time',
  LOG_OPERATION_TABLE_NAME: 'Table Name',
  LOG_OPERATION_TABLE_KEY: 'Table Key',
  LOG_OPERATION_REMARKS: 'Remarks',
  LOG_OPERATION_USER_AGENT: 'User Agent',
  LOG_OPERATION_START_TIME: 'Operation Start Time',
  LOG_OPERATION_END_TIME: 'Operation End Time',

  // 字段日志
  LOG_FIELD: 'Field Log',
  LOG_FIELD_NAME: 'Field Name',
  LOG_FIELD_SOURCE: 'Source',
  LOG_FIELD_TARGET: 'Target',
};
