export const CardStatusType = {
    List: [
        {label: "VMS_CARD_STATUS_AVAILABLE", value: "00"},
        {label: "VMS_CARD_STATUS_BINDING", value: "01"}
    ],
    Map: new Map<string, string>([
        ['00', 'VMS_CARD_STATUS_AVAILABLE'],
        ['01', 'VMS_CARD_STATUS_BINDING'],
    ]),
    Enum: {
        available: "00",
        binding: '01',
    }
};

