import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import VisitorTypeDetailDialog from "./VisitorTypeDetailDialog";
import {HttpStatus} from "../../../../../../modules/common/enums/HttpStatus";
import {VisitorTypeDto} from "../../../../../../modules/vms/dto/VisitorTypeDto";
import {VisitorTypeApi} from "../../../../../../modules/vms/api/VisitorTypeApi";
import {Card, CardHeader, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {MessageUtils} from "../../../../../../utils/MessageUtils";
import ModifyIconButton from "../../../../../../components/buttons/ModifyIconButton";
import DeleteIconButton from "../../../../../../components/buttons/DeleteIconButton";
import {useConfirm} from "material-ui-confirm";
import AddIconButton from "../../../../../../components/buttons/AddIconButton";
import RefreshIconButton from "../../../../../../components/buttons/RefreshIconButton";

export type VisitorTypeListProps = {
    onRefresh: () => void;
    visitorTypeList: Array<VisitorTypeDto>;
};

const VisitorTypeList: React.FC<VisitorTypeListProps> = (props) => {
    const intl = useIntl();

    const confirm = useConfirm();

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [currentRow, setCurrentRow] = useState<VisitorTypeDto>(new VisitorTypeDto());

    const handleAdd = () => {
        setCurrentRow(new VisitorTypeDto());
        setOpenDetailModal(true);
    };

    const handleModify = async (record: VisitorTypeDto) => {
        const responseResult = await VisitorTypeApi.getVisitorTypeDetail(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setCurrentRow(responseResult.data);
            setOpenDetailModal(true);
        }
    };

    const handleDelete = async (record: VisitorTypeDto) => {
        confirm({title: intl.formatMessage({id: 'COMMON_DELETE_CONFIRM'})})
            .then(async () => {
                const responseResult = await VisitorTypeApi.deleteVisitorType(record.id);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'COMMON_DELETE_SUCCESS'}));
                    props.onRefresh();
                }
            })
            .catch(() => {
                console.log('cancel');
            });
    };

    return (
        <Card>
            <VisitorTypeDetailDialog open={openDetailModal}
                                     onOk={() => props.onRefresh()}
                                     onClose={() => setOpenDetailModal(false)}
                                     visitorType={currentRow}/>
            <CardHeader title={intl.formatMessage({id: 'VMS_VISITOR_TYPE'})}
                        action={
                            <div>
                                <AddIconButton onClick={handleAdd}/>
                                <RefreshIconButton onClick={() => props.onRefresh()}/>
                            </div>
                        }/>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_TYPE_CODE'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISITOR_TYPE_NAME'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_VISITOR_TYPE_VALUE"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.visitorTypeList.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.value}</TableCell>
                            <TableCell>
                                <div>
                                    <ModifyIconButton onClick={() => handleModify(row)}/>
                                    <DeleteIconButton onClick={() => handleDelete(row)}/>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </Card>
    );
};

export default VisitorTypeList;
