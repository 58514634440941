export const ProfileDev = {
    // 网关服务的地址
    MATRIX_GATEWAY_SERVICE_HOST: "http://localhost:8080",

    // 登录页面
    MATRIX_LOGIN_PAGE_URL: "http://localhost:3000/account/user/login",

    // 连接 VMS Web Socket 服务的默认地址
    VMS_WEB_SOCKET_HOST: "ws://192.168.31.131:8090",

    // 连接护照扫描仪 Web Socket 服务的默认地址
    VMS_WEB_SOCKET_HOST_PASSPORT: "ws://127.0.0.1:90",
}