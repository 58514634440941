import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";

import {Formats} from "../../../../../constants/Formats";
import {RequestVisitorDto} from "../../../../../modules/vms/dto/RequestVisitorDto";
import {StatusType} from "../../../../../modules/vms/enums/StatusType";
import dayjs from "dayjs";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import RequestVisitorDetailDialog from "../../../components/RequestVisitorDetailDialog";
import {VisitRequestApi} from "../../../../../modules/vms/api/VisitRequestApi";
import {RequestVisitorApi} from "../../../../../modules/vms/api/RequestVisitorApi";
import VisitRequestDetailDialog from "../../../components/VisitRequestDetailDialog";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export type SearchResultProps = {
    searchResult: Array<RequestVisitorDto>;
};

const SearchResult: React.FC<SearchResultProps> = (props) => {
    const intl = useIntl();

    const [openRequestDetailModal, setOpenRequestDetailModal] = useState(false);
    const [openVisitorDetailModal, setOpenVisitorDetailModal] = useState(false);

    const [requestVisitor, setRequestVisitor] = useState(new RequestVisitorDto());

    const [visitRequest, setVisitRequest] = useState(new VisitRequestDto());

    const handleVisitDetail = async (record: RequestVisitorDto) => {
        const responseResult = await VisitRequestApi.getVisitRequestDetailById(record.requestId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setVisitRequest(responseResult.data);
            setOpenRequestDetailModal(true);
        }
    };

    const handleVisitorDetail = async (record: RequestVisitorDto) => {
        const responseResult = await RequestVisitorApi.getVisitorDetailById(record.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setRequestVisitor(responseResult.data);
            setOpenVisitorDetailModal(true);
        }
    };

    return (
        <div>
            <VisitRequestDetailDialog open={openRequestDetailModal}
                                      onClose={() => setOpenRequestDetailModal(false)}
                                      visitRequest={visitRequest}
                                      readonly={true}/>
            <RequestVisitorDetailDialog open={openVisitorDetailModal}
                                        onClose={() => setOpenVisitorDetailModal(false)}
                                        requestVisitor={requestVisitor}/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISIT_REQUEST_NUMBER'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'VMS_VISIT_REQUEST_ARRIVE_START_DATE'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_VISIT_REQUEST_ARRIVE_END_DATE"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_REQUEST_VISITOR_NAME"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: "VMS_VISIT_REQUEST_STATUS"})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'COMMON_ACTION'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.searchResult.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell><a href={'javascript:void(0)'} onClick={() => handleVisitDetail(row)}>{row.visitRequest?.number}</a></TableCell>
                            <TableCell>{dayjs(row.visitRequest?.arriveStartDate, Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}</TableCell>
                            <TableCell>{dayjs(row.visitRequest?.arriveEndDate, Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}</TableCell>
                            <TableCell>{row.fullName}</TableCell>
                            <TableCell><FormattedMessage id={StatusType.Map.get(row.visitRequest?.status as string)}/></TableCell>
                            <TableCell>
                                <a href={'javascript:void(0)'}  onClick={() => handleVisitorDetail(row)}>
                                    <FormattedMessage id={'COMMON_DETAIL'}/>
                                </a>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default SearchResult;