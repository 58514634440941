export const StatusType = {
    List: [
        {label: "VMS_VISIT_REQUEST_STATUS_APPROVING", value: "01"},
        {label: "VMS_VISIT_REQUEST_STATUS_APPROVED", value: "02"},
        {label: "VMS_VISIT_REQUEST_STATUS_REJECTED", value: "03"},
        {label: "VMS_VISIT_REQUEST_STATUS_EXPIRED", value: "04"},
        {label: "VMS_VISIT_REQUEST_STATUS_COMPLETED", value: "05"},
        {label: "VMS_VISIT_REQUEST_STATUS_CANCELED", value: "06"},
    ],
    Map: new Map<string, string>([
        ['01', 'VMS_VISIT_REQUEST_STATUS_APPROVING'],
        ['02', 'VMS_VISIT_REQUEST_STATUS_APPROVED'],
        ['03', 'VMS_VISIT_REQUEST_STATUS_REJECTED'],
        ['04', 'VMS_VISIT_REQUEST_STATUS_EXPIRED'],
        ['05', 'VMS_VISIT_REQUEST_STATUS_COMPLETED'],
        ['06', 'VMS_VISIT_REQUEST_STATUS_CANCELED'],
    ]),
    Enum: {
        approving: '01',
        approved: "02",
        reject: '03',
        expired: "04",
        done: '05',
        cancel: "06",
    }
};