import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {VisitorBlackListSearchCondition} from "../dto/VisitorBlackListSearchCondition";
import {VisitorBlackListDto} from "../dto/VisitorBlackListDto";

export const VisitorBlackListApi = {
    /**
     * 获得访客黑名单列表
     */
    queryVisitorBlacks: async (body: VisitorBlackListSearchCondition) => {
        try {
            const requestUrl = '/v1/vms/visitor/blacklist/query';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getVisitorBlackDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/visitor/blacklist/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    checkVisitorBlackListByName: async (lastName: string, firstName: string) => {
        try {
            const params = new Map<string, string>();
            params.set('lastName', lastName);
            params.set('firstName', firstName);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/visitor/blacklist/checkByName', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createVisitorBlack: async (body: VisitorBlackListDto) => {
        try {
            const requestUrl = '/v1/vms/visitor/blacklist/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateVisitorBlack: async (body: VisitorBlackListDto) => {
        try {
            const requestUrl = '/v1/vms/visitor/blacklist/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteVisitorBlack: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/visitor/blacklist/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
