import React, {useEffect, useState} from 'react';
import {
    Button, FormControl, FormHelperText,
    IconButton,
    InputAdornment, OutlinedInput, Stack,
    TextField
} from "@mui/material";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {useIntl, FormattedMessage} from "react-intl";
import {UserDto} from "../../../../../modules/account/dto/UserDto";
import {AccountCircle, Visibility, VisibilityOff} from "@mui/icons-material";
import {CaptchaDto} from "../../../../../modules/account/dto/CaptchaDto";
import LockIcon from '@mui/icons-material/Lock';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmailIcon from '@mui/icons-material/Email';
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {ValidateUtils} from "../../../../../utils/ValidateUtils";
import {TaskApi} from "../../../../../modules/mail/api/TaskApi";
import BadgeIcon from '@mui/icons-material/Badge';

export type RegisterFormProps = {
    user: UserDto;
    captcha: CaptchaDto,
    messageMap: Map<string, string>;
    onChangeUser: (name: string, value: any) => void;
    onChangeCaptcha: (name: string, value: any) => void;
};

// 发送验证码的倒计时
let sendCaptchaInterval: NodeJS.Timeout;

const RegisterForm: React.FC<RegisterFormProps> = (props) => {
    const intl = useIntl();

    const [showPassword, setShowPassword] = React.useState(false);

    const [seconds, setSeconds] = useState(60);

    const [canSendCaptcha, setCanSendCaptcha] = useState(true);

    useEffect(() => {
        return () => {
            if (sendCaptchaInterval) {
                clearInterval(sendCaptchaInterval);
            }
        }
    }, []);

    useEffect(() => {
        if (seconds <= 0) {
            clearInterval(sendCaptchaInterval);
            setCanSendCaptcha(true);
            setSeconds(60);
        }
    }, [seconds])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const _handleSendCaptchaCode = async () => {
        if (props.user.email === '') {
            MessageUtils.error(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_REQUIRED'}));
        } else if (!ValidateUtils.isEmail(props.user.email)) {
            MessageUtils.error(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_FORMAT_WRONG'}));
        } else {
            setCanSendCaptcha(false);
            sendCaptchaInterval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            const responseResult = await TaskApi.sendCaptchaCode(props.user.email, 'CAPTCHA_CODE', new Map<string, string>());
            if (responseResult.status === HttpStatus.SUCCESS) {
                MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_USER_CAPTCHA_SEND_SUCCESS'}));
            }
        }
    }

    return (
        <Stack spacing={2}>
            <TextField
                placeholder={intl.formatMessage({id: "ACCOUNT_USER_UID_REQUIRED"})}
                fullWidth
                required={true}
                error={props.messageMap.has("uid")}
                value={props.user.uid}
                onChange={(e) => {
                    props.onChangeUser("uid", e.target.value)
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <AccountCircle/>
                        </InputAdornment>
                    ),
                }}
            />
            <FormControl fullWidth
                         required={true} variant="outlined">
                <OutlinedInput
                    placeholder={intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_REQUIRED"})}
                    type={showPassword ? 'text' : 'password'}
                    error={props.messageMap.has("password")}
                    value={props.user.password}
                    onChange={(e) => {
                        props.onChangeUser("password", e.target.value)
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <LockIcon/>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText>{intl.formatMessage({id: "ACCOUNT_USER_PASSWORD_STRENGTH_REQUIRED"})}</FormHelperText>
            </FormControl>

            <TextField
                placeholder={intl.formatMessage({id: "ACCOUNT_USER_EMAIL_REQUIRED"})}
                fullWidth
                required={true}
                error={props.messageMap.has("email")}
                value={props.user.email}
                onChange={(e) => {
                    props.onChangeUser("email", e.target.value);
                    props.onChangeCaptcha("captchaKey", e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon/>
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                placeholder={intl.formatMessage({id: "ACCOUNT_USER_CAPTCHA_REQUIRED"})}
                fullWidth
                required={true}
                error={props.messageMap.has("captchaValue")}
                value={props.captcha.captchaValue}
                onChange={(e) => {
                    props.onChangeCaptcha("captchaValue", e.target.value)
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <VerifiedUserIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                canSendCaptcha ? <Button onClick={_handleSendCaptchaCode}>
                                    <FormattedMessage id={'ACCOUNT_USER_CAPTCHA_RECEIVE'}/>
                                </Button> : <span>{seconds}</span>
                            }

                        </InputAdornment>
                    )
                }}
            />

            <Stack direction={'row'} spacing={2}>

                <TextField
                    placeholder={intl.formatMessage({id: "ACCOUNT_USER_LAST_NAME_REQUIRED"})}
                    fullWidth
                    required={true}
                    error={props.messageMap.has("lastName")}
                    value={props.user.lastName}
                    onChange={(e) => {
                        props.onChangeUser("lastName", e.target.value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BadgeIcon/>
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    placeholder={intl.formatMessage({id: "ACCOUNT_USER_FIRST_NAME_REQUIRED"})}
                    fullWidth
                    required={true}
                    error={props.messageMap.has("firstName")}
                    value={props.user.firstName}
                    onChange={(e) => {
                        props.onChangeUser("firstName", e.target.value)
                    }}
                />
            </Stack>

        </Stack>
    );
};

export default RegisterForm;
