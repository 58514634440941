import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {FormattedMessage, useIntl} from "react-intl";
import LoginUIDForm from "./components/LoginUIDForm";
import {Button, Stack, Tab, Tabs, Typography} from "@mui/material";
import {AuthType} from "../../../../modules/account/enums/AuthType";
import {useEffect, useState} from "react";
import {UserDto} from "../../../../modules/account/dto/UserDto";
import {UserApi} from "../../../../modules/account/api/UserApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {AccountCacheKey} from "../../../../modules/account/constants/AccountCacheKey";
import LoginEmailForm from "./components/LoginEmailForm";
import {CaptchaDto} from "../../../../modules/account/dto/CaptchaDto";
import {ValidateUtils} from "../../../../utils/ValidateUtils";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {Link, useNavigate} from "react-router-dom";
import {RoutePath} from "../../../../constants/RoutePath";
import {HttpUtils} from "../../../../utils/HttpUtils";

import {INIT_ENV_TYPE} from "../../../../constants/Profile";

export default function Index() {
    const intl = useIntl();

    const navigate = useNavigate();

    const [authType, setAuthType] = React.useState(AuthType.Enum.UID);

    // 用户登录的对象
    const [user, setUser] = useState<UserDto>(new UserDto());
    const [captcha, setCaptcha] = useState<CaptchaDto>(new CaptchaDto());

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        const authCode = HttpUtils.getQueryString(window.location.href, "authCode");
        if (authCode) {
            handleLoginByOIDC(authCode);
        }
    }, []);

    const handleChangeTab = (value: string) => {
        console.log(value);
        setAuthType(value);
    }

    const handleChangeUser = (name: string, value: any) => {
        setUser({...user, [name]: value});
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (authType === AuthType.Enum.UID) {
            if (user.uid === '') {
                newMessageMap.set('uid', intl.formatMessage({id: 'ACCOUNT_USER_UID_REQUIRED'}));
            }
            if (user.password === '') {
                newMessageMap.set('password', intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_REQUIRED'}));
            }
        } else if (authType === AuthType.Enum.Email) {
            if (user.email === '') {
                newMessageMap.set('email', intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_REQUIRED'}));
            } else if (!ValidateUtils.isEmail(user.email)) {
                MessageUtils.error(intl.formatMessage({id: 'ACCOUNT_USER_EMAIL_FORMAT_WRONG'}));
            }
            if (captcha.captchaValue === '') {
                newMessageMap.set('captchaValue', intl.formatMessage({id: 'ACCOUNT_USER_CAPTCHA_REQUIRED'}));
            }
        }
        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const handleLogin = async () => {
        if (validate()) {
            // 登录
            const responseResult = await UserApi.login(user, authType, captcha.captchaValue);
            if (responseResult.status === HttpStatus.SUCCESS) {
                // 登录成功的令牌存入缓存对象
                localStorage.setItem(AccountCacheKey.ACCESS_TOKEN, responseResult.data.accessToken);
                // 登录成功重定向到首页
                navigate("/");
            }
        }
        // else {
        //     const message = intl.formatMessage({id: 'ACCOUNT_USER_PASSWORD_WRONG'});
        //     enqueueSnackbar(message, {variant: 'error'});
        // }
    }

    const handleLoginByOIDC = async (authCode: string) => {
        // 登录
        const responseResult = await UserApi.login(user, AuthType.Enum.OIDC, authCode);
        if (responseResult.status === HttpStatus.SUCCESS) {
            // 登录成功的令牌存入缓存对象
            localStorage.setItem(AccountCacheKey.ACCESS_TOKEN, responseResult.data.accessToken);
            // 登录成功重定向到首页
            navigate("/");
        }
    }

    const handleChangeCaptcha = (name: string, value: any) => {
        setCaptcha({...captcha, [name]: value});
    };

    let tabPanel = null;
    switch (authType) {
        case AuthType.Enum.UID:
            tabPanel = <LoginUIDForm user={user} messageMap={messageMap} onChange={handleChangeUser}/>;
            break;
        case AuthType.Enum.Email:
            tabPanel =
                <LoginEmailForm user={user} captcha={captcha} messageMap={messageMap} onChangeUser={handleChangeUser}
                                onChangeCaptcha={handleChangeCaptcha}/>;
            break;
    }

    if (INIT_ENV_TYPE === 'dev') {
        return (
            <Container>
                <Box display="flex"
                     flexDirection={"column"}
                     alignItems="center">
                    <br/>
                    <div>
                        <Tabs centered value={authType} onChange={(e, value) => handleChangeTab(value)}
                              aria-label="basic tabs example">
                            <Tab label={intl.formatMessage({id: 'ACCOUNT_USER_AUTH_TYPE_UID'})}
                                 value={AuthType.Enum.UID}/>
                            <Tab label={intl.formatMessage({id: 'ACCOUNT_USER_AUTH_TYPE_EMAIL'})}
                                 value={AuthType.Enum.Email}/>
                        </Tabs>
                    </div>
                    <br/>
                    {/*<Typography variant="h4" gutterBottom>*/}
                    {/*    <FormattedMessage id='ACCOUNT_USER_LOGIN'/>*/}
                    {/*</Typography>*/}
                    <Box style={{
                        padding: '3rem',
                        backgroundColor: 'hsla(0,0%,100%,.8)',
                        borderRadius: '2rem',
                        boxShadow: '0 1rem 2rem 0 rgba(0,0,0,.1)',
                        width: '40vw'
                    }}>
                        {/*<LoginUIDForm user={user} messageMap={messageMap} onChange={_handleChangeUser}/>*/}
                        {tabPanel}
                        <br/>
                        <Stack direction="row" justifyContent="right"
                               spacing={2}>
                            <Link to={RoutePath.ACCOUNT_USER_REGISTER} target={'_blank'}>
                                <FormattedMessage id={'ACCOUNT_USER_REGISTER'}/>
                            </Link>
                            {/*<Link href={'#'} onClick={() => setAuthType(AuthType.Enum.Email)}>*/}
                            {/*    <FormattedMessage id={'ACCOUNT_USER_FORGOT_PASSWORD'}/>*/}
                            {/*</Link>*/}
                        </Stack>
                        <br/>
                        <Stack spacing={2}>
                            <Button variant="contained" onClick={handleLogin} fullWidth size={'large'}>
                                <FormattedMessage id={'ACCOUNT_USER_LOGIN'}/>
                            </Button>
                            <Typography sx={{color: '#585858'}} variant="caption" display="block" gutterBottom
                                        align={'center'}>
                                <FormattedMessage id={'ACCOUNT_USER_LOGIN_AGREE_POLICY'}/>
                                <a target={"_blank"} href={''}><FormattedMessage
                                    id={'ACCOUNT_USER_PRIVACY_POLICY'}/></a>&nbsp;
                                <a target={"_blank"} href={''}><FormattedMessage id={'ACCOUNT_USER_AGREEMENT'}/></a>
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
            </Container>
        );
    } else {
        return (
            <Container></Container>
        );
    }
}