import dayjs from 'dayjs';
import {PaginationDto} from "../../common/dto/PaginationDto";
import {Formats} from "../../../constants/Formats";

export class RequestVisitorSearchCondition extends PaginationDto {
    requestNumber: string = '';
    arriveDateFrom: string = dayjs().format(Formats.DATE_SIMPLE);
    arriveDateTo: string = dayjs().format(Formats.DATE_SIMPLE);
    visitorName: string = '';
    visitorMobile: string = '';
    visitorEmail: string = '';
    visitorIdNumber: string = '';
    visitorCompany: string = '';
    hostName: string = '';
    hostMobile: string = '';
    hostEmail: string = '';
    statusList: Array<string> = [];
    status: string = '';
    hostStaffNumber: string = '';
}
