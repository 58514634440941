import React, {useEffect, useState} from 'react';
import {DictClassDto} from "../../../../modules/dict/dto/DictClassDto";
import {useIntl} from "react-intl";
import {DictClassApi} from "../../../../modules/dict/api/DictClassApi";
import {MessageUtils} from "../../../../utils/MessageUtils";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormFieldSelect from "../../../../components/FormFieldSelect";
import FormFieldText from "../../../../components/FormFieldText";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import {DictTypeDto} from "../../../../modules/dict/dto/DictTypeDto";

export type DictClassDetailModalProps = {
    open: boolean;
    typeList: Array<DictTypeDto>;
    dictClass: DictClassDto;
    onClose: () => void;
    onOk: () => void;
};

const DictClassDetailModal: React.FC<DictClassDetailModalProps> = (props) => {
    const intl = useIntl();

    const title = props.dictClass.id ? 'DICT_CLASS_MODIFY' : 'DICT_CLASS_CREATE';
    const [innerDictClass, setInnerDictClass] = useState<DictClassDto>(props.dictClass);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerDictClass({...props.dictClass});
    }, [props.dictClass]);

    const handleChange = (name: string, value: any) => {
        setInnerDictClass(previousDictClass => ({...previousDictClass, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            innerDictClass.commonFlag = 1;
            if (props.dictClass.id) {
                const responseResult = await DictClassApi.updateDictClass(innerDictClass);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'DICT_CLASS_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await DictClassApi.createDictClass(innerDictClass);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'DICT_CLASS_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerDictClass.typeId === '') {
            newMessageMap.set('typeId', intl.formatMessage({id: 'DICT_CLASS_TYPE_REQUIRED'}));
        }
        if (innerDictClass.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'DICT_CLASS_CODE_REQUIRED'}));
        }
        if (innerDictClass.name === '') {
            newMessageMap.set('name', intl.formatMessage({id: 'DICT_CLASS_NAME_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldSelect label={intl.formatMessage({id: "DICT_CLASS_TYPE"})}
                                 required={true} errorText={messageMap.get("typeId")}
                                 value={innerDictClass.typeId}
                                 onChange={(value) => {
                                     handleChange("typeId", value)
                                 }}
                                 items={props.typeList.map((item) => {
                                     return {label: item.name, value: item.id}
                                 })}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_CLASS_CODE"})} value={innerDictClass.code}
                               required={true}
                               errorText={messageMap.get("code")}
                               onChange={(value) => handleChange('code', value)}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_CLASS_NAME"})} value={innerDictClass.name}
                               required={true}
                               errorText={messageMap.get("name")}
                               onChange={(value) => handleChange('name', value)}/>
                <FormFieldText label={intl.formatMessage({id: "DICT_CLASS_SEQUENCE"})} value={innerDictClass.sequence}
                               type={'number'}
                               onChange={(value) => handleChange('sequence', value)}/>
                <FormFieldText label={intl.formatMessage({id: "COMMON_REMARKS"})} value={innerDictClass.remarks}
                               onChange={(value) => handleChange('remarks', value)}
                               maxRows={4}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default DictClassDetailModal;
