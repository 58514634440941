import * as React from 'react';
import UserForm from "./components/UserForm";
import {useEffect, useState} from "react";
import {UserDto} from "../../../../modules/account/dto/UserDto";
import {UserApi} from "../../../../modules/account/api/UserApi";
import {HttpStatus} from "../../../../modules/common/enums/HttpStatus";

export default function Index() {

    const [sessionUser, setSessionUser] = useState<UserDto>(new UserDto());

    useEffect(() => {
        fetchSessionUser();
    }, [])


    const fetchSessionUser = async () => {
        const responseResult = await UserApi.getSessionUser();
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setSessionUser(responseResult.data);
        }
    };

    return (
        <UserForm user={sessionUser}/>
    );
}