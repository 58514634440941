import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {DictTypeDto} from "../../../modules/dict/dto/DictTypeDto";
import {DictClassDto} from "../../../modules/dict/dto/DictClassDto";
import DictTypeList from "./components/DictTypeList";
import DictClassList from "./components/DictClassList";
import DictItemList from "./components/DictItemList";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";
import {DictTypeApi} from "../../../modules/dict/api/DictTypeApi";
import {HttpStatus} from "../../../modules/common/enums/HttpStatus";
import {DictClassApi} from "../../../modules/dict/api/DictClassApi";
import {DictItemApi} from "../../../modules/dict/api/DictItemApi";

const Index: React.FC = () => {

    const [dictTypeList, setDictTypeList] = useState<Array<DictTypeDto>>([]);
    const [selectedDictTypeId, setSelectedDictTypeId] = useState('');

    const [dictClassList, setDictClassList] = useState<Array<DictClassDto>>([]);
    const [selectedDictClassId, setSelectedDictClassId] = useState('');

    const [dictItemList, setDictItemList] = useState<Array<DictItemDto>>([]);

    useEffect(() => {
        // 查询用户组列表
        fetchDictTypeList();
    }, []);

    const fetchDictTypeList = async () => {
        const responseResult = await DictTypeApi.getDictTypeList();
        if (responseResult.status === HttpStatus.SUCCESS) {
            setDictTypeList(responseResult.data);
        }
    };

    const fetchDictClassList = async (dictTypeId: string) => {
        const responseResult = await DictTypeApi.getDictClasses(dictTypeId, "1");
        if (responseResult.status === HttpStatus.SUCCESS) {
            setDictClassList(responseResult.data);
        }
    };

    const fetchDictItemList = async (dictItemId: string) => {
        const responseResult = await DictClassApi.getDictItems(dictItemId);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setDictItemList(responseResult.data);
        }
    };

    const handleSelectDictType = (item: DictTypeDto) => {
        setSelectedDictTypeId(item.id);
        fetchDictClassList(item.id);
    }

    const handleSelectDictClass = (item: DictClassDto) => {
        setSelectedDictClassId(item.id);
        fetchDictItemList(item.id)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <DictTypeList dictTypeList={dictTypeList} onSelect={(value => handleSelectDictType(value))}
                              onRefresh={fetchDictTypeList}
                              selectedDictTypeId={selectedDictTypeId}/>
            </Grid>
            <Grid item xs={4}>
                <DictClassList dictTypeList={dictTypeList} dictClassList={dictClassList}
                               onSelect={(value => handleSelectDictClass(value))}
                               selectedDictTypeId={selectedDictTypeId}
                               selectedDictClassId={selectedDictClassId}
                               onRefresh={(dictTypeId) => fetchDictClassList(dictTypeId)}/>
            </Grid>
            <Grid item xs={4}>
                <DictItemList selectedDictClassId={selectedDictClassId} dictClassList={dictClassList}
                              dictItemList={dictItemList}
                              onRefresh={(dictClassId) => fetchDictItemList(dictClassId)}/>
            </Grid>
        </Grid>
    );
};

export default Index;
