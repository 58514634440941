import React, {useEffect, useState} from 'react';
import {RequestVisitorDto} from "../../../modules/vms/dto/RequestVisitorDto";
import {FormattedMessage, useIntl} from "react-intl";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";
import DictItemUtils from "../../../modules/dict/utils/DictItemUtils";
import VisitorAccessLogListDialog from "./VisitorAccessLogListDialog";
import {VisitorAccessLogApi} from "../../../modules/vms/api/VisitorAccessLogApi";
import {HttpStatus} from "../../../modules/common/enums/HttpStatus";
import {Button, Card, CardContent, CardHeader, Tab, Tabs} from "@mui/material";
import Descriptions from "../../../components/Descriptions";
import DescriptionItem from "../../../components/DescriptionItem";
import {RequestVisitorApi} from "../../../modules/vms/api/RequestVisitorApi";

export type RequestVisitorDetailProps = {
    requestVisitor: RequestVisitorDto;
    visitorTypes: Array<DictItemDto>;
    idTypes: Array<DictItemDto>;
    driveTypes: Array<DictItemDto>;
    plateNumberColors: Array<DictItemDto>;
    vehicleUseTypes: Array<DictItemDto>;
    vehicleTypes: Array<DictItemDto>;
};

const RequestVisitorDetail: React.FC<RequestVisitorDetailProps> = (props) => {
    const intl = useIntl();

    const [openAccessLogModal, setOpenAccessLogModal] = useState(false);

    const [tabIndex, setTabIndex] = React.useState(0);

    const [accessLogs, setAccessLogs] = useState([]);

    const fetchAccessLogList = async () => {
        const responseResult = await RequestVisitorApi.getVisitorAccessLogList(props.requestVisitor.id);
        if (responseResult.status === HttpStatus.SUCCESS) {
            setAccessLogs(responseResult.data);
            setOpenAccessLogModal(true);
        }
    };

    const renderVisitorDetail = () => {

            return (
                <Card>
                    <CardHeader title={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_GENERAL_INFO'})}/>

                    <CardContent>

                        <Descriptions>
                            <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_NAME'})}>
                                {props.requestVisitor.fullName}
                            </DescriptionItem>
                            <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_TYPE'})}>
                                {DictItemUtils.getText(props.visitorTypes, props.requestVisitor.visitorType)}
                            </DescriptionItem>

                            <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_MOBILE'})}>
                                {props.requestVisitor.mobile}
                            </DescriptionItem>
                            <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_EMAIL'})}>
                                {props.requestVisitor.email}
                            </DescriptionItem>
                            <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_COMPANY'})}>
                                {props.requestVisitor.company}
                            </DescriptionItem>



                        </Descriptions>
                    </CardContent>
                </Card>
            )

    }
    const renderAccessDetail = () => {

        return (
            <Card>
                <CardHeader title={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_GENERAL_INFO'})}/>

                <CardContent>

                    <Descriptions>
                        <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER'})}>
                            {props.requestVisitor.accessCardNumber}
                        </DescriptionItem>
                        <DescriptionItem label={intl.formatMessage({id: 'VMS_VISITOR_ACCESS_LOG_LIST'})}>
                            <a href={'javascript:void(0)'} onClick={fetchAccessLogList}><FormattedMessage
                                id={'VMS_VISITOR_ACCESS_LOG_LIST_FETCH'}/></a>
                        </DescriptionItem>


                    </Descriptions>
                </CardContent>
            </Card>
        )

    }
    return (
        <div>
            <VisitorAccessLogListDialog open={openAccessLogModal} onClose={() => setOpenAccessLogModal(false)}
                                        accessLogs={accessLogs}/>
            {renderVisitorDetail()}
            {/*{renderAccessDetail()}*/}

        </div>
    );
};

export default RequestVisitorDetail;
