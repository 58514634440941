import React from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {Formats} from "../../../../constants/Formats";
import dayjs from "dayjs";
import {
    Grid,
} from "@mui/material";
import FormFieldText from "../../../../components/FormFieldText";
import FormFieldDateRange from "../../../../components/FormFieldDateRange";
import SearchButton from "../../../../components/buttons/SearchButton";
import {LogOperationSearchCondition} from "../../../../modules/log/dto/LogOperationSearchCondition";

export type SearchConditionProps = {
    searchCondition: LogOperationSearchCondition;
    onChange: (name: string, value: any) => void;
    onSearch: () => void;
};

const SearchCondition: React.FC<SearchConditionProps> = (props) => {
    const intl = useIntl();
    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={6}>
                <FormFieldDateRange fromStartAdornment={intl.formatMessage({id: "LOG_OPERATION_OPERATION_TIME"})}
                                    fromValue={dayjs(props.searchCondition.startTime, Formats.DATE_TIME_SIMPLE)}
                                    fromOnChange={(value) => props.onChange("startTime", value)}
                                    toValue={dayjs(props.searchCondition.endTime, Formats.DATE_TIME_SIMPLE)}
                                    toOnChange={(value) => props.onChange("endTime", value)}/>
            </Grid>
            <Grid item xs={2}>
                <FormFieldText startAdornment={intl.formatMessage({id: "LOG_OPERATION_OPERATOR_NAME"})}
                                 value={props.searchCondition.operatorName}
                                 onChange={(value) => {
                                     props.onChange("operatorName", value)
                                 }}/>
            </Grid>
            <Grid item xs={3}>
                <FormFieldText startAdornment={intl.formatMessage({id: "LOG_OPERATION_OPERATION"})}
                               value={props.searchCondition.operation}
                               onChange={(value) => {
                                   props.onChange("operation", value)
                               }}/>
            </Grid>
            <Grid item xs={1}>
                    <SearchButton onClick={() => props.onSearch()}/>
            </Grid>
        </Grid>
    );
};

export default SearchCondition;
