import React, {useEffect, useState} from 'react';
import {RequestVisitorDto} from "../../../modules/vms/dto/RequestVisitorDto";
import {VisitRequestDto} from "../../../modules/vms/dto/VisitRequestDto";
import {FormattedMessage, useIntl} from "react-intl";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";
import ApproveTimeLine from "../../workflow/apply-process/components/ApproveTimeLine";
import {DictClassCode} from "../../../modules/vms/constants/DictClassCode";
import {DictClassApi} from "../../../modules/dict/api/DictClassApi";
import {HttpStatus} from "../../../modules/common/enums/HttpStatus";
import VisitRequestBasic from "./VisitRequestBasic";
import RequestPlaceList from "./RequestPlaceList";
import RequestVisitorDetail from "./RequestVisitorDetail";
import {VisitorAccessLogApi} from "../../../modules/vms/api/VisitorAccessLogApi";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Table} from "@mui/material";
import CloseButton from "../../../components/buttons/CloseButton";
import VisitRequestHostInfo from "./VisitRequestHostInfo";
import VisitorRequestInfo from "./VisitorRequestInfo";

export type RequestVisitorDetailDialogProps = {
    open: boolean;
    requestVisitor: RequestVisitorDto;
    onClose: () => void;
};

const RequestVisitorDetailDialog: React.FC<RequestVisitorDetailDialogProps> = (props) => {
    const intl = useIntl();

    // 访问目的
    const [purposeTypes, setPurposeTypes] = useState(new Array<DictItemDto>());

    // 证件类型
    const [idTypes, setIdTypes] = useState(new Array<DictItemDto>());

    // 访客类型
    const [visitorTypes, setVisitorTypes] = useState(new Array<DictItemDto>());

    // 驾驶类型
    const [driveTypes, setDriveTypes] = useState(new Array<DictItemDto>());

    // 车牌颜色
    const [plateNumberColors, setPlateNumberColors] = useState(new Array<DictItemDto>());

    // 车辆使用性质
    const [vehicleUseTypes, setVehicleUseTypes] = useState(new Array<DictItemDto>());

    // 车辆类型
    const [vehicleTypes, setVehicleTypes] = useState(new Array<DictItemDto>());
    // 员工实体
    const [employeeEntityTypes, setEmployeeEntityTypes] = useState(new Array<DictItemDto>());

    useEffect(() => {
        // 查询访问目的字典项
        fetchDictClassList();
    }, []);

    const fetchDictClassList = async () => {
        const codes = new Array<string>(DictClassCode.VMS_PURPOSE_TYPE, DictClassCode.VMS_VISITOR_TYPE,
            DictClassCode.VMS_ID_TYPE, DictClassCode.VMS_DRIVE_TYPE, DictClassCode.VMS_PLATE_NUMBER_COLOR, DictClassCode.VMS_VEHICLE_USE_TYPE, DictClassCode.VMS_VEHICLE_TYPE,
             DictClassCode.VMS_EMPLOYEE_ENTITY_TYPE);
        const responseResult = await DictClassApi.getDictClassList(codes);
        if (responseResult.status === HttpStatus.SUCCESS) {
            for (let dictClass of responseResult.data) {
                switch (dictClass.code) {
                    case DictClassCode.VMS_PURPOSE_TYPE:
                        setPurposeTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_VISITOR_TYPE:
                        setVisitorTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_ID_TYPE:
                        setIdTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_DRIVE_TYPE:
                        setDriveTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_PLATE_NUMBER_COLOR:
                        setPlateNumberColors(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_VEHICLE_USE_TYPE:
                        setVehicleUseTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_VEHICLE_TYPE:
                        setVehicleTypes(dictClass.dictItems);
                        break;
                    case DictClassCode.VMS_EMPLOYEE_ENTITY_TYPE:
                        setEmployeeEntityTypes(dictClass.dictItems);
                        break;
                }
            }
        }
    };

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                {intl.formatMessage({id: 'VMS_REQUEST_VISITOR_DETAIL'})}
            </DialogTitle>
            <DialogContent>
                {/*<br/>*/}
                {/*访客基本信息*/}
                <RequestVisitorDetail requestVisitor={props.requestVisitor} visitorTypes={visitorTypes}
                                      idTypes={idTypes}
                                      driveTypes={driveTypes} plateNumberColors={plateNumberColors}
                                      vehicleUseTypes={vehicleUseTypes} vehicleTypes={vehicleTypes}/>
                <br/>

                <VisitorRequestInfo requestVisitor={props.requestVisitor}
                    visitRequest={props.requestVisitor.visitRequest || new VisitRequestDto()}
                                      purposeTypes={purposeTypes}/>
                {/*申请和接待者信息*/}
                <br/>
                <VisitRequestHostInfo visitRequest={props.requestVisitor.visitRequest || new VisitRequestDto()}
                                      employeeEntityTypes={employeeEntityTypes}
                                   purposeTypes={purposeTypes}/>
                <br/>


            </DialogContent>
            <DialogActions>
                <CloseButton onClick={props.onClose}/>
            </DialogActions>
        </Dialog>
    );
};

export default RequestVisitorDetailDialog;
