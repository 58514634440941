import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, CardHeader, Grid, Stack} from "@mui/material";
import {VMSCacheKey} from "../../../../../modules/vms/constants/VMSCacheKey";
import SignDialog from "./components/SignDialog";
import {ImageUtils} from "../../../../../utils/ImageUtils";
import {HttpUtils} from "../../../../../utils/HttpUtils";
import {MessageUtils} from "../../../../../utils/MessageUtils";

const Index: React.FC = () => {

    const [consentLetterImg, setConsentLetterImg] = useState('');
    const [openSignDialog, setOpenSignDialog] = useState(false);

    const [signImageY, setSighImageY] = useState(2000);

    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        handleLoadConsentLetter();

        const signImageY = HttpUtils.getQueryString(window.location.href, "signImageY");
        if (signImageY) {
            setSighImageY(Number.parseInt(signImageY) - 100);
        }
    }, []);

    const handleLoadConsentLetter = () => {
        const cachedConsentLetter = localStorage.getItem(VMSCacheKey.CONSENT_LETTER);
        if (cachedConsentLetter) {
            setConsentLetterImg(cachedConsentLetter);
        }
    }

    const handleSign = (signImage: string) => {
        // 拼凑通知图片和签名图片
        ImageUtils.stitchPicture(consentLetterImg, signImage, 60, signImageY, (stitchImage) => {
            setConsentLetterImg(stitchImage);
        })
    }

    const handleSave = () => {
        setButtonDisabled(true);
        MessageUtils.success("提交成功，窗口降在5秒后自动关闭。Successfully submitted, the window will automatically close after 5 seconds.");
        localStorage.setItem(VMSCacheKey.CONSENT_LETTER, consentLetterImg);
        setTimeout(() => window.close(), 5000);
    }

    return (
        <div>
            <SignDialog open={openSignDialog} onClose={() => setOpenSignDialog(false)}
                        onOk={(signImage) => handleSign(signImage)}/>
            <Stack spacing={2} sx={{p: '1rem'}}>
                <Card style={{height: '100%'}}>
                    <CardHeader/>
                    <CardContent style={{overflow: 'auto', height: '80vh'}}>
                        <img alt={'consentLetterImg'} src={consentLetterImg} style={{width: '100%'}}/>
                    </CardContent>
                </Card>
                <Grid container
                      direction="row" spacing={2} alignItems="center" justifyContent={'center'}>
                    <Grid item >
                        <Button variant={'contained'} size={'large'} disabled={buttonDisabled}
                                onClick={() => setOpenSignDialog(true)}>
                            签字 / Sign
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button variant={'outlined'} color="secondary" size={'large'} disabled={buttonDisabled}
                                onClick={handleLoadConsentLetter}>
                            重置 / Reset
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button variant={'contained'} color="secondary" size={'large'} disabled={buttonDisabled}
                                onClick={handleSave}>
                            提交 / Submit
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        </div>
    );
};

export default Index;
