import {AxiosRequest, HttpUtils} from '../../../utils/HttpUtils';
import {TenantDto} from "../dto/TenantDto";
import {TenantSearchCondition} from "../dto/TenantSearchCondition";

export const TenantApi = {

    queryTenants: async (body: TenantSearchCondition) => {
        try {
            const requestUrl = '/v1/account/tenant/query';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    
    createTenant: async (body: TenantDto) => {
        try {
            const requestUrl = '/v1/account/tenant/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateTenant: async (body: TenantDto) => {
        try {
            const requestUrl = '/v1/account/tenant/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteTenant: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/account/tenant/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    
    getMyTenant: async () => {
        try {
            const requestUrl = '/v1/account/tenant/getMyTenant';
            console.log(requestUrl);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getMyResourceApps: async (associateRoles: boolean) => {
        try {
            const params = new Map<string, string>();
            params.set('associateRoles', associateRoles ? 'true' : 'false');
            const requestUrl = HttpUtils.addQueryString('/v1/account/tenant/apps', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    // getRoles: async () => {
    //     try {
    //         const requestUrl = '/v1/account/tenant/roles';
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    updateMyTenant: async (body: TenantDto) => {
        try {
            const requestUrl = '/v1/account/tenant/updateMyTenant';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    activeMyResourceApp: async (appId: string) => {
        try {
            const params = new Map<string, string>();
            params.set('appId', appId);
            const requestUrl = HttpUtils.addQueryString('/v1/account/tenant/app/active', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
