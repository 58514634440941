import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import {FormattedMessage, useIntl} from "react-intl";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {DictClassCode} from "../../../../../modules/vms/constants/DictClassCode";
import {DictClassApi} from "../../../../../modules/dict/api/DictClassApi";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {RequestPlaceDto} from "../../../../../modules/vms/dto/RequestPlaceDto";
import {Formats} from "../../../../../constants/Formats";
import {Badge, Button, Card, CardContent, CardHeader} from "@mui/material";
import {AreaDto} from "../../../../../modules/vms/dto/AreaDto";
import AreaSelectDialog from "./AreaSelectDialog";
import FormFieldText from "../../../../../components/FormFieldText";
import FormFieldDate from "../../../../../components/FormFieldDate";
import FormFieldDictItems from "../../../../dict/components/FormFieldDictItems";

export type VisitRequestProps = {
    visitRequest: VisitRequestDto;
    messageMap: Map<string, string>;
    onChange: (name: string, value: any) => void;
};

const VisitRequest: React.FC<VisitRequestProps> = (props) => {
    const intl = useIntl();

    // 访问目的
    const [purposeTypes, setPurposeTypes] = useState(new Array<DictItemDto>());

    // 场所选择的对话框
    const [areaSelectDialogOpen, setAreaSelectDialogOpen] = useState(false);

    // 选择的场所显示字符串
    const [selectedPlaceStr, setSelectedPlaceStr] = useState('');

    useEffect(() => {
        // 查询访问目的字典项
        fetchDictClassList();
    }, []);

    const fetchDictClassList = async () => {
        const codes = new Array<string>(DictClassCode.VMS_PURPOSE_TYPE);
        const responseResult = await DictClassApi.getDictClassList(codes);
        if (responseResult.status === HttpStatus.SUCCESS) {
            for (let dictClass of responseResult.data) {
                switch (dictClass.code) {
                    case DictClassCode.VMS_PURPOSE_TYPE:
                        setPurposeTypes(dictClass.dictItems);
                        break;
                }
            }
        }
    };

    const handleChange = (name: string, value: any) => {
        props.onChange(name, value);
    };

    const handleSelectAreas = (areas: Array<AreaDto>) => {
        const places = new Array<RequestPlaceDto>();
        for (let area of areas) {
            const place = new RequestPlaceDto(area);
            places.push(place);
        }
        handleChange("places", places);
        const selectedPlaceStr = areas.map((area) => {
            return area.name
        }).join(',')

        setSelectedPlaceStr(selectedPlaceStr);
        setAreaSelectDialogOpen(false);
    }

    return (
        <Card>
            <AreaSelectDialog open={areaSelectDialogOpen} onCancel={() => setAreaSelectDialogOpen(false)}
                              onOk={handleSelectAreas}/>
            <CardHeader title={intl.formatMessage({id: 'VMS_VISIT_REQUEST_GENERAL_INFO'})}/>
            <CardContent>
                <FormFieldDate label={intl.formatMessage({id: "VMS_VISIT_REQUEST_ARRIVE_DATE"})}
                               required={true} errorText={props.messageMap.get("arriveStartDate")}
                               value={dayjs(props.visitRequest.arriveStartDate, Formats.DATE_SIMPLE)}
                               minDate={dayjs()}
                               maxDate={dayjs().add(15, "days")}
                               onChange={(value) => handleChange("arriveStartDate", value)}/>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PURPOSE_TYPE"})}
                                 required={true} errorText={props.messageMap.get("purposeType")}
                                 value={props.visitRequest.purposeType}
                                 onChange={(value) => {
                                     handleChange("purposeType", value)
                                 }} dictItems={purposeTypes}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PURPOSE"})}
                               required={true} errorText={props.messageMap.get("purpose")}
                               value={props.visitRequest.purpose}
                               onChange={(value) => {
                                   handleChange("purpose", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PLACE"})}
                               disabled={true} required={true} errorText={props.messageMap.get("places")}
                               value={selectedPlaceStr}
                               endAdornment={
                                   <Badge badgeContent={props.visitRequest.places.length} color="secondary"
                                          overlap="rectangular">
                                       <Button color="primary" onClick={() => setAreaSelectDialogOpen(true)}>
                                           <FormattedMessage id="VMS_VISIT_REQUEST_PLACE_SELECT"/>
                                       </Button>
                                   </Badge>}/>
            </CardContent>
        </Card>
    );
};

export default VisitRequest;
