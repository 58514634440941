import {AxiosDownloadFileRequest, AxiosFileRequest, AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {FileSearchCondition} from "../dto/FileSearchCondition";
import {HttpStatus} from "../../common/enums/HttpStatus";
import {FileClassify} from "../enums/FileClassify";
import {FileDto} from "../dto/FileDto";
import {ImageUtils} from "../../../utils/ImageUtils";

export const FileApi = {

    getRootFolderTree: async () => {
        const responseResult = await FileApi.getFilesChildren('', FileClassify.Enum.Folder);
        if (responseResult.status === HttpStatus.SUCCESS) {
            responseResult.data = FileApi.constructTree(responseResult.data);
        }
        return responseResult;
    },

    queryFiles: async (body: FileSearchCondition) => {
        try {
            const requestUrl = '/v1/ftp/file/query';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getFileDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/ftp/file/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getFilesChildren: async (parentId: string, classify: string) => {
        try {
            const params = new Map<string, string>();
            params.set('parentId', parentId);
            params.set('classify', classify);
            const requestUrl = HttpUtils.addQueryString('/v1/ftp/file/children', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    constructTree: (fileList: Array<FileDto>) => {
        // 构建树
        const fileMap = new Map<string, FileDto>();
        const rootFileList = new Array<FileDto>();
        for (let file of fileList) {
            fileMap.set(file.id, file);
        }

        for (let file of fileList) {
            if (file.parentId && fileMap.has(file.parentId)) {
                const parentFile = fileMap.get(file.parentId);
                if (parentFile) {
                    if (!parentFile.children) {
                        parentFile.children = [];
                    }
                    parentFile.children.push(file);

                    // 赋予parent属性，在管理folderTree的递归关系时可以提高效率
                    file.parent = parentFile;
                }
            } else {
                rootFileList.push(file);
            }
        }
        return rootFileList;
    },

    createFile: async (body: FileDto) => {
        try {
            const requestUrl = '/v1/ftp/file/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateFile: async (body: FileDto) => {
        try {
            const requestUrl = '/v1/ftp/file/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteFile: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/ftp/file/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    uploadBase64File: async (folder: string, parentId: string, base64Data: string) => {
        try {
            console.log(base64Data);
            const formData = ImageUtils.toFormData(base64Data);
            console.log(formData);
            const params = new Map<string, string>();
            params.set('folder', folder);
            params.set('parentId', parentId);
            const requestUrl = HttpUtils.addQueryString('/v1/ftp/file/upload', params);
            const axiosResponse = await AxiosFileRequest.post(requestUrl, formData);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
    downloadFile: async (id: string,fileName:string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);

            const requestUrl = HttpUtils.addQueryString('/v1/ftp/file/download', params);

            const axiosResponse = await AxiosDownloadFileRequest.get(requestUrl);
            console.log(axiosResponse)
            if (axiosResponse.status == HttpStatus.SUCCESS) {

                if ('download' in document.createElement('a')) { // 非IE下载
                    const aLink = document.createElement('a');
                    document.body.appendChild(aLink);
                    aLink.style.display = 'none';
                    const objectUrl = window.URL.createObjectURL(axiosResponse.data);
                    aLink.href = objectUrl;
                    aLink.target='blank';
                    aLink.download = fileName;
                    aLink.click();
                    document.body.removeChild(aLink);
                } else {
                    // // IE10+下载
                    // if (fileName === undefined || fileName === "") {
                    //     const disposition = axiosResponse.headers.map["content-disposition"];
                    //     fileName = disposition.substr(disposition.lastIndexOf("=") + 1);
                    // }
                    // window.navigator.msSaveBlob(axiosResponse.data, fileName);
                }
            }


        } catch (error: any) {
            console.error(error);
            // return error.response.data;
        }
    },
};
