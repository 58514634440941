import React, {ReactNode} from 'react';
import {InputAdornment, TextField} from "@mui/material";

type FormFieldTextProps = {
    label?: string;
    placeholder?: string;
    value?: string | number;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    errorText?: string;
    onChange?: (value: string) => void;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    maxRows?: number;
};

const FormFieldText: React.FC<FormFieldTextProps> = (props) => {
    return (
        <TextField
            label={props.label}
            placeholder={props.placeholder}
            required={props.required}
            disabled={props.disabled}
            error={props.errorText !== undefined && props.errorText !== ''}
            helperText={props.errorText}
            fullWidth
            variant="standard"
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            value={props.value}
            type={props.type || 'text'}
            multiline={!!(props.maxRows && props.maxRows > 1)}
            maxRows={props.maxRows}
            onChange={(e) => props.onChange && props.onChange(e.target.value)}
            InputProps={{
                startAdornment: (<InputAdornment position="start">{props.startAdornment}</InputAdornment>),
                endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
            }}
        />
    );
};
export default FormFieldText;