import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {SiteDto} from "../dto/SiteDto";

export const SiteApi = {
    /**
     * 获得场所列表
     */
    getSiteList: async () => {
        try {
            const requestUrl = '/v1/vms/site/list';
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getSiteDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/site/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    createSite: async (body: SiteDto) => {
        try {
            const requestUrl = '/v1/vms/site/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateSite: async (body: SiteDto) => {
        try {
            const requestUrl = '/v1/vms/site/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteSite: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/vms/site/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
