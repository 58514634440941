export default {
    // 操作日志
    LOG_OPERATION: '操作日志',
    LOG_OPERATION_OPERATOR_NAME: '操作者姓名',
    LOG_OPERATION_OPERATOR_IP: '操作者IP',
    LOG_OPERATION_OPERATION: '操作内容',
    LOG_OPERATION_OPERATION_TIME: '操作时间',
    LOG_OPERATION_TABLE_NAME: '操作表名',
    LOG_OPERATION_TABLE_KEY: '操作键名',
    LOG_OPERATION_REMARKS: '备注',
    LOG_OPERATION_USER_AGENT: '浏览器信息',
    LOG_OPERATION_START_TIME: '操作开始时间',
    LOG_OPERATION_END_TIME: '操作结束时间',

    // 字段日志
    LOG_FIELD: '字段日志',
    LOG_FIELD_NAME: '字段名',
    LOG_FIELD_SOURCE: '变更前',
    LOG_FIELD_TARGET: '变更后',
};
