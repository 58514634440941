export default {
    VMS_SITE_LIST: 'Site List',
    VMS_SITE_CREATE: "Create Site",
    VMS_SITE_CREATE_SUCCESS: "New Site Create Successful",
    VMS_SITE_MODIFY: "Modify Site",
    VMS_SITE_MODIFY_SUCCESS: "Site Modify Successful",
    VMS_SITE_CODE: 'Code',
    VMS_SITE_CODE_REQUIRED: 'Please input code',
    VMS_SITE_NAME: 'Name ',
    VMS_SITE_NAME_REQUIRED: 'Please input name',
    VMS_SITE_SEQUENCE: 'Sequence',
    VMS_SITE_MSG_HAVE_CHILDREN: 'site exists location, please delete location first!',

    VMS_LOCATION_LIST: 'Location List',
    VMS_LOCATION_CREATE: "Create Location",
    VMS_LOCATION_CREATE_SUCCESS: "New Location Create Successful",
    VMS_LOCATION_MODIFY: "Modify Location",
    VMS_LOCATION_MODIFY_SUCCESS: "Location Modify Successful",
    VMS_LOCATION_SITE: 'Site',
    VMS_LOCATION_SITE_REQUIRED: 'Please selected in Location',
    VMS_LOCATION_CODE: 'Code',
    VMS_LOCATION_CODE_REQUIRED: 'Please input code',
    VMS_LOCATION_NAME: 'Name',
    VMS_LOCATION_NAME_REQUIRED: 'Please input name',
    VMS_LOCATION_SEQUENCE: 'Sequence',
    VMS_LOCATION_MSG_HAVE_CHILDREN: 'Location exists place, please delete place first!',

    VMS_AREA_LIST: 'Area List',
    VMS_AREA_CREATE: "Create Area",
    VMS_AREA_CREATE_SUCCESS: "New Area Create Successful",
    VMS_AREA_MODIFY: "Modify Area",
    VMS_AREA_MODIFY_SUCCESS: "Area Modify Successful",
    VMS_AREA_LOCATION: 'Location',
    VMS_AREA_LOCATION_REQUIRED: 'Please selected in Area',
    VMS_AREA_CODE: 'Code',
    VMS_AREA_CODE_REQUIRED: 'Please input code',
    VMS_AREA_NAME: 'Name',
    VMS_AREA_NAME_REQUIRED: 'Please input name',
    VMS_AREA_TYPE: 'Type',
    VMS_AREA_TYPE_REQUIRED: 'Please input type',
    VMS_AREA_SEQUENCE: 'Sequence',
    VMS_AREA_TYPE_NORMAL: "Normal Office",
    VMS_AREA_TYPE_SPECIAL: "Special Office",

    VMS_VISITOR_TYPE: 'Visitor Type',
    VMS_VISITOR_TYPE_CREATE: 'Create Visitor Type',
    VMS_VISITOR_TYPE_CREATE_SUCCESS: 'Visitor Type Create Successful',
    VMS_VISITOR_TYPE_MODIFY: 'Modify Visitor Type',
    VMS_VISITOR_TYPE_MODIFY_SUCCESS: 'Visitor Type Modify Successful',
    VMS_VISITOR_TYPE_CODE: 'Code',
    VMS_VISITOR_TYPE_CODE_REQUIRED: 'Please input code',
    VMS_VISITOR_TYPE_NAME: 'Name',
    VMS_VISITOR_TYPE_NAME_REQUIRED: 'Please input name',
    VMS_VISITOR_TYPE_VALUE: 'Value',
    VMS_VISITOR_TYPE_VALUE_REQUIRED: 'Please input value',
    VMS_VISITOR_TYPE_SEQUENCE: 'Sequence',
    VMS_VISITOR_TYPE_WORKFLOW_FORM: 'Workflow Form',

    VMS_VISIT_REQUEST_NUMBER: 'Request Number',
    VMS_VISIT_REQUEST_APPLY_DATE: 'Apply Date',
    VMS_VISIT_REQUEST_STATUS: 'Request Status',
    VMS_VISIT_REQUEST_STATUS_NEW: 'New',
    VMS_VISIT_REQUEST_STATUS_APPROVING: 'Approving',
    VMS_VISIT_REQUEST_STATUS_APPROVED: 'Approved',
    VMS_VISIT_REQUEST_STATUS_REJECTED: 'Rejected',
    VMS_VISIT_REQUEST_STATUS_EXPIRED: 'Expired',
    VMS_VISIT_REQUEST_STATUS_COMPLETED: 'Complete',
    VMS_VISIT_REQUEST_STATUS_CANCELED: 'Canceled',

    VMS_VISIT_REQUEST_GENERAL_INFO: 'Visit Request Basic',
    VMS_VISIT_REQUEST_VISITOR_INFO: 'Visitor Info',
    VMS_VISIT_REQUEST_HOST_INFO: 'Visit Host Info',
    VMS_VISIT_REQUEST_CONFIRM_INFO: 'Confirm Info',
    VMS_VISIT_REQUEST_REGISTER_COMPLETE: 'Register Complete',
    VMS_VISIT_REQUEST_PLACE: 'Place',
    VMS_VISIT_REQUEST_PLACE_SELECT: 'Select Place',
    VMS_VISIT_REQUEST_PLACES_REQUIRED: 'Please select place',
    VMS_VISIT_REQUEST_VISITORS_REQUIRED: 'Please input visitors',
    VMS_VISIT_REQUEST_ARRIVE_START_DATE: 'Arrive Start Date',
    VMS_VISIT_REQUEST_ARRIVE_END_DATE: 'Arrive End Date',
    VMS_VISIT_REQUEST_ARRIVE_DATE: 'Arrive Date',
    VMS_VISIT_REQUEST_PURPOSE_TYPE: 'Visit Purpose',
    VMS_VISIT_REQUEST_PURPOSE: 'Visit Reason',
    VMS_VISIT_REQUEST_EMPLOYEE_ENTITY: 'Employee Entity',

    VMS_VISIT_REQUEST_INVITE_SUCCESS: 'Visitor invite successful.',
    VMS_VISIT_REQUEST_REGISTER_SUCCESS: 'Visitor register successful.',

    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE: 'Apply Source',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_EMPLOYEE: 'Employee Invite',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_TERMINAL: 'Terminal Register',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_MOBILE: 'Mobile Register',
    VMS_VISIT_REQUEST_APPLY_SOURCE_TYPE_OFFLINE: 'Offline Import',

    VMS_VISIT_REQUEST_HOST_MOBILE: 'Employee Mobile',
    VMS_VISIT_REQUEST_HOST_STAFF_NUMBER: 'Employee Staff Number',
    VMS_VISIT_REQUEST_HOST_STAFF_NUMBER_REQUIRED: 'Please input employee staff number',

    VMS_VISIT_REQUEST_HOST_MOBILE_REQUIRED: 'Please input employee mobile',
    VMS_VISIT_REQUEST_HOST_EMAIL: 'Employee Email',
    VMS_VISIT_REQUEST_HOST_EMAIL_REQUIRED: 'Please input employee email',
    VMS_VISIT_REQUEST_HOST_NAME: 'Employee Name',
    VMS_VISIT_REQUEST_HOST_LAST_NAME: 'Employee Last Name',
    VMS_VISIT_REQUEST_HOST_LAST_NAME_REQUIRED: 'Please input employee last name',
    VMS_VISIT_REQUEST_HOST_FIRST_NAME: 'Employee First Name',
    VMS_VISIT_REQUEST_HOST_FIRST_NAME_REQUIRED: 'Please input employee first name',
    VMS_VISIT_REQUEST_HOST_LAST_INFO_RELOAD: 'Reception information loading',
    VMS_VISIT_REQUEST_HOST_LAST_INFO_RELOAD_SUCCESS: 'Reception information load successful.',
    VMS_VISIT_REQUEST_HOST_LAST_INFO_RELOAD_CONFIRM: 'This receptionist has a history record. Do you need to load the latest information of this receptionist?',
    VMS_VISIT_REQUEST_HOST_SUPERVISOR_NAME: 'Host Supervisor Name',
    VMS_VISIT_REQUEST_HOST_SUPERVISOR_EMAIL: 'Host Supervisor Email',
    VMS_VISIT_REQUEST_HOST_DEPARTMENT_NAME: 'Host Department',

    VMS_VISIT_REQUEST_REGISTER_SUBMIT_SUCCESS: 'Visitor Register Submit Succeed.',
    VMS_VISIT_REQUEST_REGISTER_ANOTHER_ONE: 'Register another one',
    VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO: 'Internal Employee Info',
    VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO_ALERT: 'After filling in the receptionist information once, it can be automatically filled in through phone number or email inquiry',
    VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_INFO: 'Visitor Info',
    VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_INFO_ALERT: 'After filling in visitor information once, it can be automatically filled in by querying through mobile phone number or email',
    VMS_VISIT_REQUEST_REGISTER_STEP_VISITOR_VEHICLE: 'Visitor Vehicle',
    VMS_VISIT_REQUEST_REGISTER_STEP_CONFIRM_INFO: 'Confirm Info',
    VMS_VISIT_REQUEST_REGISTER_STEP_CONSENT_LETTER:'Consent Letter',
    VMS_VISIT_REQUEST_REGISTER_STEP_COMPLETE: 'Complete',
    VMS_VISIT_REQUEST_DETAIL: 'Visit Request Detail',
    VMS_VISIT_REQUEST_PENDING_APPROVAL_LIST: 'Pending Approval List',

    VMS_REQUEST_VISITOR_REGISTER: 'Visitor Register',
    VMS_REQUEST_VISITOR_REGISTER_INFO_FILL: 'Visitor Register Info Fill',

    VMS_REQUEST_VISITOR_REGISTER_DESC: 'Register basic information of visitors and contact persons, and complete visitor registration',
    VMS_REQUEST_VISITOR_CHECKIN_TIME: 'Checkin Time',
    VMS_REQUEST_VISITOR_CHECKOUT: 'Visitor Checkout',
    VMS_REQUEST_VISITOR_CHECKOUT_TIME: 'Checkout Time',
    VMS_REQUEST_VISITOR_CHECKOUT_DESC: 'When visitors leave, they need to complete the check-out confirmation through their ID card or card number',
    VMS_REQUEST_VISITOR_CHECKOUT_SUCCESS: 'Visitor Checkout Succeed.',
    VMS_REQUEST_VISITOR_SEARCH: 'Search Today Visitor',
    VMS_REQUEST_VISITOR_SEARCH_PLACEHOLDER: 'Please enter/scan the visitor\'s ID number or access card number',
    VMS_REQUEST_VISITOR_CONFIRM_INFO: 'Visitor Information Confirmation',
    VMS_REQUEST_VISITOR_CHECKOUT_COMPLETE: 'Checkout Complete',
    VMS_REQUEST_VISITOR_CHECKOUT_ANOTHER_ONE: 'Checkout another one',
    VMS_REQUEST_VISITOR_NOT_FOUND: 'No visitor information found',

    VMS_REQUEST_VISITOR_MANAGEMENT: 'Visitor Management',
    VMS_REQUEST_VISITOR_MANAGEMENT_DESC: 'Manage visitor lists, display visitor identity information and receptionist information',

    VMS_REQUEST_VISITOR_INFO: 'Visitor Information',
    VMS_REQUEST_VISITOR_LIST: 'Visit List',
    VMS_REQUEST_VISITOR_DETAIL: 'Visitor Detail',
    VMS_REQUEST_VISITOR_CREATE: 'Create Visitor',
    VMS_REQUEST_VISITOR_MODIFY: 'Modify Visitor',
    VMS_REQUEST_VISITOR_GENERAL_INFO: 'Visitor General Info',
    VMS_REQUEST_VISITOR_VISIT_REQUEST_INFO: 'Visitor Request Info',

    VMS_REQUEST_VISITOR_VEHICLE_INFO: 'Visitor Vehicle Info',
    VMS_REQUEST_VISITOR_ARRIVE_DATE: 'Arrive Date',
    VMS_REQUEST_VISITOR_ARRIVE_START_DATE_REQUIRED: 'Please select start date',
    VMS_REQUEST_VISITOR_ARRIVE_END_DATE_REQUIRED: 'Please select end date',
    VMS_REQUEST_VISITOR_VISIT_CODE: "Visit Code",
    VMS_REQUEST_VISITOR_NAME: 'Visitor Name',
    VMS_REQUEST_VISITOR_NAME_REQUIRED: 'Please input visitor name',
    VMS_REQUEST_VISITOR_LAST_NAME: 'Visitor Last Name',
    VMS_REQUEST_VISITOR_LAST_NAME_REQUIRED: 'Please input visitor last name',
    VMS_REQUEST_VISITOR_FIRST_NAME: 'Visitor First Name',
    VMS_REQUEST_VISITOR_FIRST_NAME_REQUIRED: 'Please input visitor first name',
    VMS_REQUEST_VISITOR_MOBILE: 'Visitor Mobile',
    VMS_REQUEST_VISITOR_MOBILE_REQUIRED: 'Please input visitor mobile',
    VMS_REQUEST_VISITOR_EMAIL: 'Visitor Email',
    VMS_REQUEST_VISITOR_EMAIL_REQUIRED: 'Please input visitor email',
    VMS_REQUEST_VISITOR_COMPANY: 'Visitor Company',
    VMS_REQUEST_VISITOR_COMPANY_REQUIRED: 'Please input visitor company',
    VMS_REQUEST_VISITOR_ID_TYPE: 'ID Type',
    VMS_REQUEST_VISITOR_ID_NUMBER: 'ID Number',
    VMS_REQUEST_VISITOR_ID_NUMBER_REQUIRED: 'Please input ID number',
    VMS_REQUEST_VISITOR_TYPE: 'Visitor Type',
    VMS_REQUEST_VISITOR_JOB: 'Job',
    VMS_REQUEST_VISITOR_DRIVE_TYPE: 'Drive Type',
    VMS_REQUEST_VISITOR_PLATE_NUMBER: 'Plate Number',
    VMS_REQUEST_VISITOR_PLATE_NUMBER_COLOR: 'Plate Color',
    VMS_REQUEST_VISITOR_VEHICLE_USE_TYPE: 'Vehicle Use Type',
    VMS_REQUEST_VISITOR_VEHICLE_TYPE: 'Vehicle Type',
    VMS_REQUEST_VISITOR_ACCESS_INFO: 'Access Info',
    VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER: 'Access Card Number',
    VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER_PLACEHOLDER: 'Please input access card number',
    VMS_REQUEST_VISITOR_LAST_INFO_RELOAD: 'Visitor information loading',
    VMS_REQUEST_VISITOR_LAST_INFO_RELOAD_SUCCESS: 'Visitor information load successful',
    VMS_REQUEST_VISITOR_LAST_INFO_RELOAD_CONFIRM: 'This visitor has a history record. Do you need to load the latest information of this visitor?',
    VMS_REQUEST_VISITOR_EMPLOYEE_EMAIL: "Employee Email",

    // 访问场所
    VMS_REQUEST_PLACE_LIST: "Visit Place List",
    VMS_REQUEST_PLACE_AREA_NAME: "Area Name",
    VMS_REQUEST_PLACE_AREA_CODE: "Area Code",
    VMS_REQUEST_PLACE_AREA_MANAGER_NAME: "Manager Name",
    VMS_REQUEST_PLACE_AREA_MANAGER_EMAIL: "Manager Email",

    // 访客访问记录
    VMS_VISITOR_ACCESS_LOG_ACCESS_DATE: 'Access Date',
    VMS_VISITOR_ACCESS_LOG_ACCESS_TIME: 'Access Time',
    VMS_VISITOR_ACCESS_LOG_ACCESS_READER: 'Access Reader',
    VMS_VISITOR_ACCESS_LOG_ACCESS_CARD_NUMBER: 'Access Card Number',
    VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION: 'Access Direction',
    VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKIN: 'Checkin',
    VMS_VISITOR_ACCESS_LOG_ACCESS_DIRECTION_CHECKOUT: 'Checkout',
    VMS_VISITOR_ACCESS_LOG_LIST: "Visitor access records",
    VMS_VISITOR_ACCESS_LOG_LIST_FETCH: "Fetch Visitor Access Log",

    // 访客卡管理
    VMS_CARD_BUTTON_SHOW_FAILED_ONLY: "Show Failed",
    VMS_CARD_SEND_CARD: "Assign pass cards",
    VMS_CARD_IMPORT: "Import Visitor Card",
    VMS_CARD_NUMBER: "Card Number",
    VMS_CARD_STATUS: "Card Status",
    VMS_CARD_STATUS_AVAILABLE: "Available",
    VMS_CARD_STATUS_BINDING: "Binding",
    VMS_CARD_CLASSIFY: "Classify",
    VMS_CARD_CLASSIFY_PERSON: "Person Card",
    VMS_CARD_CLASSIFY_VEHICLE: "Vehicle Card",
    VMS_CARD_TERMINAL: "Terminal",
    VMS_CARD_NUMBER_DUPLICATE: "Duplicate Card number",
    VMS_CARD_NUMBER_REQUIRED: "Card number is required",
    VMS_CARD_STATUS_REQUIRED: "Card status is required",
    VMS_CARD_CLASSIFY_REQUIRED: "Card classify is required",
    VMS_CARD_AVAILABLE_CARD_NO_FOUND: "Available visitor card not found",
    VMS_CARD_SEND_CARD_NOTICE: "Please assign the following card number to the visitor, or to regain the card number, click the Refresh button",
    VMS_CARD_SEND_CARD_FAILED: "Failed to assign card numbers, contact your administrator to confirm that the card pool data is correct.",

    // 访客黑名单管理
    VMS_VISITOR_BLACK_LIST_ADD: "Add Visitor Blacklist",
    VMS_VISITOR_BLACK_LIST_ADD_SUCCESS: "Add Visitor Blacklist Successful.",
    VMS_VISITOR_BLACK_LIST_MODIFY: "Modify Visitor Blacklist",
    VMS_VISITOR_BLACK_LIST_MODIFY_SUCCESS: "Modify Visitor Blacklist Successful.",
    VMS_VISITOR_BLACK_LIST: "Visitor Black List",
    VMS_VISITOR_FIRST_NAME: "First Name",
    VMS_VISITOR_FIRST_NAME_REQUIRED: "First name is required",
    VMS_VISITOR_LAST_NAME: "Last Name",
    VMS_VISITOR_LAST_NAME_REQUIRED: "Last name is required",
    VMS_VISITOR_NAME: "Name",
    VMS_VISITOR_EMAIL: "Email",
    VMS_VISITOR_MOBILE: "Mobile",
    VMS_VISITOR_PHOTO: "Photo",
    VMS_VISITOR_COMPANY: "Company",
    VMS_VISITOR_ID_TYPE: "IdType",
    VMS_VISITOR_ID_NUMBER: "IdNumber",
    VMS_VISITOR_ID_NUMBER_REQUIRED: 'Please input ID number',
    VMS_VISITOR_BLACK_LIST_REGISTERED_WARNING: 'The visitor is registered on the blacklist. Are you sure you want to continue registering?',

    // 访客自助终端管理
    VMS_TERMINAL_DEVICE_LIST: "Device List",
    VMS_TERMINAL_CODE: "Code",
    VMS_TERMINAL_NAME: "Name",
    VMS_TERMINAL_CODE_REQUIRED: "Terminal code is required.",
    VMS_TERMINAL_CODE_DUPLICATE: "Terminal code is duplicate",

    VMS_VISITOR_BTN_CHECKOUT:"Checkout",
    VMS_VISITOR_MSG_CHECKOUT_CONFIRM: "Please confirm checkout the visitor?",

};

