import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {VisitorBlackListDto} from "../../../../../modules/vms/dto/VisitorBlackListDto";
import {VisitorBlackListApi} from "../../../../../modules/vms/api/VisitorBlackListApi";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";
import FormFieldText from "../../../../../components/FormFieldText";
import FormFieldDictItems from "../../../../dict/components/FormFieldDictItems";

export type VisitorBlackDetailDialogProps = {
    idTypes: Array<DictItemDto>;
    open: boolean;
    visitorBlack: VisitorBlackListDto;
    onClose: () => void;
    onOk: () => void;
};

const VisitorBlackDetailDialog: React.FC<VisitorBlackDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.visitorBlack.id ? 'VMS_VISITOR_BLACK_LIST_MODIFY' : 'VMS_VISITOR_BLACK_LIST_ADD';

    const [innerVisitorBlack, setInnerVisitorBlack] = useState<VisitorBlackListDto>(props.visitorBlack);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerVisitorBlack({...props.visitorBlack});
    }, [props.visitorBlack]);

    const handleChange = (name: string, value: any) => {
        setInnerVisitorBlack(previousVisitorBlack => ({...previousVisitorBlack, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.visitorBlack.id) {
                const responseResult = await VisitorBlackListApi.updateVisitorBlack(innerVisitorBlack);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_VISITOR_BLACK_LIST_MODIFY_SUCCESS'}));
                    handleCancel();
                    props.onOk();
                }
            } else {
                const responseResult = await VisitorBlackListApi.createVisitorBlack(innerVisitorBlack);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_VISITOR_BLACK_LIST_ADD_SUCCESS'}));
                    handleCancel();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        setMessageMap(new Map<string, string>());
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();

        if (innerVisitorBlack.lastName === '') {
            newMessageMap.set('lastName', intl.formatMessage({id: 'VMS_VISITOR_LAST_NAME_REQUIRED'}));
        }

        if (innerVisitorBlack.firstName === '') {
            newMessageMap.set('firstName', intl.formatMessage({id: 'VMS_VISITOR_FIRST_NAME_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_LAST_NAME"})}
                               required={true} errorText={messageMap.get("lastName")}
                               value={innerVisitorBlack.lastName}
                               onChange={(value) => {
                                   handleChange("lastName", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_FIRST_NAME"})}
                               required={true} errorText={messageMap.get("firstName")}
                               value={innerVisitorBlack.firstName}
                               onChange={(value) => {
                                   handleChange("firstName", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_EMAIL"})}
                               value={innerVisitorBlack.email}
                               onChange={(value) => {
                                   handleChange("email", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_MOBILE"})}
                               value={innerVisitorBlack.mobile}
                               onChange={(value) => {
                                   handleChange("mobile", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_COMPANY"})}
                               value={innerVisitorBlack.company}
                               onChange={(value) => {
                                   handleChange("company", value)
                               }}/>
                <FormFieldDictItems label={intl.formatMessage({id: "VMS_VISITOR_ID_TYPE"})}
                                    value={innerVisitorBlack.idType}
                                    onChange={(value) => {
                                        handleChange("idType", value)
                                    }} dictItems={props.idTypes}/>
                <FormFieldText label={intl.formatMessage({id: "VMS_VISITOR_ID_NUMBER"})}
                               value={innerVisitorBlack.idNumber}
                               onChange={(value) => {
                                   handleChange("idNumber", value)
                               }}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default VisitorBlackDetailDialog;
