export const Gender = {
    Map: new Map<string, string>([
        ['00', 'ACCOUNT_USER_GENDER_NONE'],
        ['01', 'ACCOUNT_USER_GENDER_MALE'],
        ['02', 'ACCOUNT_USER_GENDER_FEMALE'],
    ]),
    Enum: {
        None: "00",
        Male: "01",
        Female: '02',
    }
};
