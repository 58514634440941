import React from 'react';
import {MenuItem, TextField} from "@mui/material";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";

type FormFieldDictItemsProps = {
    label: string;
    value: string;
    required?: boolean;
    errorText?: string;
    onChange: (value: string) => void;
    dictItems: Array<DictItemDto>
};

const FormFieldDictItems: React.FC<FormFieldDictItemsProps> = (props) => {

    return (
        <TextField
            select
            label={props.label}
            required={props.required}
            error={props.errorText !== undefined && props.errorText !== ''}
            helperText={props.errorText}
            fullWidth
            variant="standard"
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            value={props.value} onChange={(e) => {
            props.onChange(e.target.value)
        }}>
            {props.dictItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};
export default FormFieldDictItems;