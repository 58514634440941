import React from 'react';
import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";
import {RoleDto} from "../dto/RoleDto";

export const RoleApi = {

    getRoleList: async (appCode: string) => {
        try {
            const params = new Map<string, string>();
            params.set('appCode', appCode);
            const requestUrl = HttpUtils.addQueryString('/v1/account/role/list', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    // getRoleDetail: async (id: string) => {
    //     try {
    //         const params = new Map<string, string>();
    //         params.set('id', id);
    //         const requestUrl = HttpUtils.addQueryString('/v1/account/role/detail', params);
    //         console.log(requestUrl);
    //         const axiosResponse = await AxiosRequest.get(requestUrl);
    //         return axiosResponse.data;
    //     } catch (error: any) {
    //         console.error(error);
    //         return error.response.data;
    //     }
    // },

    createRole: async (body: RoleDto) => {
        try {
            const requestUrl = '/v1/account/role/create';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    updateRole: async (body: RoleDto) => {
        try {
            const requestUrl = '/v1/account/role/update';
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    deleteRole: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/account/role/delete', params);
            const axiosResponse = await AxiosRequest.post(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    getRoleMenus: async (roleId: string) => {
        try {
            const params = new Map<string, string>();
            params.set('roleId', roleId);
            const requestUrl = HttpUtils.addQueryString('/v1/account/role/menus', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },

    syncRoleMenus: async (roleId: string, body: Array<string>) => {
        try {
            const params = new Map<string, string>();
            params.set('roleId', roleId);
            const requestUrl = HttpUtils.addQueryString('/v1/account/role/menus/sync', params);
            const axiosResponse = await AxiosRequest.post(requestUrl, body);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
