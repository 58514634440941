import React, {useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import CancelButton from "../../../../components/buttons/CancelButton";
import FormFieldText from "../../../../components/FormFieldText";
import SubmitButton from "../../../../components/buttons/SubmitButton";

export type RejectDialogProps = {
    onCancel: () => void;
    onOk: (value: string) => void;
    open: boolean;
};

const RejectDialog: React.FC<RejectDialogProps> = (props) => {
    const intl = useIntl();

    const [remarks, setRemarks] = useState('');

    const handleOk = () => {
        if (remarks) {
            props.onOk(remarks);
        }
    };

    const handleCancel = () => {
        props.onCancel();
    }

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                <FormattedMessage id={'WORKFLOW_PROCESS_REJECT_REMARKS'}/>
            </DialogTitle>
            <DialogContent>
                <FormFieldText label={intl.formatMessage({id: "WORKFLOW_PROCESS_REJECT_REMARKS_REQUIRED"})}
                               required={true}
                               value={remarks}
                               onChange={(value) => {
                                   setRemarks(value)
                               }}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SubmitButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default RejectDialog;