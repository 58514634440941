import common from '../modules/common/locales/en-US';
import log from "../modules/log/locales/en-US";
import dict from "../modules/dict/locales/en-US";
import account from '../modules/account/locales/en-US';
import workflow from '../modules/workflow/locales/en-US';
import cms from "../modules/cms/locales/en-US";
import vms from "../modules/vms/locales/en-US";

const en_US = {
    ...common,
    ...log,
    ...dict,
    ...account,
    ...workflow,
    ...cms,
    ...vms,
}

export default en_US;