import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {ResourceMenuDto} from "../../../../../modules/account/dto/ResourceMenuDto";
import {ResourceMenuApi} from "../../../../../modules/account/api/ResourceMenuApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {
    Checkbox,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from "@mui/material";
import FormFieldText from "../../../../../components/FormFieldText";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";
import {ResourceAppApi} from "../../../../../modules/account/api/ResourceAppApi";
import {ResourceActionDto} from "../../../../../modules/account/dto/ResourceActionDto";

export type AppMenuActionDialogProps = {
    open: boolean;
    appId: string;
    menuId: string;
    onClose: () => void;
};

const AppMenuActionDialog: React.FC<AppMenuActionDialogProps> = (props) => {
    const intl = useIntl();

    const [appActions, setAppActions] = useState<Array<ResourceActionDto>>([]);

    const [selectedActionIds, setSelectedActionIds] = useState<Array<string>>([]);

    useEffect(() => {
        if (props.appId) {
            fetchAppActions(props.appId);
        }
    }, [props.appId]);

    useEffect(() => {
        if (props.menuId) {
            fetchMenuActions(props.menuId);
        }
    }, [props.menuId]);

    const fetchAppActions = async (appId: string) => {
        const responseResult = await ResourceAppApi.getResourceAppActions(appId);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            setAppActions(responseResult.data);
        }
    };

    const fetchMenuActions = async (menuId: string) => {
        const responseResult = await ResourceMenuApi.getResourceMenuActions(menuId);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            const actionIds = [];
            for (let item of responseResult.data) {
                actionIds.push(item.id);
            }
            setSelectedActionIds(actionIds);
        }
    };

    const handleOk = async () => {
        const responseResult = await ResourceMenuApi.syncMenuActions(props.menuId, selectedActionIds);
        if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
            MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_RESOURCE_MENU_ACTIONS_BIND_SUCCESS'}))
            props.onClose();
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleToggleCheck = (actionId: string) => {
        const tempSelectActionIds = Object.assign([], selectedActionIds);
        if (tempSelectActionIds.includes(actionId)) {
            tempSelectActionIds.splice(tempSelectActionIds.indexOf(actionId), 1);
        } else {
            tempSelectActionIds.push(actionId);
        }

        setSelectedActionIds(tempSelectActionIds);
    }

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                <FormattedMessage id={'ACCOUNT_RESOURCE_MENU_ACTIONS_BIND'}/>
            </DialogTitle>
            <DialogContent>
                <List component="div" disablePadding>
                    {
                        appActions.map((item, index) => {
                            return (
                                <ListItemButton key={index} role={undefined} onClick={() => handleToggleCheck(item.id)}
                                                dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedActionIds.includes(item.id)}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={item.path}/>
                                </ListItemButton>
                            )
                        })
                    }

                </List>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppMenuActionDialog;
