import React from 'react';
import {Button} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {FormattedMessage} from "react-intl";

type SearchIconButtonProps = {
    onClick: () => void;
};

const SearchButton: React.FC<SearchIconButtonProps> = (props) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <Button
            variant="contained"
            size="small"
            // @ts-ignore
            color={props.color || "primary"}
            startIcon={<SearchIcon/>}
            onClick={(e) => handleClick(e)}>
            <FormattedMessage id={'COMMON_SEARCH'}/>
        </Button>
    );
};
export default SearchButton;