export const Language = {
    Map: new Map<string, string>([
        ['zh-CN', 'COMMON_LANGUAGE_ZH_CN'],
        ['en-US', 'COMMON_LANGUAGE_EN_US'],
    ]),
    Enum: {
        zhCN: 'zh-CN',
        enUS: 'en-US',
    },
    List: [{value: 'zh-CN', label: 'COMMON_LANGUAGE_ZH_CN'}, {value: 'en-US', label: 'COMMON_LANGUAGE_EN_US'}]
};