import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {ResourceAppDto} from "../../../../../modules/account/dto/ResourceAppDto";
import {ResourceAppApi} from "../../../../../modules/account/api/ResourceAppApi";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import FormFieldText from "../../../../../components/FormFieldText";
import CancelButton from "../../../../../components/buttons/CancelButton";
import SaveButton from "../../../../../components/buttons/SaveButton";

export type AppDetailDialogProps = {
    open: boolean;
    resourceApp: ResourceAppDto;
    onClose: () => void;
    onOk: () => void;
};

const AppDetailDialog: React.FC<AppDetailDialogProps> = (props) => {
    const intl = useIntl();
    const title = props.resourceApp.id ? 'ACCOUNT_RESOURCE_APP_MODIFY' : 'ACCOUNT_RESOURCE_APP_CREATE';

    const [innerResourceApp, setInnerResourceApp] = useState<ResourceAppDto>(props.resourceApp);

    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    useEffect(() => {
        setInnerResourceApp({...props.resourceApp});
    }, [props.resourceApp]);

    const handleChange = (name: string, value: any) => {
        setInnerResourceApp(previousSite => ({...previousSite, [name]: value}));
    };

    const handleOk = async () => {
        if (validate()) {
            if (props.resourceApp.id) {
                const responseResult = await ResourceAppApi.updateResourceApp(innerResourceApp);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_MODIFY_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            } else {
                const responseResult = await ResourceAppApi.createResourceApp(innerResourceApp);
                if (responseResult.status === HttpStatus.SUCCESS) {
                    MessageUtils.success(intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_CREATE_SUCCESS'}));
                    props.onClose();
                    props.onOk();
                }
            }
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        if (innerResourceApp.code === '') {
            newMessageMap.set('code', intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_CODE_REQUIRED'}));
        }
        if (innerResourceApp.nameZh === '') {
            newMessageMap.set('nameZh', intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_NAME_ZH_REQUIRED'}));
        }
        if (innerResourceApp.nameEn === '') {
            newMessageMap.set('nameEn', intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_NAME_EN_REQUIRED'}));
        }
        if (innerResourceApp.path === '') {
            newMessageMap.set('path', intl.formatMessage({id: 'ACCOUNT_RESOURCE_APP_PATH_REQUIRED'}));
        }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    return (
        <Dialog open={props.open} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>
                {intl.formatMessage({id: title})}
            </DialogTitle>
            <DialogContent>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_CODE"})}
                               required={true} errorText={messageMap.get("code")}
                               value={innerResourceApp.code}
                               onChange={(value) => {
                                   handleChange("code", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_NAME_ZH"})}
                               required={true} errorText={messageMap.get("nameZh")}
                               value={innerResourceApp.nameZh}
                               onChange={(value) => {
                                   handleChange("nameZh", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_NAME_EN"})}
                               required={true} errorText={messageMap.get("nameEn")}
                               value={innerResourceApp.nameEn}
                               onChange={(value) => {
                                   handleChange("nameEn", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_PATH"})}
                               required={true} errorText={messageMap.get("path")}
                               value={innerResourceApp.path}
                               onChange={(value) => {
                                   handleChange("path", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_ICON"})}
                               value={innerResourceApp.icon}
                               onChange={(value) => {
                                   handleChange("icon", value)
                               }}/>
                <FormFieldText label={intl.formatMessage({id: "ACCOUNT_RESOURCE_APP_SEQUENCE"})}
                               type="number"
                               value={innerResourceApp.sequence}
                               onChange={(value) => {
                                   handleChange("sequence", value)
                               }}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}/>
                <SaveButton onClick={handleOk}/>
            </DialogActions>
        </Dialog>
    );
};

export default AppDetailDialog;
