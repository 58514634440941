import {AxiosRequest, HttpUtils} from "../../../utils/HttpUtils";

export const ApplyFormApi = {

    getApplyFormDetail: async (id: string) => {
        try {
            const params = new Map<string, string>();
            params.set('id', id);
            const requestUrl = HttpUtils.addQueryString('/v1/workflow/apply/form/detail', params);
            const axiosResponse = await AxiosRequest.get(requestUrl);
            return axiosResponse.data;
        } catch (error: any) {
            console.error(error);
            return error.response.data;
        }
    },
};
