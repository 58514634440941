import React from 'react';
import {FormattedMessage} from "react-intl";

export type EnumItemLabelProps = {
    value: string;
    map: Map<string, string>;
};

const EnumItemLabel: React.FC<EnumItemLabelProps> = (props) => {
    return (
        <span>
            {
                props.map.has(props.value) ?
                    <FormattedMessage id={props.map.get(props.value)}/> : ''
            }
        </span>
    );
};
export default EnumItemLabel;