export class ResourceMenuDto {
    keyId: string = '';
    id: string = '';
    appId: string = '';
    parent?: ResourceMenuDto;
    parentId: string = '';
    code: string = '';
    nameZh: string = '';
    nameEn: string = '';
    path: string = '';
    sequence: number = 0;
    icon: string = '';
    children?: Array<ResourceMenuDto>;

    constructor() {
    }
}