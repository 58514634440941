import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import dayjs from "dayjs";
import {VisitRequestDto} from "../../../modules/vms/dto/VisitRequestDto";
import {DictItemDto} from "../../../modules/dict/dto/DictItemDto";
import {StatusType} from "../../../modules/vms/enums/StatusType";
import {Formats} from "../../../constants/Formats";
import DictItemUtils from "../../../modules/dict/utils/DictItemUtils";
import EnumItemLabel from "../../../components/EnumItemLabel";
import {ApplySourceType} from "../../../modules/vms/enums/ApplySourceType";
import Descriptions from "../../../components/Descriptions";
import DescriptionItem from "../../../components/DescriptionItem";
import {Card, CardContent, CardHeader, Link, TableCell, TableRow} from "@mui/material";
import {RequestVisitorDto} from "../../../modules/vms/dto/RequestVisitorDto";
import {VisitorAccessLogDto} from "../../../modules/vms/dto/VisitorAccessLogDto";
import {RequestVisitorApi} from "../../../modules/vms/api/RequestVisitorApi";
import {HttpStatus} from "../../../modules/common/enums/HttpStatus";
import {FileApi} from "../../../modules/ftp/api/FileApi";
import VisitRequestDetailDialog from "./VisitRequestDetailDialog";
import {FileDto} from "../../../modules/ftp/dto/FileDto";

export type VisitRequestBasicProps = {
    visitRequest: VisitRequestDto;

    requestVisitor: RequestVisitorDto;
    purposeTypes: Array<DictItemDto>
};

const VisitorRequestInfo: React.FC<VisitRequestBasicProps> = (props) => {
    const intl = useIntl();
    const [consentLetterFile, setConsentLetterFile] = useState(new FileDto());
    const [openConsentLetterModal, setOpenConsentLetterModal] = useState(false);


    const fetchConsentLetter = async () => {
       await FileApi.downloadFile(props.requestVisitor.consentLetterFileId,intl.formatMessage({id: 'VMS_VISIT_REQUEST_REGISTER_STEP_CONSENT_LETTER'}));
        // if (responseResult.status === HttpStatus.SUCCESS) {
        //     // setConsentLetterFile(responseResult.data);
        //     // setOpenConsentLetterModal(true);
        // }
    };
    const checkOutLog = props.requestVisitor.checkOutLog && Object.assign(new VisitorAccessLogDto(), props.requestVisitor.checkOutLog);
    const checkInLog = props.requestVisitor.checkInLog && Object.assign(new VisitorAccessLogDto(), props.requestVisitor.checkInLog);
    const placeString = props. visitRequest?.places.map(item=>item.areaName).join(",");
    return (
        <Card>

            <CardHeader title={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_VISIT_REQUEST_INFO'})}/>
            <CardContent>
                <Descriptions>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_NUMBER'})}>
                        {props.visitRequest.number}
                    </DescriptionItem>

                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_ARRIVE_DATE'})}>
                        {dayjs(props.visitRequest?.arriveStartDate ?? '', Formats.DATE_SIMPLE).format(Formats.DATE_HYPHEN)}
                    </DescriptionItem>

                    {/*<DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_PURPOSE_TYPE'})}>*/}
                    {/*    {DictItemUtils.getText(props.purposeTypes, props.visitRequest.purposeType)}*/}
                    {/*</DescriptionItem>*/}
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_PURPOSE'})}>
                        {props.visitRequest.purpose}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_PLACE'})}>
                        {placeString}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_ACCESS_CARD_NUMBER'})}>
                        {props.requestVisitor.accessCardNumber}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_CHECKIN_TIME'})}>
                        {checkInLog?.fetchAccessDateTime()}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_REQUEST_VISITOR_CHECKOUT_TIME'})}>
                      {checkOutLog?.fetchAccessDateTime()}
                    </DescriptionItem>
                    <DescriptionItem label={intl.formatMessage({id: 'VMS_VISIT_REQUEST_REGISTER_STEP_CONSENT_LETTER'})}>
                        <Link href={'javascript:void(0)'} onClick={fetchConsentLetter}><FormattedMessage
                            id={'VMS_VISIT_REQUEST_REGISTER_STEP_CONSENT_LETTER'}/></Link>
                    </DescriptionItem>

                </Descriptions>
            </CardContent>
        </Card>
    );
};

export default VisitorRequestInfo;
