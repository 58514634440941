import React, {useEffect, useState} from 'react';
import {Alert, Grid} from "@mui/material";
import PreviousNextButton from "../../../../../components/PreviousNextButton";
import {FormattedMessage, useIntl} from "react-intl";
import AreaSelectDialog from "./AreaSelectDialog";
import {VisitRequestDto} from "../../../../../modules/vms/dto/VisitRequestDto";
import {DictItemDto} from "../../../../../modules/dict/dto/DictItemDto";
import {HttpStatus} from "../../../../../modules/common/enums/HttpStatus";
import {VisitRequestApi} from "../../../../../modules/vms/api/VisitRequestApi";
import {AreaDto} from "../../../../../modules/vms/dto/AreaDto";
import {RequestPlaceDto} from "../../../../../modules/vms/dto/RequestPlaceDto";
import {useConfirm} from "material-ui-confirm";
import FormFieldText from "../../../../../components/FormFieldText";
import SearchIconButton from "../../../../../components/buttons/SearchIconButton";
import FormFieldSelect from "../../../../../components/FormFieldSelect";
import {MessageUtils} from "../../../../../utils/MessageUtils";
import {DictItemCode} from "../../../../../modules/vms/constants/DictItemCode";

export type RegisterHostFormProps = {
    visitRequest: VisitRequestDto;
    inviteProperties: Array<DictItemDto>;

    purposeTypes: Array<DictItemDto>;
    onChange: (name: string, value: any) => void;
    onNext: () => void;
    onPrevious: () => void;
    onLoadRequestHost: (requestHost: VisitRequestDto) => void;
    employeeEntityTypes: Array<DictItemDto>;
};
const RegisterHostForm: React.FC<RegisterHostFormProps> = (props) => {
    const intl = useIntl();
    const confirm = useConfirm();
    const [messageMap, setMessageMap] = useState(new Map<string, string>());

    // 场所选择的对话框
    const [areaSelectDialogOpen, setAreaSelectDialogOpen] = useState(false);
    const [hostEmailVisible, setHostEmailVisible] = useState(false);

    // 选择的场所显示字符串
    const [selectedPlaceStr, setSelectedPlaceStr] = useState('');
    useEffect(() => {
        for (let item of props.inviteProperties) {
            switch (item.code) {
                case DictItemCode.VMS_INVITE_PROPERTIES_HOST_EMAIL_FIELD_VISIBLE:
                    if (item.value === '1') {
                        setHostEmailVisible(true);
                    } else {
                        setHostEmailVisible(false);
                    }
                    break;

            }
        }
    }, [props.inviteProperties]);
    const fetchLastHostInfo = async (hostStaffNumber: string, hostEmail: string) => {
        if (hostStaffNumber || hostEmail) {
            const responseResult = await VisitRequestApi.getLastHostInfo(hostStaffNumber, hostEmail);
            if (responseResult && responseResult.status === HttpStatus.SUCCESS) {
                if (responseResult.data) {
                    MessageUtils.success(intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_LAST_INFO_RELOAD_SUCCESS'}));
                    props.onLoadRequestHost(responseResult.data);
                }
            }
        }
    };

    const handleNext = () => {
        if (validate()) {
            props.onNext();
        }
    }
    const handlePrevious = () => {
        props.onPrevious();
    }
    const handleChange = (name: string, value: any) => {
        props.onChange(name, value);
    };

    const validate = () => {
        const newMessageMap = new Map<string, string>();
        // if (props.visitRequest.hostLastName === '') {
        //     newMessageMap.set('hostLastName', intl.formatMessage({id: 'VMS_REQUEST_VISITOR_NAME_REQUIRED'}));
        // }
        // if (props.visitRequest.hostFirstName === '') {
        //     newMessageMap.set('hostFirstName', intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_FIRST_NAME_REQUIRED'}));
        // }
        // if (props.visitRequest.hostMobile === '') {
        //     newMessageMap.set('hostMobile', intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_MOBILE_REQUIRED'}));
        // } else if (!ValidateUtils.isMobileNumber(props.visitRequest.hostMobile)) {
        //     newMessageMap.set('hostMobile', intl.formatMessage({id: 'COMMON_MOBILE_FORMAT'}));
        // }
        //
        // if (props.visitRequest.hostEmail === '') {
        //     newMessageMap.set('hostEmail', intl.formatMessage({id: 'VMS_VISIT_REQUEST_HOST_EMAIL_REQUIRED'}));
        // } else if (!ValidateUtils.isEmail(props.visitRequest.hostEmail)) {
        //     newMessageMap.set('hostEmail', intl.formatMessage({id: 'COMMON_EMAIL_FORMAT'}));
        // }

        // if (props.visitRequest.places.length <= 0) {
        //     newMessageMap.set('places', intl.formatMessage({id: 'VMS_VISIT_REQUEST_PLACES_REQUIRED'}));
        // }

        setMessageMap(newMessageMap);
        return newMessageMap.size <= 0;
    };

    const handleSelectAreas = (areas: Array<AreaDto>) => {
        const places = new Array<RequestPlaceDto>();
        for (let area of areas) {
            const place = new RequestPlaceDto(area);
            places.push(place);
        }
        handleChange("places", places);
        const selectedPlaceStr = areas.map((area) => {
            return area.name
        }).join(',')

        setSelectedPlaceStr(selectedPlaceStr);
        setAreaSelectDialogOpen(false);
    }

    return (
        <div>
            <AreaSelectDialog open={areaSelectDialogOpen} onCancel={() => setAreaSelectDialogOpen(false)}
                              onOk={handleSelectAreas}/>
            <Alert severity={"warning"}>
                <FormattedMessage id={'VMS_VISIT_REQUEST_REGISTER_STEP_HOST_INFO_ALERT'}/>
            </Alert>
            <Grid container spacing={2} style={{height: '60vh'}}>
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_STAFF_NUMBER"})}
                                   placeholder={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_STAFF_NUMBER_REQUIRED"})}
                                   value={props.visitRequest.hostStaffNumber}
                                   errorText={messageMap.get("hostStaffNumber")}
                                   onChange={(value) => {
                                       handleChange("hostStaffNumber", value)
                                   }}
                                   endAdornment={<SearchIconButton
                                       onClick={() => fetchLastHostInfo(props.visitRequest.hostStaffNumber, '')}/>}/>
                </Grid>
                {hostEmailVisible ? <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_EMAIL"})}
                                   placeholder={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_EMAIL_REQUIRED"})}
                                   value={props.visitRequest.hostEmail}
                                   type={"email"}
                                   errorText={messageMap.get("hostEmail")}
                                   onChange={(value) => {
                                       handleChange("hostEmail", value)
                                   }}
                                   endAdornment={<SearchIconButton
                                       onClick={() => fetchLastHostInfo('', props.visitRequest.hostEmail)}/>}/>
                </Grid> : null}
                <Grid item xs={6}>
                    <FormFieldSelect label={intl.formatMessage({id: "VMS_VISIT_REQUEST_EMPLOYEE_ENTITY"})}
                                     errorText={messageMap.get("hostCompanyName")}
                                     value={props.visitRequest.hostCompanyName}
                                     onChange={(value) => {
                                         handleChange("hostCompanyName", value)
                                     }}
                                     items={props.employeeEntityTypes.map((item) => {
                                         return {label: item.name, value: item.value}
                                     })}/>
                </Grid>
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_LAST_NAME"})}
                                   placeholder={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_LAST_NAME_REQUIRED"})}
                                   value={props.visitRequest.hostLastName}
                                   errorText={messageMap.get("hostLastName")}
                                   onChange={(value) => {
                                       handleChange("hostLastName", value)
                                   }}/>
                </Grid>
                <Grid item xs={6}>
                    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_FIRST_NAME"})}
                                   placeholder={intl.formatMessage({id: "VMS_VISIT_REQUEST_HOST_FIRST_NAME_REQUIRED"})}
                                   value={props.visitRequest.hostFirstName}
                                   errorText={messageMap.get("hostFirstName")}
                                   onChange={(value) => {
                                       handleChange("hostFirstName", value)
                                   }}/>
                </Grid>


                {/*<Grid item xs={6}>*/}
                {/*    <FormFieldSelect label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PURPOSE_TYPE"})}*/}
                {/*                     required={true} errorText={messageMap.get("purposeType")}*/}
                {/*                     value={props.visitRequest.purposeType}*/}
                {/*                     onChange={(value) => {*/}
                {/*                         handleChange("purposeType", value)*/}
                {/*                     }}*/}
                {/*                     items={props.purposeTypes.map((item) => {*/}
                {/*                         return {label: item.name, value: item.value}*/}
                {/*                     })}/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={6}>*/}
                {/*    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PURPOSE"})}*/}
                {/*                   value={props.visitRequest.purpose}*/}
                {/*                   onChange={(value) => {*/}
                {/*                       handleChange("purpose", value)*/}
                {/*                   }}/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={6}>*/}
                {/*    <FormFieldDate label={intl.formatMessage({id: "VMS_VISIT_REQUEST_ARRIVE_DATE"})}*/}
                {/*                   required={true} errorText={messageMap.get("arriveStartDate")}*/}
                {/*                   value={dayjs(props.visitRequest.arriveStartDate, Formats.DATE_SIMPLE)}*/}
                {/*                   minDate={dayjs()}*/}
                {/*                   maxDate={dayjs().add(15, "days")}*/}
                {/*                   onChange={(value) => handleChange("arriveStartDate", value)}/>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={6}>*/}
                {/*    <FormFieldText label={intl.formatMessage({id: "VMS_VISIT_REQUEST_PLACE"})}*/}
                {/*                   disabled={true}*/}
                {/*                   required={true} errorText={messageMap.get("places")}*/}
                {/*                   value={selectedPlaceStr}*/}
                {/*                   endAdornment={*/}
                {/*                       <Badge badgeContent={props.visitRequest.places.length} color="secondary"*/}
                {/*                              overlap="rectangular">*/}
                {/*                           <Button color="primary"*/}
                {/*                                   onClick={() => setAreaSelectDialogOpen(true)}>*/}
                {/*                               <FormattedMessage id="VMS_VISIT_REQUEST_PLACE_SELECT"/>*/}
                {/*                           </Button>*/}
                {/*                       </Badge>}/>*/}
                {/*</Grid>*/}
            </Grid>
            <Grid>
                <PreviousNextButton onPrevious={handlePrevious} onNext={() => handleNext()}/>
            </Grid>
        </div>
    );
};

export default RegisterHostForm;
