import {DictItemDto} from "./DictItemDto";

export class DictClassDto {
    keyId: string = '';
    id: string = '';
    typeId: string = '';
    code: string = '';
    name: string = '';
    sequence: number = 0;
    commonFlag: number = 1;
    remarks: string = '';
    dictItems: Array<DictItemDto> = [];
}
